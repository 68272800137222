import React, { Component } from "react";

import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";

const bcrypt = require("bcryptjs");

class Close extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectTabs: 0,
            openModal: false,
            Sale_date : '',

      // ----- form3 Valid ----- //
        // Asset_Price_Valid: false, 
        // Promotion_price_Valid: false, 
        Sale_datetime_Valid: false, 
        // Promo_end_Valid: false, 

        formValid3: false, 
        formError3: {
        // Asset_Price: 0,
        // Promotion_price: 0,
        Sale_datetime: '',
        // Promo_end_date: '',
        },
        };
    }

    async ValidateField(fieldName, value) {
        let Sale_datetime_Valid = this.state.Sale_datetime_Valid;
        let fieldValidationErrors3 = this.state.formError3;
        // let consent_Valid = this.state.consent_Valid;
    
        switch(fieldName) {
        
          case 'Sale_datetime':
            if(parseFloat(this.state.Promotion_price) > 0){
              Sale_datetime_Valid = parseFloat(this.state.Promotion_price) > 0 && value && value != ''
              fieldValidationErrors3.Sale_datetime = Sale_datetime_Valid ? '' : 'โปรดระบุวันที่เริ่มต้น';
    
            }else {
              Sale_datetime_Valid = true
            }
            break;
    
        
    
        //   case 'consent': 
        //     consent_Valid = value;
        //     fieldValidationErrors5.consent = consent_Valid ? '' : 'โปรดกดยอมรับเงื่อนไขและข้อตกลง'
        //     break;
    
          default:
            break;
        }
    
 
        await this.setState({formError3: fieldValidationErrors3,
          Sale_datetime_Valid: Sale_datetime_Valid,
                                    }, await this.ValidateForm);
       }


       ValidateForm() {    
       this.setState({formValid3: this.state.Sale_datetime_Valid  });
      }
    

      async checkValid(){
        await this.ValidateField('Sale_datetime', this.state.Sale_datetime)
      }


    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.type != 'checkbox' ? e.target.value : e.target.checked ;
        this.setState({[name]: value},
                      () => { this.ValidateField(name, value) });
      }
 

    render() {
        const ValidStypes = {color: 'red', fontSize: '14px', marginBottom: "-5px"}
        
        return (
            <div>
                <div className="utf-welcome-text-item">
                    <h3>กรุณาเลือกประเภทการขาย <br /> </h3>
                    <span> ระบุลักษณะการขาย/วันที่ขาย /ราคาขาย</span>
                </div>
                {/* <input
            type="text"
            required
            className="form-control"
            name="emailLogin"
            autocomplete="off"
            placeholder="ราคาขาย"
            value={this.state.emailLogin}
            onChange={this.handleUserInput}
          /> */}
                <form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="disabledSelect">สถานะการขาย</label>
                                    <select id="disabledSelect" className="form-control">
                                        <option>ขายได้</option>
                                        <option>หมดอายุประกาศ</option>
                                        <option>งดขาย</option>
                                        <option>Disabled select</option>

                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="disabledTextInput">ราคาขาย</label>
                                    <input type="number" id="disabledTextInput" className="form-control" placeholder="ระบุราคาขาย" />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="disabledTextInput">วันที่ขาย</label>
                                    <DatePicker 
                                      name="Sale_datetime"
                                      // value={this.state.Sale_datetime}
                                      selected={this.state.Sale_datetime ? new Date(this.state.Sale_datetime) : ""} 
                                      placeholder="วันที่เริ่มต้น" 
                                      locale="th" 
                                      dateFormat="dd-MM-yyyy"
                                      onChange={e => {
                                        let date = new Date(e);
                                        let pyears = date.getFullYear();
                                        let pmonth = String(date.getMonth() + 1).padStart(2, '0');
                                        let pdays = String(date.getDate()).padStart(2, '0');
                                        const values = {target: {name: "Sale_datetime", value: pyears +"-" + pmonth + "-" + pdays}}
                                        this.handleUserInput(values)
                                    }}/>
                                    {
                                      this.state.formError3.Sale_datetime != '' ? <p style={ValidStypes}> {this.state.formError3.Sale_datetime} </p>  : null
                                    }
                                  
                                
                                </div>
                            </div>

                        </div>
                        <hr />
                        <center>
                             
                           

<button
          className={`margin-top-10 button full-width utf-button-sliding-icon ripple-effect  ${
            !this.state.formValid ? " btn btn-secondary" : ""
          }`}
          type="submit"
          onClick={async e=>{

            window.location.reload()
            if(this.state.formValid){
              let values = {
                email: this.state.emailLogin, 
                pass: this.state.passwordLogin}
              let result = await httpClient.post(server.MEMBER_URL + '/login', values)
              this.setState({isError: await result.result})

              if(result.result == 'ok'){
                  localStorage.setItem('Member_ID', result.data['Member_ID'])
                  localStorage.setItem('Member_Name', result.data['Member_Name'])
                  localStorage.setItem('Member_Picture', result.data['Member_Picture'])
                  localStorage.setItem('Member_Email', result.data['Member_Email'])
                  window.location.reload()
              }
            } else {
              alert("dfdfdf")
           
            }

            
          }}

        >
          ยืนยัน <i className="icon-feather-chevrons-right" />
        </button>

              
                            </center>
                   
                </form>

            </div>
        );
    }
}

export default Close;
