import React, { Component } from "react";
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import { encrytion, decryption } from './../../utils/EnCryption'
import queryString from 'query-string';
import parse from 'html-react-parser';
import blogComponent from './../blog/blog';

const blog = new blogComponent();

class BlogDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _data: null,
      _title: null,
      _Recently: null
    }
  }

  async componentDidMount() {
    let url = window.location.search
    let params = queryString.parse(url);
    let result = await httpClient.get(server.BLOG_URL + "/blogDetail/" + params['p'])
    let result2 = await httpClient.get(server.BLOG_URL + "/blog" )

    this.setState({ _data: await this.renderGroup(result.data) })
    this.setState({ _title: await result.data.title_name })
    this.setState({ _Recently: await blog.renderRecently(result2.data.slice(0,5))})
  }


  async renderGroup(result) {
     let item = result
    return (
        <div className="col-md-12" key={item.id}>
          <img src={item.images_blog} alt= {item.title_name} />
          <h3>{item.title_name}</h3>
          <p>{parse(item.detail_blog ? `${item.detail_blog}` : '')}</p>
          <hr />
        </div>
      
    )
  }

  render() {
    return (
      <div id="wrapper">

      <div className="parallax titlebar" lazyload="true" data-background="images/popular-location-03.jpg" data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
        <div id="titlebar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>บทความที่น่าสนใจ</h2>
                <nav id="breadcrumbs">
                  <ul>
                    <li><a href="index.html">หน้าแรก</a></li>
                    <li>บทความที่น่าสนใจ</li>
                    <li>{this.state._title}</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

<div className="container">

  <div className="row sticky-wrapper">
    <div className="col-md-8"> 
    <div className="row">
    <div className="col-md-12">
    <div className="utf-section-headline-item centered margin-bottom-0 margin-top-20">
          <h3 className="headline">{this.state._title}</h3> 
        <br /> 
      </div>  
    </div>
  </div>
      {/* Listings */}
      <div className="utf-listings-container-area list-layout"> 

        {/* Listing Item */}
        {this.state._data}
  
        {/* Listing Item / End */} 
       </div>
      {/* Listings Container / End */} 
      
    </div>
    {/* Sidebar */}
    <div className="col-md-4">
      <div className="sidebar"> 
        {/* Widget */}
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-detail-banner-add-section">
            <a href="#">
         <img src="images/banner/asset_right_banner.png" alt="banner-add-2" lazyload="true" />
         </a>
          </div>             
        </div>
    
    
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>Recently Viewed</h3>
          </div>
          {this.state._Recently}
       
        </div>
       
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>หมวดหมู่บทความ</h3>
            <div className="utf-sidebar-categorie"> 
              <ul>
                <li>ประมูลอสังหาฯ</li> 
                <li>กู้ซื้อบ้าน</li> 
                <li>โปรโมชั่น</li> 
                <li>คำแนะนำการซื้อบ้าน</li> 
                <li>Partner content</li> 
                <li>สารพันปัญหาน่ารู้</li>
                <li>เพื่อการลงทุน</li>
                <li>ฮวงจุ้ยที่อยู่อาศัย</li>
                <li>ตรวจสอบก่อนซื้อที่อยู่อาศัย</li>    
                        
              </ul>
            </div>
          </div>              
        </div>
        {/* Widget / End*/}
        {/* Widget */}
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>คำที่ค้นหาบ่อย</h3>
            <div className="task-tags"> 
              <a href="#"><span>Partner content</span></a> 
              <a href="#"><span>กู้ซื้อบ้าน</span></a> 
              <a href="#"><span>คำแนะนำ</span></a> 
              <a href="#"><span>ตรวจสอบก่อนซื้อ</span></a>  
              <a href="#"><span>บทความน่ารู้</span></a>     
              <a href="#"><span>ประมูลอสังหา</span></a> 
              <a href="#"><span>ฮวงจุ้ย</span></a>        
            </div>
          </div>              
        </div>
 
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>Social Sharing</h3>
          </div>
          <ul className="utf-social-icons rounded">
            <li><a className="facebook" href="#"><i className="icon-facebook" /></a></li>
            <li><a className="twitter" href="#"><i className="icon-twitter" /></a></li>
          </ul>
          <div className="clearfix" />
        </div>
        {/* Widget / End*/}                 
        <div className="clearfix" />  
      </div>
    </div>
    {/* Sidebar / End */} 
  </div>
</div>

  
        <div className="margin-top-65" />
        <div id="backtotop"><a href="#" /></div>
      </div>
    
    );
  }
}

export default BlogDetail;
