import {
    HTTP_SEARCHBOX_FETCHING,
    HTTP_SEARCHBOX_SUCCESS,
    HTTP_SEARCHBOX_FAILED
  } from "../constants";
  
  const initialState = {
    result: null,
    isFetching: false,
    isError: false
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_SEARCHBOX_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case HTTP_SEARCHBOX_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      case HTTP_SEARCHBOX_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
  
      default:
        return state;
    }
  };