import React, { Component } from "react";
import "./intropage.css";
import queryString from 'query-string';
import { httpClient } from "../../utils/HttpClient";
import { server, imageUrl } from "../../constants";

class Intropage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect_url: "", 
      intro_class: "intro-content",
      file_name: '', 
      start_date: ''
    }
  }

  async componentDidMount() {
    let url = window.location.search
    let params = queryString.parse(url)

    let result = await httpClient.get(server.INTROPAGE_URL)
    this.setState({file_name: imageUrl + '/' + result.data[0]['file_name'],
                   start_date: result.data[0]['start_date']})

    document.body.style.backgroundColor = result.data[0]['background_color']
    this.setState({redirect_url: params['redirect_url'] ? params['redirect_url']: '/home'
                   ,intro_class: window.innerWidth < 800 ? 'intro-content-small' : 'intro-content'})
  }

  render() {
    return (
        <div className={'text-center ' + this.state.intro_class}>
          {/* <center> */}
            <img src={this.state.file_name} />
            <div className="text-center margin-top-30">
              <button className="button utf-search-btn-item" onClick={() => {
                localStorage.setItem('accept_intro' + this.state.start_date, 'true')
                window.location.replace(this.state.redirect_url)
                document.body.style.backgroundColor = "#fff"
              }}>
                เข้าสู่เว็บไซต์
              </button>
            </div>
          {/* </center> */}
        </div>
    );
  }
}

export default Intropage;
