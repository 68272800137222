import React, { Component } from "react";
import Register from "./register";
import './header.css'

class Register_policy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display_register: 'none',
            display_policy: 'block',
            Policy: true,
            chkPolicy: 0,
        }
    }


    handleChecked = async (e) => {
        const checked = e.target.checked;
        if (checked) {
            let index = 1;
            await this.setState({ chkPolicy: index, Policy: false })
        } else {
            let index = 0;
            await this.setState({ chkPolicy: index, Policy: true })
        }
    }

    async settings() {
        this.setState({
            display_register: 'block',
            display_policy: 'none',
        })

    }

    render() {
        return (
            <div>
                <div id="register" style={{ display: this.state.display_register }}>
                    <Register />
                </div>

                <div id="policy" style={{ display: this.state.display_policy }}>
                    <h3 className="text-color-blue" style={{ magin: 5 }}>เงื่อนไขและข้อตกลง</h3>
                    <div className="utf-welcome-text-item scroll-box scroll-wrapper text-setting" style={{ display: 'block', height: '400px', overflowY: 'auto' }}>
                        &emsp; ศูนย์ข้อมูลอสังหาริมทรัพย์ ธนาคารอาคารสงเคราะห์ ได้จัดทำเว็บไซต์ www.clickthaihome.com ขึ้นมาเพื่อเป็นศูนย์กลางในการลงประกาศขาย / ซื้อ อสังหาริมทรัพย์ ซึ่งท่านสามารถเข้าไปเยี่ยมชมเว็บไซต์ของเราได้ตามต้องการ โดยไม่จำเป็นต้องให้ข้อมูลของตัวท่านเอง ทั้งนี้ ในบางกรณี เราอาจจำเป็นต้องใช้ข้อมูลของท่านเพื่อให้บริการในสิ่งที่ท่านต้องการ
                        <p><b>ข้อ 1. การรวบรวมข้อมูลส่วนบุคคล</b></p>

                        &emsp; ศูนย์ข้อมูลฯ จะทำการสอบถามข้อมูลส่วนตัวของท่าน เพื่อประโยชน์ในการลงประกาศซื้อ/ขาย ค้นหาทรัพย์และต้องการติดต่อกลับ กรณีมีผู้สนใจทรัพย์ที่ท่านประกาศขาย ดังนั้น ท่านจำเป็นจะต้องกรอกรายละเอียดข้อมูลส่วนบุคคลและรายละเอียดทรัพย์ก่อน จึงจะสามารถลงประกาศขาย / ซื้อ อสังหาริมทรัพย์ได้ ซึ่งข้อมูลส่วนบุคคลที่รวบรวมโดย www. clickthaihome.com นั้น จะมีทั้ง ชื่อ เบอร์โทรศัพท์ และอีเมล์ หลังจากที่ท่านได้ลงประกาศขายทรัพย์เรียบร้อยแล้ว ทางเจ้าหน้าที่ของ www.clickthaihome.com ก็จะตรวจความถูกต้องของข้อมูลก่อนนำขึ้นแสดงที่หน้าเว็บไซต์
                        จุดมุ่งหมายเบื้องต้นของ www.clickthaihome.com ในการรวบรวมข้อมูลส่วนบุคคล คือ ให้ผู้ใช้บริการเว็บไซต์ www.clickthaihome.com ที่สนใจจะขาย / ซื้อ อสังหาริมทรัพย์ที่ท่านได้ลงประกาศขายเอาไว้ สามารติดต่อท่านได้โดยตรง
                        ท่านสามารถเลือกที่จะไม่กรอกข้อมูลส่วนบุคคลของท่าน เมื่อท่านไม่ต้องการใช้บริการ ลงประกาศซื้อ–ประกาศขาย อสังหาริมทรัพย์บนเว็บไซต์ www.clickthaihome.com
                        <br />
                        <p><b>ข้อ 2. การใช้ระบบ Cookies เพื่อช่วยในการจดจำและบันทึกข้อมูล</b></p>
                        &emsp; เมื่อใดก็ตามที่มีคนเข้ามาเยี่ยมชมเว็บไซต์ ระบบ Cookies จะถูกป้อนเข้าในหน่วยความจำของคอมพิวเตอร์ลูกค้า (ในกรณีที่เครื่องสามารถรับ Cookies ได้) และจะทำการบันทึกไว้ว่ามีการเข้าเยี่ยมชมเว็บไซต์ ด้วยระบบนี้ เราจะสามารถบันทึกข้อมูลสถิติการเข้าชมเว็บไซต์ ดังที่กล่าวมา
                        <br />
                        <p><b> ข้อ 3. บริการ Tell-A-Friend หรือ "บอกแนะนำเพื่อน" คืออะไร</b></p>
                        &emsp; บริการ Tell-A-Friend เป็นส่วนหนึ่งของบริการของเว็บไซด์ www.clickthaihome.com ที่สนับสนุนให้เพื่อนของท่าน สามารถเข้าเยี่ยมชม เว็บเพจของศูนย์ข้อมูลฯ ได้ หากท่านพบว่าข้อมูลใดมีประโยชน์ และต้องการจะแจ้งให้เพื่อนของท่านทราบข้อมูลดังกล่าว ท่านต้องใส่ข้อมูลชื่อ และที่อยู่ไปรษณีย์อิเลคทรอนิคของเพื่อนท่าน บริการนี้จะส่งข้อมูลได้ครั้งละ 1 ข้อความเท่านั้น โดยท่านรับทราบว่าข้อมูลดังกล่าวจะไม่บันทึกอยู่ในคอมพิวเตอร์ของบริษัท และท่านสามารถส่งให้เพื่อนของท่าน ได้พร้อมๆ กัน ครั้งละหลายๆคน โดยใช้เครื่องหมาย ","
                        <br />
                        <p><b>ข้อ 4. การปรับปรุง/การลบ ข้อมูลส่วนบุคคลของท่านและการติดต่อกับบริษัท</b></p>
                        &emsp; ศูนย์ข้อมูลฯ ได้จัดให้ผู้ใช้บริการที่ได้ลงทะเบียนกับ www.clickthaihome.com สามารถเข้าถึงและปรับปรุง แก้ไข หรือลบข้อมูลส่วนบุคคลของท่านได้ตลอดเวลาโดยการใช้ข้อมูลประจำตัวของท่านและรหัสผ่าน หากท่านต้องการทราบ ข้อมูลเพิ่มเติมเกี่ยวกับการลบหรือยกเลิกแฟ้มรวบรวมไปรษณีย์อิเล็กทรอนิกส์ (E-Mail Account) สามารถติดต่อบริษัทได้ที่ clickthaihome@reic.or.th
                        <br />
                        <p><b>ข้อ 5. ข้อยกเว้นความรับผิด</b></p>
                        &emsp; ท่านรับทราบและตกลงว่าหากข้อมูลส่วนบุคคลหรือข้อมูลที่เกี่ยวข้องของท่าน ไม่ว่าจะเป็นข้อมูลทั่วไป หรือข้อมูลส่วนบุคคลได้ถูกจารกรรมทางคอมพิวเตอร์ สูญหาย หรือเสียหายซึ่งมิใช่ความผิดของศูนย์ข้อมูลฯ ศูนย์ข้อมูลฯ ขอสงวนสิทธิที่จะปฏิเสธความรับผิดใด ๆ ที่เป็นผลมาจากการดังกล่าว
                        <br />
                        <p>&emsp; ศูนย์ข้อมูลฯ อาจจะเปลี่ยนแปลงนโยบายการคุ้มครองข้อมูลส่วนบุคคลนี้ ได้ในทุกเวลาโดยปิดประกาศการ แก้ไขเปลี่ยนแปลงบนเว็บไซด์ www.clickthaihome.com การใช้บริการเว็บไซด์ของท่านถือเป็นการยอมรับข้อกำหนดของนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้และการที่ท่านใช้บริการอย่างต่อเนื่องภายหลังจากที่มีการประกาศเปลี่ยนแปลงดังกล่าว ย่อมถือเป็นการยอมรับการแก้ไขเปลี่ยนแปลงแต่ละครั้ง</p>
                    </div>

                    <div className="row">
                        <div className="col-md-1">
                            <input type="checkbox" name="chkPolicy" value={this.state.chkPolicy} onClick={this.handleChecked} />
                        </div>
                        <div className="col-md-11">
                            <p style={{ fontSize: '14px' }}>ข้าพเจ้ายืนยันว่าได้อ่าน, <span className="text-color-blue">นโยบายความเป็นส่วนตัว</span> และ 
                            <span className="text-color-blue">นโยบายการคุ้มครองข้อมูลส่วนบุคคล</span> เกี่ยวกับการสมัครสมาชิก 
                            <span className="text-color-blue"> "ClickThaiHome"</span> และยินดียอมรับเงื่อนไขข้างต้นแล้ว</p>
                        </div>

                
                        <div className="col-md-12">
                            <button
                                className="button col-md-12"
                                disabled={this.state.Policy}
                                onClick={() => { this.settings() }}
                            >
                                ดำเนินการต่อ
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Register_policy;