import axios from 'axios';
import join from 'url-join';
import {server, apiUrl, accessToken,NOT_CONNECT_NETWORK, NETWORK_CONNECTION_MESSAGE} from '../constants';
import {decryption, encrytion} from '../utils/EnCryption'


const isAbsoluteURLRegex = /^(?:\w+:)\/\// ;
const varToken = accessToken ;

axios.interceptors.request.use(async (config) => {
    if (!isAbsoluteURLRegex.test(config.url)) {
        config.url = join(apiUrl,config.url)
    }
    config.data = {value: await encrytion(config.data)}
    config.headers.common['Authorization'] = 'Bearer ' + varToken;
    config.timeout = 20000 // 10 Second
    return config
})


 axios.interceptors.response.use(async (response) =>  {
    //  console.log(response.data)
    response = await JSON.parse(decryption(response.data))
    return response;


}, error => {
    console.log(JSON.stringify(error, undefined, 2))
    if (axios.isCancel(error)) {
        return Promise.reject(error)
    } else if (!error.response) {
        return Promise.reject({code: NOT_CONNECT_NETWORK, message: NETWORK_CONNECTION_MESSAGE})
    } else
        return Promise.reject(error)
}) 

export const httpClient = axios;



