import React, { Component } from "react";
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import parse from 'html-react-parser';
import { encrytion, decryption } from './../../utils/EnCryption'
import Pagination from "react-js-pagination";
import Dotdotdot from 'react-dotdotdot'
import { currencyFormat } from "./../default-class/default-class";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _data: null,
      _Recently: null,
      listsAll: null,
      listsCount: 1
    }
  }

  async componentDidMount() {
    let result = await httpClient.get(server.BLOG_URL + "/blog")

    this.setState({listsAll: result.data, listsCount: result.data.length})
    // this.setState({ _data: await this.renderRows(result.data) })
    this.setState({ _Recently: await this.renderRecently(result.data.slice(0, 5)) })
 
    this.handlePageChange(1)

   
  }

  async handlePageChange(pageNumber) {
    await this.setState({activePage: pageNumber});
    let lists = null
    lists = await this.renderRows(this.state.listsAll.slice((pageNumber * 5) - 5, pageNumber * 5))
    this.setState({_data: lists})
  }

  async renderRecently(result) {
    return (
      result.map(item => (
<ul className="widget-tabs" key={item.id}>
  {/* Post #1 */}
  <li>
    <div className="widget-content">
      <div className="widget-thumb"> 
          <a href={"/blog_detail?p=" + encrytion(item.id)} target="_blank">
            <img src={item.images_blog} lazyload="true" />
          </a>
      </div>
      <div className="widget-text">
        <h5><a href= {"/blog_detail?p=" + encrytion(item.id)} target="_blank">{item.title_name}</a></h5>
        <span style={{ fontWeight: 100, margin: 0, fontSize: 13 }}>{item.date_blog.split('T')[0]}</span> 
      </div>
      <hr />
          <div className="clearfix" />
          
    </div>
  </li>
</ul>

      ))
    )
  }

  async renderRows(result) {
    return (
      result.map(item => (

        <div className="utf-listing-item" key={item.id}> 
        <a href={"/blog_detail?p=" + encrytion(item.id)} target="_blank" className="utf-smt-listing-img-container" style={{height: 215}}>
            {/* <div className="utf-listing-badges-item"> <span className="featured">คำแนะนำ</span>   </div> */}
        
            <img src={item.images_blog}  lazyload="true" alt={item.title_name} style={{height: 215}} /> 
        </a>
          <div className="utf-listing-content">
            <div className="utf-listing-title">
             <h3>
              <Dotdotdot clamp={ 2 }>
              <div>
              <a href={"/blog_detail?p=" + encrytion(item.id)} target="_blank" > {item.title_name.trim()} </a>
              </div>
               </Dotdotdot>               
              </h3>
            
              <span className="utf-listing-address"> 
              <Dotdotdot  clamp={ 3 } useNativeClamp={ true } truncationChar="&raquo;">
              <div>
              {this.removeHTML(item.detail_blog)}
              </div>
               </Dotdotdot>   
              
              </span>
            </div>
   
            <div className="utf-listing-user-info">
              <i className="icon-line-awesome-user" /> Admin
                <span>{item.date_blog.split('T')[0]}</span>
            </div>
          </div>
        </div>

 
      ))
    )
  }


  convertDate(datas) {
    datas = datas.split("T")[0]
    datas = datas.split("-")
    datas = datas[2] + "/" + datas[1] + "/" + datas[0]
    return datas
  }

  

    removeHTML(str){ 
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
    }
    
    
  render() {
    return (
      <div id="wrapper">

      <div className="parallax titlebar" lazyload="true" data-background="images/popular-location-03.jpg" data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
        <div id="titlebar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>บทความที่น่าสนใจ</h2>
                <nav id="breadcrumbs">
                  <ul>
                    <li><a href="index.html">หน้าแรก</a></li>
                    <li>บทความที่น่าสนใจ</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

<div className="container">


  <div className="row">
    <div className="col-md-12">
    <div className="utf-section-headline-item centered margin-bottom-0 margin-top-20">
          <h3 className="headline">บทความที่น่าสนใจ</h3> 
       

          <p className="utf-slogan-text">รวมบทความที่น่าสนใจ รวมทั้งเทคนิคการตกแต่งบ้าน พร้อมคำแนะนำจากผู้เชี่ยวชาญชั้นนำระดับประเทศ</p>
        </div>  
        <div  ><a href="agents-profile.html"><i className="icon-line-awesome-list" /> จำนวนทั้งหมด  {currencyFormat(this.state.listsCount)} รายการ</a> </div>
        <br />
    </div>
  </div>
  <div className="row sticky-wrapper">
    <div className="col-md-8"> 
      {/* Listings */}
      <div className="utf-listings-container-area list-layout"> 

        {/* Listing Item */}
        {this.state._data}
        <div className="row">
                      <div className="col-md-12" style={{ display: this.state.listsCount < 6 ? 'none' : 'block' }} >

                        <div className="utf-pagination-container margin-top-20">
                          <nav className="pagination" >
                            <ul>
                              <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={10}
                                totalItemsCount={this.state.listsCount}
                                pageRangeDisplayed={10}
                                onChange={this.handlePageChange.bind(this)}
                                activeLinkClass="current-page"
                                hideFirstLastPages={true}
                                hideDisabled={true}
                              />
                            </ul>
                          </nav>
                        </div>

                      </div>
                    </div>
        {/* Listing Item / End */} 
       </div>
      {/* Listings Container / End */} 
      
    </div>
    {/* Sidebar */}
    <div className="col-md-4">
      <div className="sidebar"> 
        {/* Widget */}
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-detail-banner-add-section">
            <a href="#">
         <img src="images/banner/asset_right_banner.png" alt="banner-add-2" lazyload="true" />
         </a>
          </div>             
        </div>
     
        {/* <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>Find New Home</h3>
          </div>
          <button className="button fullwidth margin-top-10">Search</button>
        </div> */}
    
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>Recently Viewed</h3>
          </div>
          {this.state._Recently}
       
        </div>
       
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>หมวดหมู่บทความ</h3>
            <div className="utf-sidebar-categorie"> 
              <ul>
                <li>ประมูลอสังหาฯ</li> 
                <li>กู้ซื้อบ้าน</li> 
                <li>โปรโมชั่น</li> 
                <li>คำแนะนำการซื้อบ้าน</li> 
                <li>Partner content</li> 
                <li>สารพันปัญหาน่ารู้</li>
                <li>เพื่อการลงทุน</li>
                <li>ฮวงจุ้ยที่อยู่อาศัย</li>
                <li>ตรวจสอบก่อนซื้อที่อยู่อาศัย</li>    
                        
              </ul>
            </div>
          </div>              
        </div>
        {/* Widget / End*/}
        {/* Widget */}
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>คำที่ค้นหาบ่อย</h3>
            <div className="task-tags"> 
              <a href="#"><span>Partner content</span></a> 
              <a href="#"><span>กู้ซื้อบ้าน</span></a> 
              <a href="#"><span>คำแนะนำ</span></a> 
              <a href="#"><span>ตรวจสอบก่อนซื้อ</span></a>  
              <a href="#"><span>บทความน่ารู้</span></a>     
              <a href="#"><span>ประมูลอสังหา</span></a> 
              <a href="#"><span>ฮวงจุ้ย</span></a>        
            </div>
          </div>              
        </div>
 
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>Social Sharing</h3>
          </div>
          <ul className="utf-social-icons rounded">
            <li><a className="facebook" href="#"><i className="icon-facebook" /></a></li>
            <li><a className="twitter" href="#"><i className="icon-twitter" /></a></li>
          </ul>
          <div className="clearfix" />
        </div>
        {/* Widget / End*/}                 
        <div className="clearfix" />  
      </div>
    </div>
    {/* Sidebar / End */} 
  </div>
</div>

  
        <div className="margin-top-65" />
        <div id="backtotop"><a href="#" /></div>
      </div>
    );
  }
}


export default Blog;

