import React, { Component } from 'react';
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import NumberFormat from "react-number-format";
import { encrytion, decryption } from './../../utils/EnCryption'
import { Modal } from 'semantic-ui-react'
import queryString from 'query-string';

import Calculator from './../calculator/calculator_content'
import Blog from './../blog/blog'

import parse from 'html-react-parser';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import './asset.css'
import './../calculator/calculator.css'
import './../my-properties/my-properties.css'

import "react-responsive-carousel/lib/styles/carousel.min.css";
 

import { Helmet } from "react-helmet";
import { Passers } from "prop-passer";
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LineIcon,
  EmailIcon
} from "react-share";

import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import "react-leaflet-markercluster/dist/styles.min.css"; // sass

import "leaflet-switch-scale-control";
import "leaflet-switch-scale-control/src/L.Control.SwitchScaleControl.css";
import "leaflet.locatecontrol";


// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import Loader from "react-loader-spinner";
import { ConvDate_format, currencyFormat } from './../default-class/default-class'

import ThreeSixty from "react-360-view";

const Cals = new Calculator()
const blog = new Blog()

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});
 

const basePath = "https://fastly-production.24c.in/webin/360";
//import { AppRegistry, StyleSheet, View, VrButton, asset } from 'react-360';
 
//import { Pannellum } from "pannellum-react";

class Asset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      _recommend: null,
      _gallerySlide: null,
      _nearby: null,
      _facilities: null,
      _news: null,
      _metaTags: null,
      _share: null,
      _video: null,
      _ip: null,
      _blogsLists: null,
      like_icon: 'fa fa-heart-o',
      showText: "show-more",
      loading: true,
      _image360:null,
    }
  }
  async componentDidMount() {
    let url = window.location.search
    let params = queryString.parse(url);
    if (params['p']) {

      let values = {
        p: decryption(params['p']),
        Member_ID: localStorage.getItem("Member_ID") ? parseInt(localStorage.getItem("Member_ID")) : 0
      }

      let result = await httpClient.post(server.ASSET_URL + '/asset', values)
      this.setState(result)

      if (result.gallery.length > 0) {
        this.setState({ _gallerySlide: await this.renderDisplayImg(result.gallery) })
      }

   

      this.setState({ _image360: await result.Url_img_360 >'' ? result.Url_img_360 : '../images/no_img_360.png' })
   
      let _price = result.price > 0 ? 'ราคา ' + result.price + ' บาท' : ''
      let metaData = {
        title: result.action + ' ' + result.project + ' ' + result.location + ' ' + _price + " | Clickthaihome",
        url: window.location.href,
        img_url: result.gallery[0].file_name,
        description: result.action + ' ' + result.project + ' ' + result.location + ' ' + _price,
      }


      document.title = metaData.title

      this.setState({ _metaTags: await this.renderMetaTags(metaData) })
      this.setState({ _facilities: await this.renderFacilities(JSON.parse(result.Asset_Facilities)) })
      this.setState({ _recommend: await this.renderRecommend(result.hitpoint) })
      // this.setState({_news: await this.renderNews(result.news)})
      this.setState({ _blogsLists: await blog.renderRecently(result.blog.slice(0, 20)) })
      this.setState({ _video: this.renderVideo(result.youtube_url) })
      this.setState({
        _nearby: await this.renderNearby(result.nearby),
        like_icon: result.Liked == 1 ? 'fa fa-heart' : 'fa fa-heart-o'
      })

      //console.log(result)
      //console.log(result.hitpoint)
      this.setState({ _share: await this.renderShare() })

      this.mapOption([{ lat: this.state.lat, lng: this.state.lon }]);

      fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
          values = { ...values, ...{ ip: data.ip } }
          httpClient.post(server.ASSET_URL + '/assethitpoint', values)
        });
      this.setState({ loading: result ? false : true })
    } else {
      this.props.history.push('/home')
    }
  }

  async renderMetaTags(data) {
    return (
      <Helmet>
        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content={data.url} />
        <meta property="og:image" content={data.img_url} />
        <meta property="og:image:secure_url" content={data.img_url} />
        <meta property="og:title" content={data.description} />
        <meta property="og:description" content={data.description} />
        <meta name="site_name" content="http://uat.clickthaihome.com/" />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content={1143514505848497} />
        <meta property="og:image:width" content={1200} />
        <meta property="og:image:height" content={630} />
        <meta property="og:locale" content="th_TH" />
        <meta property="og:locale:alternate" content="en_US" />
        <link rel="canonical" href={data.url} />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:title" content={data.title} />
        <meta name="twitter:text:title" content={data.title} />
        <meta name="twitter:image" content={data.img_url} />
        <meta name="twitter:url" content="http://uat.clickthaihome.com/" />
        <meta name="twitter:description" content={data.description} />
        <meta name="twitter:card" content="summary_large_image" />

        {/* <!-- Micro data --> */}
        <meta itemprop="name" content={data.title} />
        <meta itemprop="image" content={data.img_url} />
        <meta itemprop="description" content={data.description} />
        <meta name="format-detection" content="telephone=no" />

      </Helmet>
    )
  }

  mapOption(locations = []) {
    let self = this;
    const heremap_appID = "W336uytfRUG9kKud1fnj";
    const heremap_appCode = "eYy0bLdL7xizsFDZkBfJGQ";
    let houseLayer = new L.MarkerClusterGroup();
    if (locations.length > 0) {
      for (var i = 0; i < locations.length; i++) {
        houseLayer.addLayer(L.marker(locations[i]));
      }
    }

    let osm_map = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      {
        attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 19,
        minZoom: 6
      }
    );

    let sattelite = L.tileLayer("https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png?app_id=" + heremap_appID + "&app_code=" + heremap_appCode,
      {
        attribution: '&copy; <a href="https://www.here.com">HERE Technologies | HERE </a>',
        maxZoom: 19,
        minZoom: 6
      }
    );

    let powermap = L.tileLayer(
      "https://maps.powermap.live/api/v2/map/vtile/thailand_th/{z}/{x}/{y}.png?access_token=943364ad7b8956345d870a14c7855117d0dad5ee",
      {
        attribution: '&copy; <a href="http://powermap.in.th">Powermap</a>',
        maxZoom: 19,
        minZoom: 6
      }
    );

    self.map = L.map(self.refs.map, {
      layers: [osm_map, houseLayer]
    }).setView([this.state.lat != 0 ? this.state.lat : 13,
    this.state.lon != 0 ? this.state.lon : 100],
      this.state.lat != 0 && this.state.lon != 0 ? 14 : 6);

    let baseMaps = {
      'OpenStreetMap': osm_map,
      'Powermap': powermap,
      'ภาพดาวเทียม': sattelite,
    };

    self.map.addControl(new L.Control.SwitchScaleControl({
      ratioCustomItemText: "1: ระบุเลข scale",
      updateWhenIdle: true,
      scales: [1000, 2500, 5000, 10000, 20000, 40000, 70000, 140000, 300000, 600000, 1200000, 2300000]
    }));

    L.control.locate().addTo(self.map);

    L.control.layers(baseMaps).addTo(self.map);
  }


  countChar(val) {

    var length = 0;
    for (var k in val) {
      if (val.hasOwnProperty(k)) {
        length++;
      }
    }
    // alert(length);
    return length;
  }

  renderVideo(data) {
    if (data && data != '' && data != null) {
      let video = data.split('=')[1]
      return (
        <div>
          {/* <div className="utf-desc-headline-item">
            <h3><i className="icon-feather-video" />  วีดีโอแนะนำ</h3>
          </div> */}
          <div className="widget utf-sidebar-widget-item" style={{ border: 0, boxShadow: 'none', marginBottom: 0 }}>
            <div className="utf-boxed-list-headline-item">
              <h3>
                <i className="icon-feather-video" /> วีดีโอแนะนำ
              </h3>
            </div>
          </div>
          <div className="responsive-iframe">
            <iframe width={560} height={315} src={"https://www.youtube.com/embed/" + video} allowFullScreen />
          </div>
        </div>

      )
    } else {
      return null;
    }
  }

  renderFacilities(data) {
    if (data && data.length > 0) {
      return (
        <div key={'Facilities_01'}>
          {/* <div className="utf-desc-headline-item">
            <h3><i className="sl sl-icon-briefcase" /> สิ่งอำนวยความสะดวกในโครงการ</h3>
          </div> */}
          <div className="widget utf-sidebar-widget-item" style={{ border: 0, boxShadow: 'none', marginBottom: 0 }}>
            <div className="utf-boxed-list-headline-item">
              <h3>
                <i className="sl sl-icon-briefcase" /> สิ่งอำนวยความสะดวกในโครงการ
              </h3>
            </div>
          </div>


          <ul className="property-features checkboxes margin-top-0 margin-left-55">
            {
              data != null &&
              data.map(item => (
                <li key={item.value}>{item.value}</li>
              ))
            }
          </ul>
        </div>
      )
    } else {
      return null;
    }

  }

  renderDisplayImg(data) {

    return (
      <OwlCarousel autoplay={true}
        className="owl-theme utf-listing-carousel-item outer"
        loop={true} 
        margin={30}
        nav={true}
        smartSpeed={500}
        items={1}
        dots={true} >
        {
          data != null &&
          data.map(item => (
            <div className="item" style={{ height: 500 }} key={item.Asset_ID + item.file_name}> {/*key={item.Asset_ID}*/}
              <div className="utf-listing-item compact">
                <center><img src={item.file_name} style={{ height: 500, width: 'auto' }} lazyload="true" /></center>
              </div>
            </div>
          ))
        }
      </OwlCarousel>
    )
  }


  async renderRecommend(data) {
    return (
      <OwlCarousel autoplay={true}
        className="owl-theme utf-listing-carousel-item outer"
        loop={true}
        margin={30}
        nav={false}
        smartSpeed={500}
        items={1}
        dots={true}>
        {
          data &&
          data != null &&
          data.slice(0, 3).map(item => (
            <div className="item" key={item.Asset_ID + item.DataFrom}>
              <div className="utf-listing-item compact">
                <a href={"/asset?p=" + encrytion(item.DataFrom + '/' + item.Asset_ID)} className="utf-smt-listing-img-container">
                  <div className="utf-listing-badges-item">
                    {/* <span className="featured">Featured</span>  */}
                    <span className="for-sale">{item.action}</span> </div>
                  <div className="utf-listing-img-content-item">
                    <span className="utf-listing-compact-title-item">{item.Asset_Project}
                      <i>
                        <NumberFormat
                          value={item.Asset_Price}
                          displayType={"text"}
                          thousandSeparator={true}
                          style={{ fontColor: 'white', marginBottom: -1 }}
                          renderText={(value, props) => <p {...props}>{value} บาท</p>}
                        />
                      </i>
                    </span>
                  </div>
                  <center><img src={item.File_Name} alt={item.Asset_Project + ' ' + item.Address} style={{ height: 250, width: 'auto' }} lazyload="true" /></center>
                  <ul className="listing-hidden-content">
                    <li><i className="fa fa-bed" /> <span>{item.Number_Bed_Room}</span></li>
                    <li><i className="fa fa-bath" />  <span>{item.Number_Bath_Room}</span></li>
                    <li><i className="fa fa-car" />  <span>{item.Number_Car_Park}</span></li>
                    <li><i className="fa fa-arrows-alt" />  <span>{item.Asset_Usage_Area}</span></li>
                  </ul>
                </a>
              </div>
            </div>
          ))
        }
      </OwlCarousel>
    )
  }


  async renderNearby(data) {
    if (data.length > 0) {
      return (


        <div className="row">

          <div className="col-md-12">
            <div className="widget utf-sidebar-widget-item" style={{ border: 0, boxShadow: 'none', marginBottom: 0 }}>
              <div className="utf-boxed-list-headline-item">
                <h3 style={{ fontFamily: 'Kanit-Light' }}>รายการทรัพย์ที่ใกล้เคียง
                </h3>
              </div>
            </div>

            <div>
              <div className="row margin-bottom-30 griad-lyout">
                {
                  data.map(item => (
                    <div className="col-md-3" key={item.datafrom + '/' + item.Asset_ID}>
                      <div className="utf-listing-item">
                        <a
                          href={
                            "/asset?p=" +
                            encrytion(item.datafrom + "/" + item.Asset_ID)
                          }
                          className="utf-smt-listing-img-container">
                          <div className="utf-listing-badges-item">
                            {/* {item.New_Asset == "Y" ? (
                            <span className="featured">โครงการใหม่</span>
                          ) : null} */}
                            {item.Action_Type == "4" ? (
                              <span className="for-sale"><span className="icon-material-outline-gavel"> </span> ประมูล </span>
                            ) : null}
                          </div>
                          <div className="utf-listing-img-content-item">

                            <img
                              className="utf-user-picture"
                              src={item.datafrom == 'Asset_Institute_Desc' ? 'images/Logo/LED.jpeg' : 'images/Logo/user.png'}
                              lazyload="true"
                              style={{ width: 70, height: 70, marginTop: 5 }}
                            />
                            <span className={item.Bookmark == 1 ? "like-icon with-tip liked" : 'like-icon with-tip'}
                              data-tip-content="Bookmark"
                              onClick={async e => {
                                e.preventDefault();
                                if (localStorage.getItem("Member_ID")) {
                                  let liked = ''
                                  if (e.target.className == "like-icon with-tip") {
                                    //like
                                    liked = true
                                  } else {
                                    //unlike
                                    liked = false
                                  }

                                  let values = {
                                    Member_ID: localStorage.getItem("Member_ID"),
                                    Asset_ID: item.Asset_ID,
                                    Data_From: item.datafrom,
                                    Liked: liked
                                  }
                                  let result = await httpClient.post(server.ASSET_URL + '/bookmarks', values)
                                  if (result.status == 'ok') {
                                    if (e.target.className == "like-icon with-tip") {
                                      e.target.className = "like-icon with-tip liked"
                                    } else {
                                      e.target.className = "like-icon with-tip"
                                    }
                                  } else if (result.status == 'nok' && result.message == 'Bookmarks Field, Admin not approve.') {
                                    alert('กรุณากรอกข้อมูลพื้นฐานให้ครบและรอให้ผู้ดูแลระบบอนุมัติสมาชิก เพื่อใช้ฟังก์ชั่นนี้ \n\n คำอธิบายเกี่ยวกับ  Bookmark \n 1. หากท่านกรอกข้อมูลพื้นฐานไม่ครบ จะทำการ เลือกติดตามได้ 1 โครงการ \n 2. เมื่อท่านกรอกข้อมูลพื้นฐานตามระบบกำหนด จะสามารถเลือกติดตามได้หลายโครงการ')

                                  }
                                  else if (result.status == 'nok' && result.message == 'Bookmarks Field, Please contact admin.') {
                                    alert('สถานะการใช้งานของท่านถูกยกเลิก กรุณาติดต่อผู้ดูและระบบ')
                                  }
                                } else {
                                  alert('กรุณาเข้าสู่ระบบเพื่อใช้งานฟังก์ชั่นนี้')
                                }
                              }} />
                          </div>
                          <div className="utf-listing-carousel-item">
                            <div>
                              <center>
                                <img
                                  lazyload="true"
                                  src={item.File_Name}
                                  alt={item.Asset_Project + " " + item.Address}
                                  style={{ height: 250, width: "auto" }}
                                />
                              </center>
                            </div>
                          </div>
                        </a>
                        <div className="utf-listing-content">
                          <div className="utf-listing-title">
                            <h4 className="text-warp">
                              <a
                                href={
                                  "/asset?p=" +
                                  encrytion(item.datafrom + "/" + item.Asset_ID)
                                }
                              >
                                {item.Asset_Project}
                              </a>
                            </h4>
                            <span className="utf-listing-address text-warp">
                              <i className="icon-material-outline-location-on" />{" "}
                              {item.Address}
                            </span>
                            <span className="txt_refcode">
                              รหัสหน่วยงาน: {item.ref_code ? item.ref_code : '-'}
                            </span>

                            <span style={{ display: 'flex', marginTop: '-25px', marginRight: 10 }}>
                              <i className="fa fa-eye" style={{ marginLeft: 'auto' }}>
                                <font style={{ fontSize: '10pt', fontFamily: 'Kanit-Light' }}> {Cals.numberWithCommas(item.All_Hit_Point ? item.All_Hit_Point : 0)}</font>
                              </i>
                            </span>
                            <span >
                              <div>
                                <div className="row2">
                                  <div className="column" >
                                    <i className="fa fa-bed" data-toggle="tooltip" data-placement="top"
                                      title="ห้องนอน" />
                                    <span> {item.Number_Bed_Room}</span>

                                  </div>
                                  <div className="column">
                                    <i className="fa fa-bath" data-toggle="tooltip" data-placement="top"
                                      title="ห้องน้ำ" />
                                    <span> {item.Number_Bath_Room}</span>

                                  </div>
                                  <div className="column" >
                                    <i className="icon-line-awesome-arrows" data-toggle="tooltip" data-placement="top"
                                      title="พท.ใช้สอย" />
                                    <span> {item.Asset_Usage_Area} </span>
                                  </div>
                                </div>

                              </div>
                            </span>
                          </div>
                          <div className="blockprice">
                            <div>
                              <span className="lbprice" style={{ float: 'left' }}>ราคาตั้งขาย</span>
                              {(item.Promotion_Price > 0 ?
                                <span className="lbpromo" style={{ float: 'right' }}>ราคาพิเศษ</span> : "")}
                            </div>
                            <br />
                            <div>
                              <span className={item.Promotion_Price > 0 ? "txtpricelow" : 'txtprice'} >
                                <span className={item.Promotion_Price > 0 ? "strikethrough" : ''}>
                                  {(item.Asset_Price > 0 ? currencyFormat(item.Asset_Price) + " " + "บาท" : "โปรดติดต่อหน่วยงาน")}
                                </span>
                              </span>
                              <span className="txtpromotion">
                                {(item.Promotion_Price > 0 ? currencyFormat(item.Promotion_Price) + " " + "บาท" : "")}
                              </span>
                            </div>
                          </div>

                          <div className="utf-listing-user-info">
                            <a href="#">
                              <i className="icon-line-awesome-user text-warp" />{" "}
                              {item.Member_Name}
                            </a>
                            <span></span>
                          </div>

                        </div>
                      </div>
                      {/* <div className="utf-listing-item"> 
                            <a href={"/asset?p=" + encrytion(item.datafrom + '/' + item.Asset_ID)} className="utf-smt-listing-img-container">
                              <div className="utf-listing-img-content-item">
                              <img
                                className="utf-user-picture"
                                src="listing/Agent_1/Logo/sm_logo.png"
                                alt="user_1"
                                style={{width: 70, height: 70}}
                                lazyload="true"
                              />
                              <span
                                className="like-icon with-tip"
                                data-tip-content="Like"
                                style={{left: 20}}
                              ><div className="tip-content">Like</div></span>
                              
                                <span className="video-button with-tip" data-tip-content="Video"><div className="tip-content">Video</div><div className="tip-content">Video</div></span>
                              </div>
                              <div style={{height: 280}}>
                                <center><img src={item.File_Name} alt={item.Asset_Project + ' ' + item.Address} style={{height: 280, width: 'auto'}} lazyload="true"/></center>
                              </div> </a>
                            <div className="utf-listing-content">
                              <div className="utf-listing-title">
                              {
                                    item.asset_Price > 0 ? 
                                    <NumberFormat
                                            className="utf-listing-price"
                                            value={item.asset_Price}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            renderText={(value, props) => <span {...props}>{value} บาท</span>}
                                    />
                                  : 
                                  <span className="utf-listing-price">โปรดติดต่อหน่วยงาน</span>
                                  }
                                <h4><a href={"/asset?p=" + encrytion(item.datafrom + '/' + item.Asset_ID)}>{item.Asset_Project}</a></h4>
                                <span className="utf-listing-address"><i className="icon-material-outline-location-on" /> {item.Address}</span>
                              </div>
                              <div className="utf-listing-user-info"><a href="agents-profile.html"><i className="icon-line-awesome-user" /> {item.Member_Name}</a> <span>{item.Update_Date}</span></div>              
                            </div>
                          </div> */}
                    </div>
                  ))

                }
              </div>


            </div>
          </div>
        </div>




      )
    } else {
      return null;
    }

  }

  async renderNews(data) {
    if (data.length > 0) {
      return (
        <ul className="widget-tabs">
          {
            data.map(item => (
              <li key={item.id + item.pb_title}>
                <div className="widget-content">
                  <div className="widget-text" style={{ fontSize: 13 }}>
                    <a href={"/news_detail?p=" + encrytion(item.id)} target="_blank">
                      <h5>{item.pb_title}</h5>
                      {item.pb_des}
                    </a>
                    <span style={{ fontWeight: 100, margin: 0, fontSize: 13 }}>{ConvDate_format(item.pb_date_news, 'TH', '/')}</span>
                  </div>
                  <div className="clearfix" />
                </div>
              </li>
            ))
          }

        </ul>
      )
    } else {
      return null;
    }
  }

  handleBookmark = async (e) => {
    e.preventDefault()
    if (localStorage.getItem("Member_ID")) {
      let liked = ''
      if (this.state.like_icon == 'fa fa-heart-o') {
        //like
        liked = true
      } else {
        //unlike
        liked = false
      }

      let values = {
        Member_ID: localStorage.getItem("Member_ID"),
        Asset_ID: this.state.id,
        Data_From: this.state.datafrom,
        Liked: liked
      }
      let result = await httpClient.post(server.ASSET_URL + '/bookmarks', values)
      if (result.status == 'ok') {
        if (this.state.like_icon == 'fa fa-heart-o') {
          this.setState({ like_icon: 'fa fa-heart' })
        } else {
          this.setState({ like_icon: 'fa fa-heart-o' })
        }
      } else if (result.status == 'nok' && result.message == 'Bookmarks Field, Admin not approve.') {
        alert('กรุณากรอกข้อมูลพื้นฐานให้ครบและรอให้ผู้ดูแลระบบอนุมัติสมาชิก เพื่อใช้ฟังก์ชั่นนี้ \n\n คำอธิบายเกี่ยวกับ  Bookmark \n 1. หากท่านกรอกข้อมูลพื้นฐานไม่ครบ จะทำการ เลือกติดตามได้ 1 โครงการ \n 2. เมื่อท่านกรอกข้อมูลพื้นฐานตามระบบกำหนด จะสามารถเลือกติดตามได้หลายโครงการ')
        /*
        คำอธิบายเกี่ยวกับ  Bookmark 
          1. หากท่านกรอกข้อมูลพื้นฐานไม่ครบ จะทำการ เลือกติดตามได้ 1 โครงการ  
          2. เมื่อท่านกรอกข้อมูลพื้นฐานตามระบบกำหนด จะสามารถเลือกติดตามได้หลายโครงการ
        */
      }
      else if (result.status == 'nok' && result.message == 'Bookmarks Field, Please contact admin.') {
        alert('สถานะการใช้งานของท่านถูกยกเลิก กรุณาติดต่อผู้ดูและระบบ')
      }

    } else {
      alert('กรุณาเข้าสู่ระบบเพื่อใช้งานฟังก์ชั่นนี้')
    }
  }

  async renderShare() {
    // const {
    //   url = String(window.location.href),
    //   title = "Clickthaihome share",
    //   size = "5rem",
    // } = this.props;
    // const {
    //   ,
    // };

    let url = String(window.location.href)
    let title = "Clickthaihome share"
    let size = "5rem"

    const ShareList = Passers({
      url,
      className: "network__share-button text-center",
    })({
      className: "network cursor-pointer hover transition--default",
      title: `Share ${String(window.location)}`,
    })("a");
    return (
      <ShareList>
        <FacebookShareButton
          url={url}
          quote={title}
        >
          <FacebookIcon
            size={size}
            round={true}
          />
        </FacebookShareButton>

        <TwitterShareButton title={title} >
          <TwitterIcon size={size} round={true} />
        </TwitterShareButton>

        <LineShareButton title={title} >
          <LineIcon size={size} round={true} />
        </LineShareButton>

        <EmailShareButton title={title} >
          <EmailIcon size={size} round={true} />
        </EmailShareButton>
      </ShareList>
    )

  }

  render() {
    return (
      <div>

        {this.state._metaTags}





        {/* Titlebar */}
        <div className="parallax titlebar" data-background="images/popular-location-01.jpg" data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>รายละเอียดทรัพย์</h2>
                  {/* Breadcrumbs */}
                  <nav id="breadcrumbs">
                    <ul>
                      <li><a href="index.html">หน้าแรก</a></li>
                      <li>รายละเอียดทรัพย์</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div className="container">
          <div className="row">
            {/* Property Description */}
            {/* Images Content */}

            <div className="col-lg-8 col-md-7">

              <div className="style-2">
                {/* Tabs Navigation */}
                <ul className="tabs-nav">
                  <li className="active"><a href="#tab11a"><i className="icon-material-outline-crop-original" />รูปภาพ</a></li>
                  <li><a href="#tab12a"><i className="icon-material-outline-format-shapes" /> 360 องศา</a></li> 
                </ul>
                {/* Tabs Content */}
                <div className="tabs-container">
                  <div className="tab-content" id="tab11a" style={{ display: 'none' }}>
                    <center>
                      {/* <Loader
                        type="ThreeDots"
                        color="#e33324"
                        height={100}
                        width={100}
                        visible={this.state.loading}
                      /> */}
                    </center>

                    <div className="row margin-bottom-10 margin-left-30 margin-right-30 margin-top-10" id="gallery" name="gallery">
                     <div style={{width:"100%", height:'500'}}> 
                      {
                            this.state._gallerySlide
                      }
                    </div>
                    </div>
                  </div>
                  <div className="tab-content" id="tab12a" style={{ display: 'none' }}>
                  {/* <ThreeSixty
                    amount={75}
                    imagePath={basePath}
                    fileName="output_{index}.jpeg"
                    spinReverse
                  /> */}
       
       {/* https://my.matterport.com/show/?m=zeS7yDG7iz4 */}
                <iframe width='100%' height='500' textAlign="center" Object-fit ='cover' src={this.state._image360}  
                frameborder='0' allowfullscreen allow='xr-spatial-tracking'>
                </iframe>
                    </div> 
                </div>
              </div>

              






              {/* Titlebar */}


              <div className='boxprice_asset'>
                <div className="property-title">
                  <h2>{this.state.project} ({this.state.action})</h2>

                  <div className='row'>
                    <div className='col-md-12'>

                      <div className="row2">
                        <div className="column2" >
                          <h3>ราคาตั้งขาย</h3>
                          <span className={this.state.Promotion_Price > 0 ? "txtpricelow_asset" : 'txtprice_asset'} >
                            <span className={this.state.Promotion_Price > 0 ? "strikethrough" : ''}>
                              {(this.state.price > 0 ? currencyFormat(this.state.price) + " " + "บาท" : <div className='lbcontact_promo'> โปรดติดต่อหน่วยงาน</div>)}
                            </span>
                          </span>
                        </div>
                        <div className="column2">
                          {(this.state.Promotion_Price > 0 ?
                            <span className='txtpromo_asset'>
                              <h3>ราคาพิเศษ</h3>
                              {(this.state.Promotion_Price > 0 ? currencyFormat(this.state.Promotion_Price) + " " + "บาท" : <div className='lbcontact_promo'>โปรดติดต่อหน่วยงาน</div>)}
                            </span>
                            : "")}
                        </div>
                        <div className="column2">
                          <h2> </h2>
                          <p> </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <span className="utf-listing-address"><i className="icon-material-outline-location-on" /> {this.state.location}</span>
                  <div className='divdashed'></div>
                  <ul className="property-features">
                    {/* {this.state.bed > 0 ? <li>ห้องนอน<span>{this.state.bed}</span></li>: null}
                      {this.state.bath > 0 ? <li>ห้องน้ำ<span>{this.state.bath}</span></li>: null}
                      {this.state.living > 0 ? <li>ห้องนั่งเล่น<span>{this.state.living}</span></li>: null}
                      {this.state.sqm > 0 ? <li>พื้นที่ใช้สอย<span>{this.state.sqm}</span></li>: null} */}
                    <li><span><a href="#bookmark" onClick={this.handleBookmark}>เก็บรายการโปรด <i className={this.state.like_icon} /></a></span></li>
                    <li><span><a href="#share" onClick={e => this.setState({ openModal: true })}>แชร์ <i className="sl sl-icon-share" /></a></span></li>
                    <li><span><a ><i className="fa fa-eye"></i>&nbsp;{currencyFormat(this.state.Hit_Point ? this.state.Hit_Point + 1 : 0)} ครั้ง</a>
                    </span> </li>
                  </ul>
                </div>
              </div>
              <div className="property-description">
                {/* Details */}

                <div className="widget utf-sidebar-widget-item" style={{ border: 0, boxShadow: 'none', marginBottom: 0 }}>
                  <div className="utf-boxed-list-headline-item">
                    <h3>
                      <i className="sl sl-icon-briefcase" /> รายละเอียดทรัพย์
                    </h3>
                  </div>
                </div>

                <ul className="property-features margin-top-0 margin-left-55">
                  {/* {
                      this.state.price > 0 ? 
                      <li><i className=""></i>ราคาขาย: 
                      <NumberFormat
                          // className="utf-listing-price"
                          value={this.state.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value, props) => <span {...props}>{value} บาท</span>}
                      /></li> : null
                    } */}
                  {this.state.sqm > 0 ? <li> <i className="icon-line-awesome-arrows"></i> พื้นที่ใช้สอย: <span>{this.state.sqm}</span></li> : null}
                  {this.state.built_year > 0 ? <li>ปีที่สร้าง: <span>{this.state.built_year}</span></li> : null}
                  {this.state.bed > 0 ? <li><i className="fa fa-bed"></i> จำนวนห้องนอน: <span>{this.state.bed}</span></li> : null}
                  {this.state.bath > 0 ? <li> <i className="fa fa-bath"></i> จำนวนห้องน้ำ: <span>{this.state.bath}</span></li> : null}
                  {this.state.living > 0 ? <li><i className="fa fa-coffee" ></i>  ห้องนั่งเล่น: <span>{this.state.living}</span></li> : null}
                  {this.state.type > 0 ? <li> <i className="fa fa-bookmark"></i> ประเภท: <span>{this.state.type}</span></li> : null}
                  {this.state.action > 0 ? <li><i className="fa fa-exclamation"></i>สถานะ: <span>{this.state.action}</span></li> : null}
                </ul>

                <div className="margin-bottom-50"></div>

                {/* Description */}
                {/* <div className="utf-desc-headline-item">
                    <h3><i className="icon-material-outline-description" /> คำอธิบายเพิ่มเติมเกี่ยวกับทรัพย์</h3>
                  </div> */}
                <div className="widget utf-sidebar-widget-item" style={{ border: 0, boxShadow: 'none', marginBottom: 0 }}>
                  <div className="utf-boxed-list-headline-item">
                    <h3>
                      <i className="icon-material-outline-description" /> คำอธิบายเพิ่มเติมเกี่ยวกับทรัพย์
                    </h3>
                  </div>
                </div>

                <div className={this.countChar(JSON.stringify(this.state.detail)) > 500 ? this.state.showText : "show-more visible"} >
                  <div className="margin-left-55">
                    {parse(this.state.detail ? this.state.detail : '')}
                  </div>

                  {this.countChar(JSON.stringify(this.state.detail)) > 500 ?
                    <a className="show-more-button" onClick={() => this.setState({ showText: "show-more visible" })}>Show More <i className="sl sl-icon-plus" /></a> : null}
                </div>


                <div className="margin-bottom-50"></div>
                {/* facilities */}
                {this.state._facilities}


                <div className="margin-bottom-50"></div>
                <div className="widget utf-sidebar-widget-item" style={{ border: 0, boxShadow: 'none', marginBottom: 0 }}>
                  <div className="utf-boxed-list-headline-item">
                    <h3>
                      <i className="fa fa-phone" /> ติดต่อผู้ประกาศ
                    </h3>
                  </div>
                </div>

                <div className="agent-title margin-left-50">
                  <div className="agent-photo"><img src="images/image-user.png" lazyload="true" style={{ height: '70px' }} /></div>
                  <div className="agent-details">
                    <h4><a>{this.state.member_name}</a></h4>
                    <span>{this.state.email}</span>
                  </div>
                  <div className="clearfix" />
                </div>

                {/* Video */}

                <div className="margin-bottom-50"></div>

                {this.state._video}

                <div className="margin-bottom-50"></div>

                {/* hidden={this.state.lat > 0 && this.state.lon > 0 ? false : true} */}
                <div hidden={this.state.lat > 0 && this.state.lon > 0 ? false : true} >
                  {/* Location */}
                  <div className="widget utf-sidebar-widget-item" style={{ border: 0, boxShadow: 'none', marginBottom: 0, zIndex: 1, }}>
                    <div className="utf-boxed-list-headline-item">
                      <h3>
                        <i className="icon-material-outline-location-on" /> แผนที่ Map
                      </h3>
                    </div>
                  </div>

                  <div
                    ref="map"
                    style={{
                      height: "400px",
                      width: "100%",
                      position: "relative",
                      borderRadius: "6px"
                    }}
                  />
                  <p>
                    <a href={"https://www.google.com/maps/search/?api=1&query=" + this.state.lat + ',' + this.state.lon} target="_blank" rel="noreferrer"> <img src="images/googlemap.svg" alt="Google Map" /> ดูแผนที่ในรูปแบบ Google Map คลิกที่นี่</a>
                  </p>
                  {/* end map  */}
                </div>


                <div className="widget utf-sidebar-widget-item" style={{ border: 0, boxShadow: 'none', marginBottom: 0 }}>
                  <div className="utf-boxed-list-headline-item">
                    <h3 style={{ fontFamily: 'Kanit-Light' }}>
                    </h3>
                  </div>
                </div>

                <Calculator price={this.state.Promotion_Price > 0 ? this.state.Promotion_Price : this.state.price} />

                {/* Layout Switcher */}
                <div className="utf-layout-switcher hidden"><a href="#" className="list"><i className="fa fa-th-list" /></a></div>
                <div className="utf-listings-container-area list-layout">
                </div>
                {/* Similar Listings Container / End */}
                <div className="clearfix" />
                <div className="margin-top-35" />
                <style dangerouslySetInnerHTML={{ __html: "\n          .spline{\n          padding: 0px;\n          }\n     " }} />
              </div>




              {/* เพิ่มหมายเหตุ */}


              <div className='row'>
                <div className='col-md-12'>
                  {/* หมายเหตุความรับผิดชอบทรัพย์ */}
                  <div style={{ color: '#e33324' }}>
                    <hr />
                    <b> หมายเหตุ :</b> <br />
                    ข้อมูลที่ปรากฏในเว็บไซต์นี้ ศูนย์ข้อมูลฯ ได้รับมาจากแหล่งข้อมูลที่เชื่อถือได้ โดยได้ตรวจสอบจนมั่นใจในระดับหนึ่งแล้ว แต่ศูนย์ข้อมูลฯ ไม่สามารถยืนยันความถูกต้องหรือความเป็นจริง และไม่อาจรับผิดชอบต่อความเสียหายที่เกิดขึ้นไม่ว่ากรณีใดๆ จากการใช้ข้อมูล ผู้นำข้อมูลไปใช้พึงใช้วิจารณญาณและตรวจสอบตามความเหมาะสม
                    ศูนย์ข้อมูลฯ ได้ทำการตรวจสอบตำแหน่งที่ตั้งทรัพย์บนแผนที่ที่ปรากฎอยู่บนเว็บไซต์ โดยอาศัยข้อมูลจากแหล่งข้อมูลต่างๆ ที่เชื่อถือได้ เพื่อให้ตำแหน่งทรัพย์บนแผนที่ ใช้อ้างอิงท่านไปยังที่ตั้งของทรัพย์นั้นๆ โดยประมาณ ทั้งนี้ศูนย์ข้อมูลฯ ไม่สามารถยืนยันความถูกต้องของตำแหน่งทรัพย์ได้ ดังนั้น ทางศูนย์ข้อมูลฯ ขอสงวนสิทธิ์ไม่รับผิดชอบต่อข้อผิดพลาดทางตำแหน่งทรัพย์ที่เกิดขึ้นบนแผนที่ ผู้นำข้อมูลไปใช้พึงใช้วิจารณญาณและตรวจสอบตามความเหมาะสม
                  </div>
                </div>
              </div>
            </div>



            {/* Property Description / End */}
            {/* Sidebar */}
            <div className="col-lg-4 col-md-5">
              <div className="sidebar">
                {/* Right baner */}
                <div className="widget utf-sidebar-widget-item" style={{ padding: 5 }}>
                  <div className="utf-detail-banner-add-section">
                    <a href="#"><img src="images/banner/asset_right_banner.png" alt="banner-add-2" lazyload="true" /></a>
                  </div>
                </div>


                {/* Widget / End */}
                {/* Widget banner */}
                <div className="widget utf-sidebar-widget-item" style={{ padding: 13 }}>
                  <div className="utf-boxed-list-headline-item">
                    <h3>ผู้สนับสนุน</h3>
                  </div>

                  <OwlCarousel autoplay={true}
                    className="owl-theme"
                    loop={true}
                    margin={30}
                    nav={false}
                    smartSpeed={500}
                    items={1}
                    dots={true}>
                    <div className="item">
                      <div ><img className="utf-user-picture" src="images/banner/425x318_2.jpg" lazyload="true" /></div>
                    </div>
                    <div className="item">
                      <div ><img src="images/banner/425x318px.jpg" lazyload="true" /></div>
                    </div>
                  </OwlCarousel>
                </div>



                {/* Widget / End */}
                {/* Widget */}
                <div className="widget utf-sidebar-widget-item" style={{ display: this.state.hitpoint ? 'block' : 'none' }}>
                  <div className="utf-boxed-list-headline-item">
                    <h3>ทรัพย์แนะนำ</h3>
                  </div>
                  {
                    this.state._recommend
                  }

                </div>
                {/* Widget / End */}


                {/* Widget */}
                <div className="widget utf-sidebar-widget-item">
                  <div className="utf-boxed-list-headline-item">
                    <h3>บทความน่าสนใจ</h3>
                  </div>
                  {this.state._blogsLists}
                  {/* {this.state._news} */}

                </div>
                {/* Widget / End*/}

                {/* Widget */}
                <div className="widget utf-sidebar-widget-item">
                  <div className="utf-boxed-list-headline-item">
                    <h3>คำที่ค้นหาบ่อย</h3>
                    <div className="task-tags">
                      <a href="#"><span>อพาร์ทเม้นท์</span></a>
                      <a href="#"><span>บ้านมือสอง</span></a>
                      <a href="#"><span>บ้านเดี่ยว</span></a>
                      <a href="#"><span>โปรโมชั่น</span></a>
                      <a href="#"><span>Condo</span></a>
                    </div>
                  </div>
                </div>
                {/* Widget / End*/}

              </div>
            </div>
            {/* Sidebar / End */}
          </div>




          {this.state._nearby}

        </div>
        <section className="fullwidth" data-background-color="#ffffff" style={{ background: 'rgb(255, 255, 255)', textAlign: 'center' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <div className="item"><a href="#"><img src="images/banner/GHB_D+Plus_BannerFL_1004x250.jpg" lazyload="true" /></a></div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <Modal
          onClose={() => this.setState({ openModal: false })}
          onOpen={() => this.setState({ openModal: true })}
          open={this.state.openModal}
          size="mini"
        >
          <Modal.Header >
            <h3>เลือก Social media ที่คุณต้องการแชร์</h3>
          </Modal.Header>
          <Modal.Content >
            <Modal.Description>
              <center>
                {/* <ShareList>
                  <FacebookShareButton quote={title} >
                    <FacebookIcon size={size} round={true} />
                  </FacebookShareButton>

                  <TwitterShareButton title={title} >
                    <TwitterIcon size={size} round={true} />
                  </TwitterShareButton>

                  <LineShareButton title={title} >
                    <LineIcon size={size} round={true} />
                  </LineShareButton>

                  <EmailShareButton title={title} >
                    <EmailIcon size={size} round={true} />
                  </EmailShareButton>

                  
              </ShareList> */}
                {this.state._share}
                <br />

              </center>
            </Modal.Description>
          </Modal.Content>
        </Modal>


      </div>
    );
  }
}

export default Asset;
