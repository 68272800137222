import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import "./calendar.css";
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";

function renderEventContent(eventInfo) {
  // Generate a random color for each event
  const randomColor = '#' + Math.floor(Math.random()*16777215).toString(16);

  return (
    <>
      &nbsp;&nbsp; <span className="bullet" style={{ color: randomColor }}>&#8226;</span> &nbsp;
      <i style={{ color: randomColor }}>{eventInfo.event.title}</i>
    </>
  );
}


class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekendsVisible: true,
      currentEvents: [],
    };
  }

  async componentDidMount() {
    try {
      let result = await httpClient.get(server.CALENDAR_URL);
      this.setState({
        currentEvents: result.data,
      });
    } catch (error) {
      console.error("Error fetching calendar data: ", error);
    }
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    });
  };

  handleEventClick = (clickInfo) => {
    this.props.history.push("/calendar-detail?p=" + clickInfo.event.title);
  };

  render() {
    return (
      <div>
        {/* Titlebar */}
        <div
          className="parallax titlebar"
          data-background="images/popular-location-01.jpg"
          data-color="rgba(48, 48, 48, 1)"
          data-color-opacity="0.8"
          data-img-width={800}
          data-img-height={505}
        >
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>ปฏิทินกิจกรรม</h2>
                  {/* Breadcrumbs */}
                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="index">หน้าแรก</a>
                      </li>
                      <li>ปฏิทินกิจกรรม</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Show Calendar */}
        <section
          className="fullwidth"
          data-background-color="#fbfbfb"
          style={{ background: "rgb(251, 251, 251)", display: "block" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <FullCalendar
                  locale={"TH"} 
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                  headerToolbar={{
                    left: "prev,next",
                    center: "title",
                    right: "dayGridMonth,listDay",
                  }}
                  initialView="dayGridMonth"
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  stickyHeaderDates={true} 
                  weekends={this.state.weekendsVisible}
                  events={this.state.currentEvents}
                  eventContent={renderEventContent} 
                />
              </div>
            </div>
          </div>
        </section>

        <div className="margin-bottom-50"></div>
      </div>
    );
  }
}

export default Calendar;
