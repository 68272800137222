import React, { Component} from "react";
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import {encrytion, decryption} from './../../utils/EnCryption'
import queryString from 'query-string';
import parse from 'html-react-parser';
import Pagination from "react-js-pagination";
import './news-group.css';

import {ConvDate_format} from './../default-class/default-class'
import newsComponent from "./../asset/asset";
import Dotdotdot from 'react-dotdotdot'


const news = new newsComponent();
 
class NewsGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _postId: null,
      _data: null,
      _news: null,
      listsAll: null,
      listsCount: 1
    }
  }
  

  async componentDidMount() {

    let url = window.location.search
    let params = queryString.parse(url);
    let result = await httpClient.get(server.NEWS_URL + "/getNewsRelate/" + params['g'])
    let result2 = await httpClient.get(server.NEWS_URL + "/getNewsRelate/T0dFeE1YWmpZWFpWY2poYVFtdEJhRVphVkd0M1pEUTFTVnBzV2xsM01VUlhVVTlyWkV3NFNubFdUSGhQV1dSWlQwOXZUalF5WkhvMWFrdFZlWFZyTWxsb1FWUllSa2N6V205VFJEUTFibng4VDBGMFpHTTBjVEo2VFRoMFNFaHhWRUkyV214TE4zbHhZMkYyY0dOcU9VRktkMk42VjBkTU5VOXFTVEJxWWtoSE1GZGpha3RwZDBoNVltZzVmSHd6Zkh4QldtdHlibGRqTWpkVU0yTkhObWRNWmtkUlpreFpkbmxtUWpsbFMxbzRXRTQwYlRoeVVYWnFhamxaY0hsRg==")
  
    this.setState({listsAll:result,listsCount:result.length})
    this.setState({_postId: await this.convertGroupId(params['g'])})
    // this.setState({_data: await this.renderGroup(result)})    
    this.setState({_news: await news.renderNews(result2.slice(0,7))})
    
    //console.log(this.state.listsAll)

    this.handlePageChange(1)
  }

  async handlePageChange(pageNumber) {
    await this.setState({activePage: pageNumber});
    let lists = null
    lists = await this.renderGroup(this.state.listsAll.slice((pageNumber * 10) - 10, pageNumber * 10))
    this.setState({_data: lists})
     
    // this.myRef.current.scrollIntoView({behavior: 'smooth'});
  }

  removeHTML(str){ 
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
    }
  
  async renderGroup(result){
    const style = {justifyContent: 'center', width: '10', height: '10'}

    return (
      result.map(item => (

        <div className="utf-listing-item"  key={item.id}> 
        {/* <a href={"/news_detail?p=" + encrytion(item.id)} target="_blank" className="utf-smt-listing-img-container" >
            <div className="utf-listing-badges-item"> <span className="featured">คำแนะนำ</span>   </div>
         <img src="https://www.ascentgeomatics.com/wp-content/uploads/Latest-News-Banner.png" lazyload="true" alt= {item.pb_title} style={{height: 215}} /> 
        </a> */}
          <div className="utf-listing-content">
            <div className="utf-listing-title">
             <h3>
              <Dotdotdot clamp={ 2 }>
              <div>
              <a href={"/news_detail?p=" + encrytion(item.id)} target="_blank" > {item.pb_title} </a>
              </div>
               </Dotdotdot>               
              </h3>
            
              <span className="utf-listing-address"> 
              <Dotdotdot  clamp={ 3 } useNativeClamp={ true } truncationChar="&raquo;">
              <div>
              {this.removeHTML(item.pb_des)}
              </div>
               </Dotdotdot>   
              
              </span>
            </div>
   
            <div className="utf-listing-user-info">
              <i className="icon-line-awesome-user" /> Admin
                <span>{ConvDate_format(item.pb_date_news, 'TH', '/')}</span>
            </div>
          </div>
        </div>

      // <div  key={item.id}> 
      //   <div className="bblog">
      //   <tr key={item.Asset_ID + item.pb_title}  style={style}>
      //     <td><i className="fa fa-newspaper-o" style={{marginTop: 10} ,{marginRight:20}}></i></td>
      //     <td >                         
      //           <div className="title">
      //             <h4><a href={"/news_detail?p=" + encrytion(item.id)} target="_blank"> {item.pb_title}</a></h4>
      //           <span>{ parse(item.pb_des.substring(0, 100)? `${item.pb_des.substring(0, 100)}...` : '') }</span>
      //           <span className="boxwidget-text"> {ConvDate_format(item.pb_date_news, 'TH', '/')}</span>
      //           </div>
      //    </td>    
         
      //  </tr>

      //   </div>
      // </div>
    ))
    )
  }

  spiltDate(val){
    val = val.split(" ")[0]
    return val
  }

  async convertGroupId(val){
    val = await decryption(val)
    if(val == 1){
      val = "ข่าวนโยบายการเงิน-การคลัง"
    }
    else if(val == 2){
      val = "ข่าวโครงการอสังหาฯ ภาครัฐ"
    }
    else if(val == 3){
      val = "ข่าวโครงการอสังหาฯ ภาคเอกชน"
    }
    else if(val == 4){
      val = "ข่าวกฎหมายอสังหาฯ"
    }
    else if(val == 5){
      val = "ข่าวพัฒนาสาธารณูปโภค"
    }
    else if(val == 6){
      val = "ข่าวบ้านมือสอง"
    }
    else if(val == 7){
      val = "ข่าววัสดุก่อสร้าง-เฟอร์นิเจอร์"
    }
    else if(val == 8){
      val = "ข่าวเขตเศรษฐกิจพิเศษ"
    }
    else if(val == 9){
      val = "ข่าว reic จากสื่อสิ่งพิมพ์"
    }
    else if(val == 52){
      val = "ข่าวอสังหาริมทรัพย์ภูมิภาค"
    }
    return val
  }


  render() {
    return (



      <div id="wrapper">

      <div className="parallax titlebar" lazyload="true" data-background="images/popular-location-03.jpg" data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
        <div id="titlebar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>{this.state._postId}</h2>
                <nav id="breadcrumbs">
                  <ul>
                    <li><a href="index.html">หน้าแรก</a></li>
                    <li>{this.state._postId}</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

<div className="container">


  <div className="row">
    <div className="col-md-12">
    <div className="utf-section-headline-item centered margin-bottom-0 margin-top-20">
          <h3 className="headline">{this.state._postId}</h3> 
    
          <br />
        </div>  
    </div>
  </div>
  <div className="row sticky-wrapper">
    <div className="col-md-8"> 
      {/* Listings */}
      <div className="utf-listings-container-area list-layout"> 

        {/* Listing Item */}
        {this.state._data}
        <div className="row">
                      <div className="col-md-12" style={{ display: this.state.listsCount < 6 ? 'none' : 'block' }} >

                        <div className="utf-pagination-container margin-top-20">
                          <nav className="pagination" >
                            <ul>
                              <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={10}
                                totalItemsCount={this.state.listsCount}
                                pageRangeDisplayed={10}
                                onChange={this.handlePageChange.bind(this)}
                                activeLinkClass="current-page"
                                hideFirstLastPages={true}
                                hideDisabled={true}
                              />
                            </ul>
                          </nav>
                        </div>

                      </div>
                    </div>
        {/* Listing Item / End */} 
       </div>
      {/* Listings Container / End */} 
      
    </div>
    {/* Sidebar */}
    <div className="col-md-4">
      <div className="sidebar"> 
        {/* Widget */}
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-detail-banner-add-section">
            <a href="#">
         <img src="images/banner/asset_right_banner.png" alt="banner-add-2" lazyload="true" />
         </a>
          </div>             
        </div>
     
    
    
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>ข่าวอสังหาริมทรัพย์ภาคเอกชน</h3>
          </div>
          {this.state._news}
       
        </div>
       
        {/* Widget / End*/}
        {/* Widget */}
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>คำที่ค้นหาบ่อย</h3>
            <div className="task-tags"> 
              <a href="#"><span>Partner content</span></a> 
              <a href="#"><span>กู้ซื้อบ้าน</span></a> 
              <a href="#"><span>คำแนะนำ</span></a> 
              <a href="#"><span>ตรวจสอบก่อนซื้อ</span></a>  
              <a href="#"><span>บทความน่ารู้</span></a>     
              <a href="#"><span>ประมูลอสังหา</span></a> 
              <a href="#"><span>ฮวงจุ้ย</span></a>        
            </div>
          </div>              
        </div>
 
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>Social Sharing</h3>
          </div>
          <ul className="utf-social-icons rounded">
            <li><a className="facebook" href="#"><i className="icon-facebook" /></a></li>
            <li><a className="twitter" href="#"><i className="icon-twitter" /></a></li>
          </ul>
          <div className="clearfix" />
        </div>
        {/* Widget / End*/}                 
        <div className="clearfix" />  
      </div>
    </div>
    {/* Sidebar / End */} 
  </div>
</div>

  
        <div className="margin-top-65" />
        <div id="backtotop"><a href="#" /></div>
      </div>

      // <div id="wrapper">   
      //      <div className="parallax titlebar" data-background="images/Hora/top_banner.png" 
      //        data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
      //     <div id="titlebar">
      //       <div className="container">
      //         <div className="row">
      //           <div className="col-md-12">
      //             <h2>{this.state._postId}</h2>
      //             <nav id="breadcrumbs">
      //               <ul>
      //                 <li><a href="home">หน้าแรก</a></li>
      //                 <li>{this.state._postId}</li>
      //               </ul>
      //             </nav>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   {/* Content */}
      //   <div className="container"> 
      //     <div className="blog-page">
      //       <div className="row">
      //       <div className="col-md-8"> 
      //         {/* Blog Post */}
      //         <div className="blog"> 
      //           <div className="row">
               

      //   <div className="utf-section-headline-item centered margin-bottom-0 margin-top-20">
      //     <h3 className="headline">{this.state._postId}</h3> 
      //   <br /> 
      // </div>  
 
       
      //       { this.state._data }
      // {/* Pagination */}
      // <div className="row">
      //         <div className="col-md-12" style={{display: this.state.listsCount < 6 ? 'none' : 'block'}} >
                  
      //             <div className="utf-pagination-container margin-top-20">
      //               <nav className="pagination" >
      //                 <ul>
      //                   <Pagination
      //                     activePage={this.state.activePage}
      //                     itemsCountPerPage={10}
      //                     totalItemsCount={this.state.listsCount}
      //                     pageRangeDisplayed={10}
      //                     onChange={this.handlePageChange.bind(this)}
      //                     activeLinkClass="current-page"
      //                     hideFirstLastPages={true}
      //                     hideDisabled={true}
      //                   />
      //               </ul>
      //               </nav>           
      //             </div>


                  
      //           </div>
      //         </div> 

      //         </div>
            
      //           </div>
      //           {/* News Post / End */}	
      //         </div>
      //         {/* Widgets */}
      //         <div className="col-md-4">
      //           <div className="sidebar"> 
      //             {/* Widget */}
      //             <div className="widget utf-sidebar-widget-item">
      //               <div className="utf-detail-banner-add-section">
      //                 <a href="#">  <img src="images/banner/asset_right_banner.png" alt="banner-add-2" lazyload="true" /></a>
      //               </div>             
      //             </div>
      //             {/* Widget / End*/}
      //                  {/* Widget */}
      //             <div className="widget utf-sidebar-widget-item">
      //               <div className="utf-boxed-list-headline-item">
      //                 <h3>ข่าวอสังหาริมทรัพย์ ภาคเอกชน</h3>
      //               </div>

      //               {this.state._news}
                    
      //             </div>
      //             {/* Widget / End*/} 
                          
      //             <div className="clearfix" />
      //           </div>
      //         </div>
              
      //         {/* Blog Posts / End */} 
      //       </div>
      //       {/* Sidebar / End */}       
      //     </div>
      //   </div> 
        
      // </div>
    );
  }
}

export default NewsGroup;
