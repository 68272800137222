import React, { Component } from "react";
import './tab-component.css';
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import Dotdotdot from 'react-dotdotdot';
import { currencyFormat } from "../default-class/default-class";
import { encrytion } from "../../utils/EnCryption";
import calculator_content from './../calculator/calculator_content'
const initialState = {
  Top8Card: null,
  rent_assetCard: null,
  activeTab: 'tab1',
};

const Cals = new calculator_content();

class TabContent extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    try {
      const values = { Member_ID: localStorage.getItem("Member_ID") || '0' };
      const result = await httpClient.post(server.BLOG_TAB_URL, values);

      const Top8cards = this.renderAssetCards(result.top8);
      const RentAsset = this.renderAssetCards(result.rent_asset);
 
      this.setState({
        Top8Card: Top8cards,
        rent_assetCard: RentAsset,
      });
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  renderAssetCards = (data) => {
    console.log(data)
    if (!Array.isArray(data)) return null;

    return data.map(item => (
      <div key={item.Asset_ID + item.DataFrom} className="col-md-3">
        <div className="hover-img">
          <a href={`/asset?p=${encrytion(item.datafrom + "/" + item.Asset_ID)}`} target="_blank" rel="noopener noreferrer">
            <img
              src={item.File_Name}
              alt={`${item.Asset_Project} ${item.Address}`}
              style={{ height: 120, borderRadius: '1rem' }}
              lazyload="true"
            />
            <span style={{ display: 'flex', marginTop: '-25px', marginRight: 10 }}>
              <i className="fa fa-eye" style={{ marginLeft: 'auto', color: 'white' }}>
                <font style={{ fontSize: '10pt', fontFamily: 'Kanit-Light' }}>
                 &nbsp;{Cals.numberWithCommas(item.All_Hit_Point ? item.All_Hit_Point : 0)}
                </font>
              </i>
            </span>
          </a>
          <div className="utf-post-content-area-top">
            <a href={`/asset?p=${encrytion(item.datafrom + "/" + item.Asset_ID)}`} target="_blank" rel="noopener noreferrer">
              <div className="title-hot-asset" style={{ marginTop: "1rem" }}>
                <Dotdotdot clamp={1} useNativeClamp={true} truncationChar="&raquo;">
                  <div>{item.Asset_Project}</div>
                </Dotdotdot>
              </div>
              <div style={{ borderBottom: "1px dashed #f2f2f2" }}></div>
              <div className="word-spacing small">
                <span className="utf-listing-address text-warp" style={{ margin: "0" }}>
                  <div className="detail-hot-asset">
                    <div className="ditail-hot-price">
                      เริ่มต้นที่ {item.Asset_Price > 0 ? `${currencyFormat(item.Asset_Price)} บาท` : "โปรดติดต่อหน่วยงาน"}
                    </div>
                  </div>
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    ));
  };

  handleTabClick = (tab) => {
    this.setState({ activeTab: tab });
    
  };

  render() {
    const { activeTab, Top8Card, rent_assetCard } = this.state;

    return (
      <div>
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="Boxhead">
                <h2>รายการทรัพย์ล่าสุด ประจำสัปดาห์</h2>
              </div>
            </div>
          </div>
          <br />
          <div className="style-1">
            <ul className="tabs-nav">
              <li className={activeTab === 'tab1' ? 'active' : ''}>
                <div href="#tab1" onClick={() => this.handleTabClick('tab1')}><i className="sl sl-icon-calculator"></i>ทรัพย์มาใหม่</div>
              </li>
              <li className={activeTab === 'tab2' ? 'active' : ''}>
                <div href="#tab2" onClick={() => this.handleTabClick('tab2')}><i className="sl sl-icon-wallet"></i> ทรัพย์ประเภทเช่า</div>
              </li>
            </ul>
            <div className="tabs-container">
              <div className="tab-content" id="tab1" style={{ display: activeTab === 'tab1' ? 'block' : 'none' }} key="tab1">
                <div className="row">
                  <div className="col-md-12">
 
                    {Top8Card}
                  </div>
                </div>
              </div>
              <div className="tab-content" id="tab2" style={{ display: activeTab === 'tab2' ? 'block' : 'none' }} key="tab2">
                <div className="row">
                  <div className="col-md-12">
 
                    {rent_assetCard}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TabContent;
