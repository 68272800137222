import React, { Component } from "react";
import "./about.css";

class About extends Component {
  render() {
    return (
      <div>
   
        <div
          className="parallax titlebar"
          data-background="images/popular-location-03.jpg"
          data-color="rgba(48, 48, 48, 1)"
          data-color-opacity="0.8"
          data-img-width={800}
          data-img-height={505}
        >
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>เกี่ยวกับเรา</h2>
                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="index.html">หน้าแรก</a>
                      </li>
                      <li>เกี่ยวกับเรา</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="margin-top-65 padding-bottom-55">

        <div className="container">


<div className="row"> 
  <div className="col-md-12">
                      <div className="utf-section-headline-item centered margin-bottom-30 margin-top-0">
                        <h3 className="headline">
                          ประวัติการจัดตั้งศูนย์ข้อมูลอสังหาริมทรัพย์
                        </h3>
                        <div className="utf-headline-display-inner-item">
                          Real Estate Information Center History
                        </div>                      
                      </div>
                      <div>

                      <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="content-editor no-bg">
                  <div className="container content-desc padding-row">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="widget utf-sidebar-widget-item">
                          <div className="utf-boxed-list-headline-item">
                            <h3>วิสัยทัศน์</h3>
                          </div>
                        </div>
                        เป็นศูนย์กลางข้อมูลด้านอสังหาริมทรัพย์ของประเทศที่ได้รับการยอมรับในระดับสากล
                        <div className="widget utf-sidebar-widget-item">
                          <div className="utf-boxed-list-headline-item">
                            <h3>พันธกิจ</h3>
                          </div>
                        </div>
                        ดำเนินการด้านข้อมูล การวิเคราะห์วิจัย การพยากรณ์ธุรกิจ
                        และการให้คำปรึกษา
                        รวมถึงเผยแพร่ข้อมูลด้านอสังหาริมทรัพย์ของประเทศสู่สาธารณะ
                      </div>
                      <div className="col-md-6">
                        <div className="widget utf-sidebar-widget-item">
                          <div className="utf-boxed-list-headline-item">
                            <h3>ภารกิจ</h3>
                          </div>
                        </div>
                        <ol>
                          <li>
                            เป็นศูนย์กลางการรวบรวมและพัฒนาข้อมูลอสังหาริมทรัพย์
                            และข้อมูลประกอบอื่นๆ
                          </li>
                          <li>
                            เป็นศูนย์สำรวจ วิจัย
                            และวิเคราะห์ข้อมูลเพื่อประเมินสถานการณ์และแนวโน้มอสังหาริมทรัพย์
                          </li>
                          <li>
                            เป็นศูนย์พยากรณ์อุปสงค์ อุปทาน ราคาอสังหาริมทรัพย์
                          </li>
                          <li>
                            เป็นศูนย์เผยแพร่และให้บริการข้อมูลสารสนเทศเกี่ยวกับอสังหาริมทรัพย์
                          </li>
                          <li>
                            เป็นศูนย์ให้บริการปรึกษาและพัฒนาความรู้เกี่ยวกับอสังหาริมทรัพย์
                          </li>
                        </ol>
                      </div>
                    </div>
                    <br />
                     
                  
          
                  </div>
                </div>
              </div>
            </div>


                      &nbsp; &nbsp;
                      &nbsp;วิกฤตการณ์ทางการเงินในเอเชียซึ่งเกิดขึ้นในช่วง พ.ศ.
                      2540 ส่งผลโดยตรงต่อธุรกิจพัฒนาอสังหาริมทรัพย์
                      โดยเฉพาะปัญหากำลังซื้อที่หดตัวอย่างรุนแรง
                      ทำให้ตลาดที่อยู่อาศัยในกรุงเทพฯ
                      และปริมณฑลซบเซาต่อเนื่องได้
                      สถาบันการเงินขาดความเชื่อมั่นต่อการลงทุนในธุรกิจที่อยู่อาศัย
                      หลายภาคส่วนมีความเห็นสอดคล้องกันว่าปัญหาที่เกิดขึ้นแสดงถึงความบกพร่องของระบบข้อมูลที่อยู่อาศัย
                      และข้อมูลตลาดอสังหาริมทรัพย์ในประเทศไทย
                      ซึ่งการที่จะป้องกันมิให้เกิดวิกฤตหรือลดความเสี่ยงในอนาคตในลักษณะทำนองเดียวกันนี้
                      จำเป็นที่ประเทศไทยจะต้องมี”ศูนย์ข้อมูลอสังหาริมทรัพย์”
                      เป็นหน่วยงานกลางให้ทำหน้าที่หลัก
                      ในการจัดเก็บรวบรวมข้อมูลอสังหาริมทรัพย์ที่กระจายอยู่ตามหน่วยงานต่างมาประมวลผลเป็นข้อมูลภาพรวมของประเทศมาเผยแพร่โดยรวดเร็ว
                      และทันเหตุการณ์&nbsp;
                      <br />
                      <br />
                      &nbsp; &nbsp; &nbsp;ด้วยการผลักดันของกระทรวงการคลัง
                      และส่วนงานที่เกี่ยวข้องทั้งภาครัฐ ภาคเอกชน และภาคการเงิน
                      คณะรัฐมนตรีจึงมีมติเมื่อวันที่ 17 สิงหาคม 2547
                      ตั้ง”ศูนย์ข้อมูลอสังหาริมทรัพย์”โดยเป็นหน่วยงานอิสระระดับสายงานภายใต้การกำกับดูแลของธนาคารอาคารสงเคราะห์
                      &nbsp;การดำเนินงานอยู่ภายใต้การกำกับดูแลของคณะกรรมการศูนย์ข้อมูลอสังหาริมทรัพย์ซึ่งได้รับการแต่งตั้งจากรัฐมนตรีว่าการกระทรวงการคลัง
                      โดยมีวัตถุประสงค์หลักในการจัดตั้ง 3 ประการ ได้แก่
                      <br />
                      &nbsp;
                    </div>
                    <ol style={{ marginLeft: 40 }}>
                      <li>
                        เพื่อทำหน้าที่เป็น
                        “หน่วยงานกลางจัดเก็บข้อมูลอสังหาริมทรัพย์ของประเทศ”
                        (Data Collection) ให้เป็นฐานข้อมูลที่มีความถูกต้อง
                        เชื่อถือได้ และทันต่อเหตุการณ์
                      </li>
                      <li>
                        เพื่อทำหน้าที่ จัดทำข้อมูล (Information Provider)
                        ทางด้านอสังหาริมทรัพย์ให้กับกระทรวงการคลัง
                        และหน่วยงานรัฐที่ทำหน้าที่กำหนดนโยบายเศรษฐกิจของประเทศ
                        รวมทั้งให้บริการข้อมูลแก่
                        ภาคเอกชนและประชาชนผู้สนใจทั่วไปด้วย
                      </li>
                      <li>
                        เพื่อเก็บรวบรวมข้อมูลอสังหาริมทรัพย์ 7 ประเภท ได้แก่
                        ที่อยู่อาศัย อาคารสำนักงาน ศูนย์การค้า โรงแรม-รีสอร์ท
                        นิคมอุตสาหกรรม สนามกอล์ฟ และที่ดินเปล่า
                        โดยจัดเก็บและรวบรวมจากหน่วยงานต่างๆ
                        ทั้งของภาครัฐและเอกชน และเผยแพร่ข้อมูลในด้านอุปทาน
                        อุปสงค์ ภาวะตลาด และการเงินที่อยู่อาศัย ฯลฯ
                      </li>
                    </ol>
                    <br />
                    &nbsp; &nbsp;
                    &nbsp;ศูนย์ข้อมูลอสังหาริมทรัพย์ได้เริ่มดำเนินงานอย่างเป็นทางการ
                    เมื่อวันที่ 25 สิงหาคม 2547 โดยมีสำนักงานอยู่ที่ชั้น 18
                    อาคาร 2 ธนาคารอาคารสงเคราะห์ สำนักงานใหญ่
                    <br />
                    <br />
                    &nbsp; &nbsp;
                    &nbsp;การดำเนินงานในช่วงแรกภารกิจสำคัญยังคงมุ่งเน้นไปที่การรวบรวมและพัฒนาข้อมูลอสังหาริมทรัพย์ให้ได้ครบทั้ง
                    7 ประเภท ประกอบด้วย ที่อยู่อาศัย อาคารสำนักงาน
                    ศูนย์การค้าโรงแรม-รีสอร์ทนิคมอุตสาหกรรมสนามกอล์ฟ
                    &nbsp;และที่ดินเปล่า &nbsp;จนถึงปัจจุบัน
                    ศูนย์ข้อมูลอสังหาริมทรัพย์ จัดเก็บรวบรวมข้อมูล
                    และประมวลผลข้อมูล
                    ทั้งโดยการรับข้อมูลจากแหล่งข้อมูลปฐมภูมิซึ่งมีหน่วยงานภาครัฐบางแห่งจัดเก็บอยู่แล้ว
                    และโดยการสำรวจ ค้นคว้า วิจัย
                    โดยศูนย์ข้อมูลอสังหาริมทรัพย์ดำเนินการเอง เพื่อนำมาประมวลผล
                    และเผยแพร่ผ่านช่องทางหลักบนเว็บไซต์&nbsp;
                    <a href='http://www.reic.or.th'>
                      www.reic.or.th
                    </a>{" "}
                    และผ่านช่องทางอื่นๆ จำนวนถึง 77 รายการข้อมูล
                    กระจายอยู่ครบในหมวดย่อยต่างๆของธุรกิจอสังหาริมทรัพย์ คือ
                    ที่อยู่อาศัย อาคารสำนักงาน โรงแรมและรีสอร์ท ศูนย์การค้า
                    นิคมอุตสาหกรรม สนามกอล์ฟ และที่ดินเปล่า
                    ซึ่งถือว่าเป็นการพัฒนาฐานข้อมูลที่มีความสำคัญต่อระบบเศรษฐกิจประเทศชาติ
                    สมควรต่อการได้รับการสนับสนุนจากทุกฝ่ายที่เกี่ยวข้องทั้งภาครัฐและเอกชนทั้งในปัจจุบันและอนาคต&nbsp;
          
   </div> 

    
  <div className="utf-section-headline-item centered margin-bottom-30 margin-top-30">
  
                        <h3 className="headline ">
                        ประวัติการพัฒนาเว็บ Clickthaihome.com
                        </h3>
                                          
                      </div>
                      
    <div className="row">
   
        <div className="main-timeline4">
          <div className="timeline">
            <span  className="timeline-content">
              <span className="year">2548</span>
              <div className="inner-content">
                <h3 className="title">เริ่มจัดทำเว็บไซต์</h3>
                <p className="description" width={320}  >
                คณะรัฐมนตรีได้มีมติเมื่อวันที่ 13 กันยายน 2548 เรื่องการกระตุ้นตลาดบ้านมือสอง  
                 เพื่อให้เกิดประโยชน์สูงสุดต่อทุกฝ่าย ทั้งภาครัฐ สถาบันการเงินและภาคเอกชน และได้มอบหมายให้ ธนาคารอาคารสงเคราะห์ โดยศูนย์ข้อมูลอสังหาริมทรัพย์ เป็นผุ้รับผิดชอบ  ในการจัดงานมหกรรมบ้านมือสองแห่งชาติ ขึ้นเป็นครั้งแรก เพื่อเป็นกิจกรรมที่ช่วยสนับสนุนมติคณะรัฐมนตรีดังกล่าว
                </p>
              </div>
            </span>
          </div>
          <div className="timeline">
            <span   className="timeline-content">
              <span className="year">2551</span>
              <div className="inner-content">
                <h3 className="title">พัฒนามาเป็นเว็บประกาศซื้อ-ขาย บ้านมือสอง</h3>
                <p className="description">
                ต่อมา ศูนย์ข้อมูลอสังหาริมทรัพย์ ได้จัดงานมหกรรมบ้านมือสองแห่งชาติ ขึ้นต่อเนื่องเป็นประจำทุกปี จนถึงครั้งที่ 4 ในปี 2551 ผู้เข้าร่วมเปิดบูธในงาน ประกอบด้วย AMC รัฐและเอกชน รวมถึงธนาคารพาณิชย์ 10 แห่ง บริษัทนายหน้าอสังหาริมทรัพย์ 24 แห่ง มีทรัพย์ที่เสนอขายในงานเกือบ 20,000 รายการ โดยจะมีการคัดทรัพย์เด่น 2,000 รายการเสนอให้ผู้สนใจเข้าชมทรัพย์ก่อนตัดสินใจซื้อ ผ่านเว็บตลาดบ้านมือสองที่ศูนย์ข้อมูลฯ พัฒนาขึ้น ในชื่อ www.resalehomethai.com เพื่อประชาสัมพันธ์การจัดงานและเป็นแหล่งรวมบ้านมือสองประกาศขายจากหน่วยงานต่าง ๆ ที่เข้าร่วมงาน ให้ประชาชนและผู้สนใจ สามารถสืบค้นข้อมูลบ้านมือสองที่มีในงานได้โดยสะดวก 
                ภายหลังเว็บดังกล่าวได้พัฒนามาเป็นเว็บประกาศซื้อ-ขาย บ้านมือสอง สำหรับหน่วยงานภาครัฐ เอกชน AMC รัฐและเอกชน รวมถึงธนาคารพาณิชย์ บริษัทนายหน้าอสังหาริมทรัพย์ และประชาชนทั่วไป สามารถนำทรัพย์มาลงประกาศได้โดยไม่คิดค่าใช้จ่าย ในชื่อ www.clickthaihome.com เพื่อสนับสนุนตลาดบ้านมือสองของประเทศตามนโยบายของรัฐบาล ซึ่งจุดเด่นของเว็บคือ มีข้อมูลบ้านมือสองที่มากที่สุดในประเทศ โดยเฉพาะข้อมูลบ้านมือสองจากกรมบังคับคดี ที่มีมากกว่า สองแสนรายการ ในฐานข้อมูล

                </p>
              </div>
            </span>
          </div>
          <div className="timeline">
            <span  className="timeline-content">
              <span className="year">2565 </span>
              <div className="inner-content">
                <h3 className="title">Re-Branding</h3>
                <p className="description">
                ปัจจุบัน ปี 2565 ศูนย์ข้อมูลฯ ได้ทำการ re-branding และปรับปรุงเว็บ www.clickthaihome.com ให้มีความทันสมัย สวยงาม เพิ่ม Function การใช้งานที่อำนวยความสะดวกแก่ผู้ใช้มากยิ่งขึ้น เพื่อมอบประสบบการณ์การใช้งานที่ดีแก่ผู้ใช้
                </p>
              </div>
            </span>
          </div>
        
        </div>
       
    </div>

    </div>

  </div>

    


    
        </div>
      </div>
    );
  }
}

export default About;
