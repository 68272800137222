import React, { Component } from 'react';
import './../home/home.css'

import {server, imageUrl} from './../../constants/index'
import { httpClient }  from './../../utils/HttpClient'
import NumberFormat from "react-number-format";

const Region_Config = [
  {region: 'P', region_img: 'images/popular-location-04.jpg'},
  {region: 'C', region_img: 'images/popular-location-05.jpg'},
  {region: 'N', region_img: 'images/popular-location-06.jpg'},
  {region: 'S', region_img: 'images/popular-location-01.jpg'},
  {region: 'E', region_img: 'images/popular-location-02.jpg'},
  {region: 'NE', region_img: 'images/popular-location-03.jpg'},
]


class AllArea extends Component {

  constructor(props) {
    super(props);
    this.state = {_bangkok_path1: null, 
                  _bangkok_path2: null,
                  _bangkok_path3: null,

                  _p_zone: null,
                  _c_zone: null,
                  _n_zone: null,
                  _ne_zone: null,
                  _e_zone: null,
                  _s_zone: null,

                  _top3: null,
                }
  }

  async componentDidMount(){
    let result = await httpClient.get(server.ASSET_URL + '/allarea')
    let _bangkok = result.data.bangkok
    let _provinces = result.data.provinces
    let _region = result.data.region

    this.setState({_top3: await this.renderCaroesel(_region.slice(0, 3))})

    this.setState({_bangkok_path1: await this.renderBangkokItems(_bangkok.slice(0, 20))})
    this.setState({_bangkok_path2: await this.renderBangkokItems(_bangkok.slice(20, 40))})
    this.setState({_bangkok_path3: await this.renderBangkokItems(_bangkok.slice(40, 51))})

    let p_zone = _provinces.filter(item => {if(item.Region == 'P' && item.Province_ID != '10'){return true;}})
    this.setState({_p_zone: await this.renderProvinceItems(p_zone)})

    let c_zone = _provinces.filter(item => {if(item.Region == 'C'){return true;}})
    this.setState({_c_zone: await this.renderProvinceItems(c_zone)})

    let n_zone = _provinces.filter(item => {if(item.Region == 'N'){return true;}})
    this.setState({_n_zone: await this.renderProvinceItems(n_zone)})

    let ne_zone = _provinces.filter(item => {if(item.Region == 'NE'){return true;}})
    this.setState({_ne_zone: await this.renderProvinceItems(ne_zone)})

    let e_zone = _provinces.filter(item => {if(item.Region == 'E'){return true;}})
    this.setState({_e_zone: await this.renderProvinceItems(e_zone)})

    let s_zone = _provinces.filter(item => {if(item.Region == 'S'){return true;}})
    this.setState({_s_zone: await this.renderProvinceItems(s_zone)})

    // console.log()

    
  }

  async renderBangkokItems(data) {
    if(data && data.length > 0) {
      return (
        data.map(item => (
          <li key={item.Province_ID + item.Amphoe_Name}>
            <a href={"listings?p=" + item.Province_ID + '&d=' + item.Amphoe_ID} target="_blank">{item.Amphoe_Name}</a>
            <NumberFormat
                value={item._count}
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value, props) => <span {...props}>{value}</span>}
              />
            </li>  
        ))
      )
    } else {
      return null;
    }
  }

  async renderProvinceItems(data) {
    if(data && data.length > 0) {
      return (
        data.map(item => (
          <li key={item.Province_ID + item.Province_Name}>
            <a href={"listings?p=" + item.Province_ID} target="_blank">{item.Province_Name}</a>
            <NumberFormat
                value={item._count}
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value, props) => <span {...props}>{value}</span>}
              />
            </li>  
        ))
      )
    } else {
      return null;
    }
  }


  async renderCaroesel(data) {
    return (
        <div className="row" style={{marginLeft: '-5px', marginRight: '-5px'}}>      
          <div className="col-md-5 col-sm-6"> 
            <a className="img-box" target="_blank" href={"listings?region=" + data[0].Region}>
              <img src={Region_Config.filter(item => {if(item.region == data[0].Region){return true;}})[0].region_img}/>
              <div className="utf-cat-img-box-content visible">
                <h4>{data[0].Region_Name}</h4>
                <NumberFormat
                value={data[0]._count}
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value, props) => <span {...props}>{value} รายการ</span>}
              />
              </div>
            </a> 
          </div>
          <div className="col-md-4 col-sm-6"> 
            <a className="img-box" target="_blank" href={"listings?region=" + data[1].Region}>
              <img src={Region_Config.filter(item => {if(item.region == data[1].Region){return true;}})[0].region_img}/>
              <div className="utf-cat-img-box-content visible">
                <h4>{data[1].Region_Name}</h4>
                <NumberFormat
                value={data[1]._count}
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value, props) => <span {...props}>{value} รายการ</span>}
              />
              </div>
            </a> 
          </div>
          <div className="col-md-3 col-sm-6"> 
            <a className="img-box" target="_blank" href={"listings?region=" + data[2].Region}>
              <img src={Region_Config.filter(item => {if(item.region == data[2].Region){return true;}})[0].region_img}/>
              <div className="utf-cat-img-box-content visible">
                <h4>{data[2].Region_Name}</h4>
                <NumberFormat
                value={data[2]._count}
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value, props) => <span {...props}>{value} รายการ</span>}
              />
              </div>
            </a> 
          </div>	            
        </div>
    )
  }




  render() {
    return <div>
        {/* Titlebar */}
        <div className="parallax titlebar" data-background="images/popular-location-03.jpg" data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>รายการทรัพย์ตามภูมิภาค</h2>
                  {/* Breadcrumbs */}
                  <nav id="breadcrumbs">
                    <ul>
                      <li><a href="home">หน้าแรก</a></li>
                      <li>รายการทรัพย์ตามภูมิภาค</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>



       <section className="fullwidth margin-bottom-0 padding-top-60 padding-bottom-65" data-background-color="linear-gradient(to bottom,rgba(0,0,0,0.03) 0%,rgba(255,255,255,0))"> 
          {/* Logo Carousel */}
          <div className="container">
            <div className="row">
              <div className="col-md-12">	
                <div className="utf-section-headline-item centered margin-bottom-30 margin-top-0">
                  <h3 className="headline"><span style={{background: 'none', boxShadow: 'none'}}> &nbsp;</span> TOP 3  ทำเลที่ถูกเลือกดูมากที่สุด</h3>
                  <div className="utf-headline-display-inner-item"> Most Popular Region</div>            
                </div>  
              </div>    
              {/* Carousel */}
              <div className="col-md-12">         
                {this.state._top3}
                <div className="col-md-12">	
                  <div className="utf-section-headline-item centered margin-bottom-30 margin-top-0">
                    <h3 className="headline"><span style={{background: 'none', boxShadow: 'none'}}> &nbsp;</span>  เลือกดูรายการทรัพย์ตามภูมิภาค </h3>
                    <div className="utf-headline-display-inner-item"> Most Popular Region</div>
                    <p style={{marginTop: 10}}>ค้นหาบ้านมือสองกับย่านที่ชอบ สำรวจที่อยู่อาศัยในทำเลที่ต้องการ เพื่อรับข้อมูลพื้นฐาน ศักยภาพทำเล รีวิว เสมือนไปสำรวจด้วยตัวเอง</p>
                  </div>  
                </div>  
                <div className="row">
                  <div className="col-md-12">  
                    <div className="row">
                      <div className="col-md-12">
                        <div className="widget utf-sidebar-widget-item">
                          <div className="utf-boxed-list-headline-item">
                            {/* <h3>กรุงเทพฯ - ปริมณฑล</h3> */}
                            <div className="utf-sidebar-categorie"> 
                              <div className="row">
                                <div className="col-md-4"> 
                                  <ul>
                                    <div className="utf-boxed-list-headline-item" style={{marginTop: 10}}>
                                      <h3 style={{marginBottom: 5}}>เขตกรุงเทพฯ</h3> 
                                    </div>  
                                    {this.state._bangkok_path1}
                                  </ul>
                                </div>
                                <div className="col-md-4"> 
                                  <ul>
                                    <div style={{marginTop: 17}} />  
                                    {this.state._bangkok_path2}
                                  </ul>
                                </div>
                                <div className="col-md-4"> 
                                  <ul>                 
                                    <div style={{marginTop: 17}} />                  
                                    {this.state._bangkok_path3}
                                    <div className="utf-boxed-list-headline-item" style={{marginTop: 10}}>
                                      <h3 style={{marginBottom: 5}}>เขตปริมณฑล</h3> 
                                    </div>  
                                    {this.state._p_zone}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>              
                        </div>
                      </div>
                    </div>    
                    <div className="row">
                      <div className="col-md-3">
                        <div className="widget utf-sidebar-widget-item">
                          <div className="utf-boxed-list-headline-item">
                            <h3 style={{marginBottom: 5}}>ภาคกลาง</h3>
                            <div className="utf-sidebar-categorie"> 
                              <ul>
                                {this.state._c_zone}
                                <div className="margin-top-105" />          
                              </ul>
                            </div>
                          </div>              
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="widget utf-sidebar-widget-item">
                          <div className="utf-boxed-list-headline-item">
                            <h3 style={{marginBottom: 5}}>ภาคเหนือ</h3>
                            <div className="utf-sidebar-categorie"> 
                              <ul>
                                {this.state._n_zone} 
                                <div className="margin-top-75" />            
                              </ul>
                            </div>
                          </div>              
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="widget utf-sidebar-widget-item">
                          <div className="utf-boxed-list-headline-item">
                            <h3 style={{marginBottom: 5}}>ภาคตะวันออกเฉียงเหนือ</h3>
                            <div className="utf-sidebar-categorie"> 
                              <ul>
                                {this.state._ne_zone}     
                                <div className="margin-top-15" />               
                              </ul>
                            </div>
                          </div>              
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="widget utf-sidebar-widget-item">
                          <div className="utf-boxed-list-headline-item">
                            <h3 style={{marginBottom: 5}}>ภาคตะวันออก</h3>
                            <div className="utf-sidebar-categorie"> 
                              <ul>
                                {this.state._e_zone}
                                <div className="utf-boxed-list-headline-item" style={{marginTop: 10}}>
                                  <h3 style={{marginBottom: 5}}>ภาคใต้ </h3> 
                                </div>  
                                {this.state._s_zone}                                           
                              </ul>
                            </div>
                          </div>              
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                {/* <div className="utf-logo-carousel-item dot-navigation">
                  <div className="item">   
                    <a href="listings-list-with-sidebar.html" className="img-box">
                      <img src="images/popular-location-04.jpg" alt />
                      <div className="utf-cat-img-box-content visible">
                        <h4>กรุงเทพและปริมณฑล</h4>
                        <span> 5,000 โครงการ</span> 
                      </div>
                    </a>                 
                  </div>
                  <div className="item">   
                    <a href="listings-list-with-sidebar.html" className="img-box">
                      <img src="images/popular-location-04.jpg" alt />
                      <div className="utf-cat-img-box-content visible">
                        <h4>กรุงเทพและปริมณฑล</h4>
                        <span> 5,000 โครงการ</span> 
                      </div>
                    </a>                 
                  </div>
                  <div className="item">   
                    <a href="listings-list-with-sidebar.html" className="img-box">
                      <img src="images/popular-location-04.jpg" alt />
                      <div className="utf-cat-img-box-content visible">
                        <h4>กรุงเทพและปริมณฑล</h4>
                        <span> 5,000 โครงการ</span> 
                      </div>
                    </a>                 
                  </div>
                  <div className="item">   
                    <a href="listings-list-with-sidebar.html" className="img-box">
                      <img src="images/popular-location-04.jpg" alt />
                      <div className="utf-cat-img-box-content visible">
                        <h4>กรุงเทพและปริมณฑล</h4>
                        <span> 5,000 โครงการ</span> 
                      </div>
                    </a>                 
                  </div>
                  <div className="item">   
                    <a href="listings-list-with-sidebar.html" className="img-box">
                      <img src="images/popular-location-04.jpg" alt />
                      <div className="utf-cat-img-box-content visible">
                        <h4>กรุงเทพและปริมณฑล</h4>
                        <span> 5,000 โครงการ</span> 
                      </div>
                    </a>                 
                  </div>
                  <div className="item">   
                    <a href="listings-list-with-sidebar.html" className="img-box">
                      <img src="images/popular-location-04.jpg" alt />
                      <div className="utf-cat-img-box-content visible">
                        <h4>กรุงเทพและปริมณฑล</h4>
                        <span> 5,000 โครงการ</span> 
                      </div>
                    </a>                 
                  </div>
                  <div className="item">   
                    <a href="listings-list-with-sidebar.html" className="img-box">
                      <img src="images/popular-location-04.jpg" alt />
                      <div className="utf-cat-img-box-content visible">
                        <h4>กรุงเทพและปริมณฑล</h4>
                        <span> 5,000 โครงการ</span> 
                      </div>
                    </a>                 
                  </div>
                  <div className="item">   
                    <a href="listings-list-with-sidebar.html" className="img-box">
                      <img src="images/popular-location-04.jpg" alt />
                      <div className="utf-cat-img-box-content visible">
                        <h4>กรุงเทพและปริมณฑล</h4>
                        <span> 5,000 โครงการ</span> 
                      </div>
                    </a>                 
                  </div>
                </div>    */}


              </div>
              {/* Carousel / End */}         
            </div>
          </div>
          {/* Logo Carousel / End */} 
        </section>




    </div>;
  }
}

export default AllArea;
