import React, { Component } from 'react';
import './calculator.css'
import Calculator_content from './calculator_content';

class Calculator extends Component {


  render() {
    return <div>


      <div className="parallax titlebar" lazyload="true" data-background="images/popular-location-03.jpg" data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
        <div id="titlebar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>เครื่องมือคำนวนสินเชื่อ</h2>
                <nav id="breadcrumbs">
                  <ul>
                    <li><a href="index.html">หน้าแรก</a></li>
                    <li>เครื่องมือคำนวนสินเชื่อ</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="wrapper">
          <div className="container">
            <Calculator_content />
        </div>
      </div>
    </div>;
  }
}

export default Calculator;
