import React, { Component } from "react";
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import {encrytion, decryption} from './../../utils/EnCryption'
import queryString from 'query-string';
import parse from 'html-react-parser';
import newsComponent from "./../asset/asset";
import {ConvDate_format} from './../default-class/default-class'

const news = new newsComponent();

class NewsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _data: null,
      _news: null,
      _recommend: null,
      _title: null
    }
  }
  

  async componentDidMount() {

    let url = window.location.search
    let params = queryString.parse(url);
    let result = await httpClient.get(server.NEWS_URL + "/getNewsDetail/" + params['p'])
    let result2 = await httpClient.get(server.NEWS_URL + "/getNewsRelate/T0dFeE1YWmpZWFpWY2poYVFtdEJhRVphVkd0M1pEUTFTVnBzV2xsM01VUlhVVTlyWkV3NFNubFdUSGhQV1dSWlQwOXZUalF5WkhvMWFrdFZlWFZyTWxsb1FWUllSa2N6V205VFJEUTFibng4VDBGMFpHTTBjVEo2VFRoMFNFaHhWRUkyV214TE4zbHhZMkYyY0dOcU9VRktkMk42VjBkTU5VOXFTVEJxWWtoSE1GZGpha3RwZDBoNVltZzVmSHd6Zkh4QldtdHlibGRqTWpkVU0yTkhObWRNWmtkUlpreFpkbmxtUWpsbFMxbzRXRTQwYlRoeVVYWnFhamxaY0hsRg==")
  
    

    this.setState({_news: await news.renderNews(result2.slice(0,5))})
    this.setState({_data: await this.renderGroup(result)})

  }

  async renderGroup(result){
  
    return (
      result.map(item => (

        <div className="col-md-8" key={item.id}> 
        <div className="row">
        <div className="col-md-12">
        <div className="utf-section-headline-item centered margin-bottom-0 margin-top-20">
              <h3 className="headline">{item.pb_title}</h3> 
            <br /> 
          </div>  
        </div>
      </div>
          <div className="utf-listings-container-area list-layout"> 
          <div className="col-md-12" >
        {/* <img src={item.images_blog} alt= {item.pb_title} /> */}
        <h3>{item.pb_title}</h3>
        <p>{parse(item.pb_des ? `${item.pb_des}` : '')}</p>
        <p>{parse(item.pb_info ? `${item.pb_info}` : '')}</p>
        <hr />
        อัพเดทวันที่ {ConvDate_format(item.pb_date_news, 'TH', '/')} 
      </div>
           </div>
        </div>
 
    ))
    )
  }

  
  async convertGroupId(val){
    val = await decryption(val)
    if(val == 1){
      val = "ข่าวนโยบายการเงิน-การคลัง"
    }
    else if(val == 2){
      val = "ข่าวโครงการอสังหาฯ ภาครัฐ"
    }
    else if(val == 3){
      val = "ข่าวโครงการอสังหาฯ ภาคเอกชน"
    }
    else if(val == 4){
      val = "ข่าวกฎหมายอสังหาฯ"
    }
    else if(val == 5){
      val = "ข่าวพัฒนาสาธารณูปโภค"
    }
    else if(val == 6){
      val = "ข่าวบ้านมือสอง"
    }
    else if(val == 7){
      val = "ข่าววัสดุก่อสร้าง-เฟอร์นิเจอร์"
    }
    else if(val == 8){
      val = "ข่าวเขตเศรษฐกิจพิเศษ"
    }
    else if(val == 9){
      val = "ข่าว reic จากสื่อสิ่งพิมพ์"
    }
    else if(val == 52){
      val = "ข่าวอสังหาริมทรัพย์ภูมิภาค"
    }
    return val
  }


  render() {
    return (
      <div id="wrapper">

      <div className="parallax titlebar" lazyload="true" data-background="images/popular-location-03.jpg" data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
        <div id="titlebar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>ข่าวอสังหาริมทรัพย์</h2>
                <nav id="breadcrumbs">
                  <ul>
                    <li><a href="index.html">หน้าแรก</a></li>
                    <li>บทความที่น่าสนใจ</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

<div className="container">

  <div className="row sticky-wrapper">
 
     {this.state._data}
    <div className="col-md-4">
      <div className="sidebar"> 
        {/* Widget */}
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-detail-banner-add-section">
            <a href="#">
         <img src="images/banner/asset_right_banner.png" alt="banner-add-2" lazyload="true" />
         </a>
          </div>             
        </div>
    
    
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>ข่าวใหม่แนะนำ</h3>
          </div>
          {this.state._news}
       
        </div>
       
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>หมวดหมู่บทความ</h3>
            <div className="utf-sidebar-categorie"> 
              <ul>
                <li>ประมูลอสังหาฯ</li> 
                <li>กู้ซื้อบ้าน</li> 
                <li>โปรโมชั่น</li> 
                <li>คำแนะนำการซื้อบ้าน</li> 
                <li>Partner content</li> 
                <li>สารพันปัญหาน่ารู้</li>
                <li>เพื่อการลงทุน</li>
                <li>ฮวงจุ้ยที่อยู่อาศัย</li>
                <li>ตรวจสอบก่อนซื้อที่อยู่อาศัย</li>    
                        
              </ul>
            </div>
          </div>              
        </div>
        {/* Widget / End*/}
        {/* Widget */}
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>คำที่ค้นหาบ่อย</h3>
            <div className="task-tags"> 
              <a href="#"><span>Partner content</span></a> 
              <a href="#"><span>กู้ซื้อบ้าน</span></a> 
              <a href="#"><span>คำแนะนำ</span></a> 
              <a href="#"><span>ตรวจสอบก่อนซื้อ</span></a>  
              <a href="#"><span>บทความน่ารู้</span></a>     
              <a href="#"><span>ประมูลอสังหา</span></a> 
              <a href="#"><span>ฮวงจุ้ย</span></a>        
            </div>
          </div>              
        </div>
 
        <div className="widget utf-sidebar-widget-item">
          <div className="utf-boxed-list-headline-item">
            <h3>Social Sharing</h3>
          </div>
          <ul className="utf-social-icons rounded">
            <li><a className="facebook" href="#"><i className="icon-facebook" /></a></li>
            <li><a className="twitter" href="#"><i className="icon-twitter" /></a></li>
          </ul>
          <div className="clearfix" />
        </div>
        {/* Widget / End*/}                 
        <div className="clearfix" />  
      </div>
    </div>
    {/* Sidebar / End */} 
  </div>
</div>

  
        <div className="margin-top-65" />
        <div id="backtotop"><a href="#" /></div>
      </div>
      // <div id="wrapper">   
      //   <div className="parallax titlebar" data-background="images/Hora/top_banner.png" data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
      //     <div id="titlebar">
      //       <div className="container">
      //         <div className="row">
      //           <div className="col-md-12">
      //             <h3>ข่าวอสังหาริมทรัพย์</h3>
      //             {/* Breadcrumbs */}
      //             <nav id="breadcrumbs">
      //               <ul>
      //                 <li><a href="index.html">หน้าแรก  </a></li>
      //                 <li>ข่าวอสังหาริมทรัพย์</li>
      //               </ul>
      //             </nav>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //   {/* Content */}
      //   <div className="container"> 
      //     <div className="blog-page">
      //       <div className="row">
      //       <div className="col-md-8"> 
      //         {/* Blog Post */}
      //         <div className="blog-post"> 
      //           <div className="row">
      //             { this.state._data }
      //           </div>
      //         </div>
      //           {/* News Post / End */}  
      //         </div>
      //         {/* Widgets */}
      //         <div className="col-md-4">
      //           <div className="sidebar"> 
      //             {/* Widget */}
      //             <div className="widget utf-sidebar-widget-item">
      //               <div className="utf-detail-banner-add-section">
      //                 <a href="#"><img src="images/Hora/logo_horo.png" alt="banner-add-2" /></a>
      //               </div>             
      //             </div>
      //             {/* Widget / End*/}
      //             {/* Widget */}
      //             <div className="widget utf-sidebar-widget-item">
      //               <div className="utf-boxed-list-headline-item">
      //                 <h3>ข่าวอสังหาริมทรัพย์ ภาคเอกชน</h3>
      //               </div>
      //               {this.state._news}
      //             </div>
      //             {/* Widget / End*/} 
                     
      //             <div className="clearfix" />
      //           </div>
      //         </div>
              
      //         {/* Blog Posts / End */} 
      //       </div>
      //       {/* Sidebar / End */}       
      //     </div>
      //   </div>
        
        
      //   <div className="margin-top-65" />
      //   <div id="backtotop"><a href="#" /></div>
      // </div>
    );
  }
}

export default NewsDetail;

