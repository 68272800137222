import React, { Component } from 'react';
import UserMenu from './../user-menu';
import { httpClient } from "../../utils/HttpClient";
import { server} from "../../constants";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)


class UserChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '', 
      newPassword: '',
      confirmPassword: '',

      currentPassword_Valid: false, 
      newPassword_Valid: false, 
      confirmPassword_Valid: false, 

      formValid: false, 
      formError: {
        currentPassword: '', 
        newPassword: '',
        confirmPassword: '',
      },
    }
  }

  componentDidMount() {
    document.title = 'เปลี่ยนรหัสผ่าน | Clickthaihome.com'
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
                  () => { this.ValidateField(name, value) });
  }

  async ValidateField(fieldName, value) {
    let fieldValidationErrors = this.state.formError;
    let currentPassword_Valid = this.state.currentPassword_Valid;
    let newPassword_Valid = this.state.newPassword_Valid;
    let confirmPassword_Valid = this.state.confirmPassword_Valid;


    switch(fieldName) {
      case 'currentPassword':
        currentPassword_Valid = value.length >= 6;
        fieldValidationErrors.currentPassword = currentPassword_Valid ? '' : 'รหัสผ่านสั้นเกินไป';
        break;
        case 'newPassword':
          newPassword_Valid = value.length >= 6;
          fieldValidationErrors.newPassword = newPassword_Valid ? '' : 'รหัสผ่านสั้นเกินไป';
  
          if(this.state.confirmPassword != ''){
            confirmPassword_Valid = value == this.state.confirmPassword;
            fieldValidationErrors.confirmPassword = confirmPassword_Valid ? '' : 'รหัสผ่านไม่ตรงกัน';
          }
  
          break;
        case 'confirmPassword':
            confirmPassword_Valid = value.length >= 6;
            fieldValidationErrors.confirmPassword = confirmPassword_Valid ? '' : 'รหัสผ่านสั้นเกินไป';
            if(confirmPassword_Valid){
              confirmPassword_Valid = value == this.state.newPassword;
              fieldValidationErrors.confirmPassword = confirmPassword_Valid ? '' : 'รหัสผ่านไม่ตรงกัน';
            }
            
          break;

      default:
        break;
    }

    await this.setState({formError: fieldValidationErrors,
                         currentPassword_Valid: currentPassword_Valid,
                         newPassword_Valid: newPassword_Valid,
                         confirmPassword_Valid: confirmPassword_Valid,
                  }, await this.ValidateForm);
  }

  ValidateForm() {
    this.setState({formValid: this.state.currentPassword_Valid  
                            && this.state.newPassword_Valid
                            && this.state.confirmPassword_Valid
                            });
  }

  async save(){
    let values = {Member_ID: localStorage.getItem('Member_ID'), 
                  currentPassword: this.state.currentPassword, 
                  newPassword: this.state.newPassword}
    let result = await httpClient.post(server.MEMBER_URL + '/changepassword', values)
    if(result.result == 'ok'){
      Swal.fire({
        icon: 'success',
        title: 'เปลี่ยนรหัสผ่านสำเร็จ',
        confirmButtonText: 'ตกลง',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.props.history.push('/profile')
        } 
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ผิดพลาด',
        text: 'รหัสผ่านไม่ถูกต้อง !',
        confirmButtonText: 'ตกลง',
      })
    }
  }

  render() {
    const ValidStypes = {color: 'red', fontSize: '14px', marginBottom: "-5px"}

    return <div>
      <div ref={this.myRef} className="parallax titlebar" data-background="images/visual_banner_desktop_2.jpg" 
           data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
        <div id="titlebar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>เปลี่ยนรหัสผ่าน</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li><a href="home">หน้าแรก</a></li>
                    <li>เปลี่ยนรหัสผ่าน</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row"> 
          <div className="col-md-3">
            <UserMenu/>
          </div>

          <div className="col-md-9">
            <div className="utf-user-profile-item">
              <div className="utf-submit-page-inner-box">
                <h3><i className="sl sl-icon-key" /> เปลี่ยนรหัสผ่าน</h3>
                <div className="content with-padding">
                  <div className="col-md-4">
                    <label>รหัสผ่านปัจจุบัน *</label>
                    <input type="password" 
                           name="currentPassword"
                           value={this.state.currentPassword}
                           onChange={this.handleUserInput}
                           placeholder="รหัสผ่านปัจจุบัน" />
                    {
                      this.state.formError.currentPassword != '' ? <p style={ValidStypes}> {this.state.formError.currentPassword} </p>  : null
                    }
                  </div>
                  <div className="col-md-4">
                    <label>รหัสผ่านใหม่ *</label>
                    <input type="password" 
                           name="newPassword"
                           value={this.state.newPassword}
                           onChange={this.handleUserInput}
                           placeholder="รหัสผ่านใหม่" />
                    {
                      this.state.formError.newPassword != '' ? <p style={ValidStypes}> {this.state.formError.newPassword} </p>  : null
                    }
                  </div>
                  <div className="col-md-4">
                    <label>ยืนยันรหัสผ่าน *</label>
                    <input type="password" 
                           name="confirmPassword"
                           value={this.state.confirmPassword}
                           onChange={this.handleUserInput} 
                           placeholder="ยืนยันรหัสผ่าน" />
                    {
                      this.state.formError.confirmPassword != '' ? <p style={ValidStypes}> {this.state.formError.confirmPassword} </p>  : null
                    }
                  </div>
                  <div className="col-md-4">
                    <a href="forgot-password" className="forgot-password"><u><h5>ลืมรหัสผ่าน ?</h5></u></a>	
                  </div>
                </div>		
              </div>  
              <div className="row">
                  <div className="col-md-12">
                    <center>
                      {" "}
                      <button className="utf-centered-button margin-top-30 button" 
                              onClick={async (e) => {
                                if(this.state.formValid){
                                  Swal.fire({
                                    title: 'ต้องการเปลี่ยนรหัสผ่านใช่หรือไม่?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'ใช่',
                                    cancelButtonText: 'ยกเลิก'
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      this.save()
                                    } 
                                  })
                                } else {
                                  await this.ValidateField('currentPassword', this.state.currentPassword)
                                  await this.ValidateField('newPassword', this.state.newPassword)
                                  await this.ValidateField('confirmPassword', this.state.confirmPassword)
                                }
                                }}>
                        <i className="fa fa-save" aria-hidden="true" /> เปลี่ยนรหัสผ่าน
                      </button>
                    </center>
                    <br />
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>


    </div>;
  }
}

export default UserChangePassword;
