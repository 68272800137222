import React, { Component  } from 'react';
import UserMenu from './../user-menu/user-menu'
// import {encrytion, decryption, CryptoEncode} from './../../utils/EnCryption';
import { httpClient } from "../../utils/HttpClient";
import { server  } from "../../constants";
// import queryString from 'query-string';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./add-new-property.css";
import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
import parse from 'html-react-parser';
import NumberFormat from "react-number-format";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import Datepicker from "react-datepicker";
// import moment from 'moment';
// import $ from 'jquery'; 

// import 'leaflet/dist/leaflet.css';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale  } from "react-datepicker";
import th from "date-fns/locale/th";

// import L from 'leaflet';

import asset_type from "./../default_files/asset_type.json";
import asset_facilities from "./../default_files/asset_facilities.json";
import province from "./../default_files/province.json";
import amphur from "./../default_files/amphur.json";
import tumbon from "./../default_files/tumbon.json";

import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import "react-leaflet-markercluster/dist/styles.min.css"; // sass

import "leaflet-switch-scale-control";
import "leaflet-switch-scale-control/src/L.Control.SwitchScaleControl.css";
import "leaflet.locatecontrol";

// import MarkerClusterGroup from "react-leaflet-markercluster";


registerLocale("th", th);

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

// const iconPerson = new L.Icon({
//     iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
//     shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
// });

// const MySwal = withReactContent(Swal)

var theMarker = {}

class AddNewProperty extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      tapsPageSelected: 'discover',

      provincesOptions: <option value={""}>จังหวัด</option>,
      provinceSelceted: '',
      amphoesLists: null,
      amphoeOptions: <option value={""}>อำเภอ/เขต</option>,
      amphoeSelected: '',
      tumbonsLists: null,
      tumbonOptions: <option value={""}>ตำบล/แขวง</option>,
      tumbonSelected: '',

      Asset_ID : '', 
      Update_ID : '', 
      Asset_Moo : '', 
      Asset_Project : '', 
      Ref_Code:'',
      Asset_Road : '', 
      Asset_Soi : '', 
      Asset_Address_No : '', 
      Asset_Type : '', 
      Sub_Asset_Type : '', 
      Document_Ref : '', 
      rai: 0,
      ngan: 0,
      wa: 0,
      Asset_Usage_Area : 0, 
      Asset_Built_Year : '', 
      Building_Floor : 0, 
      Asset_Floor : 0, 
      Number_Bed_Room : 0, 
      Number_Living_Room : 0, 
      Number_Bath_Room : 0, 
      Number_Kitchen : 0, 
      Number_Car_Park : 0, 
      Number_Air_Condition : 0, 
      // Asset_OriginalY : 13.7618965, 
      // Asset_OriginalX : 100.5688152, 
      Asset_OriginalY : 0.0, 
      Asset_OriginalX : 0.0, 
      Asset_Price : 0, 
      Apprisal_Price : 0, 
      Asset_Common_Fee: 0,
      Action_Type : '', 
      Cat_Type:'',
      Asset_Detail : '', 
      Update_By : localStorage.getItem("Member_ID"), 
      Asset_Transfer_Fee_ID: 0, 
      Asset_Transfer_Fee_Name: 'ไม่ระบุ', 
      youtube_url : '', 
      Url_img_360 : '',
      Asset_Facilities : [], 

      Main_Img: 'images/single-property-01.jpg', // File for Upload
      Main_Img_Name: 'single-property-01.jpg',

      imageDetails: [], // File for Upload {filename: 'name', img: base64}
      imagesShow: [], //<div><img src=""/></div>
      
      displayImgs: [],
      displayImgsDiv: [],

      Sub_Asset_Type_name: 'ไม่มีข้อมูล',
      Action_Type_name: 'ไม่มีข้อมูล',
      Cat_Type_name:'ไม่มีข้อมูล',
      province_name: '', 
      amphoe_name: '', 
      tumbon_name: '',

      Promotion_price: 0,
      Promo_start_date: '',
      Promo_end_date: '',

      pdate: new Date(),
      consent: false,

      // ----- form1 Valid ----- //
      Sub_Asset_Type_Valid : false, 
      Action_Type_Valid : false,
      Cat_Type_Valid:false,
      Asset_Project_Valid : false,
      provinceSelceted_Valid : false,
      amphoeSelected_Valid: false,
      tumbonSelected_Valid: false,

      formValid1: false,
      formError1: {
        Sub_Asset_Type : '', 
        Action_Type : '', 
        Cat_Type: '', 
        Asset_Project : '', 
        provinceSelceted: '',
        amphoeSelected: '',
        tumbonSelected: '',
      },

      // ----- form2 Valid ----- //
      Asset_Floor_Valid: true, 

      formValid2: false, 
      formError2: {
        Asset_Floor: 0
      },

      // ----- form3 Valid ----- //
      Asset_Price_Valid: false, 
      Promotion_price_Valid: true, 
      Promo_start_Valid: true, 
      Promo_end_Valid: true, 

      formValid3: false, 
      formError3: {
        Asset_Price: 0,
        Promotion_price: 0,
        Promo_start_date: '',
        Promo_end_date: '',
      },

      // ----- form4 Valid ----- //
      Main_Img_Valid: false, 

      formValid4: false, 
      formError4: {
        Main_Img: [],
      }, 

      // ----- form5 Valid ----- //
      consent_Valid: false, 

      formValid5: false, 
      formError5: {
        consent: false,
      }, 

    }
  }

  async componentDidMount(){

    document.title = 'ประกาศทรัพย์ใหม่ | Clickthaihome.com'

    let noselect = [{ CODE: "", NAME: "จังหวัด" }];
    let provinceRows = await this.renderOptions([...noselect, ...province]);

    await this.setState({ provincesOptions: provinceRows, 
                          amphoesLists: amphur, 
                          tumbonsLists: tumbon, 
    });


    noselect = [{ CODE: "", NAME: "อำเภอ/เขต" }];
    let amphoes = await this.state.amphoesLists.filter(this.findAmphoe);
    this.setState({
      amphoeOptions: await this.renderOptions([...noselect, ...amphoes]),
    });

    noselect = [{ CODE: "", NAME: "ตำบล/แขวง" }];
    let tumbons = await this.state.tumbonsLists.filter(this.findTumbon);
    await this.setState({
      tumbonOptions: await this.renderOptions([...noselect, ...tumbons]),
    });

    noselect = [{ID: '',  NAME: "ประเภทอสังหาฯ"}]
    let assetrows = await this.renderAssetType([...noselect,...asset_type])
    this.setState({assetOptions: assetrows})

    
    var years = new Date().getFullYear() + 543;
    noselect = [{ID: '',  NAME: "ปีที่สร้างเสร็จ"}]
    for (var i = 0; i < 50; i++) {
      noselect = [...noselect, {ID: years - i, NAME: years - i}]
    }
    let yearsrows = await this.renderOptions(noselect)
    this.setState({yearsOptions: yearsrows})


    this.mapOption();
  }


  mapOption(locations = []) {
    let self = this;
    const heremap_appID = "W336uytfRUG9kKud1fnj";
    const heremap_appCode = "eYy0bLdL7xizsFDZkBfJGQ";
    let houseLayer = new L.MarkerClusterGroup();
    if(locations.length > 0){
      for (var i = 0; i < locations.length; i++) {
        houseLayer.addLayer(L.marker(locations[i]));
      }
    }
    theMarker = houseLayer
    // houseLayer.addLayer(L.marker([13, 100], { icon: Icon.icon_house_red }));
    // houseLayer.addLayer(L.marker([13.01, 100.1]));
    // houseLayer.addLayer(L.marker([13.021, 100.2]));
    // houseLayer.addLayer(L.marker([13.031, 100.3]));
    // houseLayer.addLayer(L.marker([13.015, 100.15]));
    // houseLayer.addLayer(L.marker([13, 100]));

    let osm_map = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      {
        attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors', 
        maxZoom: 19,
        minZoom: 6
      }
    );

    let sattelite = L.tileLayer("https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png?app_id=" + heremap_appID + "&app_code=" + heremap_appCode,
      {
        attribution: '&copy; <a href="https://www.here.com">HERE Technologies | HERE </a>', 
        maxZoom: 19,
        minZoom: 6
      }
    );

    // let powermap = L.tileLayer(
    //   "https://maps.powermap.live/api/v2/map/vtile/thailand_th/{z}/{x}/{y}.png?access_token=943364ad7b8956345d870a14c7855117d0dad5ee",
    //   {
    //     attribution: '&copy; <a href="http://powermap.in.th">Powermap</a>', 
    //     maxZoom: 19,
    //     minZoom: 6
    //   }
    // );

    self.map = L.map(self.refs.map, {
      layers: [osm_map, theMarker]
    }).setView([13, 100], 6);

    let baseMaps = {
      'OpenStreetMap': osm_map,
      'ภาพดาวเทียม': sattelite,
    };

    self.map.addControl(new L.Control.SwitchScaleControl({
      ratioCustomItemText: "1: ระบุเลข scale",
      updateWhenIdle: true,
      scales: [1000, 2500, 5000, 10000, 20000, 40000, 70000, 140000, 300000, 600000, 1200000, 2300000]
    }));

    

    L.control.locate().addTo(self.map);

    L.control.layers(baseMaps).addTo(self.map);

    self.map.on('click', e => {
      if (theMarker !== undefined) {
        this.map.removeLayer(theMarker);
      };
      houseLayer.clearLayers()
      houseLayer.addLayer(L.marker(e.latlng));
      theMarker = houseLayer
      this.map.addLayer(theMarker)
      this.map.setView(e.latlng);
      this.setState({Asset_OriginalY: e.latlng.lat, 
                     Asset_OriginalX: e.latlng.lng})
    })

  }

  async ValidateField(fieldName, value) {
    let fieldValidationErrors1 = this.state.formError1;
    let Sub_Asset_Type_Valid = this.state.Sub_Asset_Type_Valid;
    let Action_Type_Valid = this.state.Action_Type_Valid;
    let Cat_Type_Valid = this.state.Cat_Type_Valid;
    let Asset_Project_Valid = this.state.Asset_Project_Valid;
    let provinceSelceted_Valid = this.state.provinceSelceted_Valid;
    let amphoeSelected_Valid = this.state.amphoeSelected_Valid;
    let tumbonSelected_Valid = this.state.tumbonSelected_Valid;

    let fieldValidationErrors2 = this.state.formError2;
    let Asset_Floor_Valid = this.state.Asset_Floor_Valid;

    let fieldValidationErrors3 = this.state.formError3;
    let Asset_Price_Valid = this.state.Asset_Price_Valid;
    
    let Promo_start_Valid = this.state.Promo_start_Valid;
    let Promo_end_Valid = this.state.Promo_end_Valid;

    let fieldValidationErrors4 = this.state.formError4;
    let Main_Img_Valid = this.state.Main_Img_Valid;

    let fieldValidationErrors5 = this.state.formError5;
    let consent_Valid = this.state.consent_Valid;

    switch(fieldName) {
      case 'Sub_Asset_Type': 
        Sub_Asset_Type_Valid = value !== '' && value !== 0 ;
        fieldValidationErrors1.Sub_Asset_Type = Sub_Asset_Type_Valid ? '' : 'โปรดเลือกประเภททรัพย์';
        break;
      case 'Action_Type': 
        Action_Type_Valid = value !== '' && value !== 0 ;
        fieldValidationErrors1.Action_Type = Action_Type_Valid ? '' : 'โปรดเลือกประเภทประกาศ';
        break;
        case 'Cat_Type': 
        Cat_Type_Valid = value !== '' && value !== 0 ;
        fieldValidationErrors1.Cat_Type = Cat_Type_Valid ? '' : 'โปรดเลือกลักษณะทรัพย์';
        break;
      case 'Asset_Project':
        Asset_Project_Valid = value.length > 3 ;
        fieldValidationErrors1.Asset_Project = Asset_Project_Valid ? '' : 'โปรดระบุชื่อทรัพย์';
        break;
      case 'provinceSelceted': 
        provinceSelceted_Valid = value !== '' && value !== 0 ;
        fieldValidationErrors1.provinceSelceted = provinceSelceted_Valid ? '' : 'โปรดเลือกจังหวัด';
        break;
      case 'amphoeSelected': 
      amphoeSelected_Valid = value !== '' && value !== 0 ;
        fieldValidationErrors1.amphoeSelected = amphoeSelected_Valid ? '' : 'โปรดเลือกอำเภอ/เขต';
        break;
      case 'tumbonSelected': 
        tumbonSelected_Valid = value !== '' && value !== 0 ;
        fieldValidationErrors1.tumbonSelected = tumbonSelected_Valid ? '' : 'โปรดเลือกตำบล/แขวง';
        break;
      case 'Building_Floor': 
        if(this.state.Building_Floor !== '' && this.state.Building_Floor !== 0){
          Asset_Floor_Valid = parseInt(this.state.Building_Floor) >= parseInt(this.state.Asset_Floor) ;
          fieldValidationErrors2.Asset_Floor = Asset_Floor_Valid ? '' : 'ไม่สามารถระบุชั้นที่อยู่มากกว่าจำนวนชั้นได้';
        } else {
          Asset_Floor_Valid = true;
          fieldValidationErrors2.Asset_Floor = ''
        }
        break;
      case 'Asset_Floor': 
        if(this.state.Building_Floor !== '' && this.state.Building_Floor !== 0){
          Asset_Floor_Valid = parseInt(this.state.Building_Floor) >= parseInt(this.state.Asset_Floor) ;
          fieldValidationErrors2.Asset_Floor = Asset_Floor_Valid ? '' : 'ไม่สามารถระบุชั้นที่อยู่มากกว่าจำนวนชั้นได้';
        } else {
          Asset_Floor_Valid = true;
          fieldValidationErrors2.Asset_Floor = ''
        }
        break;
      case 'Asset_Price':
        Asset_Price_Valid = parseInt(value) > 0 ;
        fieldValidationErrors3.Asset_Price = Asset_Price_Valid ? '' : 'โปรดระบุราคา';
        break;
      case 'Promotion_price':
        if(value !== null && value !== '' && value !== 0) {// Promotion_price  != 0
          Promo_start_Valid = this.state.Promo_start_date !== '' && this.state.Promo_start_date
          Promo_end_Valid = this.state.Promo_end_date !== '' && this.state.Promo_end_date
          fieldValidationErrors3.Promo_start_date = Promo_start_Valid ? '': 'โปรดระบุวันที่เริ่มต้น'
          fieldValidationErrors3.Promo_end_date = Promo_end_Valid ? '': 'โปรดระบุวันที่สิ้นสุด'
        } else {
          Promo_start_Valid = true
          Promo_end_Valid = true
          fieldValidationErrors3.Promo_start_date = ''
          fieldValidationErrors3.Promo_end_date = ''
          this.setState({Promo_start_date: '', Promo_end_date: ''})
        }
        break;
      case 'Promo_start_date':
        if(parseFloat(this.state.Promotion_price) > 0){
          Promo_start_Valid = parseFloat(this.state.Promotion_price) > 0 && value && value !== ''
          fieldValidationErrors3.Promo_start_date = Promo_start_Valid ? '' : 'โปรดระบุวันที่เริ่มต้น';

        }else {
          Promo_start_Valid = true
        }
      break;

      case 'Promo_end_date':
        if(parseFloat(this.state.Promotion_price) > 0){
          Promo_end_Valid = parseFloat(this.state.Promotion_price) > 0 && value && value !== ''
          fieldValidationErrors3.Promo_end_date = Promo_end_Valid ? '' : 'โปรดระบุวันที่สิ้นสุด';
        } else {
          Promo_end_Valid = true
        }
      break;
      
      case 'Main_Img': 
        Main_Img_Valid = value !== '' || value !== 'single-property-01.jpg';
        fieldValidationErrors4.Main_Img = Main_Img_Valid ? '' : 'โปรดเลือกรูปภาพหลัก'
        break;

      case 'consent': 
        consent_Valid = value;
        fieldValidationErrors5.consent = consent_Valid ? '' : 'โปรดกดยอมรับเงื่อนไขและข้อตกลง'
        break;

      default:
        break;
    }

    await this.setState({formError1: fieldValidationErrors1,
                    Sub_Asset_Type_Valid: Sub_Asset_Type_Valid,
                    Action_Type_Valid: Action_Type_Valid,
                    Cat_Type_Valid: Cat_Type_Valid,
                    Asset_Project_Valid: Asset_Project_Valid,
                    provinceSelceted_Valid: provinceSelceted_Valid,
                    amphoeSelected_Valid: amphoeSelected_Valid,
                    tumbonSelected_Valid: tumbonSelected_Valid
                  }, await this.ValidateForm);

    await this.setState({formError2: fieldValidationErrors2,
      Asset_Floor_Valid: Asset_Floor_Valid
                  }, await this.ValidateForm);
                  

    await this.setState({formError3: fieldValidationErrors3,
      Asset_Price_Valid: Asset_Price_Valid,
      Promo_start_Valid: Promo_start_Valid,
      Promo_end_Valid: Promo_end_Valid
                                }, await this.ValidateForm);

    await this.setState({formError4: fieldValidationErrors4,
      Main_Img_Valid: Main_Img_Valid
                                    }, await this.ValidateForm);

    await this.setState({formError5: fieldValidationErrors5,
                         consent_Valid: consent_Valid
                         }, await this.ValidateForm);
  }

  ValidateForm() {
    this.setState({formValid1: this.state.Sub_Asset_Type_Valid  
                            && this.state.Action_Type_Valid
                            && this.state.Cat_Type_Valid
                            && this.state.Asset_Project_Valid
                            && this.state.provinceSelceted_Valid
                            && this.state.amphoeSelected_Valid
                            && this.state.tumbonSelected_Valid
                            });

    this.setState({formValid2: this.state.formValid1 
                            && this.state.Asset_Floor_Valid});

    this.setState({formValid3: this.state.formValid1 
                            && this.state.formValid2
                            && this.state.Asset_Price_Valid
                            && this.state.Promo_start_Valid
                            && this.state.Promo_end_Valid});

    this.setState({formValid4: this.state.formValid1 
                            && this.state.formValid2
                            && this.state.formValid3
                            && this.state.Main_Img_Valid});

    this.setState({formValid5: this.state.formValid1 
                              && this.state.formValid2
                              && this.state.formValid3 
                              && this.state.formValid4
                              && this.state.consent_Valid});
  }


  findAmphoe = (item) => {
    if (
      Number.isFinite(item.P_CODE) &&
      item.P_CODE == this.state.provinceSelceted
    ) {
      return true;
    }
  };

  findTumbon = (item) => {
    if (
      Number.isFinite(item.P_CODE) &&
      item.P_CODE == this.state.provinceSelceted &&
      item.A_CODE == this.state.amphoeSelected
    ) {
      return true;
    }
  };

  renderOptions = async (data) => {
    try {
      return (
        data !== null &&
        (await data.map((item) => (
          <option key={Math.floor(Math.random() * Math.floor(100000)) + item.NAME} value={item.CODE}>
            {item.NAME}
          </option>
        )))
      );
    } catch (error) {
      // console.log(error);
    }
  };

  renderAssetType = async (data) => {
    try {
      return (
        data !== null &&
        await data.map(item => (
          <option  key={Math.floor(Math.random() * Math.floor(900000)) + item.NAME} value={item.ID} >{item.NAME}</option>
        ))
      );
    } catch (error) {
      console.log(error)
    }
  }

  renderFacilities = (data) => {
    try {

      return (
        data !== null &&
        data.map(item => (
          <a key={"check-01-" + item.id}>
              <input id={"check-" + item.id}
                    type="checkbox" 
                    name={"check-" + item.id}
                    value={item.value}
                    onChange={this.handleChecked}/>
            <label htmlFor={"check-" + item.id}>{item.value}</label>
          </a>
        ))
      )
    } catch (error) {
      console.log(error)
    }
  }


  handleChecked = async (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

     if (checked){
      await this.setState({Asset_Facilities: [...this.state.Asset_Facilities, ...[{value: value}]]})
    } else {
      let array = this.state.Asset_Facilities
      let index = 0;
      if(array.length > 0){
        index = await array.findIndex(obj => obj.value === e.target.value)
      }
      await array.splice(index, 1);
      await this.setState({Asset_Facilities: array})
    }
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.type !== 'checkbox' ? e.target.value : e.target.checked ;
    this.setState({[name]: value},
                  () => { this.ValidateField(name, value) });
  }

  previewImageDetail = async (files) => {

    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader();
      await reader.readAsDataURL(files[i]);

      reader.onload = async (e) => {
        await this.setState({imageDetails: [  ...this.state.imageDetails, 
                                              ...[{filename: files[i].name, img: e.target.result}]
                                           ],
                             displayImgs: [ ...this.state.displayImgs, 
                                            ...[{img: e.target.result}]],
                            }) 

        await this.setState({imagesShow: [  ...this.state.imagesShow, 
                                            ...[<div key={this.state.imagesShow.length + files[i].name} className="col-md-3 " style={{marginTop: 10, height: 150}}>
                                                    <button key={this.state.imagesShow.length + files[i].name} value={files[i].name} 
                                                            onClick={e=>{
                                                                let array = this.state.imageDetails
                                                                let array2 = this.state.imagesShow

                                                                let index = 0
                                                                if(array.length > 0){
                                                                  index = array.findIndex(obj => obj.filename === e.target.value)
                                                                } 

                                                                array.splice(index, 1);
                                                                array2.splice(index, 1);

                                                                this.setState({imageDetails: array, 
                                                                                imagesShow: array2
                                                                })

                                                            }}> X </button>
                                                    <center><img src={e.target.result} style={{width: 'auto', height: 150}} lazyload="true"/></center>
                                              </div>]]})
      }
    }
  }

  renderDisplayImg(data){

    // data = [...[{img: this.state.Main_Img}], ...this.state.displayImgs]
    // let data = [...[{img: this.state.Main_Img}], ...this.state.displayImgs]
    return(
      <OwlCarousel  autoplay={true} className="owl-theme utf-listing-carousel-item outer"  loop margin={30} nav={true} smartSpeed={500} items={1} dots={true} >    
        {
          data != null &&
          data.map(item=>(
            <div className="item" style={{height: 500}} key={item.img}> {/*key={item.Asset_ID}*/}
              <div className="utf-listing-item compact"> 
              <center><img src={item.img} style={{height: 500, width: 'auto'}} lazyload="true"/></center>
            </div>
          </div>
          ))
        }
        </OwlCarousel> 
    )
  }

  scollToTop(){
    this.myRef.current.scrollIntoView({behavior: 'smooth'});
  }

  setMapLocation(lat, lng){
    this.setState({Asset_OriginalY: lat, Asset_OriginalX: lng})
    let latlng = {lat: lat, lng: lng}
    if (theMarker !== undefined) {
      this.map.removeLayer(theMarker);
    };


    let houseLayer = new L.MarkerClusterGroup();
    houseLayer.clearLayers()
    houseLayer.addLayer(L.marker(latlng));

    theMarker = houseLayer
    this.map.addLayer(theMarker)
    this.map.setView(latlng);
  }



  async save(){

    let values = {
      Asset_ID: '',
      Asset_Province: this.state.provinceSelceted,
      Asset_Amphoe: this.state.amphoeSelected,
      Asset_Tambon: this.state.tumbonSelected,
      Asset_Moo: this.state.Asset_Moo,
      Asset_Project: this.state.Asset_Project,
      Ref_Code:this.state.Ref_Code,
      Asset_Road: this.state.Asset_Road,
      Asset_Soi: this.state.Asset_Soi,
      Asset_Address_No: this.state.Asset_Address_No,
      // Asset_Type: this.state.Asset_Type,
      Sub_Asset_Type: this.state.Sub_Asset_Type,
      // Document_Ref: this.state.Document_Ref,
      // Asset_Land_Area: this.state.Asset_Land_Area,
      rai: this.state.rai,
      ngan: this.state.ngan,
      wa: this.state.wa,
      Asset_Usage_Area: this.state.Asset_Usage_Area,
      Asset_Built_Year: this.state.Asset_Built_Year,
      Building_Floor: this.state.Building_Floor,
      Asset_Floor: this.state.Asset_Floor,
      Number_Bed_Room: this.state.Number_Bed_Room,
      Number_Living_Room: this.state.Number_Living_Room,
      Number_Bath_Room: this.state.Number_Bath_Room,
      Number_Kitchen: this.state.Number_Kitchen,
      Number_Car_Park: this.state.Number_Car_Park,
      Number_Air_Condition: this.state.Number_Air_Condition,
      Asset_OriginalX: this.state.Asset_OriginalX,
      Asset_OriginalY: this.state.Asset_OriginalY,
      Asset_Price: this.state.Asset_Price,
      Promotion_price : this.state.Promotion_price,   
      Promo_start_date: this.state.Promo_start_date,
      Promo_end_date: this.state.Promo_end_date,
      // Apprisal_Price: this.state.Apprisal_Price,
      Action_Type: this.state.Action_Type,
      Cat_Type: this.state.Cat_Type,
      Asset_Detail: this.state.Asset_Detail,
      Update_By: this.state.Update_By,
      Asset_Transfer_Fee_ID: this.state.Asset_Transfer_Fee_ID,
      youtube_url: this.state.youtube_url,
      Url_img_360: this.state.Url_img_360,
      Asset_Facilities: this.state.Asset_Facilities,
      Asset_Common_Fee: this.state.Asset_Common_Fee,
      EXEC_SATATUS: 'INSERT',

      Main_Img: this.state.Main_Img,
      Main_Img_Name: this.state.Main_Img_Name,
      imageDetails: this.state.imageDetails,
    }

    let check = await httpClient.post(server.MEMBER_URL + '/member-activity-status', {Member_ID: localStorage.getItem("Member_ID")})
    if (check.status == 'ok') {
      let result = await httpClient.post(server.ASSET_URL + '/addnewasset', values)
      if(result.result == 'ok'){
        Swal.fire({
          title: 'บันทึกสำเร็จ',
          icon: 'success',
          text: "สถานะ : รออนุมัติขึ้นประกาศ\n " + "(รายการทรัพย์ของท่าน อยู่ระหว่างรออนุมัติ)",
          confirmButtonText: 'ตกลง',
        }).then((result) => {
          if (result.isConfirmed) {
            // this.props.history.push('/my-properties')
            this.props.history.push('/my-properties')
            window.location.reload(false)
          } 
        })
      } else {
        Swal.fire({
          title: 'ผิดพลาด',
          icon: 'error',
          confirmButtonText: 'ปิด',
        })
      }
    } else {
      alert('กรุณากรอกข้อมูลพื้นฐานให้ครบและรอให้ผู้ดูแลระบบอนุมัติสมาชิก เพื่อใช้ฟังก์ชั่นนี้ \n\n คำอธิบายเกี่ยวกับการเพิ่มทรัพย์ \n  หากท่านกรอกข้อมูลพื้นฐานไม่ครบหรือยังไม่ได้รับการอนุมัติสมาชิกจากผู้ดูแลระบบ ท่านจะไม่สามารถเพิ่มทรัพย์ได้')                           
    }


    

  }

  render() {
    const ValidStypes = {color: 'red', fontSize: '14px', marginBottom: "-5px"}
  //   const handleChange = date => {
  //       this.setState({pdate: date}); 
  // }

    return (<div>

        <div ref={this.myRef} className="parallax titlebar" data-background="images/visual_banner_desktop_2.jpg" 
             data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>เพิ่มประกาศใหม่</h2>
                  {/* Breadcrumbs */}
                  <nav id="breadcrumbs">
                    <ul>
                      <li><a href="home">หน้าแรก</a></li>
                      <li>เพิ่มประกาศใหม่</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
 
        
        <div className="container" >
          <div className="row"> 
            <div className="col-md-3">
              <UserMenu/>
            </div>


            <div className="col-md-9" > 
              {/* <div className="row sticky-wrapper" ref={this.myRef}></div> */}
              <div className="utf-submit-page-inner-box">
                  <div className="content with-padding">  
                    <div className="row">
                      <div className="col-md-12"> 
                        <ul className="nav nav-tabs process-model more-icon-preocess" role="tablist">
                          <li role="presentation" 
                              className={this.state.tapsPageSelected == 'discover' ? "active" : ''} 
                              onClick={() => this.setState({tapsPageSelected: 'discover'})}>
                                <a href="#discover" aria-controls="discover" role="tab" data-toggle="tab"><i className="fa fa fa-newspaper-o" aria-hidden="true" />
                              <p>ข้อมูลสำคัญ</p>
                            </a></li>
                          <li role="presentation" 
                              className={this.state.tapsPageSelected == 'strategy' ? "active" : ''} 
                              onClick={() => {
                                if(this.state.formValid1){
                                  this.setState({tapsPageSelected: 'strategy'})
                                }
                              }}>
                                <a 
                                  href={this.state.formValid1 ? "#strategy" : '#'}
                                  aria-controls="strategy" 
                                  role="tab" 
                                  data-toggle="tab"
                                  >
                                    <i className="fa fa-send-o" aria-hidden="true" />
                              <p>ข้อมูลเพิ่มเติม</p>
                            </a></li>
                          <li role="presentation" 
                              className={this.state.tapsPageSelected == 'optimization' ? "active" : ''} 
                              onClick={() => {
                                if(this.state.formValid2){
                                  this.setState({tapsPageSelected: 'optimization'})
                                }
                              }}>
                                <a 
                                  href={this.state.formValid2 ? "#optimization" : '#'}
                                  aria-controls="optimization" 
                                  role="tab" 
                                  data-toggle="tab">
                                    <i className="fa fa-dollar" aria-hidden="true" />
                              <p>ราคา</p>
                            </a></li>
                          <li role="presentation" 
                              className={this.state.tapsPageSelected == 'content' ? "active" : ''} 
                              onClick={() => {
                                if(this.state.formValid3){
                                  this.setState({tapsPageSelected: 'content'})
                                }
                              }}>
                                <a 
                                  href={this.state.formValid3 ? "#content" : '#'} 
                                  aria-controls="content" 
                                  role="tab" 
                                  data-toggle="tab">
                                    <i className="fa fa-file-image-o" aria-hidden="true" />
                              <p>รูปภาพและวิดีโอ</p>
                            </a></li>
                          <li role="presentation" 
                              className={this.state.tapsPageSelected == 'reporting' ? "active" : ''} 
                              onClick={async () => {
                                  if(this.state.formValid4){
                                    let data = [...[{img: this.state.Main_Img}], ...this.state.displayImgs]
  
                                    //ยังหาวิธีแก้ที่ต้อง set displayImgsDiv 2 ครั้งไม่ได้
                                    this.setState({displayImgsDiv: null})
                                    this.setState({displayImgsDiv: await this.renderDisplayImg(data)})
                                    this.setState({displayImgsDiv: await this.renderDisplayImg(data),
                                      tapsPageSelected: 'reporting'})
                                  }
                                }}>
                                <a 
                                  href={this.state.formValid4 ? "#reporting" : ''} 
                                  aria-controls="reporting" 
                                  role="tab" 
                                  data-toggle="tab">
                                    <i className="fa fa-clipboard" aria-hidden="true" />
                              <p>สรุปประกาศ</p>
                            </a></li>
                        </ul>

                    
                        {/* end design process steps*/} 
                        {/* Tab panes */}
                        <div className="tab-content">
                          <div role="tabpanel" className={"tab-pane " + (this.state.tapsPageSelected == 'discover' ? 'active' : '')} id="discover">
                            <div className="content with-padding">  
                              <div className="widget utf-sidebar-widget-item"> 
                                <div className="utf-boxed-list-headline-item">
                                  <h3 style={{fontFamily: 'Kanit-Light'}}>รายละเอียดทรัพย์</h3>
                                </div>             
                              </div>

                              <div className="row">
                                  <div className="col-md-6">
                                    <h5>ประเภททรัพย์ <span className="s_validate"> * </span></h5>
                                    <select name="Sub_Asset_Type" 
                                            data-placeholder="Property Type" 
                                            value={this.state.Sub_Asset_Type} 
                                            // onChange={this.handleUserInput}
                                            onChange={e => {
                                              var index = e.nativeEvent.target.selectedIndex;
                                              this.setState({Sub_Asset_Type: e.target.value, 
                                                             Sub_Asset_Type_name: e.nativeEvent.target[index].text})
                                              this.ValidateField('Sub_Asset_Type', e.target.value)
                                            }}
                                      >
                                      {this.state.assetOptions != null ? this.state.assetOptions: null}
                                    </select>
                                    {
                                      this.state.formError1.Sub_Asset_Type !== '' ? <p style={ValidStypes}> {this.state.formError1.Sub_Asset_Type} </p>  : null
                                    }
                                  </div>
                                  <div className="col-md-3">
                                    <h5>ประเภทประกาศ <span className="s_validate"> * </span></h5>
                                    <select name="Action_Type" 
                                            value={this.state.Action_Type}
                                            // onChange={this.handleUserInput}
                                            onChange={e => {
                                              var index = e.nativeEvent.target.selectedIndex;
                                              this.setState({Action_Type: e.target.value, 
                                                             Action_Type_name: e.nativeEvent.target[index].text})
                                              this.ValidateField('Action_Type', e.target.value)
                                            }}
                                            >
                                      <option value="">เลือกประเภทประกาศ</option>
                                      <option value="1">ขาย</option>
                                      <option value="2">เช่า</option>		
                                      <option value="3">ขายดาวน์</option>					  
                                    </select>
                                    {
                                      this.state.formError1.Action_Type !== '' ? <p style={ValidStypes}> {this.state.formError1.Action_Type} </p>  : null
                                    }
                                  </div>
                                  
                                  <div className="col-md-3">
                                    <h5>ลักษณะทรัพย์ <span className="s_validate"> * </span></h5>
                                    <select name="Cat_Type" 
                                            value={this.state.Cat_Type}
                                            // onChange={this.handleUserInput}
                                            onChange={e => {
                                              var index = e.nativeEvent.target.selectedIndex;
                                              this.setState({Cat_Type: e.target.value, 
                                                             Cat_Type_name: e.nativeEvent.target[index].text})
                                              this.ValidateField('Cat_Type', e.target.value)
                                            }}
                                            >
                                      <option value="">เลือกลักษณะทรัพย์</option>
                                      <option value="1">ทรัพย์ใหม่</option>
                                      <option value="2">ทรัพย์มือสอง</option>					  
                                    </select>
                                    {
                                      this.state.formError1.Cat_Type !== '' ? <p style={ValidStypes}> {this.state.formError1.Cat_Type} </p>  : null
                                    }
                                  </div>
                                  
                              </div>   

                          <div className="row">
                            <div className="col-md-8">
                              <h5>ชื่อทรัพย์<span className="s_validate"> * </span></h5>
                              <input name="Asset_Project"
                                type="text"
                                placeholder="ชื่อทรัพย์"
                                value={this.state.Asset_Project}
                                onChange={this.handleUserInput} />
                              {
                                this.state.formError1.Asset_Project !== '' ? <p style={ValidStypes}> {this.state.formError1.Asset_Project} </p> : null
                              }
                            </div>
                            <div className="col-md-4">
                              <h5>รหัสอ้างอิง</h5>
                              <input name="Ref_Code"
                                type="text"
                                placeholder="รหัสอ้างอิง"
                                value={this.state.Ref_Code}
                                onChange={this.handleUserInput} />
                            </div>
                          </div>  

                            
                                  
                              <div className="row">
                                  <div className="col-md-2">
                                    <h5>บ้านเลขที่ {/*<span className="s_validate"> * </span>*/}</h5>
                                    <input name="Asset_Address_No" 
                                           type="text" 
                                           placeholder="บ้านเลขที่" 
                                           value={this.state.Asset_Address_No}
                                           onChange={this.handleUserInput}
                                           />
                                  </div>
                                  <div className="col-md-2">
                                    <h5>หมู่</h5>
                                    <input name="Asset_Moo" 
                                           type="text" 
                                           placeholder="หมู่" 
                                           value={this.state.Asset_Moo}
                                           onChange={this.handleUserInput}
                                           />
                                  </div>
                                  <div className="col-md-4">
                                    <h5>ซอย</h5>
                                    <input name="Asset_Soi" 
                                           type="text"
                                           placeholder="ซอย" 
                                           value={this.state.Asset_Soi}
                                           onChange={this.handleUserInput}
                                          />
                                  </div>
                                  <div className="col-md-4">
                                    <h5>ถนน</h5>
                                    <input name="Asset_Road"
                                           type="text"
                                           placeholder="ถนน" 
                                           value={this.state.Asset_Road}
                                           onChange={this.handleUserInput}
                                          />
                                  </div>
                              </div>    
                                  

                              <div className="row">
                                  <div className="col-md-4">
                                    {/* <label>จังหวัด <span><font color="red"> * </font></span></label> */}
                                    <h5>จังหวัด<span className="s_validate"> * </span></h5>
                                    <select
                                      name="provinceSelceted"
                                      data-placeholder="Province"
                                      value={this.state.provinceSelceted}
                                      onChange={async (e) => {
                                        // console.log(e.target.option)
                                        var index = e.nativeEvent.target.selectedIndex;
                                        await this.setState({
                                          provinceSelceted: e.target.value,
                                          province_name: e.nativeEvent.target[index].text,
                                          amphoeSelected: "",
                                          tumbonSelected: "",
                                          tumbonOptions: (
                                            <option value={""}>ตำบล/แขวง</option>
                                          ),
                                        }, async () => { 
                                                  await this.ValidateField('provinceSelceted', e.target.value)
                                                  await this.ValidateField('amphoeSelected', "")
                                                  await this.ValidateField('tumbonSelected', "")
                                                });

                                        let amphoes = await this.state.amphoesLists.filter(
                                          this.findAmphoe
                                        );
                                        let noselect = [{ CODE: "", NAME: "อำเภอ/เขต" }];
                                        this.setState({
                                          amphoeOptions: await this.renderOptions([
                                            ...noselect,
                                            ...amphoes,
                                          ]),
                                        });
                                      }}
                                    >
                                      {this.state.provincesOptions}
                                    </select>
                                    {
                                      this.state.formError1.provinceSelceted !== '' ? <p style={ValidStypes}> {this.state.formError1.provinceSelceted} </p>  : null
                                  }
                                  </div>
                                  <div className="col-md-4">
                                    {/* <label>อำเภอ/เขต <span><font color="red"> * </font></span></label> */}
                                    <h5>อำเภอ/เขต<span className="s_validate"> * </span></h5>
                                    <select
                                      name="amphoeSelected"
                                      data-placeholder="District"
                                      value={this.state.amphoeSelected}
                                      onChange={async (e) => {
                                        var index = e.nativeEvent.target.selectedIndex;
                                        await this.setState({
                                          amphoeSelected: e.target.value,
                                          amphoe_name: e.nativeEvent.target[index].text,
                                        }, async () => { 
                                          await this.ValidateField('amphoeSelected', e.target.value)
                                          await this.ValidateField('tumbonSelected', "")
                                        });
                                        let tumbons = await this.state.tumbonsLists.filter(
                                          this.findTumbon
                                        );
                                        let noselect = [{ CODE: "", NAME: "ตำบล/แขวง" }];
                                        this.setState({
                                          tumbonOptions: await this.renderOptions([
                                            ...noselect,
                                            ...tumbons,
                                          ]),
                                          tumbonSelected: "",
                                        });
                                      }}
                                    >
                                      {this.state.amphoeOptions}
                                    </select>
                                    {
                                      this.state.formError1.amphoeSelected !== '' ? <p style={ValidStypes}> {this.state.formError1.amphoeSelected} </p>  : null
                                  }
                                  </div>
                                  <div className="col-md-4">
                                    {/* <label>แขวง/ตำบล <span><font color="red"> * </font></span></label> */}
                                    <h5>แขวง/ตำบล<span className="s_validate"> * </span></h5>
                                    <select
                                      name="tumbonSelected"
                                      data-placeholder="Sub district"
                                      value={this.state.tumbonSelected}
                                      onChange={async (e) => {
                                        var index = e.nativeEvent.target.selectedIndex;
                                        this.setState({ tumbonSelected: e.target.value,
                                                        tumbon_name: e.nativeEvent.target[index].text }, 
                                          () => { 
                                          this.ValidateField('tumbonSelected', e.target.value)
                                        });
                                      }}
                                    >
                                      {this.state.tumbonOptions}
                                    </select>
                                    {
                                      this.state.formError1.tumbonSelected !== '' ? <p style={ValidStypes}> {this.state.formError1.tumbonSelected} </p>  : null
                                  }
                                  </div>
                                
                                </div>
                              <div className="row margin-top-20">
                                <div className="col-md-12">
                                  <div className="widget utf-sidebar-widget-item"> 
                                    <div className="utf-boxed-list-headline-item">
                                      <h3 style={{fontFamily: 'Kanit-Light'}}>แผนที่</h3>
                                    </div>             
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div
                                        ref="map"
                                        style={{
                                          height: "400px",
                                          width: "100%",
                                          position: "relative",
                                          borderRadius: "6px"
                                        }}
                                      />

                                      <div className="col-md-6" style={{paddingLeft: 5, paddingRight: 5}}> 
                                        <h5>ละติจูด</h5>
                                        <input name="Asset_OriginalY"
                                               type="number" 
                                               placeholder="ละติจูด" 
                                               value={this.state.Asset_OriginalY}
                                              onChange={e => {this.setMapLocation(e.target.value, this.state.Asset_OriginalX)}}
                                               />
                                      </div>
                                      <div className="col-md-6" style={{paddingLeft: 5, paddingRight: 5}}>
                                        <h5>ลองติจูด</h5>								
                                        <input name="Asset_OriginalX"
                                               type="number" 
                                               placeholder="ลองติจูด" 
                                               value={this.state.Asset_OriginalX}
                                              onChange={e => {this.setMapLocation(this.state.Asset_OriginalY, e.target.value)}}
                                               />
                                      </div>
                                    </div>
                                  </div>			
                                </div>
                              </div> 
                              <hr />
                              <div className="row margin-top-10">
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6" style={{textAlign: 'right'}}>
                                  <button style={{width: 120, height: 40}} 
                                          disabled={!this.state.formValid1} 
                                          type="button" 
                                          className="btn btn-primary"
                                          onClick={e => {
                                            e.preventDefault()
                                            this.setState({tapsPageSelected: 'strategy'})
                                            this.scollToTop()
                                          }}
                                          >
                                            <span className="icon-material-outline-arrow-forward"> </span> ถัดไป</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div role="tabpanel" className={"tab-pane " + (this.state.tapsPageSelected == 'strategy' ? 'active' : '')} id="strategy">
                            <div className="content with-padding">
                              <div className="widget utf-sidebar-widget-item"> 
                                <div className="utf-boxed-list-headline-item">
                                  <h3 style={{fontFamily: 'Kanit-Light'}}>ข้อมูลเพิ่มเติม</h3>
                                </div>             
                              </div>
                              {/* Section */}
                              {/* <div className="row"> */}

                               <div className="row">
                                <div className="utf-listing-user-info header-inbox">
                                    <i className="icon-feather-codepen" />จำนวนห้อง *
                                  </div>
                               </div>
                                
                                <div className="col-md-12" style={{padding: 0}}>
                                  
                                  <div className="row">
                                    <div className="col-md-3">
                                      <h5>ปีที่สร้างเสร็จ</h5>
                                      <select name="Asset_Built_Year" 
                                              // className="utf-chosen-select-single-item"
                                              value={this.state.Asset_Built_Year}
                                              onChange={this.handleUserInput}
                                              >  
                                        {this.state.yearsOptions}
                                      </select>
                                    </div>
                                    <div className="col-md-3">
                                      <h5>จำนวนชั้น</h5>
                                      <input name="Building_Floor"
                                            type="number" 
                                            placeholder="จำนวนชั้น" 
                                            value={this.state.Building_Floor}
                                            onChange={this.handleUserInput}
                                            />
                                    </div>
                                    <div className="col-md-3">
                                      <h5>อยู่ชั้นที่</h5>
                                      <input name="Asset_Floor"
                                            type="number" 
                                            placeholder="อยู่ชั้นที่" 
                                            value={this.state.Asset_Floor}
                                            onChange={this.handleUserInput}
                                            />
                                      {
                                        this.state.formError2.Asset_Floor !== '' ? <p style={ValidStypes}> {this.state.formError2.Asset_Floor} </p>  : null
                                    }
                                    </div>
                                    <div className="col-md-3">
                                      <h5>ห้องนอน</h5>
                                      <input name="Number_Bed_Room"
                                            type="number"
                                            placeholder="ห้องนอน" 
                                            value={this.state.Number_Bed_Room}
                                            onChange={this.handleUserInput}
                                            />
                                    </div>
                                  </div>

                                  <div className="row">

                                    <div className="col-md-3">
                                      <h5>ห้องน้ำ</h5>
                                      <input name="Number_Bath_Room"
                                            type="number" 
                                            placeholder="ห้องน้ำ" 
                                            value={this.state.Number_Bath_Room}
                                            onChange={this.handleUserInput}
                                            />
                                    </div>
                                    <div className="col-md-3">
                                      <h5>ห้องนั่งเล่น</h5>
                                      <input name="Number_Living_Room"
                                            type="number" 
                                            placeholder="ห้องนั่งเล่น" 
                                            value={this.state.Number_Living_Room}
                                            onChange={this.handleUserInput}
                                            />
                                    </div>
                                    <div className="col-md-3">
                                      <h5>ที่จอดรถ</h5>
                                      <input name="Number_Car_Park"
                                            type="number" 
                                            placeholder="ที่จอดรถ" 
                                            value={this.state.Number_Car_Park}
                                            onChange={this.handleUserInput}
                                            />
                                    </div>
                                    <div className="col-md-3">
                                    <h5>ห้องครัว</h5>
                                    <input name="Number_Kitchen"
                                           type="number" 
                                           placeholder="ห้องครัว" 
                                           value={this.state.Number_Kitchen}
                                           onChange={this.handleUserInput}
                                           />
                                  </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-3">
                                      <h5>แอร์</h5>
                                      <input name="Number_Air_Condition"
                                            type="number" 
                                            placeholder="แอร์" 
                                            value={this.state.Number_Air_Condition}
                                            onChange={this.handleUserInput}
                                            />

                                   
                                    </div>
                                  </div>
                                </div>
                              {/* </div> */}


                              <div className="row"></div>
                              <div className="row">
                                <div className="utf-listing-user-info header-inbox">
                                  <i className="icon-line-awesome-arrows" />ขนาดพื้นที่ *
                                </div>
                                <div className="col-md-12" style={{padding: 0}}> 
                                  <div className="col-md-3">
                                    <h5>ขนาดที่ดิน / ไร่</h5>
                                    <input name="rai"
                                           type="number" 
                                           placeholder="ไร่" 
                                           value={this.state.rai}
                                           onChange={this.handleUserInput}
                                           />
                                  </div>
                                  <div className="col-md-3">
                                    <h5>ขนาดที่ดิน / งาน</h5>
                                    <input name="ngan"
                                           type="number" 
                                           placeholder="งาน" 
                                           value={this.state.ngan}
                                           onChange={this.handleUserInput}
                                           />
                                  </div>
                                  <div className="col-md-3">
                                    <h5>ขนาดที่ดิน / ตร.ว.</h5>
                                    <input name="wa"
                                           type="number" 
                                           placeholder="ตร.ว." 
                                           value={this.state.wa}
                                           onChange={this.handleUserInput}
                                           />
                                  </div>
                                  <div className="col-md-3">
                                    <h5>พื้นที่ใช้สอย / ตร.ม {/*<span className="s_validate"> * </span>*/}</h5>
                                    <input name="Asset_Usage_Area" 
                                           type="number" 
                                           placeholder="พื้นที่ใช้สอย" 
                                           value={this.state.Asset_Usage_Area}
                                           onChange={this.handleUserInput}
                                           />
                                  </div>
                                </div>
                              </div>   
                              <div className="row">
                                <div className="utf-listing-user-info header-inbox">
                                  <i className="icon-material-outline-wb-incandescent" />สิ่งอำนวยความสะดวกในโครงการ(optional) 
                                </div>
                                {/* Facilities */}
                                <div className="col-md-12" style={{padding: 10, fontFamily: 'Kanit-Light'}}>	
                                  <div className="checkboxes in-row margin-bottom-20">
                                    {this.renderFacilities(asset_facilities)}
                                  </div>
                                </div>  
                                {/* End Facilities */}
                              </div> 
                              {/* Section */}
                              <div className="row">
                                <div className="content with-padding">
                                  <div className="widget utf-sidebar-widget-item"> 
                                    <div className="utf-boxed-list-headline-item">
                                      <h3 style={{fontFamily: 'Kanit-Light'}}>ข้อมูลเพิ่มเติม</h3>
                                    </div>             
                                  </div>
                                  <div className="col-md-12" style={{padding: 0}}>				 
                                    <div className="form-group">					   
                                      {/* <textarea name="editor1" defaultValue={""} /> */}
                                      <CKEditor
                                          name="Asset_Detail"
                                          editor={ ClassicEditor }
                                          data={this.state.Asset_Detail}
                                          onChange={ async ( event, editor ) => {
                                            const data = editor.getData();
                                            await this.setState({Asset_Detail: data})
                                            this.ValidateField('Asset_Detail', data)
                                        } }
                                      />
                                    </div>
                                  </div>   
                                </div>
                              </div>	
                              <hr />
                              <div className="row margin-top-10">
                                <div className="col-md-6">
                                  <button style={{width: 120, height: 40}} 
                                          type="button" 
                                          className="btn btn-light"
                                          onClick={e => {
                                            e.preventDefault()
                                            this.setState({tapsPageSelected: 'discover'})
                                            this.scollToTop()
                                          }}
                                          >
                                            <span className="icon-material-outline-arrow-back"> </span>ย้อนกลับ</button>
                                </div>
                                <div className="col-md-6" style={{textAlign: 'right'}}>
                                  <button style={{width: 120, height: 40}} 
                                          disabled={!this.state.formValid2} 
                                          type="button" 
                                          className="btn btn-primary"
                                          onClick={e => {
                                            e.preventDefault()
                                            this.setState({tapsPageSelected: 'optimization'})
                                            this.scollToTop()
                                          }}
                                          >
                                            <span className="icon-material-outline-arrow-forward"> </span> ถัดไป</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div role="tabpanel" className={"tab-pane " + (this.state.tapsPageSelected == 'optimization' ? 'active' : '')} id="optimization">
                            <div className="content with-padding">
                              <div className="widget utf-sidebar-widget-item"> 
                                <div className="utf-boxed-list-headline-item">
                                  <h3 style={{fontFamily: 'Kanit-Light'}}>ราคาขายทรัพย์</h3>
                                </div>  
                                <div className="utf-listing-user-info header-inbox" style={{margin: 0}}>
                                  <i className="icon-feather-dollar-sign" />ราคาที่ต้องการขาย
                                </div>					 
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <h5>ราคาขาย  <span className="s_validate"> * </span></h5>
                                  <input name="Asset_Price"
                                         type="number" 
                                         placeholder="ราคาขาย" 
                                         data-unit="บาท" 
                                         value={this.state.Asset_Price}
                                         onChange={this.handleUserInput}
                                         />
                                  {
                                      this.state.formError3.Asset_Price !== '' ? <p style={ValidStypes}> {this.state.formError3.Asset_Price} </p>  : null
                                  }
                                </div>
                                <div className="col-md-4">
                                    <h5>ค่าส่วนกลาง / ปี</h5>
                                    <input name="Asset_Common_Fee" 
                                           type="number" 
                                           placeholder="ค่าส่วนกลาง" 
                                           value={this.state.Asset_Common_Fee}
                                           onChange={this.handleUserInput}
                                           />
                                  </div>
                                  <div className="col-md-4">
                                    <h5>ผู้รับผิดชอบค่าโอน</h5>
                                    <select name="Asset_Transfer_Fee_ID" 
                                            value={this.state.Asset_Transfer_Fee_ID}
                                            // onChange={this.handleUserInput}
                                            onChange={async (e) => {
                                              var index = e.nativeEvent.target.selectedIndex;
                                              this.setState({ Asset_Transfer_Fee_ID: e.target.value,
                                                              Asset_Transfer_Fee_Name: e.nativeEvent.target[index].text }, 
                                                () => { 
                                                this.ValidateField('Asset_Transfer_Fee_ID', e.target.value)
                                              });
                                            }}

                                            >
                                      <option value={0}>ไม่ระบุ</option>
                                      <option value={1}>ผู้ซื้อ</option>
                                      <option value={2}>ผู้ขาย</option>
                                      <option value={3}>คนละครึ่ง</option> 					  
                                    </select>
                                  </div>
                              </div>	
                              <hr />
                              <div className="widget utf-sidebar-widget-item"> 
                                  <div className="utf-listing-user-info header-inbox" style={{margin: 0}}>
                                    <i className="icon-feather-dollar-sign" />ราคาโปรโมชั่น
                                  </div>					 
                                </div>
                              <div className='row'>
                                  <div className="col-md-4">
                                    <h5>ราคาโปรโมชั่น (ถ้ามี)</h5>
                                    <input name="Promotion_price"
                                          type="number" 
                                          placeholder="ราคาโปรโมชั่น" 
                                          data-unit="บาท" 
                                          value={this.state.Promotion_price}
                                          onChange={this.handleUserInput}
                                          />
                                    
                                  </div>
                                  <div className="col-md-4">
                                    <h5>วันที่เริ่มต้น (โปรโมชั่น) </h5>
                                    {/* <input name="Promo_start_date"
                                          type="date" 
                                          placeholder="วันที่เริ่มต้น" 
                                          value={this.state.Promo_start_date}
                                          onChange={this.handleUserInput}
                                          dateFormat="dd-MM-yyyy"
                                          /> */}
                                    <DatePicker 
                                      name="Promo_start_date"
                                      // value={this.state.Promo_start_date}
                                      selected={this.state.Promo_start_date ? new Date(this.state.Promo_start_date) : ""} 
                                      placeholder="วันที่เริ่มต้น" 
                                      locale="th" 
                                      dateFormat="dd-MM-yyyy"
                                      onChange={e => {
                                        let date = new Date(e);
                                        let pyears = date.getFullYear();
                                        let pmonth = String(date.getMonth() + 1).padStart(2, '0');
                                        let pdays = String(date.getDate()).padStart(2, '0');
                                        const values = {target: {name: "Promo_start_date", value: pyears +"-" + pmonth + "-" + pdays}}
                                        this.handleUserInput(values)
                                      }}
                                      
                                      />
                                    {
                                      this.state.formError3.Promo_start_date !== '' ? <p style={ValidStypes}> {this.state.formError3.Promo_start_date} </p>  : null
                                    }
                                  </div>
                                  <div className="col-md-4">
                                    <h5>วันที่สิ้นสุด (โปรโมชั่น)</h5>
                                    {/* <input name="Promo_end_date"
                                          type="date" 
                                          placeholder="วันที่สิ้นสุด" 
                                          data-unit="บาท" 
                                          value={this.state.Promo_end_date}
                                          onChange={this.handleUserInput}
                                          /> */}
                                    <DatePicker 
                                      name="Promo_end_date"
                                      // value={this.state.Promo_start_date}
                                      selected={this.state.Promo_end_date ? new Date(this.state.Promo_end_date) : ""} 
                                      placeholder="วันที่สิ้นสุด" 
                                      locale="th" 
                                      dateFormat="dd-MM-yyyy"
                                      onChange={e => {
                                        let date = new Date(e);
                                        let pyears = date.getFullYear();
                                        let pmonth = String(date.getMonth() + 1).padStart(2, '0');
                                        let pdays = String(date.getDate()).padStart(2, '0');
                                        const values = {target: {name: "Promo_end_date", value: pyears +"-" + pmonth + "-" + pdays}}
                                        this.handleUserInput(values)
                                      }}/>
                                    {
                                      this.state.formError3.Promo_end_date !== '' ? <p style={ValidStypes}> {this.state.formError3.Promo_end_date} </p>  : null
                                    }
                                  </div>
                              </div>
                             


                              <div className="row margin-top-10">
                                <div className="col-md-6">
                                  <button style={{width: 120, height: 40}} 
                                          type="button" 
                                          className="btn btn-light"
                                          onClick={e => {
                                            e.preventDefault()
                                            this.setState({tapsPageSelected: 'strategy'})
                                            this.scollToTop()
                                          }}
                                          >
                                            <span className="icon-material-outline-arrow-back"> </span>ย้อนกลับ</button>
                                </div>
                                <div className="col-md-6" style={{textAlign: 'right'}}>
                                  <button style={{width: 120, height: 40}} 
                                          disabled={!this.state.formValid3} 
                                          type="button" 
                                          className="btn btn-primary"
                                          onClick={e => {
                                            e.preventDefault()
                                            this.setState({tapsPageSelected: 'content'})
                                            this.scollToTop()
                                          }}
                                          >
                                            <span className="icon-material-outline-arrow-forward"> </span> ถัดไป</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div role="tabpanel" className={"tab-pane " + (this.state.tapsPageSelected == 'content' ? 'active' : '')} id="content">
                            <div className="content with-padding">
                              <div className="widget utf-sidebar-widget-item"> 
                                <div className="utf-boxed-list-headline-item">
                                  <h3 style={{fontFamily: 'Kanit-Light'}}>รูปภาพและวิดีโอ</h3>
                                </div>  
                                <div className="utf-listing-user-info header-inbox" style={{margin: 0}}>
                                  <i className="fa fa-file-image-o" />รูปภาพหลัก <font color="red"> *</font>
                                </div>	
                                <div className="margin-top-10" />
                                <div className="row"> 
                                  <div className="col-md-12">
                                    {/* <img src="images/single-property-01.jpg" /> */}
                                    <div className="utf-edit-profile-photo-area">
                                      {" "}
                                      <center><img src={this.state.Main_Img} style={{width: 'auto'}} lazyload="true"/></center>
                                      <div className="utf-change-photo-btn-item">
                                        <div className="utf-user-photo-upload">
                                          {" "}
                                          <span> <i className="fa fa-upload" /> Select Photo </span>
                                          <input
                                            type="file"
                                            accept="image/*"
                                            className="upload tooltip top"
                                            title="Upload Photo"
                                            onChange={async e => {
                                              let files = e.target.files;
                                              let reader = new FileReader();
                                              reader.readAsDataURL(files[0]);

                                              reader.onload = async(e) => {
                                                 await this.setState({
                                                      Main_Img: await e.target.result,
                                                      Main_Img_Name: files[0].name, 
                                                    })

                                                  this.ValidateField('Main_Img', files[0].name)
                                              }
                                            }}
                                          />

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="margin-top-10" />
                                <div className="utf-listing-user-info header-inbox" style={{margin: 0}}>
                                  <i className="icon-line-awesome-image" />รูปอื่นๆ เพิ่มเติม จำนวน 4/20 ขนาดไม่เกิน 25 MB
                                </div>	
                                
                                <div className="margin-top-10 " />
                                
                                <input
                                  disabled={!this.state.formValid4} 
                                  type="file"
                                  accept="image/*"
                                  className="upload tooltip top"
                                  title='เลือกรูปเพิ่มเติม'
                                  multiple={true}
                                  onChange={async e => {
                                    let files = e.target.files;
                                    this.previewImageDetail(files)
                                  }}
                                />
                                
                                <div className="row margin-bottom-20 row margin-top-20"> 
                                  {this.state.imagesShow}
                                </div>  

                                
                                <div className="margin-top-50" />
                                <div className="utf-listing-user-info header-inbox" style={{margin: 0}}>
                                  <i className="icon-brand-youtube" />วิดีโอ Youtube (ถ้ามี)
                                </div>	
                                <div className="row"> 
                                  <div className="margin-top-20" />
                                  <div className="col-md-12"> 
                                    <input name="youtube_url" 
                                           type="text" 
                                           placeholder="Link Youtube" 
                                           value={this.state.youtube_url}
                                           onChange={this.handleUserInput}
                                           />
                                  </div>
                                </div>  
                                <div className="margin-top-50" />
                                <div className="utf-listing-user-info header-inbox" style={{margin: 0}}>
                                  <i className="sl sl-icon-compass" />360° องศา (ถ้ามี)
                                </div>	
                                <div className="row"> 
                                  <div className="margin-top-20" />
                                  <div className="col-md-12"> 
                                    <input name="Url_img_360" 
                                           type="text" 
                                           placeholder="Link 360°" 
                                           value={this.state.Url_img_360}
                                           onChange={this.handleUserInput}
                                           />
                                  </div>
                                </div>   
                              </div>
                              <hr />
                              <div className="row margin-top-10">
                                <div className="col-md-6">
                                  <button style={{width: 120, height: 40}}
                                          type="button" 
                                          className="btn btn-light"
                                          onClick={e => {
                                            e.preventDefault()
                                            this.setState({tapsPageSelected: 'optimization'})
                                            this.scollToTop()
                                          }}
                                          >
                                            <span className="icon-material-outline-arrow-back"> </span>ย้อนกลับ</button>
                                </div>
                                <div className="col-md-6" style={{textAlign: 'right'}}>
                                  <button style={{width: 120, height: 40}} 
                                          disabled={!this.state.formValid4} 
                                          type="button" 
                                          className="btn btn-primary"
                                          onClick={async e => {
                                            // e.preventDefault()
                                            // this.setState({tapsPageSelected: 'reporting'})
                                            this.scollToTop()

                                            let data = [...[{img: this.state.Main_Img}], ...this.state.displayImgs]
  
                                            //ยังหาวิธีแก้ที่ต้อง set displayImgsDiv 2 ครั้งไม่ได้
                                            this.setState({displayImgsDiv: null})
                                            this.setState({displayImgsDiv: await this.renderDisplayImg(data)})
                                            this.setState({displayImgsDiv: await this.renderDisplayImg(data),
                                              tapsPageSelected: 'reporting'})
                                          }}
                                          >
                                            <span className="icon-material-outline-arrow-forward"> </span> ถัดไป</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div role="tabpanel" className={"tab-pane " + (this.state.tapsPageSelected == 'reporting' ? 'active' : '')} id="reporting">
                            <div className="content with-padding">
                              <div className="widget utf-sidebar-widget-item"> 
                                <div className="utf-boxed-list-headline-item">
                                  <h3 style={{fontFamily: 'Kanit-Light'}}>สรุปประกาศ</h3>
                                </div>  
                                <style dangerouslySetInnerHTML={{__html: "\n\t\t\t\t\t\t.listing-details-primary .property-attr .value-block {\n\t\t\t\t\t\t\tfont-size: 14px;\n\t\t\t\t\t\t\tline-height: 1.57;\n\t\t\t\t\t\t \tmargin: 5px 20px 10px 0px;\n\t\t\t\t\t\t\tborder-bottom: 1px dashed #cbcdd0;\n\t\t\t\t\t\t}\n\t\t\t\t\t\t.title-block{\n\t\t\t\t\t\t\tfont-family:Kanit-Light;\n\t\t\t\t\t\t}\n\t\t\t\t\t\t.label-block\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tcolor: #1b1a1a;\n\t\t\t\t\t\t\tfont-family:Kanit-Light;\n\t\t\t\t\t\t\tfont-weight:900;\n\t\t\t\t\t\t}\n\t\t\t\t\t\t.value-block{\n\t\t\t\t\t\t\tcolor: #4c4d4a;\n\t\t\t\t\t\t}\n\n\t\t\t\t\t" }} />
                                <div className="utf-listing-user-info header-inbox" style={{margin: 0}}>
                                  <i className="fa fa-file-image-o" /> รูปภาพ
                                </div>	
                                <div className="margin-top-10" />
                                <div className="row"> 
                                  <div className="col-md-12">
                                    {this.state.displayImgsDiv}
                                  </div>

                                </div>

                                  <br />
                                <div className="listing-details-primary">
                                  <div className="utf-listing-user-info header-inbox" style={{marginLeft: 0, marginBottom: 10}}>
                                    <i className="fa fa-indent" />รายละเอียด * 
                                  </div>	
                                  <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ชื่อทรัพย์ / โครงการ </div>
                                        <div className="value-block" itemProp="value">
                                          {/* แกรนด์ หลังสวน ลุมพินี ปทุมวัน กรุงเทพมหานคร */}
                                          {this.state.Asset_Project !== '' ? this.state.Asset_Project: 'ไม่มีข้อมูล'}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ราคาประกาศ</div>
                                        <div className="value-block" itemProp="value">
                                          <font color="red"><NumberFormat
                                                                className="utf-listing-price"
                                                                value={this.state.Asset_Price}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                renderText={(value, props) => <span {...props}>{value} บาท</span>}
                                                        /> </font>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ที่อยู่ </div>
                                        <div className="value-block" itemProp="value">
                                          {this.state.Asset_Address_No !== '' ? this.state.Asset_Address_No + ' ': 'ไม่มีข้อมูล'}
                                          {this.state.Asset_Moo !== '' ? 'ม.' + this.state.Asset_Moo + ' ' : '' }
                                          {this.state.Asset_Soi !== '' ? 'ซ.' + this.state.Asset_Soi + ' ' : ''}
                                          {this.state.Asset_Road !== ''? 'ถ.' + this.state.Asset_Road + ' ' : ''}
                                          {this.state.tumbon_name !== '' ? this.state.tumbon_name + ' ' : ''}
                                          {this.state.amphoe_name !== '' ? this.state.amphoe_name + ' ' : ''}
                                          {this.state.province_name !== '' ? this.state.province_name + ' ' : ''}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ผู้รับผิดชอบค่าโอน
                                        </div>
                                        <div className="value-block" itemProp="value">{this.state.Asset_Transfer_Fee_Name}</div>
                                      </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ประเภททรัพย์
                                        </div>
                                        <div className="value-block" itemProp="value">{this.state.Sub_Asset_Type_name}</div>
                                      </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr ">
                                        <div className="label-block" itemProp="name">ประเภทประกาศ</div>
                                        <div className="value-block" itemProp="value">{this.state.Action_Type_name}</div>
                                      </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr ">
                                        <div className="label-block" itemProp="name">ลักษณะทรัพย์</div>
                                        <div className="value-block" itemProp="value">{this.state.Cat_Type_name}</div>
                                      </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ห้องนอน</div>
                                        <div className="value-block" itemProp="value">{this.state.Number_Bed_Room}</div>
                                      </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ห้องน้ำ</div>
                                        <div className="value-block" itemProp="value">{this.state.Number_Bath_Room}</div>
                                      </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ห้องนั่งเล่น</div>
                                        <div className="value-block" itemProp="value">{this.state.Number_Living_Room}</div>
                                      </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ที่จอดรถ</div>
                                        <div className="value-block" itemProp="value">{this.state.Number_Car_Park}</div>
                                      </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ห้องครัว</div>
                                        <div className="value-block" itemProp="value">{this.state.Number_Kitchen}</div>
                                      </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">แอร์</div>
                                        <div className="value-block" itemProp="value">{this.state.Number_Air_Condition}</div>
                                      </div>
                                    </div>



                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">พื้นที่ใช้สอย</div>
                                        <div className="value-block" itemProp="value">{this.state.Asset_Usage_Area} ตร.ม.</div>
                                      </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ขนาดที่ดิน</div>
                                        <div className="value-block" itemProp="value">
                                          {this.state.rai + ' ไร่ '}
                                          {this.state.ngan + ' งาน '}
                                          {this.state.wa + ' วา'}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ราคาต่อตร.ม.</div>
                                        <div className="value-block" itemProp="value">
                                          {
                                        parseFloat(this.state.Asset_Usage_Area) > 0 ? <NumberFormat
                                          className="utf-listing-price"
                                          value={(parseFloat(this.state.Asset_Price) / parseFloat(this.state.Asset_Usage_Area)).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          renderText={(value, props) => <span {...props}>{value} บาท / ตารางเมตร</span>} /> : 'ไม่มีข้อมูล'
                                        }</div>
                                      </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ปีที่สร้างเสร็จ</div>
                                        <div className="value-block" itemProp="value">{this.state.Asset_Built_Year !== '' ? this.state.Asset_Built_Year : 'ไม่มีข้อมูล'}</div>
                                      </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ชั้น</div>
                                        <div className="value-block" itemProp="value">{this.state.Building_Floor !== 0 ? this.state.Building_Floor : 'ไม่มีข้อมูล'}</div>
                                      </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                      <div className="property-attr">
                                        <div className="label-block" itemProp="name">ชั้นที่</div>
                                        <div className="value-block" itemProp="value">{this.state.Asset_Floor !== 0 ? this.state.Asset_Floor: 'ไม่มีข้อมูล'}</div>
                                      </div>
                                    </div>
                                  
                                  </div>

                                  <div className="utf-listing-user-info header-inbox" style={{marginLeft: 0, marginBottom: 10}}>
                                    <i className="fa fa-list" />รายละเอียดเพิ่มเติม 
                                  </div>	
                                  <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                      {parse(this.state.Asset_Detail ? this.state.Asset_Detail : '')}
                                    </div>
                                  </div>
                                </div>	
                              </div>
                              <hr />
                              <div className="margin-top-10">
                                <div className="checkbox margin-top-0">
                                <input type="checkbox" id="two-step10"  
                                       name="consent"
                                       value={this.state.consent} 
                                       onChange={this.handleUserInput}
                                />
                                <label htmlFor="two-step10">
                                  <span className="checkbox-icon" />
                                   ฉันยอมรับเงื่อนไขและข้อตกลง&nbsp;<a href="#">
                                    Terms &amp; Conditions
                                  </a>{" "}
                                  and <a href="#">Privacy Policy</a>
                                </label>
                              </div>
                              </div>
                              <div className="row margin-top-10">
                                <div className="col-md-6">
                                  <button style={{width: 120, height: 40}}
                                          type="button" 
                                          className="btn btn-light"
                                          onClick={e => {
                                            e.preventDefault()
                                            this.setState({tapsPageSelected: 'content'})
                                            this.scollToTop()
                                          }}
                                          >
                                            <span className="icon-material-outline-arrow-back"> </span>ย้อนกลับ</button>
                                </div>
                                <div className="col-md-6" style={{textAlign: 'right'}}>
                                  <button style={{width: 120, height: 40}} 
                                          disabled={!this.state.formValid5} 
                                          type="button" 
                                          className="btn btn-danger" 
                                          onClick={e => this.save()}>
                                            <span className="icon-feather-arrow-up-circle"> </span> เผยแพร่</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>	
                </div>

            </div>
          </div>
        </div>



    </div>);
  }
}

export default AddNewProperty;
