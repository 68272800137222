import React, { Component } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Login from "./login";
// import Register from "./register";
import "./header.css";
import { Modal } from 'semantic-ui-react'
import Register_policy from "./register_policy";

const bcrypt = require("bcryptjs");

class SignPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectTabs: 0,
      openModal: false,
    };
  }

  render() {
    return (
      <div>
        <Tabs
          style={{ backgroundColor: "white" }}
          selectedIndex={this.state.selectTabs}
          onSelect={(index) => this.setState({ selectTabs: index })}
        >
          <TabList>
            <Tab
              style={{
                width: "50%",
                padding: "15px",
                textAlign: "center",
                backgroundColor:
                  this.state.selectTabs == 0
                    ? "rgb(227, 51, 36)"
                    : "whitesmoke",
                color: this.state.selectTabs == 0 ? "white" : "gray",
              }}
            >
              <span className="icon-line-awesome-key"> เข้าสู่ระบบ </span>
            </Tab>
            <Tab
              style={{
                width: "50%",
                padding: "15px",
                textAlign: "center",
                backgroundColor:
                  this.state.selectTabs == 1
                    ? "rgb(227, 51, 36)"
                    : "whitesmoke",
                color: this.state.selectTabs == 1 ? "white" : "gray",
              }}
            >
              <span className="icon-line-awesome-user"> สมัครสมาชิก </span>

            </Tab>
          </TabList>

          <TabPanel
            className="container"
            style={{
              width: "100%",
              borderTop: { color: "red", borderTopWidth: 1 },
            }}
          >
            <Login />
          </TabPanel>
          <TabPanel
            className="container"
            style={{ width: "100%",
             borderColor: "red",
             borderTopWidth: 1
            }}
          >
            <Register_policy/>
          </TabPanel>
        </Tabs>

      </div>
    );
  }
}

export default SignPage;
