import React, { Component } from "react";
import './header.css'
import SignPage from './singpage'
import {Modal } from 'semantic-ui-react'
import {imageUrl} from './../../constants/index'


class Headers extends Component {
  constructor (props) {
    super(props);
    this.state = {
      openModal: false,
    }
  }

  componentDidMount () {

  }


  render() {
    
    return (
      <div>

        <Modal
          onClose={() => this.setState({openModal: false}) }
          onOpen={() => this.setState({openModal: true}) }
          open={this.state.openModal}
          size="tiny"
        >
          <Modal.Content >
            <Modal.Description>
              <SignPage />
            </Modal.Description>
          </Modal.Content>
        </Modal>      
      

       
        <div className="vfx-loader">
          <div className="loader-wrapper">
            <div className="loader-content">
              <div className="loader-dot dot-1" />
              <div className="loader-dot dot-2" />
              <div className="loader-dot dot-3" />
              <div className="loader-dot dot-4" />
              <div className="loader-dot dot-5" />
              <div className="loader-dot dot-6" />
              <div className="loader-dot dot-7" />
              <div className="loader-dot dot-8" />
              <div className="loader-dot dot-center" />
            </div>
          </div>
        </div>

        <div id="wrapper">
          <header id="header-container" className="fullwidth">
            
            <div id="header">
              <div className="container">
                <div className="left-side">
                  <div id="logo">
                    <a href="home">
                      <img src="images/logo_1.png" />
                    </a>
                  </div>
                  <div className="mmenu-trigger">
                    <button
                      className="hamburger hamburger--collapse"
                      type="button"
                    >
                      {" "}
                      <span className="hamburger-box">
                        {" "}
                        <span className="hamburger-inner" />{" "}
                      </span>{" "}
                    </button>
                  </div>
               
                  <nav id="navigation" className="style-1">
                    <ul id="responsive">
                      <li className="active">
                        <a className="current" href="home">
                          หน้าแรก
                        </a>
                      </li>
                      <li>
                        <a href="news">ข่าวอสังหาริมทรัพย์</a>
                      </li>
                      <li>
                            <a href="calculator">คำนวนสินเชื่อ</a>
                      </li>
                      <li>
                        <a href="/listings?selecttype=Rent">เช่า</a>
                      </li>
                    
                      <li>
                        <a href="#">เพิ่มเติม</a>
                        <ul> 
                          <li>
                            <a href="product-advert">
                              ผลิตภัณฑ์ / พื้นที่โฆษณา
                            </a>
                          </li>
                          <li>
                            <a href="about">เกี่ยวกับเรา</a>
                          </li>
                             <li>
                            <a href="contact">ติดต่อเรา</a>
                          </li>
                        </ul>
                      </li>
                   
                    </ul>
                  </nav>
                  <div className="clearfix" />
                </div>
                <div className="right-side">
                  <div className="header-widget">

                    {localStorage.getItem('Member_ID') ?
                    <div className="user-menu">
                      <div className="user-name" ><span><img src={localStorage.getItem('Member_Picture') != 'null' && localStorage.getItem('Member_Picture') != null ? imageUrl + '/' + localStorage.getItem('Member_Picture'): "images/agent-03.jpg"}  style={{width: 'auto', height: '40px'}}/></span>
                        <div className="user-name-title">{localStorage.getItem('Member_Name')}</div>
                      </div>
                      <ul>
                        <li><a href="profile"><i className="sl sl-icon-user" /> บัญชีส่วนตัว</a></li>
                        <li><a href="bookmarks"><i className="sl sl-icon-star" /> ทรัพย์ที่ชื่นชอบ</a></li>
                        <li><a href="my-properties"><i className="sl sl-icon-docs" /> ประกาศของฉัน</a></li>
                        <li><a href="add-new-property"><i className="sl sl-icon-action-redo" /> เพิ่มประกาศใหม่</a></li>
                        <li><a href="change-password"><i className="sl sl-icon-lock" /> เปลี่ยนรหัสผ่าน</a></li>
                        <li onClick={e=>{
                          localStorage.clear()
                          window.location.reload(false)
                        }}><a><i className="sl sl-icon-power" /> ออกจากระบบ</a></li>
                      </ul>
                    </div>
                    :     
                    <a href="#" 
                       className="log-in-button sign-in" 
                       onClick={() => this.setState({openModal: true})}>
                        <i className="icon-line-awesome-user" /> 
                        <span>เข้าสู่ระบบ</span>
                    </a>
                  }
                    
                    <a href={localStorage.getItem("Member_ID") ? 'add-new-property' : '#'} 
                       className="button border"
                       onClick={() => {
                         if(!localStorage.getItem("Member_ID")){
                           this.setState({openModal: true})
                         } 
                       }}                 
                    >
                      <i className="icon-feather-plus-circle" />{" "}
                      <span>ลงประกาศฟรี!</span>
                    </a>

                  </div>
                </div>
              </div>
            </div>
            
          </header>
        </div>
{/* Header Container */}
 


        <div className="clearfix" />        

        <div id="backtotop">
          <a href="#" />
        </div>
      </div>
    );
  }
}

export default Headers;

