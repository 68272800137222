
import React, { Component } from "react";
class Logout extends Component {
    componentDidMount() {
        localStorage.clear()
        this.props.history.push('/home')
        window.location.reload(false)
    }

    render() {
        return (<div>Logout</div>)
    }

}

export default Logout;