import React, { Component } from "react";
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";

import 'react-tabs/style/react-tabs.css';


const bcrypt = require("bcryptjs");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailLogin: "",
      passwordLogin: "",

      emailLoginValid: false,
      passwordLoginValid: false,
      formValid: false,

      formErrors: { emailLogin: "", passwordLogin: "" },
      isError: 'ok'
    };
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailLoginValid = this.state.emailLoginValid;
    let passwordLoginValid = this.state.passwordLoginValid;

    switch (fieldName) {
      case "emailLogin":
        emailLoginValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.emailLogin = emailLoginValid
          ? ""
          : "รูปแบบอีเมลไม่ถูกต้อง";
        break;
      case "passwordLogin":
        passwordLoginValid = value.length >= 6;
        fieldValidationErrors.passwordLogin = passwordLoginValid
          ? ""
          : "รหัสผ่านสั้นเกินไป";
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailLoginValid: emailLoginValid,
        passwordLoginValid: passwordLoginValid,
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailLoginValid && this.state.passwordLoginValid,
    });
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error";
  }

  render() {
    return (
      <div >
        <div className="utf-welcome-text-item">
          <h3>ยินดีต้อนรับ <br /> กรุณากรอกอีเมล และรหัสผ่านเพื่อเข้าสู่ระบบ</h3>
          <span>
          หากท่านยังไม่ได้เป็นสมาชิก?{" "}
            <a href="#" className="register-tab">
            คลิก Tab สมัครสมาชิก!
            </a>
          </span>
        </div>

        <div
          className={`utf-no-border ${this.errorClass(
            this.state.formErrors.emailLogin
          )}`}
        >
          <input
            type="email"
            required
            className="form-control"
            name="emailLogin"
            autocomplete="off"
            placeholder="อีเมล *"
            value={this.state.emailLogin}
            onChange={this.handleUserInput}
          />
          {this.state.formErrors.emailLogin != "" ? (
            <p style={{ color: "red" }}>
              {this.state.formErrors.emailLogin}
              <br />
            </p>
          ) : null}
        </div>

        <div
          className={`utf-no-border ${this.errorClass(
            this.state.formErrors.passwordLogin
          )}`}
        >
          <input
            type="password"
            className="form-control"
            name="passwordLogin"
            placeholder="รห้สผ่าน *"
            value={this.state.passwordLogin}
            onChange={this.handleUserInput}
          />
          {this.state.formErrors.passwordLogin != "" ? (
            <p style={{ color: "red" }}>
              {this.state.formErrors.passwordLogin}
              <br />
            </p>
          ) : null}
        </div>

        {
            this.state.isError != 'ok' ?
                <div className="notification error closeable"  >
                <p>อีเมลหรือรหัสผ่านไม่ถูกต้อง</p>
                </div> 
            : null
        }

        <div className="checkbox margin-top-0">
          <input type="checkbox" id="two-step" />
          <label htmlFor="two-step">
            <span className="checkbox-icon" /> จดจำรหัสผ่าน
          </label>
        </div>
        
        

        <a href="forgot-password" className="forgot-password">
          ลืมรหัสผ่าน?
        </a>

        <button
          className={`margin-top-10 button full-width utf-button-sliding-icon ripple-effect  ${
            !this.state.formValid ? " btn btn-secondary" : ""
          }`}
          type="submit"
          onClick={async e=>{

            if(this.state.formValid){
              let values = {
                email: this.state.emailLogin, 
                pass: this.state.passwordLogin}
              let result = await httpClient.post(server.MEMBER_URL + '/login', values)
              this.setState({isError: await result.result})

              if(result.result == 'ok'){
                  localStorage.setItem('Member_ID', result.data['Member_ID'])
                  localStorage.setItem('Member_Name', result.data['Member_Name'])
                  localStorage.setItem('Member_Picture', result.data['Member_Picture'])
                  localStorage.setItem('Member_Email', result.data['Member_Email'])
                  window.location.reload()
              }
            } else {
              await this.validateField('emailLogin', this.state.emailLogin)
              await this.validateField('passwordLogin', this.state.passwordLogin)
            }

            
          }}

        >
          เข้าสู่ระบบ <i className="icon-feather-chevrons-right" />
        </button>



        

      </div>
    );
  }
}
export default Login;
