import {
    HTTP_SEARCHBOX_FETCHING,
    HTTP_SEARCHBOX_SUCCESS,
    HTTP_SEARCHBOX_FAILED,
    server, 
  } from "../constants";
  
  import { httpClient } from "./../utils/HttpClient";
  
  export const setSearchboxStateToFetching = () => ({
    type: HTTP_SEARCHBOX_FETCHING
  });
  
  export const setSearchboxStateToSuccess = payload => ({
    type: HTTP_SEARCHBOX_SUCCESS,
    payload
  });
  
  export const setSearchboxStateToFailed = () => ({
    type: HTTP_SEARCHBOX_FAILED
  });

  export const getHomeData = () => {
    return dispatch => {
        dispatch(setSearchboxStateToFetching());
        doGetSearchData(dispatch);
    }
  }

  const doGetSearchData = (dispatch) => {
      httpClient.get(server.HOME_URL).then(result => {
          dispatch(setSearchboxStateToSuccess(result))
      }).catch(error => {
          dispatch(setSearchboxStateToFailed())
      })
  }

  // let globalSearchState = {
  //   assetType: 0,
  //   assetOptions: null,
  
  //   provincesOptions: null,
  //   provinceSelceted: null,
  
  //   amphoesLists: null,
  //   amphoeOptions: null ,  
  //   amphoeSelected: null,
  
  //   tumbonsLists: null,
  //   tumbonOptions: null,
  //   tumbonSelected: null,
  
  //   projectName: "",
  //   bedroom: 0,
  //   bathroom:0,
  //   carpark: 0,
  //   minPrice: "",
  //   maxPrice: "",
  //   minUses: "",
  //   maxUses: "", 
  // }

export const getGlobalSearchData = ()=> {
    return dispatch => {
        dispatch(setSearchboxStateToFetching());
        // dispatch(setSearchboxStateToSuccess(globalSearchState))
        doGetDatas(dispatch)
    }
}

const doGetDatas = async (dispatch)=>{
  await httpClient.get(server.SEARCHBOX_URL).then(result=>{
      dispatch(setSearchboxStateToSuccess(result.data))
  }).catch(error=>{
      // alert(JSON.stringify(error))
      dispatch(setSearchboxStateToFailed())
  })
}



// export const setGlobalSearchState = (val) => {

// }

