import React, { Component } from "react";
import "./coming-soon.css";

class ComingSoon extends Component {
  render() {
    return (
      <div>
       
        <div
          className="parallax titlebar"
          data-background="images/popular-location-03.jpg"
          data-color="rgba(48, 48, 48, 1)"
          data-color-opacity="0.8"
          data-img-width={800}
          lazyload="true"
          data-img-height={505}
        >
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>เปิดใช้งานในเร็วๆ นี้ </h2>
                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="index.html">หน้าแรก</a>
                      </li>
                      <li>สามารถใช้งานได้ภายในเร็วๆนี้</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="wrapper">
          <section className="countdown-area">
              <div className="container bg">
                <div className="row">
                  <div className="col-md-6">
                    <div className="text text-center">
                      <h2> <b>  กำลังเปิดใช้งานในเร็วๆ นี้ </b> </h2>
                      {/* <div className="count">
                        <div className="month">
                          <h3 className="month">03</h3>
                          <p>Month</p>
                        </div>
                        <div>
                          <h3 className="day">30</h3>
                          <p>Day</p>
                        </div>
                        <div>
                          <h3 className="min">01</h3>
                          <p>Minute</p>
                        </div>
                        <div>
                          <h3 className="sec">12</h3>
                          <p>Second</p>
                        </div>
                      </div> */}
                      <div className="form">
                        <h2>ท่านสามารถฝากขายทรัพย์ ฟรีด้วยการ สมัครสมาชิกกับเรา</h2>
                        <div className="row">
              
                <div className="utf_subscribe_block clearfix">
               
                  <div className="col-md-12 col-sm-12">
                    <div className="contact-form-action">
                      <form method="post">
                        <span className="la la-envelope-o" />
                      
                        <a href="home" className="button margin-top-10" type="submit">
                          กลับหน้าแรก
                        </a>
                      </form>
                    </div>
                  </div>
                </div>
              
            </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="image">
                       <img src="https://i.postimg.cc/NMnm6w5X/image.png" />  
                    </div>
                  </div>
                </div>
              </div>
            </section> 
          </div>

        <div className="margin-top-40"></div>

      </div>
    );
  }
}

export default ComingSoon;
