import React, { Component } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';
import './contact.css'

const iconPerson = new L.Icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

class Contact extends Component {

  
  render() {
    const position = [13.761912,100.569904]
    return  <div>      
  <div className="clearfix" />
  <div className="parallax titlebar" data-background="images/login.jpg" data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
    <div id="titlebar">
      <div className="container">
        <div className="row">        
          <div className="col-md-12">
            <h2>ติดต่อเรา</h2>
            <nav id="breadcrumbs">
              <ul>
                <li><a href="home">หน้าแรก</a></li>
                <li>ติดต่อเรา</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="row">  
    <div className="col-md-12">
         <h2> เรายินดีให้ความช่วยเหลือ</h2>
<p>หากคุณมีคำถามหรือต้องการเสนอแนะเกี่ยวกับการให้บริการ สามารถกรอกแบบฟอร์มทางด้านข้างเพื่อติดต่อเรา เจ้าหน้าที่จะติดต่อกลับไปหาเพื่อให้ความช่วยเหลือโดยเร็ว
การให้บริการคุณคือสิ่งสำคัญที่สุดของเรา</p>
 
          </div>
         
      <div className="col-md-12">
        <div className="utf-contact-map margin-bottom-50">
          <div className="utf-google-map-container">
        
          </div>
        </div>
      </div>	
      {/* Contact Details */}
      <div className="col-md-5">
     
        {/* Contact Details */}

     


        <div className="boxmap">
        <MapContainer center={position} zoom={15} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}icon={iconPerson}>
              <Popup>
                ศูนย์ข้อมูลอสังหาริมทรัพย์ ธนาคารอาคารสงเคราะห์ <br />
                ( Real Estate Information Center )
                
              </Popup>
            </Marker>
          </MapContainer>
        </div>
        <div className="utf-contact-location-info-aera sidebar-textbox margin-bottom-40">
          <ul >
            <li><i className="icon-feather-smartphone" /> <strong>Phone Number:</strong> <span>0-2202-1768</span></li>
            <li><i className="icon-material-outline-email" /> <strong>Email Address:</strong> <span><a href="#">webmaster@clickthaihome.com</a></span></li>
            <li><i className="icon-feather-globe" /> <strong>Website:</strong> <span><a href="https://www.reic.or.th/">https://www.reic.or.th/ </a></span></li>
            <li><i className="icon-feather-map-pin" /> <strong>Address:</strong> <span>ศูนย์ข้อมูลอสังหาริมทรัพย์ ธนาคารอาคารสงเคราะห์ 63 ถนนพระราม 9 แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพฯ 10310</span></li>            
          </ul>
        </div>
      </div>
      {/* Contact Form */}
      <div className="col-md-7">
        <section id="contact">
          <div className="utf-boxed-list-headline-item">
            <h3><i className="icon-feather-layers" /> แบบฟอร์มติดต่อ</h3>
          </div>
          <div className="utf-contact-form-item">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <input name="name" type="text" placeholder="ชื่อ" required />                
                </div>
                <div className="col-md-6">
                  <input name="name" type="text" placeholder="นามสกุล" required />                
                </div>
                <div className="col-md-6">
                  <input name="email" type="email" placeholder="Email Address" required />                
                </div>
                <div className="col-md-6">
                  <input name="name" type="text" placeholder="หัวข้อ" required />                
                </div>
                <div className="col-md-12">
                  <textarea name="comments" cols={40} rows={3} placeholder="ระบุข้อความ..." spellCheck="true" required defaultValue={""} />
                </div>
              </div>
         
              <div className="utf-centered-button margin-bottom-10">	
              <button className="button margin-top-10" type="submit">
              ส่งข้อความ
                </button>
                </div>
            </form>
          </div>	  
        </section>
      </div>
      {/* Contact Form / End */}       
    </div>
  </div>
</div> ;
  }
}

export default Contact;
