import React, { Component } from 'react';

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: ''
    }
  }

  componentDidMount(){
    let url = window.location.href
    this.setState({pathname: url.split('/')[3]})
  }


  render() {
    
    return (
      <div>
        <div className="margin-bottom-20"> </div>
              <div className="sidebar margin-top-20">
                <div className="user-smt-account-menu-container">
                  <ul className="user-account-nav-menu">
                    <li><a href="profile" className={this.state.pathname == 'profile' ? 'current': ''}><i className="sl sl-icon-user" /> ข้อมูลของฉัน</a></li>
                    <li><a href="bookmarks" className={this.state.pathname == 'bookmarks' ? 'current': ''}><i className="sl sl-icon-star" /> ทรัพย์ที่ชื่นชอบ</a></li>
                    <li><a href="my-properties" className={this.state.pathname == 'my-properties' ? 'current': ''}><i className="sl sl-icon-docs" /> ประกาศของฉัน</a></li>
                    <li><a href="add-new-property" className={this.state.pathname == 'add-new-property' ? 'current': ''}><i className="sl sl-icon-action-redo" /> ประกาศทรัพย์ใหม่</a></li>
                    <li><a href="change-password" className={this.state.pathname == 'change-password' ? 'current': ''}><i className="sl sl-icon-lock" /> เปลี่ยนรหัสผ่าน</a></li>
                    <li onClick={e=>{
                          localStorage.clear()
                          window.location.reload(false)
                        }}><a href="logout"><i className="sl sl-icon-power" /> ออกจากระบบ</a></li>
                  </ul>            
                </div>
              </div>
              <div className="widget utf-sidebar-widget-item">
                <div className="utf-detail-banner-add-section">
                  <a href="#"><img src="images/banner/425x318_2.jpg" alt="banner-add-2" /></a>
                </div>             
              </div>



      </div>


    );
  }
}

export default UserMenu;
