import React, { Component } from "react";
import "./product-advert.css";
class ProductAdvert extends Component {
  render() {
    return (
      <div>
        <div
          className="parallax titlebar"
          data-background="images/login.jpg"
          data-color="rgba(48, 48, 48, 1)"
          data-color-opacity="0.8"
          data-img-width={800}
          data-img-height={505}
          style={{
            backgroundImage: 'url("images/listings-parallax.jpg")',
            backgroundAttachment: "fixed",
            backgroundSize: "1349px 851.556px",
            backgroundPosition: "50% -647.579px",
          }}
        >
          <div
            className="parallax-overlay"
            style={{ backgroundColor: "rgb(48, 48, 48)", opacity: "0.8" }}
          />
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>ผลิตภัณฑ์ / โฆษณา</h2>
                  {/* Breadcrumbs */}
                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="home">หน้าแรก</a>
                      </li>
                      <li>ผลิตภัณฑ์ / โฆษณา</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-md-12">
            <h2> สินค้าและบริการของศูนย์ข้อมูลอสังหาริมทรัพย์ </h2>
            <p>
              หากคุณสนใจผลิตภัณฑ์ ข้อมูลการวิเคราะห์ทางด้านอสังหาฯ
              สามารถติดต่อกับเจ้าหน้าที่ ที่รับผิดชอบ
            </p>
          </div>

          <div className="row">
            <div className="col-md-3">
              <img src="images/product/AdOnline1.jpg" lazyload="true" />
            </div>
            <div className="col-md-3">
              <img src="images/product/AdOnline2.jpg" lazyload="true" />
            </div>
            <div className="col-md-3">
              <img src="images/product/AdOnline3.jpg" lazyload="true" />
            </div>
            <div className="col-md-3">
              <img src="images/product/AdOnline4.jpg" lazyload="true" />
            </div>
          </div>

          <div className="margin-top-20"></div>

          <div className="col-md-12">
            <h2> รายละเอียดพื้นที่โฆษณา เว็บไซต์ ClickThaiHome.com </h2>
            <p>
              หากคุณมีคำถามหรือต้องการเสนอแนะเกี่ยวกับการให้บริการ
              สามารถติดต่อเจ้าหน้าที่ที่ได้รับมอบหมาย
              ทางเราจะติดต่อกลับไปหาเพื่อให้ความช่วยเหลือโดยเร็ว
              การให้บริการคุณคือสิ่งสำคัญที่สุดของเรา
            </p>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div
                className="show-xs hidden-lg hidden-md"
                style={({ fontSize: 8 }, { color: "red" })}
              >
                <span className="icon-line-awesome-caret-square-o-right"> </span>
                เลื่อนซ้าย ขวา เพื่อดูข้อมูลเพิ่มเติม
              </div>
              <div className="hack1">
                <div className="hack2">
                  <table className="table table-bordered text-center table-responsive">
                    <thead>
                      <tr style={{ backgroundColor: "beige" }}>
                        <th scope="col">แพ็คเกจ</th>
                        <th scope="col"><i className="fa fa-image" aria-hidden="true"></i> ตัวอย่าง</th>
                        <th scope="col">ตำแหน่ง </th>
                        <th scope="col">ขนาด (Pixels)</th> 
                        <th scope="col" className="text-nowrap">
                          รูปแบบการโฆษณา
                        </th>
                        <th scope="col">ประเภท Banner</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col">ค่าบริการ (บาท/เดือน) </th>
                        <th scope="col">ราคา 3 เดือน (ส่วนลด 5%)</th>
                        <th scope="col">ราคา 6 เดือน (ส่วนลด 10%)</th>
                        <th scope="col">ราคา 12 เดือน (ส่วนลด 20%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td><a herf="#"><i className="fa fa-image" aria-hidden="true"></i></a></td>
                        <td className="txt-left">C - Upper</td>
                        <td>468x60</td>
                        <td className="txt-left">
                          แสดงทุกหน้า ด้านบนสุด ยกเว้น หน้ามุมบ้านใหม่
                        </td>
                        <td>Fix ตำแหน่ง</td>
                        <td>1 ตำแหน่ง</td>
                        <td>5,000</td>
                        <td>14,250</td>
                        <td>27,000</td>
                        <td>48,000</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td><a herf="#"><i className="fa fa-image" aria-hidden="true"></i></a></td>
                        <td className="txt-left">C – Lower Middle</td>
                        <td>468x60</td>
                        <td className="txt-left">แสดงเฉพาะหน้าแรก ด้านกลาง</td>
                        <td>Fix ตำแหน่ง</td>
                        <td>2 ตำแหน่ง</td>
                        <td>2,500</td>
                        <td>7,125</td>
                        <td>13,500</td>
                        <td>24,000</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td><a herf="#"><i className="fa fa-image" aria-hidden="true"></i></a></td>
                        <td className="txt-left">C - Bottom</td>
                        <td>120x60</td>
                        <td className="txt-left">แสดงเฉพาะหน้าแรก ด้านล่าง</td>
                        <td>Fix ตำแหน่ง</td>
                        <td>10 ตำแหน่ง</td>
                        <td>1,500</td>
                        <td>4,275</td>
                        <td>8,100</td>
                        <td>14,400</td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td><a herf="#"><i className="fa fa-image" aria-hidden="true"></i></a></td>
                        <td className="txt-left">C - Upper Middle</td>
                        <td>468x60</td>
                        <td className="txt-left">
                          แสดงทุกหน้าค้นหาบ้าน ภาคละ 1 ตำแหน่ง
                          <p>- ภาคกลาง</p>
                          <p>- ภาคหนือ</p>
                          <p>- ภาคตะวันออก</p>
                          <p>- ภาคกลางตะวันออกเฉียงเหนือ</p>
                          <p>- ภาคใต้</p>
                        </td>
                        <td>Fix ตำแหน่ง</td>
                        <td>5 ตำแหน่ง</td>
                        <td>4,000</td>
                        <td>11,400</td>
                        <td>21,600</td>
                        <td>38,400</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="show-xs hidden-lg hidden-md"
                style={({ fontSize: 8 }, { color: "red" })}
              >
                <span className="icon-line-awesome-caret-square-o-right"> </span>
                เลื่อนซ้าย ขวา เพื่อดูข้อมูลเพิ่มเติม
              </div>

              {/* Compare List / End */}
            </div>
            <div className="col-md-12 text-center">
              <br />
              <p>
              <i className="sl sl-icon-call-end sl-icon"></i>
              </p>
              <b>สนใจติดต่อโฆษณา</b> <br />
              คุณศรัณยา (Miss Saranya) ฝ่ายประชาสัมพันธ์และบริการข้อมูล โทร :
              0-2645-9676 <br />
              E-mail : saranya.k@reic.or.th <br />
              E-mail : webmaster@reic.or.th
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductAdvert;
