import React, { Component } from 'react';
import './calculator.css'
import './../my-properties/my-properties.css'
import CanvasJSReact from '../../assets/canvasjs.react';
// import NumberFormat from "react-number-format";
import { currencyFormat } from "./../default-class/default-class"

var CanvasJSChart = CanvasJSReact.CanvasJSChart;


class calculator_content extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            numCal: 0,
            _isChecked: '',
            stamp: 0,
            tax: 0,
            sumfee: 0,
            sumCal: 0,
            numPrice: 0,
            // numBorrow: 0,
            numTime: 30,
            numRate: 6,
            sumInstallments: 0,
            sumRateM: 1,
            sumMoney: 1,
            sumBorrow: 0,
        }

        this.state = this.initialState
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.price != this.props.numPrice) {
          this.setState({ numPrice: nextProps.price, sumBorrow: Math.round((20/ 100) * nextProps.price, -2)});
          this.SumLoan()
        }
    }

    handleUserInput = async (e) => {
        const name = e.target.name;
        const value = e.target.value;

        await this.setState({ [name]: value });
        this.Sum()
    }

    handlePrice = async (e) => {
        const name = e.target.name;
        const value = e.target.value;

        await this.setState({ [name]: value });
        this.SumLoan()
    }

    SumLoan(){
        if (this.state.numPrice != 0 && this.state.sumBorrow != 0) {
            let _numPrice = this.state.numPrice.toString().replace(/,/g, '')
            let _sumBorrow = this.state.sumBorrow.toString().replace(/,/g, '')

            let B1 = parseInt(_numPrice)         //ราคาบ้าน
            let B6 = (20 / 100 ) * B1                       //จำนวนเงินดาวน์
            let B9 = parseInt(this.state.numTime) * 12      // จำนวนปี * 12 คือเดือน
            let B7 = parseFloat(_sumBorrow)        // จำนวนเงินกู้
            // let B7 = B1 - B6                            // จำนวนเงินกู้
            let B4 = parseFloat(this.state.numRate)          // อัตราดอกเบี้ย
            let B8 = (B4/100)/12                           // อัตราดอกเบี้ยต่อปี
                        
            //จำนวนเงินทีต่อจ่ายต่อเดือน B11
            let _sumPrice = Math.round(Math.ceil(Math.abs((B8*B7*Math.pow(1+B8,B9)) / (1-Math.pow(1+B8,B9))) /100) *100)
            // let _sumPrice = Math.round(Math.abs(B8*B7*Math.pow((1+B8),B9)/(1-Math.pow((1+B8),B9))) ,-2)
            
            // จำนวนดอกเบี้ยทั้งหมด B12
            let _sumRateY =  (_sumPrice*B9)-B7

            //จำนวนดอกเบี้ยต่อเดือน B13
            let _sumRateM =  Math.round( (_sumRateY /B9) )

            //จำนวนเงินต้น
            let _sumMoney = _sumPrice - _sumRateM

            this.setState({sumInstallments: _sumPrice})
            this.setState({sumRateM: _sumRateM})
            this.setState({sumMoney: _sumMoney})

        } else { this.setState({sumInstallments: 0 }) 
        }
    }

    Sum(){
        let _numCal = this.state.numCal.replace(/,/g, '')
        if (_numCal != 0) {
            if (this.state._isChecked == "0"){ 
                this.setState({stamp: ((_numCal * 0.5) / 100) ,tax: 0,})
            }
            else if (this.state._isChecked == "1"){ 
                this.setState({stamp: 0 ,tax: ((_numCal * 3.3) / 100),})
            }
            this.setState({sumfee: ((_numCal * 2.0) / 100)})
            this.setState({sumCal: this.state.sumfee + this.state.stamp + this.state.tax })
        } else { this.setState({sumfee: 0 })  }
    }

    numberWithCommas(x) {
        return x.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        return (
            // <div id="wrapper">
            <div >
                <div className="row">
                    <div className="col-md-12">
                        <div className="Boxhead">
                            <h2>โปรแกรมคำนวณเงินกู้ คำนวณสินเชื่อบ้านเบื้องต้น</h2>
                            <p>การคำนวณเงินกู้นี้เป็นการประมาณการยอดเงินกู้ได้สูงสุด เพื่อแนะนำโครงการ หรือ บริการสินเชื่อที่เหมาะกับคุณ
                                ซึ่งขึ้นอยู่กับรายได้และระยะในการผ่อนของคุณ</p>
                        </div>
                    </div>
                </div>
                <div className="hrstyle" ></div>

                <br />
                <div className="style-1">
                    <ul className="tabs-nav">
                        <li className="active"><a href="#tab1"><i className="sl sl-icon-calculator"></i> คำนวณสินเชื่อบ้านเบื้องต้น</a></li>
                        <li><a href="#tab2"><i className="sl sl-icon-wallet"></i>  คำนวณค่าใช้จ่าย & ราคาประเมิน
                        </a></li>

                    </ul>
                    <div className="tabs-container">
                        <div className="tab-content" id="tab1" >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="content with-padding">
                                        <div className="col-md-12">
                                            <h3>กรอกข้อมูลเพื่อคำนวนสินเชื่อ</h3>
                                        </div>

                                        <div className="col-md-12">
                                            <h5>ราคา (บาท)</h5>
                                            <div>
                                                {
                                                    this.props.price ? <p style={{fontSize: '20px'}}>&nbsp;&nbsp;{this.numberWithCommas(this.props.price)}</p> : <input 
                                                                min="0"
                                                                max="500000000"
                                                                type="text" 
                                                                name="numPrice"
                                                                value={this.numberWithCommas(this.state.numPrice)}
                                                                onChange={this.handlePrice}
                                                    />
                                                }
                                                
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <h5>วงเงินที่ต้องการกู้ (บาท)</h5>
                                            <div>
                                                <input 
                                                min="0"
                                                max="500000000"
                                                type="text" 
                                                name="sumBorrow"
                                                // readOnly
                                                value={this.numberWithCommas(this.state.sumBorrow)}
                                                onChange={this.handlePrice} 
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <h5>ระยะที่ต้องการผ่อนชำระ (ปี)</h5>
                                            <div>
                                                <input 
                                                min="0"
                                                max="100"
                                                type="number"
                                                data-unit="ปี" 
                                                name="numTime"
                                                value={this.state.numTime}
                                                onChange={this.handlePrice} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <h5>อัตตราดอกเบี้ย (%)</h5>
                                            <div>
                                                <input 
                                                min="0"
                                                max="100"
                                                type="number" 
                                                data-unit="%" 
                                                name="numRate"
                                                value={this.state.numRate}
                                                onChange={this.handlePrice} 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <br />
                                            <p><b>หมายเหตุ:</b> </p>
                                            <p className="small" style={{marginTop: '20px'}}>** การคำนวณเงินงวดคิดจากอัตราดอกเบี้ยที่ท่านระบุบวกร้อยละ 1 เพื่อให้ตัดชำระเป็นเงินต้น และผลลัพธ์จะปัดตัวกลมเป็นหลักร้อยบาท ทั้งนี้ ผลการประเมินจากเครื่องคำนวณสินเชื่อเป็นเพียงการประเมินวงเงินกู้ในเบื้องต้น ส่วนการอนุมัติสินเชื่อสงวนสิทธิ์เป็นไปตามหลักเกณฑ์ของธนาคาร</p>
                                            {!this.props.price ? <button className="button border" 
                                            onClick={async (e) => { await this.setState(this.initialState)}}
                                            >
                                                <i className="icon-line-awesome-refresh"></i> 
                                                เริ่มใหม่ 
                                            </button>: null}
                                        </div>
                                       
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="col-md-12">
                                        <h3>ผลการคำนวณเงินกู้</h3>
                                    </div>
                                    <div className="col-md-12" >
                                        
                                        <CanvasJSChart 
                                                options = {{
                                                animationEnabled: true,
                                                subtitles: [{
                                                    text: this.numberWithCommas(this.state.sumInstallments? this.state.sumInstallments: '0') + ' /เดือน'  ,
                                                    verticalAlign: "center",
                                                    fontSize: 24,
                                                    fontFamily: 'Kanit-Light',
                                                    dockInsidePlotArea: true,
                                                }],
                                                data: [{
                                                    type: "doughnut",
                                                    showInLegend: true,
                                                    indexLabel: "{name}: {y}",
                                                    yValueFormatString: "#,###'฿'",
                                                    dataPoints: [
                                                        { name: "จำนวนดอกเบี้ยต่อเดือน", y: this.state.sumRateM, color: "#e33324" },
                                                        { name: "จำนวนเงินต้น", y: this.state.sumMoney, color: "RoyalBlue"  },
                                                    ]
                                                }]
                                        }} />

                                    </div>
                                    <div className="col-md-12" style={{marginTop: '-20px'}}>
                                        <br />
                                        <div className="txtprice">
                                            <h5>ยอดผ่อนชำระต่อเดือน : <b style={{color: '#e33324'}}>{this.numberWithCommas(this.state.sumInstallments? this.state.sumInstallments: '0')}</b>  บาท</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{marginTop: '-20px'}}>
                                        <div className="txtprice">
                                            <h5>จำนวนดอกเบี้ยต่อเดือน : <b style={{color: '#e33324'}}>{this.numberWithCommas(this.state.sumRateM > 1 ? this.state.sumRateM : '0')}</b>  บาท</h5>
                                        </div>
                                    </div>

                                    <div className="col-md-12" style={{marginTop: '-20px'}}>
                                        <div className="txtprice">
                                            <h5>จำนวนเงินต้น : <b style={{color: '#e33324'}}>{this.numberWithCommas(this.state.sumMoney > 1 ? this.state.sumMoney : '0')}</b>  บาท</h5>
                                        </div>
                                    </div>

                                </div>  {/*   end box right tab 1  */}
                            </div>
                        </div>

                        <div className="tab-content" id="tab2" >
                            <div className="col-md-12">
                                <h3>กรอกข้อมูลเพื่อคำนวนค่าใช้จ่าย</h3>
                            </div>
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <h3>คำนวนค่าใช้จ่าย</h3>           
                                    <span className="remark">ป้อนราคาประเมิน ราคาขายในช่องสีขาวและเลือกการถือครอง</span>
                                    <div className="widget utf-sidebar-widget-item">
                                        <div className="utf-boxed-list-headline-item">
                                            <h3>1. คำนวณค่าธรรมเนียมการโอน</h3>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="txt_tab">1.1 ราคาประเมินที่ดินพร้อมสิ่งปลูกสร้าง</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div >
                                                <input type="text"
                                                    min="0"
                                                    max="100000000000"
                                                    style={{ backgroundColor: '#fff8a7' }}
                                                    placeholder="0"
                                                    name="numCal"
                                                    value={this.numberWithCommas(this.state.numCal)}
                                                    onChange={this.handleUserInput}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-5"> บาท  </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="txt_tab">1.2 ค่าธรรมเนียมการโอน</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div >
                                                <input type="number" readOnly={true} value="2.0" />
                                            </div>
                                        </div>
                                        <div className="col-md-5"> % ของราคาประเมิน  </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="txt_tab_bold"> คิดเป็นค่าธรรมเนียมการโอน </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div >
                                                <input type="text"
                                                    readOnly={true}
                                                    placeholder="0"
                                                    value={currencyFormat(this.state.sumfee)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-5"> บาท  </div>
                                    </div>


                                    <div className="widget utf-sidebar-widget-item">
                                        <div className="utf-boxed-list-headline-item">
                                            <h3>2. การคำนวณค่าอากรแสตมป์ หรือค่าภาษีธุรกิจเฉพาะ</h3>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="checkbox margin-top-0">
                                                <input type="radio" 
                                                id="t1" 
                                                name="_isChecked" 
                                                value="0" 
                                                onChange={this.handleUserInput } 
                                                />
                                                <label htmlFor="t1"><span className="checkbox-icon" /> ผู้ขายถือครองอสังหาริมทรัพย์ดังกล่าว เกิน 5 ปี หรือมีชื่ออยู่ในทะเบียนบ้าน เกิน 1 ปี</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="checkbox margin-top-0">
                                                <input type="radio" 
                                                id="t2" 
                                                name="_isChecked" 
                                                value="1" 
                                                onChange={this.handleUserInput} />
                                                <label htmlFor="t2"><span className="checkbox-icon" /> ผู้ขายถือครองอสังหาริมทรัพย์ดังกล่าว ไม่เกิน 5 ปี หรือมีชื่ออยู่ในทะเบียนบ้าน ไม่เกิน 1 ปี</label>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="txt_tab">2.1 ค่าอากรแสตมป์</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div >
                                                <input type="text" 
                                                readOnly={true} 
                                                name="stamp" 
                                                placeholder="0" 
                                                value={currencyFormat(this.state.stamp)} />
                                            </div>
                                        </div>
                                        <div className="col-md-5"> บาท  </div>
                                        <br />

                                    </div>
                                    <p> (คิดอัตรา 0.5% ของราคาซื้อขาย หรือราคาประเมิน แล้วแต่ราคาใดจะสูงกว่า)</p>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="txt_tab">2.2 ค่าภาษีธุรกิจเฉพาะ</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div >
                                                <input 
                                                type="text" 
                                                readOnly={true} 
                                                name="tax" 
                                                placeholder="0"  
                                                value={currencyFormat(this.state.tax)} />
                                            </div>
                                        </div>
                                        <div className="col-md-5"> บาท  </div>
                                        <br />

                                    </div>
                                    <p>
                                        (คิดอัตรา 3.3% ของราคาซื้อขาย หรือราคาประเมิน แล้วแต่ราคาใดจะสูงกว่า)
                                        ** หมายเหตุ ถ้าผู้ขายอยู่ในข่ายต้องเสียภาษีธุรกิจเฉพาะแล้ว จะได้รับการยกเว้นค่าอากรแสตมป์ แต่ถ้าไม่อยู่ในข่ายต้องเสียภาษีธุรกิจเฉพาะ
                                    </p>

                                    <div className="widget utf-sidebar-widget-item">
                                        <div className="utf-boxed-list-headline-item">
                                            <h3>3. สรุปค่าใช้จ่าย</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="txt_tab">3.1 ค่าธรรมเนียมการโอน</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div >
                                                <input 
                                                type="text" 
                                                readOnly={true} 
                                                placeholder="0" 
                                                value={currencyFormat(this.state.sumfee)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-5"> บาท  </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="txt_tab">3.2 ค่าอากร</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div >
                                                <input type="text" 
                                                readOnly={true} 
                                                placeholder="0" 
                                                value={currencyFormat(this.state.stamp)}/>
                                            </div>
                                        </div>
                                        <div className="col-md-5"> บาท  </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="txt_tab">3.3 ค่าภาษีธุรกิจเฉพาะ</div>
                                        </div>
                                        <div className="col-md-3">
                                            <div >
                                                <input type="text" 
                                                readOnly={true} 
                                                placeholder="0" 
                                                value={currencyFormat(this.state.tax)} />
                                            </div>
                                        </div>
                                        <div className="col-md-5"> บาท </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="txt_tab_bold"> รวมค่าใช้จ่าย </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div >
                                                <input type="text" 
                                                style={{ backgroundColor: '#b9eab3' }} 
                                                readOnly={true} 
                                                placeholder="0" 
                                                value={currencyFormat(this.state.sumCal)} />
                                            </div>
                                        </div>
                                        <div className="col-md-5"> บาท  </div>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            // </div>
        );
    }
}

export default calculator_content;