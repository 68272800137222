import {
    HTTP_HOME_FETCHING,
    HTTP_HOME_SUCCESS,
    HTTP_HOME_FAILED,
    server, 
  } from "../constants";
  
  import { httpClient } from "./../utils/HttpClient";
  
  export const setHomeStateToFetching = () => ({
    type: HTTP_HOME_FETCHING
  });
  
  export const setHomeStateToSuccess = payload => ({
    type: HTTP_HOME_SUCCESS,
    payload
  });
  
  export const setHomeStateToFailed = () => ({
    type: HTTP_HOME_FAILED
  });



  export const getHomeData = () => {
    return dispatch => {
        dispatch(setHomeStateToFetching());
        doGetHomeData(dispatch);
    }
  }

  const doGetHomeData = (dispatch) => {
      httpClient.get(server.HOME_URL).then(result => {
          dispatch(setHomeStateToSuccess(result))
      }).catch(error => {
          dispatch(setHomeStateToFailed())
      })
  }