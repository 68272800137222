import React, { Component , useEffect}  from 'react';
import { connect } from "react-redux";
import * as actions from "./../../actions/searchbox.action";
import queryString from 'query-string';

import './searchbox.css'

import asset_type from './../default_files/asset_type.json'
import province from './../default_files/province.json'
import amphur from './../default_files/amphur.json'
import tumbon from './../default_files/tumbon.json'
import { size } from 'lodash';


const initialState = {
    assetType: 0,
    assetOptions: null,

    provincesOptions: <option value={''}>จังหวัด</option>,
    provinceSelceted: null,

    amphoesLists: null,
    amphoeOptions: <option value={''}>อำเภอ/เขต</option>,
    amphoeSelected: null,

    tumbonsLists: null,
    tumbonOptions: <option value={''}>ตำบล/แขวง</option>,
    tumbonSelected: null,

    project: "",
    bedroom: '',
    bathroom: '',
    carpark: '',
    minPrice: "",
    maxPrice: "",
    minUses: "",
    maxUses: "", 
    selecttype:"",
    selectedOption: "Sale", // Default selected option
    
}

class Searchbox extends Component {
  constructor(props) {
    super(props);
    // this.state = initialState;
    const storedOption = localStorage.getItem('selecttype');
    
    let url = window.location.search
    let params = queryString.parse(url);
    
    this.state = {
      assetType: params.type,
      assetOptions: null,

      provincesOptions: <option value={''}>จังหวัด</option>,
      provinceSelceted: params.p,

      amphoesLists: null,
      amphoeOptions: <option value={''}>อำเภอ/เขต</option>,
      amphoeSelected: params.d,

      tumbonsLists: null,
      tumbonOptions: <option value={''}>ตำบล/แขวง</option>,
      tumbonSelected: params.sd,

      project: params.project,
      bedroom: params.bed,
      bathroom: params.bath,
      carpark: params.carpark,
      minPrice: params.min_p,
      maxPrice: params.max_p,
      minUses: params.min_u,
      maxUses: params.max_u,
      region: params.region,
      datafrom: params.datafrom,  
      selectedOption: ['Rent', 'Downpayment', 'Sale'].includes(storedOption) ? storedOption : 'Sale',
      selecttype:params.selecttype,
    }
    
  } 
  
   
  async componentDidMount() {
    let noselect = [{ID: '',  NAME: "ประเภทอสังหาริมทรัพย์"}];
    let assetrows = await this.renderAssetType([...noselect,...asset_type]);
    this.setState({assetOptions: assetrows});

    noselect = [{CODE: '',  NAME: "จังหวัด"}];
    let provinceRows = await this.renderOptions([...noselect,...province]);
    await this.setState({provincesOptions: provinceRows});
    await this.setState({amphoesLists: amphur});
    await this.setState({tumbonsLists: tumbon});

    noselect = [{CODE: '',  NAME: "อำเภอ/เขต"}];
    let amphoes = await this.state.amphoesLists.filter(this.findAmphoe);
    this.setState({amphoeOptions: await this.renderOptions([...noselect, ...amphoes])});

    noselect = [{CODE: '',  NAME: "ตำบล/แขวง"}];
    let tumbons = await this.state.tumbonsLists.filter(this.findTumbon);
    await this.setState({tumbonOptions: await this.renderOptions([...noselect, ...tumbons])});

    const script = document.createElement("script");
    script.src = "js/searchboxscript.js";
    script.async = true;
    document.body.appendChild(script); 
    
    //===  set Selet Type เช็คประเภทการค้นหาหน้าแรก
    changeType(localStorage.getItem("selecttype"));

    // Remove event listener after first mount
    this.componentDidMount = () => {};
    console.log(localStorage.getItem("selecttype"));
}


  findAmphoe = (item) => {
    if (Number.isFinite(item.P_CODE) && item.P_CODE == this.state.provinceSelceted) {
      return true
    }
  }

  findTumbon = (item) => {
    if (Number.isFinite(item.P_CODE) && item.P_CODE == this.state.provinceSelceted && item.A_CODE == this.state.amphoeSelected) {
      return true
    }
  }

   renderAssetType = async (data) => {
    try {
      return (
        data != null &&
        await data.map(item => (
          <option  key={item.ID + item.NAME} value={item.ID} >{item.NAME}</option>
        ))
      );
    } catch (error) {
      console.log(error)
    }
  }

  renderOptions = async (data) => {
    try {
      return (
        data != null &&
        await data.map(item => (
          <option  key={item.CODE + item.NAME} value={item.CODE} >{item.NAME}</option>
        ))
      );
    } catch (error) {
      console.log(error)
    }
  }

  logoCardClick(from){
    this.setState({datafrom: from})
  }

  // Function to handle option click
  handleOptionClick = (option) => {
    // Update state with the selected option
    this.setState({ selectedOption: option });
    // Call the changeType function to update localStorage
      changeType(option);

    // Update the SelectType state
    this.setState({ selecttype: option });
  };

  render() {
    
    return (
      <div>

     

        <form className="utf-main-search-form-item" action="/listings" >
            <input type="hidden" name="region" value={this.state.region}/>
            <input type="hidden" name="datafrom" value={this.state.datafrom}/>
              <div className="margin-top-65"></div>

            <div className="utf-search-type-block-area-edit">
            <div className="search-type">
              {/* <label className={this.state.selectedOption === "Sale" ? "active" : ""}> */}
              <label className={this.state.selectedOption ==="Sale" ? "active":""}   > 
                <input 
                  name='selecttype'
                  type="radio"
                  value={this.state.selectedOption}
                  checked={this.state.selectedOption === "Sale"}
                  onChange={() => this.handleOptionClick("Sale")}
                />{" "}
                ขาย
              </label>
              <label className={this.state.selectedOption ==="Rent" ? "active":""}>  
                <input 
                  name='selecttype'
                  type="radio"
                  value={this.state.selectedOption}
                  checked={this.state.selectedOption === "Rent"}
                  onChange={() => this.handleOptionClick("Rent")}
                />{" "}
                เช่า
              </label>
              <label className={this.state.selectedOption ==="Downpayment" ? "active":""}>  
                <input 
                  name='selecttype'
                  type="radio"
                  value={this.state.selectedOption}
                  checked={this.state.selectedOption === "Downpayment"}
                  onChange={() => this.handleOptionClick("Downpayment")}
                />{" "}
                ขายดาวน์
              </label>
              <div className="utf-search-type-arrow" />
            </div>
          </div>

              <div className="utf-main-search-box-area ">
                <div className="row with-forms">
                  <div className="col-md-4 col-sm-12">
                
                    <input
                      type="text"
                      name="project"
                      className="ico-01"
                      placeholder="ชื่อโครงการ / รหัสอ้างอิง"
                      value={this.state.project}
                      onChange={e => {
                        this.setState({project: e.target.value})
                      }}
                    />
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <select name="type" 
                            // className="utf-chosen-select-single-item" 
                            data-placeholder="Property Type" 
                            value={this.state.assetType} 
                            onChange={e => {
                                        this.setState({assetType: e.target.value})
                                      }}
                    >
                      {this.state.assetOptions != null ? this.state.assetOptions: null}
                    </select>
                  </div>

                  <div className="col-md-2 col-sm-6">
                    <button className="button utf-search-btn-item" id="btnsearch" >
                      <i className="fa fa-search" /> ค้นหา
                    </button>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <a 
                      href="#" 
                      className="utf-utf-more-search-options-area-button" 
                      data-open-title="ค้นหาจาก ทำเล / หน่วยงาน" 
                      data-close-title="ค้นหาน้อยลง" />
                  </div>
                </div>
                
                <div className="utf-more-search-options-area">
                  <div className="utf-more-search-options-area-container">
                    <div className="row with-forms">
                      <div className="col-md-4">
                        <select
                          name="p"
                          data-placeholder="Province"
                          value={this.state.provinceSelceted}
                          onChange={async (e) => {
                            await this.setState({provinceSelceted: e.target.value, amphoeSelected: '',  tumbonSelected: '', tumbonOptions: <option value={''}>ตำบล/แขวง</option>})
                            let amphoes = await this.state.amphoesLists.filter(this.findAmphoe)
                            let noselect = [{CODE: '',  NAME: "อำเภอ/เขต"}]
                            this.setState({amphoeOptions: await this.renderOptions([...noselect, ...amphoes])})

                          }}
                        >
                          {this.state.provincesOptions}
                        </select>
                      </div>
                      <div className="col-md-4">
                        <select
                          name="d"
                          data-placeholder="District"
                          value={this.state.amphoeSelected}
                          onChange={async (e) => {
                            await this.setState({amphoeSelected: e.target.value})
                            let tumbons = await this.state.tumbonsLists.filter(this.findTumbon)
                            let noselect = [{CODE: '',  NAME: "ตำบล/แขวง"}]
                            this.setState({tumbonOptions: await this.renderOptions([...noselect, ...tumbons]), tumbonSelected: ''})
                          }}
                        >
                          {this.state.amphoeOptions}
                        </select>
                      </div>
                      <div className="col-md-4">
                        <select
                          name="sd"
                          data-placeholder="Sub district"
                          value={this.state.tumbonSelected}
                          onChange={async (e) => {
                            this.setState({tumbonSelected: e.target.value})
                          }}
                        >
                          {this.state.tumbonOptions}
                        </select>
                      </div>
                      <div className="col-md-2">
                        <select
                          name="bed"
                          data-placeholder="Bedroom"
                          value={this.state.bedroom}
                          onChange={async (e) => {
                          this.setState({bedroom: e.target.value})
                          }}
                        >
                          <option value={''}>จำนวนห้องนอน</option>
                          <option value={1}>1 ห้อง</option>
                          <option value={2}>2 ห้อง</option>
                          <option value={3}>3 ห้อง</option>
                          <option value={4}>4 ห้อง</option>
                          <option value={5}>5 ห้อง</option>
                        </select>
                      </div>
                      <div className="col-md-2">
                        <select
                          name="bath"
                          data-placeholder="Bathroom"
                          value={this.state.bathroom}
                          onChange={async (e) => {
                          this.setState({bathroom: e.target.value})
                          }}
                        >
                          <option value={''}>จำนวนห้องน้ำ</option>
                          <option value={1}>1 ห้อง</option>
                          <option value={2}>2 ห้อง</option>
                          <option value={3}>3 ห้อง</option>
                          <option value={4}>4 ห้อง</option>
                          <option value={5}>5 ห้อง</option>
                        </select>
                      </div>
                      <div className="col-md-2">
                      <select
                          name="carpark"
                          data-placeholder="Carpark"
                          value={this.state.carpark}
                          onChange={async (e) => {
                          this.setState({carpark: e.target.value})
                          }}
                        >
                          <option value={''}>ที่จอดรถ</option>
                          <option value={1}>1 </option>
                          <option value={2}>2 </option>
                          <option value={3}>3 </option>
                          <option value={4}>4 </option>
                        </select>
                      </div>
                      <div className="col-md-3">
                        <div className="select-input">
                          <input
                            name="min_p"
                            type="number"
                            placeholder="ราคาต่ำสุด"
                            data-unit="บาท"
                            value={this.state.minPrice}
                            onChange={async (e) => {
                              this.setState({minPrice: e.target.value})
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="select-input">
                          <input
                            name="max_p"
                            type="number"
                            placeholder="ราคาสูงสุด"
                            data-unit="บาท"
                            value={this.state.maxPrice}
                            onChange={async (e) => {
                              this.setState({maxPrice: e.target.value})
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                        <div className="select-input">
                          <input
                            name="min_u"
                            type="text"
                            placeholder="พื้นที่ใช้สอยต่ำสุด"
                            data-unit="ตร.ม."
                            value={this.state.minUses}
                            onChange={async (e) => {
                              this.setState({minUses: e.target.value})
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="select-input">
                          <input
                            name="max_u"
                            type="text"
                            placeholder="พื้นที่ใช้สอยสูงสุด"
                            data-unit="ตร.ม."
                            value={this.state.maxUses}
                            onChange={async (e) => {
                              this.setState({maxUses: e.target.value})
                            }}
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-md-3">
                        
                      </div> */}
                      <div className="col-md-3 show-xs hidden-lg hidden-md">
                    <button className="button utf-search-btn-item" id="btnsearch" >
                      <i className="fa fa-search" /> ค้นหา
                    </button>
                  </div>

                    </div>
                    <div className="row hidden-xs show-lg show-md" hidden={true}>
                    <p className='text-center'>
                    <span className="utf-listing-address">
                        <i className="icon-feather-check-square"></i>   เลือกหน่วยงานแล้วกดปุ่มค้นหา 
                      </span>
                    </p>
                            <div className="col-md-1 col-sm-1 col-xs-1" ></div>
                            <div className="col-md-3 col-sm-3 col-xs-3" >
                              <a href="#User" onClick={() => this.logoCardClick('user')}>
                                <div className="utf-icon-box-item-area">
                                  <img src="images/Logo/user.jpeg" style={{height: 70}}/>
                                  <div>ทรัพย์บุคคลทั่วไป</div>
                                </div>
                              </a>
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-3" >
                              <a href="#GHB" onClick={() => this.logoCardClick('ghb')}>
                              <div className="utf-icon-box-item-area" >
                                <img src="images/Logo/GHB.png" style={{height: 70}}/>
                                <div>ทรัพย์ ธอส.</div>
                              </div>
                              </a>
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-3">
                              <a href="#Institute" onClick={() => this.logoCardClick('institute')}>
                              <div className="utf-icon-box-item-area" >
                                <img src="images/Logo/LED.jpeg" style={{height: 70}}/>
                                <div>ทรัพย์กรมบังคับคดี</div>
                              </div>
                              </a>
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-2" ></div>
                        </div>

                  
                  </div>
                </div>
              </div>
            </form>
      </div>
    );
  }
}

// export default Searchbox;
 

const mapStateToProps = ({ searchboxReducer }) => ({
  searchboxReducer
});

const mapDispatchToProps = {
  ...actions
};

const changeType = (pcode) => {
  if(pcode === 'Null' || pcode ==='undefined' || pcode === ''){
    localStorage.setItem('selecttype', 'Sale');
  }
  if (pcode == 'Sale') { 
    localStorage.setItem('selecttype', pcode);

  } else { 
    localStorage.setItem('selecttype', pcode);
    
  }
  // this.setState({ SelectType: pcode });  
  // localStorage.setItem('KeyId', 4);
  // window.location.reload();
  // console.log(localStorage.getItem('Question'));
}
export default connect(mapStateToProps, mapDispatchToProps)(Searchbox);
