import React, { Component } from 'react';
import { httpClient } from '../../utils/HttpClient';
import { server} from "../../constants";
import {Modal } from 'semantic-ui-react'
import SignPage from './../header/singpage';

class UserVerifySendmail extends Component {
  constructor(props) {
    super(props);
      this.state = {
        openModal: false,
        email: '',
        showResend: null,
        tabStyle: 'block',

        email_Valid: false,
        formValid: false,
        formError: {
          email: "",
        },
      }
  }


  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.ValidateField(name, value);
    });
  };

  async ValidateField(fieldName, value) {
    let fieldValidationErrors = this.state.formError;
    let email_Valid = this.state.email_Valid;

    switch (fieldName) {
      case "email":
        email_Valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = email_Valid
          ? ""
          : "รูปแบบอีเมลไม่ถูกต้อง";
        break;

      default:
        break;
    }

    await this.setState({formError: fieldValidationErrors,
                         email_Valid: email_Valid},
      await this.ValidateForm
    );
  }

  ValidateForm() {
    this.setState({ formValid: this.state.email_Valid });
  }

  render() {
    const ValidStypes = {
      color: "red",
      fontSize: "14px",
      marginBottom: "-5px",
    };

    return (
      <div>
        <Modal
            onClose={() => this.setState({openModal: false}) }
            onOpen={() => this.setState({openModal: true}) }
            open={this.state.openModal}
            size="tiny"
          >
            <Modal.Content >
              <Modal.Description>
                <SignPage />
              </Modal.Description>
            </Modal.Content>
          </Modal>    

        <div ref={this.myRef} className="parallax titlebar" data-background="images/forgot_pass.jpg" 
            data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>ยืนยันตัวตน</h2>
                  {/* Breadcrumbs */}
                  <nav id="breadcrumbs">
                    <ul>
                      <li><a href="home">หน้าแรก</a></li>
                      <li>ยืนยันตัวตน</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>



        {/* Contact */} 
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <div className="my-account">


                <div className="tabs-container" style={{display: this.state.tabStyle}}> 
                  <div className="utf-welcome-text-item" >
                    <div className="utf-section-headline-item centered margin-bottom-30 margin-top-0">
                      <h3 className="headline">ระบุอีเมลล์เพื่อยืนยันตัวตน </h3>		  
                    </div>
                    <span>ป้อนที่อยู่อีเมล ระบบจะทำการส่งรหัสผ่านไปให้โดยผ่านทางอีเมลของคุณ</span> 
                  </div>
                    <div className="form-row form-row-wide">
                      <input type="text" 
                            className="input-text"
                            placeholder="Email Address" 
                            autocomplete="off"
                            name="email" 
                            value={this.state.email}
                            onChange={this.handleUserInput}
                            />
                      {this.state.formError.email != '' ? <p style={ValidStypes}> {this.state.formError.email} </p>  : null}
                      {this.state.showResend ? this.state.showResend: null}
                    </div>
                    <button className="utf-centered-button margin-top-30 button"
                            onClick={async e => {
                              if (this.state.formValid) {
                                if(localStorage.getItem("Member_Email") == this.state.email){
                                  let values = {Member_Email: this.state.email, Member_ID: localStorage.getItem("Member_ID")}
                                  let result = await httpClient.post(server.MEMBER_URL + '/verify-sendmail', values)
                                  if(result.result == 'ok'){
                                    this.setState({
                                      tabStyle: 'none',
                                      showResend: <a href="verify-sendmail" onClick={async e => {
                                        await httpClient.post(server.MEMBER_URL + '/verify-sendmail', values)
                                      }}><h5><u>ส่งอีกครั้ง</u></h5></a>
                                    })
                                  }
                                } 
                                else{
                                  this.setState({formError: {email: 'ไม่พบอีเมลของท่าน'}})
                                }
                              }    
                              else {this.ValidateField("email", this.state.email)}
                            }}
                    >Send Email</button>
                    <div className="forget-text margin-top-15">
                      <span> เข้าสู่ระบบ <a href="#" onClick={() => this.setState({openModal: true})}> คลิกที่นี่</a></span> 
                    </div>
                </div>


                <div className="tabs-container" style={{display: this.state.tabStyle != 'block' ? 'block' : 'none'}}> 
                  <div className="utf-welcome-text-item" >
                    <div className="utf-section-headline-item centered margin-bottom-30 margin-top-0">
                      <h3 className="headline">ตรวจสอบอีเมล</h3>		  
                    </div>
                    <span>ระบบได้ทำการส่ง  Email ตามที่ระบุเรียบร้อยแล้ว กรุณาปฏิบัติตามคำแนะนำเพื่อยืนยันตัวตน</span> 
                    {this.state.showResend ? this.state.showResend: null}
                  </div>
                    {/* <div className="form-row form-row-wide">
                    </div> */}
                </div>


              </div>
            </div>
          </div>
        </div>

        <div className="margin-top-65"></div>

      </div>
    );
  }
}

export default UserVerifySendmail;
