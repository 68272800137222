import React, { Component } from 'react';
import { httpClient } from '../../utils/HttpClient';
import { server} from "../../constants";
import { CryptoDecode } from "./../../utils/EnCryption";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {encrytion} from './../../utils/EnCryption';

const MySwal = withReactContent(Swal);

class UserVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionExpired: false,
    };
  }

  async componentDidMount(){
    let url = window.location.href;

    if(url.split("user-verify?p=")[1]){
      let vals = await CryptoDecode(url.split("user-verify?p=")[1]);
      vals = await JSON.parse(JSON.parse(vals));
      await this.setState(vals);
      
      const millis = Date.now() - vals["date"];
      const _date = Math.floor(millis / 1000);
      let expired = _date > 1800
      this.setState({sessionExpired: expired})
      // document.title = expired ? "Error" : "Verify | Clickthaihome.com"
      // console.log(resultVerify.data.Member_Verify)
      let values = {Member_ID: vals.Member_ID}
      let resultVerify = await httpClient.post(server.MEMBER_URL + '/verifyalert', values)
      if(this.state.sessionExpired == true && resultVerify.data.Member_Verify == "N"){
        this.props.history.push('/verify-sendmail')
        // window.location.reload()
        Swal.fire({
          icon: "error",
          title: "รหัสยืนยันตัวตนของท่านหมดอายุ กรุณากรอกอีเมล์ใหม่อีกครั้ง...",
          confirmButtonText: "ตกลง",
        });
      }else if(this.state.sessionExpired == false && resultVerify.data.Member_Verify == "N"){
        
      }else{
        this.props.history.push("/home");
        window.location.reload()
      }
      
      // console.log(this.state.Member_ID)
    }else{
      this.props.history.push('/home')
      window.location.reload()
    }
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <div className="my-account">

              <div className="tabs-container margin-top-30"> 
                <div className="utf-welcome-text-item" >
                  <div className="utf-section-headline-item centered margin-bottom-30 margin-top-0">
                    <h3 className="headline"> Complete Verification </h3>		  
                  </div>
                  <span>You are a button away from completing the registration! Please click</span> 
                  <span>confirm to start logging into your account.</span> 
                </div>
                    <button 
                      className="utf-centered-button margin-top-30 button"
                      onClick={async e => {
                        let result = await httpClient.post(server.MEMBER_URL + '/verifymail', {Member_ID : this.state.Member_ID})
                        if (result.result == "ok") {
                          Swal.fire({
                            icon: "success",
                            title: "ยืนยันตัวตนเสร็จสิ้น",
                            confirmButtonText: "ตกลง",
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              // localStorage.clear()
                              this.props.history.push("/home");
                              window.location.reload()
                            }
                          });
                        } else {
                          Swal.fire({
                            icon: "error",
                            title: "ผิดพลาด",
                            confirmButtonText: "ตกลง",
                          });
                        }
                      }}
                    >CONFIRM</button>
              </div>

            </div>
          </div>
        </div>

        <div className="margin-top-65"></div>
      </div>

      
    );
  }
}

export default UserVerify;
