
import {hashSecretkey1, hashSecretkey2} from './../constants/index'
var CryptoJS = require("crypto-js");

function getRandomInt(max){
    return Math.floor(Math.random() * Math.floor(max));
}

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }



export const decryption = function(hash) {
    let buff = Buffer.from(hash, 'base64');
    let text = buff.toString('utf-8');
    buff = Buffer.from(text, 'base64');
    text = buff.toString('utf-8');
    return text.split('||')[2] ;
}

export const encrytion = function(_data) {
    let data = ( makeid(getRandomInt(100))).toString() 
                + '||' + (makeid(getRandomInt(99))).toString()
                + '||' + JSON.stringify(_data) 
                + '||' + (makeid(getRandomInt(101))).toString() ;
    let buff =  Buffer.from(data);
    let base64data = buff.toString('base64');

    buff =  Buffer.from(base64data);
    base64data = buff.toString('base64');
    return base64data;
}


export const CryptoEncode =  function (str){
    return new Promise((resolve, reject) => {
        try {
            var ciphertext = CryptoJS.AES.encrypt(str, hashSecretkey1).toString();
                ciphertext = CryptoJS.AES.encrypt(ciphertext, hashSecretkey2).toString();
            resolve(ciphertext)
        } catch (error) {
            reject(error)
        }
    })
}

export const CryptoDecode = function (hash){
    return new Promise((resolve, reject) => {
        try {
            var bytes = CryptoJS.AES.decrypt(hash, hashSecretkey2);
                bytes = CryptoJS.AES.decrypt(bytes.toString(CryptoJS.enc.Utf8), hashSecretkey1);
            var originalText = bytes.toString(CryptoJS.enc.Utf8);
            resolve(originalText)
        } catch (error) {
            reject(error)
        }
    })
}


// exports = {CryptoEncode, CryptoDecode}

