import React, { Component, useEffect } from "react";
import { setApp } from "./actions/app.action";
import { connect } from "react-redux"; 
import { RecoilRoot } from "recoil";   
import { GA_TRACKING_CODE } from "../src/constants";
import ReactGA from 'react-ga';

import Header from "./components/header/header";
import Footer from "./components/footer/footer";
// import Cookies from "./components/cookies/cookies"
import HomePage from "./components/home/home";
import Listings from "./components/listings/listings";
import Asset from "./components/asset/asset";
// import Register from "./components/header/register";
// import Login from "./components/header/login";
import Logout from "./components/header/logout";
import SignPage from "./components/header/singpage";
import Contact from "./components/contact/contact";
import About from "./components/about/about";
import Calculator from "./components/calculator/calculator";

import Blog from "./components/blog/blog";
import BlogDetail from "./components/blog_detail/blog_detail";
import News from "./components/news/news";
import NewsGroup from "./components/news-group/news-group";
import NewsDetail from "./components/news-detail/news-detail";

import Product from "./components/product-advert/product-advert";

import ComingSoon from "./components/coming-soon/coming-soon";
import Calendar from "./components/calendar/calendar";
import CalendarDetail from "./components/calendar-detail/calendar-detail";
import TermConditions from "./components/terms-and-conditions/terms-and-conditions"

import TabComponent from "./components/tab-component/tab-component";

//UserMenu
// import UserMenu from "./components/user-menu/user-menu"
import UserProfile from "./components/user-profile/user-profile";
import UserProfileEdit from "./components/user-profile-edit/user-profile-edit";
import MyProperties from "./components/my-properties/my-properties";
import AddNewProperty from "./components/add-new-property/add-new-property";
import EditProperty from "./components/edit-property/edit-property";
import UserChangePassword from "./components/user-change-password/user-change-password";
import UserForgotPassword from "./components/user-forgot-password/user-forgot-password";
import UserBookmarks from "./components/user-bookmarks/user-bookmarks";
import UserVerify from "./components/user-verify/user-verify";
import UserVerifySendMail from "./components/user-verify-sendmail/user-verify-sendmail";
import UserResetPassword from "./components/user-reset-password/user-reset-password";

//Link Footer
import Privacypolicy from "./components/privacy-policy/privacy-policy";
import PrivacyNotice from "./components/privacy-notice/privacy-notice"; 

import AllArea from './components/all-area/all-area'

import Intropage from "./components/intropage/intropage"

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

ReactGA.initialize(GA_TRACKING_CODE)


// const isAcceptIntro = async () => {
//   let data = await new Promise((resolve, reject) => {
//     fetch(apiUrl + server.INTROPAGE_URL, {
//       method: 'GET', // or 'PUT'
//       headers: {
//         'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImVtYWlsIjoidGVzdEBlbWFpbC5jb20iLCJ1c2VybmFtZSI6InRlc3R1c2VybmFtZSIsInJvbGUiOiJhZG1pbmlzdHJhdG9yIn0sImlhdCI6MTYxNTI3NzQyOX0.WxN1rQ1oDLnfHB2mu3J1J2nuWp4_0x3fvsm-cK6OKXA',
//         }
//       })
//       .then(response => response.json())
//       .then(async data => {
//         resolve(JSON.parse(await decryption(data)))
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   })

//   if(data.data.length > 0){
//     return sessionStorage.getItem('accept_intro' + data.data[0]['start_date']) == 'true'
//   } else {
//     return true;
//   }
// };

const SecuredRoute = ({ component: Component, ...rest }) => {

  useEffect(() => {
    ReactGA.pageview(rest.path + rest.location.search)
  })

  // const [isAccept, setAccept] = useState(true)
  // isAcceptIntro().then(async function(result) {
  //   await setAccept(result)
  // }) 

  // return (<Route
  //         {...rest}
  //         render = {(props) => (
  //           isAccept == true ? (
  //           <Component {...props} />
  //           ) : (
  //             <Redirect to={"/intropage?redirect_url=" + window.location.href} />
  //             // <Redirect to="/home" />
  //           )
  //         )}
  //       />)

  return (<Route
    {...rest}
    render={(props) => (
      <Component {...props} />
    )}
  />)

};

const RenderRoute = () => {
  // const [isAccept, setAccept] = useState(true)
  // isAcceptIntro().then(async function(result) {
  //   await setAccept(result)
  // })

  const redirectToHome = () => {
    return <Redirect to="/home" />;
  };

  return (
    <div className="App">
      <RecoilRoot>
        <Router>
          <Header />
          {/* <Cookies /> */}
          {/* {isAccept ? <Header />: null}
          {isAccept ? <Cookies/>: null} */}
          <Switch>

            <Route path="/intropage" component={Intropage} />
            <SecuredRoute path="/home" component={HomePage} />
            <SecuredRoute path="/listings" component={Listings} />
            <SecuredRoute path="/asset" component={Asset} />
            {/* <SecuredRoute path="/register" component={Register} />
            <SecuredRoute path="/login" component={Login} /> */}
            <SecuredRoute path="/logout" component={Logout} />
            <SecuredRoute path="/signin" component={SignPage} />
            <SecuredRoute path="/contact" component={Contact} />
            <SecuredRoute path="/about" component={About} />
            <SecuredRoute path="/calculator" component={Calculator} />
            <SecuredRoute path="/blog" component={Blog} />
            <SecuredRoute path="/blog_detail" component={BlogDetail} />
            <SecuredRoute path="/news" component={News} />
            <SecuredRoute path="/news_group" component={NewsGroup} />
            <SecuredRoute path="/news_detail" component={NewsDetail} />
            <SecuredRoute path="/all-area" component={AllArea} />
            <SecuredRoute path="/calendar" component={Calendar} />
            <SecuredRoute path="/calendar-detail" component={CalendarDetail} />
            <SecuredRoute path="/tab-component" component={TabComponent} />

            <SecuredRoute path="/product-advert" component={Product} />

            <SecuredRoute path="/coming-soon" component={ComingSoon} />
            <SecuredRoute path="/terms-and-conditions" component={TermConditions} />


            {/* comment */}

            {/* User */}
            {/* <UserMenu style={{display: 'none'}}/> */}
            <SecuredRoute path="/profile" component={localStorage.getItem("Member_ID") ? UserProfile : redirectToHome} />
            <SecuredRoute path="/profile-edit" component={localStorage.getItem("Member_ID") ? UserProfileEdit : redirectToHome} />
            <SecuredRoute path="/my-properties" component={localStorage.getItem("Member_ID") ? MyProperties : redirectToHome} />
            <SecuredRoute path="/add-new-property" component={localStorage.getItem("Member_ID") ? AddNewProperty : redirectToHome} />
            <SecuredRoute path="/edit-property" component={localStorage.getItem("Member_ID") ? EditProperty : redirectToHome} />
            <SecuredRoute path="/change-password" component={localStorage.getItem("Member_ID") ? UserChangePassword : redirectToHome} />
            <SecuredRoute path="/bookmarks" component={localStorage.getItem("Member_ID") ? UserBookmarks : redirectToHome} />
            <SecuredRoute path="/terms-and-conditions" component={localStorage.getItem("Member_ID") ? TermConditions : redirectToHome} />


            <SecuredRoute path="/user-verify" component={localStorage.getItem("Member_ID") ? UserVerify : redirectToHome} />
            <SecuredRoute path="/verify-sendmail" component={localStorage.getItem("Member_ID") ? UserVerifySendMail : redirectToHome} />
            <SecuredRoute path="/forgot-password" component={UserForgotPassword} />
            <SecuredRoute path="/reset-password" component={UserResetPassword} />

            {/* Link footer */}
            <SecuredRoute path="/privacy-policy" component={Privacypolicy} />
            <SecuredRoute path="/privacy-notice" component={PrivacyNotice} />
            

            {/* <Route path="/admin"/> */}

            <Route exact={true} path="/" component={redirectToHome} />
            <Route path="*" component={redirectToHome} />
          </Switch>
          
          <Footer />
          {/* {isAccept ? <Footer /> : null} */}
        </Router>
      </RecoilRoot>
    </div>
  );

}

class App extends Component {
  componentDidMount() {
    this.props.setApp(this);
  }

  render() {
    return (<RenderRoute />);
  }
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(App)