import React, { Component } from "react";
import { CryptoDecode } from "./../../utils/EnCryption";
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

class UserResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionExpired: false,

      newPassword: "",
      confirmPassword: "",

      newPassword_Valid: false,
      confirmPassword_Valid: false,

      formValid: false,
      formError: {
        newPassword: "",
        confirmPassword: "",
      },
    };
  }

  async componentDidMount() {
    let url = window.location.href;
    if(url.split("password?p=")[1]){
      let vals = await CryptoDecode(url.split("password?p=")[1]);
      vals = await JSON.parse(JSON.parse(vals));
      await this.setState(vals);
      const millis = Date.now() - vals["date"];
      const _date = Math.floor(millis / 1000);
      let expired = _date > 1800
      this.setState({sessionExpired: expired})
      document.title = expired ? "Error" : "Reset Password | Clickthaihome.com"
      // console.log(this.state.Member_ID)
    }else{
      this.props.history.push('/home')
      window.location.reload()
    }
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.ValidateField(name, value);
    });
  };

  async ValidateField(fieldName, value) {
    let fieldValidationErrors = this.state.formError;
    let newPassword_Valid = this.state.newPassword_Valid;
    let confirmPassword_Valid = this.state.confirmPassword_Valid;

    switch (fieldName) {
      case "newPassword":
        newPassword_Valid = value.length >= 6;
        fieldValidationErrors.newPassword = newPassword_Valid
          ? ""
          : "รหัสผ่านสั้นเกินไป";

        if (this.state.confirmPassword != "") {
          confirmPassword_Valid = value == this.state.confirmPassword;
          fieldValidationErrors.confirmPassword = confirmPassword_Valid
            ? ""
            : "รหัสผ่านไม่ตรงกัน";
        }

        break;
      case "confirmPassword":
        confirmPassword_Valid = value.length >= 6;
        fieldValidationErrors.confirmPassword = confirmPassword_Valid
          ? ""
          : "รหัสผ่านสั้นเกินไป";
        if (confirmPassword_Valid) {
          confirmPassword_Valid = value == this.state.newPassword;
          fieldValidationErrors.confirmPassword = confirmPassword_Valid
            ? ""
            : "รหัสผ่านไม่ตรงกัน";
        }

        break;

      default:
        break;
    }

    await this.setState(
      {
        formError: fieldValidationErrors,
        newPassword_Valid: newPassword_Valid,
        confirmPassword_Valid: confirmPassword_Valid,
      },
      await this.ValidateForm
    );
  }

  ValidateForm() {
    this.setState({
      formValid:
        this.state.newPassword_Valid && this.state.confirmPassword_Valid,
    });
  }

  async save() {
    let values = {
      Member_ID: this.state.Member_ID,
      newPassword: this.state.newPassword,
    };
    let result = await httpClient.post(server.MEMBER_URL + "/resetpassword", values);
    if (result.result == "ok") {
      Swal.fire({
        icon: "success",
        title: "รีเซ็ตรหัสผ่านสำเร็จ",
        confirmButtonText: "ตกลง",
      }).then(async (result) => {
        if (result.isConfirmed) {
          localStorage.clear()
          this.props.history.push("/home");
          window.location.reload()
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "ผิดพลาด",
        confirmButtonText: "ตกลง",
      });
    }
  }

  expiredForm() {
    return (
      <div className="tabs-container">
        <div className="utf-welcome-text-item">
          <div className=" centered margin-bottom-30 margin-top-0">
            <img src="images/logo/logo_1.png" style={{ width: "30%" }} />
            <div className="margin-bottom-30"></div>
            <h3 className="headline">
               ท่านดำเนินการเกินเวลาที่กำหนด <br /> กรุณา Click ลืมรหัสผ่านใหม่ อีกครั้ง
            </h3>
            <p  style={{ marginTop: "10px"}}>
            <a  class="btn btn-warning"href="/forgot-password">คลิกที่นี่</a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const ValidStypes = {
      color: "red",
      fontSize: "14px",
      marginBottom: "-5px",
    };
    return (
      <div>
        <div
          ref={this.myRef}
          className="parallax titlebar"
          data-background="images/forgot_pass.jpg"
          data-color="rgba(48, 48, 48, 1)"
          data-color-opacity="0.8"
          data-img-width={800}
          data-img-height={505}
        >
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>RESET PASSWORD</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact */}
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <div className="my-account">
                {this.state.sessionExpired ? (
                  this.expiredForm()
                ) : (
                  <div className="tabs-container">
                    <div className="utf-welcome-text-item">
                      <div className="utf-section-headline-item centered margin-bottom-30 margin-top-0">
                        <h3 className="headline">Reset Password</h3>
                      </div>
                    </div>

                    <div className="content with-padding">
                      <div className="col-md-12">
                        <label>รหัสผ่านใหม่ *</label>
                        <input
                          type="password"
                          name="newPassword"
                          value={this.state.newPassword}
                          onChange={this.handleUserInput}
                          placeholder="รหัสผ่านใหม่"
                        />
                        {this.state.formError.newPassword != "" ? (
                          <p style={ValidStypes}>
                            {" "}
                            {this.state.formError.newPassword}{" "}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-12">
                        <label>ยืนยันรหัสผ่าน *</label>
                        <input
                          type="password"
                          name="confirmPassword"
                          value={this.state.confirmPassword}
                          onChange={this.handleUserInput}
                          placeholder="ยืนยันรหัสผ่าน"
                        />
                        {this.state.formError.confirmPassword != "" ? (
                          <p style={ValidStypes}>
                            {" "}
                            {this.state.formError.confirmPassword}{" "}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-md-12">
                        <center>
                          {" "}
                          <button
                            className="utf-centered-button margin-top-30 button"
                            onClick={async (e) => {
                              if (this.state.formValid) {
                                Swal.fire({
                                  title: "ต้องการรีเซ็ตรหัสผ่านใช่หรือไม่?",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonText: "ใช่",
                                  cancelButtonText: "ยกเลิก",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    this.save();
                                  }
                                });
                              } else {
                                await this.ValidateField(
                                  "currentPassword",
                                  this.state.currentPassword
                                );
                                await this.ValidateField(
                                  "newPassword",
                                  this.state.newPassword
                                );
                                await this.ValidateField(
                                  "confirmPassword",
                                  this.state.confirmPassword
                                );
                              }
                            }}
                          >
                            <i className="fa fa-save" aria-hidden="true" />{" "}
                            Reset Password
                          </button>
                        </center>
                        <br />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="margin-top-65" />
      </div>
    );
  }
}

export default UserResetPassword;
