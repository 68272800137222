import React, { Component } from 'react';
import {encrytion, decryption, CryptoEncode} from './../../utils/EnCryption';
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";

class Register extends Component {
  constructor (props) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      emailRegis: '',
      passwordRegis: '',
      RePasswordRegis: '',

      fnameValid: false,
      lnameValid: false,
      emailRegisValid: false,
      passwordRegisValid: false,
      RePasswordRegisValid: false,
      formValid: false,

      formErrors: {fname: '', lname: '', emailRegis: '', passwordRegis: '', RePasswordRegis: ''},

    }
  }

  async componentDidMount() {

  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
                  () => { this.validateField(name, value) });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let fnameValid = this.state.fnameValid;
    let lnameValid = this.state.lnameValid;
    let emailRegisValid = this.state.emailRegisValid;
    let passwordRegisValid = this.state.passwordRegisValid;
    let RePasswordRegisValid = this.state.RePasswordRegisValid;

    switch(fieldName) {
      case 'fname': 
        fnameValid = value.length >= 3;
        fieldValidationErrors.fname = fnameValid ? '' : 'โปรดกรอกชื่อของคุณ';
        break;
      case 'lname': 
        lnameValid = value.length >= 3;
        fieldValidationErrors.lname = lnameValid ? '' : 'โปรดกรอกนามสกุลของคุณ';
        break;
      case 'emailRegis':
        emailRegisValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.emailRegis = emailRegisValid ? '' : 'รูปแบบอีเมลไม่ถูกต้อง';
        break;
      case 'passwordRegis':
        passwordRegisValid = value.length >= 6;
        fieldValidationErrors.passwordRegis = passwordRegisValid ? '' : 'รหัสผ่านสั้นเกินไป';

        if(this.state.RePasswordRegis != ''){
          RePasswordRegisValid = value == this.state.RePasswordRegis;
          fieldValidationErrors.RePasswordRegis = RePasswordRegisValid ? '' : 'รหัสผ่านไม่ตรงกัน';
        }

        break;
      case 'RePasswordRegis':
        RePasswordRegisValid = value == this.state.passwordRegis;
        fieldValidationErrors.RePasswordRegis = RePasswordRegisValid ? '' : 'รหัสผ่านไม่ตรงกัน';
        break;
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    fnameValid: fnameValid,
                    lnameValid: lnameValid,
                    emailRegisValid: emailRegisValid,
                    passwordRegisValid: passwordRegisValid,
                    RePasswordRegisValid: RePasswordRegisValid
                  }, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.fnameValid  
                              && this.state.lnameValid  
                              && this.state.emailRegisValid  
                              && this.state.passwordRegisValid
                              && this.state.RePasswordRegisValid
                            });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }

  render () {
    return (
      <div >
          <div className="utf-welcome-text-item">
            <h3>ลงทะเบียนเพื่อสมัครสมาชิก</h3>
            <span> กรุณากรอกข้อมูลของท่านให้ครบถ้วนทุกช่อง </span>
        </div>
        
        <div className="row">
          <div className={`col-md-6 col-sm-12 col-xs-12 utf-no-border ${this.errorClass(this.state.formErrors.fname)}`}>
            <input type="text" 
                  name="fname"
                  placeholder="ชื่อ *" 
                  required 
                  autocomplete="off"
                  value={this.state.fname}
                  onChange={this.handleUserInput}
                  />
            {
              this.state.formErrors.fname != '' ? <p style={{color: 'red'}}>{this.state.formErrors.fname} <br /></p>  : null
            }
            
          </div>

          <div className={`col-md-6 col-sm-12 col-xs-12 utf-no-border ${this.errorClass(this.state.formErrors.lname)}`}>
            <input type="text" 
                  name="lname"
                  placeholder="นามสกุล *" 
                  required 
                  autocomplete="off"
                  value={this.state.lname}
                  onChange={this.handleUserInput}
                  />
            {
              this.state.formErrors.lname != '' ? <p style={{color: 'red'}}>{this.state.formErrors.lname} <br /></p> : null
            }
          </div>
        </div>

        <div className={`utf-no-border ${this.errorClass(this.state.formErrors.emailRegis)}`}>
          <input type="email" required className="form-control" name="emailRegis" autocomplete="off"
            placeholder="อีเมล *"
            value={this.state.emailRegis}
            onChange={this.handleUserInput}  />
          {
            this.state.formErrors.emailRegis != '' ? <p style={{color: 'red'}}>{this.state.formErrors.emailRegis}<br /></p> : null
          }
        </div>
        
        <div className="row">
          <div className={`col-md-6 col-sm-12 col-xs-12 utf-no-border ${this.errorClass(this.state.formErrors.passwordRegis)}`}>
            <input type="password" 
              className="form-control" 
              name="passwordRegis"
              placeholder="รห้สผ่าน *"
              value={this.state.passwordRegis}
              onChange={this.handleUserInput}  />
              {
              this.state.formErrors.passwordRegis != '' ? <p style={{color: 'red'}}>{this.state.formErrors.passwordRegis}<br /></p> : null
            }
          </div>


          <div className={`col-md-6 col-sm-12 col-xs-12 utf-no-border ${this.errorClass(this.state.formErrors.RePasswordRegis)}`}>
            <input type="password" className="form-control" name="RePasswordRegis"
              placeholder="ยืนยันรหัสผ่าน *"
              value={this.state.RePasswordRegis}
              onChange={this.handleUserInput}  />
              {
              this.state.formErrors.RePasswordRegis != '' ? <p style={{color: 'red'}}>{this.state.formErrors.RePasswordRegis}</p> : null
            }
          </div>
        </div>

        <div className="checkbox margin-top-0">
                    <input type="checkbox" id="two-step0" />
                    <label htmlFor="two-step0">
                      <span className="checkbox-icon" />
                      ฉันยอมรับเงื่อนไขและข้อตกลง<a href="#">
                      Terms & Conditions
                      </a>{" "}
                      and <a href="#">Privacy Policy</a>
                    </label>
                  </div>

        <button
              className={`margin-top-10 button full-width utf-button-sliding-icon ripple-effect  ${!this.state.formValid ? " btn btn-secondary" : ""}`}
              type="submit"
              // disabled={!this.state.formValid} 
              onClick={async e => {

                if(this.state.formValid){
                  let values = {fname: this.state.fname, 
                    lname: this.state.lname,
                    email: this.state.emailRegis, 
                    pass: this.state.passwordRegis}
                  let result = await httpClient.post(server.MEMBER_URL + '/register', values)

                  if(result.result == 'ok'){
                      localStorage.setItem('Member_ID', result.data['Member_ID'])
                      localStorage.setItem('Member_Name', result.data['Member_Name'])
                      window.location.reload(false)
                  }

                  

                } else {
                  await this.validateField('fname', this.state.fname)
                  await this.validateField('lname', this.state.lname)
                  await this.validateField('emailRegis', this.state.emailRegis)
                  await this.validateField('passwordRegis', this.state.passwordRegis)
                }

              
              }}
            >
              สมัครสมาชิก <i className="icon-feather-chevrons-right" />
        </button>
      </div>
    )
  }
}

export default Register;