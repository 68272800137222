import React, { Component } from 'react';
import SignPage from './../header/singpage' 
import {Modal } from 'semantic-ui-react'


class Footer extends Component {
  constructor (props) {
    super(props);
    this.state = {
      openModal: false,
    }
  }
 

  Chklogin = (e) => {
     console.log(e.target.href)
     if(!localStorage.getItem("Member_ID")){
      this.setState({openModal: true})
    }else{
      window.location.href = e.target.href;
    }
    e.preventDefault();
  };

  validateForm(obj) {
    
  }

  render() {
    return <div>
 


        <Modal
          onClose={() => this.setState({openModal: false}) }
          onOpen={() => this.setState({openModal: true}) }
          open={this.state.openModal}
          size="tiny"
        >
          <Modal.Content >
            <Modal.Description>
              <SignPage />
            </Modal.Description>
          </Modal.Content>
        </Modal>            
 
     <div id="footer"> 
  <div className="container">
  
 
 
    {/* footer 2 */}
    <div className="row" style={{display:"none"}}>
    <div className="col-md-3 col-sm-4 col-xs-6">
        <h4>ประกาศขาย</h4>
        <ul className="utf-footer-links-item">
          <li><a href="about" target="_blank">ขายบ้านเดี่ยว</a></li>
          <li><a href="news">ขายคอนโด</a></li>
          <li><a href="blog" target="_blank">ขายทาวน์เฮ้าส์</a></li>
          <li><a href="contact" target="_blank">ขายที่ดินเปล่า</a></li>
          <li><a href="contact" target="_blank">ขายอาคารพาณิชย์</a></li>
          <li><a href="contact" target="_blank">ขายโกดัง/โรงงาน</a></li>  
        </ul>
      </div>
      <div className="col-md-3 col-sm-4 col-xs-6">
        <h4>ประกาศขายบ้านเดี่ยว</h4>
        <ul className="utf-footer-links-item">
          <li><a href="about" target="_blank">ขายบ้านเดี่ยว ราคาไม่เกิน 1 ล้านบาท</a></li>
          <li><a href="news">ขายบ้านเดี่ยว ราคาไม่เกิน 2 ล้านบาท</a></li>
          <li><a href="blog" target="_blank">ขายบ้านเดี่ยว ราคาไม่เกิน 3 ล้านบาท</a></li>
          <li><a href="contact" target="_blank">ขายบ้านเดี่ยว ราคาไม่เกิน 5 ล้านบาท</a></li>
          <li><a href="contact" target="_blank">ขายบ้านเดี่ยว ราคามากกว่า 5 ล้านบาท</a></li>
        </ul>
      </div>
      <div className="col-md-3 col-sm-4 col-xs-6">
        <h4>ประกาศขายคอนโดมิเนียม</h4>
        <ul className="utf-footer-links-item">
          <li><a href="profile" onClick={this.Chklogin} target="_blank">ขายคอนโด ราคาไม่เกิน 1 ล้านบาท</a></li>
          <li><a href="add-new-property" onClick={this.Chklogin} target="_blank">ขายคอนโด ราคาไม่เกิน 2 ล้านบาท</a></li>
          <li><a href="my-properties" onClick={this.Chklogin} target="_blank">ขายคอนโด ราคาไม่เกิน 3 ล้านบาท</a></li>
          <li><a href="bookmarks" onClick={this.Chklogin} target="_blank">ขายคอนโด ราคาไม่เกิน 5 ล้านบาท</a></li>
          <li><a href="bookmarks" onClick={this.Chklogin} target="_blank">ขายคอนโด ราคามากกว่า 5 ล้านบาท</a></li>
        </ul>
      </div> 
      <div className="col-md-3 col-sm-4 col-xs-12">
        <h4>ประกาศขายอาคารพาณิชย์</h4>
        <ul className="utf-footer-links-item">
          <li><a href="coming-soon"   target="_blank">ขายอาคารพาณิชย์ ราคาไม่เกิน 1 ล้านบาท</a></li>
          <li><a href="admin" target="_blank">ขายอาคารพาณิชย์ ราคาไม่เกิน 2 ล้านบาท</a></li>
          <li><a href="coming-soon" target="_blank">ขายอาคารพาณิชย์ ราคาไม่เกิน 3 ล้านบาท</a></li>
          <li><a href="privacy-policy" target="_blank">ขายอาคารพาณิชย์ ราคาไม่เกิน 5 ล้านบาท</a></li>
          <li><a href="terms-and-conditions" target="_blank">ขายอาคารพาณิชย์ ราคามากกว่า 5 ล้านบาท</a></li>
        </ul>
      </div>	
    </div>

        {/* footer 1 */}
   <div className="row">
      <div className="col-md-5 col-sm-12 col-xs-12"> 
        <a href="home"><img className="footer-logo" src="images/logo_1.png"  /></a>
        <p className="font-footer">ศูนย์ข้อมูลอสังหาริมทรัพย์ (Real Estate Information Center) เป็นหน่วยงานอิสระใน สังกัด ธนาคารอาคารสงเคราะห์ เพื่อทำหน้าที่เป็นหน่วยงานกลางด้านข้อมูลอสังหาริมทรัพย์</p>  

        <h4>ติดตามทางโซเชียล</h4>
        <div className="container-fluid px-0">
          <div className="d-flex row align-items-center">
            {/* <div className="col-3 col-md-1 mr-md-2 mb-3"><a href="https://twitter.com/taladnudbaan" title target="_blank"><img src="https://media.taladnudbaan.com/assets/images/social/icons/twitter.png" alt="twitter" className="footer_social_icon" /></a></div> */}
            <div className="col-3 col-md-2 mr-md-2 mb-3"><a href="https://www.facebook.com/clickthaihome" title target="_blank"><img src="https://media.taladnudbaan.com/assets/images/social/icons/facebook.png" alt="facebook" className="footer_social_icon" /></a></div>
            {/* <div className="col-3 col-md-1 mr-md-2 mb-3"><a href="https://www.instagram.com/taladnudbaan/" title target="_blank"><img src="https://media.taladnudbaan.com/assets/images/social/icons/instagram.png" alt="instagram" className="footer_social_icon" /></a></div> */}
            {/* <div className="col-3 col-md-1 mr-md-2 mb-3"><a href="https://www.youtube.com/channel/UCteKzyu9-LBqHOAIe7YY0Dg" title target="_blank"><img src="https://media.taladnudbaan.com/assets/images/social/icons/youtube.png" alt="youtube" className="footer_social_icon" /></a></div> */}
            {/* <div className="col-3 col-md-1 mr-md-2 mb-3"><a href="https://line.me/R/ti/p/@557djxvj" title target="_blank"><img src="https://media.taladnudbaan.com/assets/images/social/icons/line.png" alt="line" className="footer_social_icon" /></a></div> */}
            {/* <div className="col-3 col-md-1 mr-md-2 mb-3"><a href="https://www.pinterest.com/taladnudbaan/_created/" title target="_blank"><img src="https://media.taladnudbaan.com/assets/images/social/icons/pinterest.png" alt="pinterest" className="footer_social_icon" /></a></div> */}
            {/* <div className="col-3 col-md-1 mr-md-2 mb-3"><a href="https://taladnudbaan.podbean.com/" title target="_blank"><img src="https://media.taladnudbaan.com/assets/images/social/icons/podcast.png" alt="podcast" className="footer_social_icon" /></a></div> */}
          </div>
        </div>

      </div>
      <div className="col-md-2 col-sm-4 col-xs-6">
        <h4>ข้อมูลบริษัท</h4>
        <ul className="utf-footer-links-item">
          <li><a href="about" target="_blank">เกี่ยวกับเรา</a></li>
          <li><a href="news">ข่าวสาร และกิจกรรม</a></li>
          <li><a href="blog" target="_blank">บทความ</a></li>
          <li><a href="contact" target="_blank">ติดต่อเรา</a></li>
        </ul>
      </div>
      <div className="col-md-2 col-sm-4 col-xs-6">
        <h4>ข้อมูลของฉัน</h4>
        <ul className="utf-footer-links-item">
          <li><a href="profile" onClick={this.Chklogin} target="_blank">บัญชีส่วนตัว</a></li>
          <li><a href="add-new-property" onClick={this.Chklogin} target="_blank">เพิ่มประกาศใหม่</a></li>
          <li><a href="my-properties" onClick={this.Chklogin} target="_blank">ประกาศของฉัน</a></li>
          <li><a href="bookmarks" onClick={this.Chklogin} target="_blank">ทรัพย์ที่ชื่นชอบ</a></li>
        </ul>
      </div> 
      <div className="col-md-3 col-sm-4 col-xs-12">
        <h4>บริการอิเล็กทรอนิกส์</h4>
        <ul className="utf-footer-links-item">
          <li><a href="coming-soon"   target="_blank">บริการข้อมูล API</a></li>
          <li><a href="admin" target="_blank">สำหรับเจ้าหน้าที่</a></li>
          <li><a href="coming-soon" target="_blank">คำถามที่พบบ่อย FAQ</a></li>
          <li><a href="privacy-policy" target="_blank">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a></li>
          <li><a href="terms-and-conditions" target="_blank">ข้อความจำกัดความรับผิดชอบ</a></li>
        </ul>
      </div>	
    </div>

    <div className="row">
      <div className="col-md-12">
        <div className="copyrights">Copyright © 2021 by <a href="http://www.reic.or.th" target="_blank"><u>Real Estate Information Center</u></a>. <br /> All right reserved.
          Contact webmaster for more information <a href="#">webmaster@clickthaihome.com</a>
        </div>
      </div>
    </div>
  </div>
</div>

{/*BEGIN WEB STAT CODE*/}

{/* <script language="javascript1.1" src="http://hits.truehits.in.th/data/r0028354.js" id="TrueHitScript" /> */}

{/* <noscript>
<a target="_blank" href="http://truehits.net/stat.php?id=r0028354">
  <img src="http://hits.truehits.in.th/noscript.php?id=r0028354" alt="Thailand Web Stat" border={0} width={14} height={17} id="TrueHitImage" /></a>
</noscript> */}
 
{/* <script src="http://www.google-analytics.com/urchin.js" type="text/javascript">
</script>
<script type="text/javascript">
_uacct = "UA-2040090-1";
urchinTracker();
</script> */}



    </div>;
  }
}

export default Footer;
