var CryptoJS = require("crypto-js");

//LOGIN PAGE
export const APP_INIT = "APP_INIT";

//LOGIN PAGE
export const HTTP_LOGIN_FETCHING = "HTTP_LOGIN_FETCHING" ;
export const HTTP_LOGIN_SUCCESS = "HTTP_LOGIN_SUCCESS";
export const HTTP_LOGIN_FAILED = "HTTP_LOGIN_FAILED";

//HOME PAGE
export const HTTP_HOME_FETCHING = "HTTP_HOME_FETCHING" ;
export const HTTP_HOME_SUCCESS = "HTTP_HOME_SUCCESS";
export const HTTP_HOME_FAILED = "HTTP_HOME_FAILED";

//SEARCHBOX COMPONENT
export const HTTP_SEARCHBOX_FETCHING = "HTTP_SEARCHBOX_FETCHING" ;
export const HTTP_SEARCHBOX_SUCCESS = "HTTP_SEARCHBOX_SUCCESS";
export const HTTP_SEARCHBOX_FAILED = "HTTP_SEARCHBOX_FAILED";

//ERROR CODE
export const E_PICKER_CANCELLED = "E_PICKER_CANCELLED";
export const E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR = "E_PICKER_CANNOT_RUN_CAMERA_ON_SIMULATOR";
export const E_PERMISSION_MISSING = "E_PERMISSION_MISSION";
export const E_PICKER_NO_CAMERA_MISSING = "E_PICKER_NO_CAMERA_PERMISSION";
export const E_UNKNOW = "E_UNKNOW";
export const E_DEVELOPER_ERROR = "E_DEVELOPER_ERROR";
export const TIMEOUT_NETWORK = "TIMEOUT_NETWORK";
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";


/////////////// Localization Begin ///////////////
export const NETWORK_CONNECTION_MESSAGE = "Connot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE = "A network timeout has occurred, Please try again.";
export const UPLOAD_PHOTO_FAIL_MASSAGE = "An error has occurred. The photo was unable to upload." ;


export const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjksImlhdCI6MTYzODM1NTQ1OH0.9BNJ3_HT1u7z6A89b1XmKp-jwYpsq_rTt1Ck400Klxo'

// export const apiUrl = "http://localhost:4020/api/v1/";
// export const imageUrl = "http://localhost:4020";

// export const apiUrl = "https://apiuat.clickthaihome.com/api/v1/";
// export const imageUrl = "https://apiuat.clickthaihome.com/";

export const apiUrl = "https://api.clickthaihome.com/api/v1/";
export const imageUrl = "https://api.clickthaihome.com/";




// export const lineNotifyLink = "https://notify-api.line.me/api/notify";
// export const lineToken = "Bearer x3hrYKEqsntg6oT3g0PJ0HbWD1KJeuihQtkCOXKPeAs";

export const YES = 'YES';
export const NO = 'NO';
export const OK = 'ok';
export const NOK = 'nok';

export const hashSecretkey1 = CryptoJS.SHA512('h@$h฿yitRE!CDev').toString();
export const hashSecretkey2 = CryptoJS.SHA512('itRE!CD3vH@$h3d').toString();
export const GA_TRACKING_CODE = 'UA-78908309-2'

export const server = {
    LOGIN_URL: 'authen/login',
    HOME_URL: 'home',
    SEARCHBOX_URL: 'searchbox',
    LISTINGS_URL: 'listings',
    ASSET_URL: 'asset',
    MEMBER_URL: 'member',
    BLOG_URL: 'blog',
    NEWS_URL: 'news',
    NEWSLETTER_URL: 'newsletter',
    INTROPAGE_URL: 'intropage',
    CALENDAR_URL: 'calendar',
    BLOG_TAB_URL:'blogtab',
    // LOGIN_PASSED: 'yes'
}