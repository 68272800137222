import React, { Component } from "react";
// import ReactDOM from "react-dom";
import * as actions from "./../../actions/home.action";
// import * as $ from "jquery";

import { connect } from "react-redux";
import { httpClient } from "../../utils/HttpClient";
import { server, imageUrl } from "../../constants";
// import { selector, useRecoilState, useRecoilValue } from 'recoil';
// import { nameState } from '../../store/atoms';

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./home.css";
import NumberFormat from "react-number-format";
// import { Link, withRouter, Redirect } from "react-router-dom";
import { encrytion } from "./../../utils/EnCryption";
// import parse from "html-react-parser";
import Searchbox from "./../searchbox/searchbox";
// import SearchComponents from './../searchbox/searchbox';
import blogComponent from "./../blog/blog";
import calculator_content from "./../calculator/calculator_content";

import Dotdotdot from "react-dotdotdot";
import { currencyFormat } from "./../default-class/default-class";
// import showcalendar from "./../calendar/calendar";
import { Modal } from "semantic-ui-react";
// import Intro_page from './../intropage/intropage'
// import { nodeName } from "jquery";

// import TabComponent from "./../tab-component/tab-component";

// const Calen = new showcalendar();
const Cals = new calculator_content();
const blog = new blogComponent();

const initialState = {
  region: [
    { Region: "C", _count: 0 },
    { Region: "E", _count: 0 },
    { Region: "N", _count: 0 },
    { Region: "NE", _count: 0 },
    { Region: "P", _count: 0 },
    { Region: "S", _count: 0 },
  ],
  hitpoint: [
    {
      Asset_ID: "111",
      File_Name: "111",
      Asset_Project: "111",
      Address: "111",
      Asset_Price: "111",
      Number_Bed_Room: "",
      Number_Bath_Room: "",
      Number_Car_Park: "",
      Asset_Usage_Area: "",
      Activate_Start_Time: "",
      Update_Time: "",
      Approve_Status: "",
      hit_point: "",
      DataFrom: "",
    },
  ],
  hitpointCard: null,
  GHBCard: null,
  LEDCard: null,
  searchBox: null,
  itemId: 4,
  blogList: null,
  blogPost: null,
  newsletter_email: "",
  emailError: true,
  status: null,
  openModal: true,
  openPopup: true,
  displaynewhome: "",
  Top8Card: null,
  rent_assetCard: null,
  activeTab: "tab1",
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  async componentDidMount() {
    document.title =
      "ClickThaiHome บ้านมือสอง มากที่สุดในประเทศไทย บ้านเดี่ยว ทาวน์เฮาส์ บ้านแฝด บ้าน คอนโด ค้นหา บ้านใหม่ ประกาศซื้อ ประกาศขาย ฟรี";

    let result_intro = await httpClient.get(server.INTROPAGE_URL + "/");
    let result_popup = await httpClient.get(server.INTROPAGE_URL + "/popup");

    if (result_intro.data.length > 0) {
      this.intro_p(result_intro.data.length > 0 ? result_intro.data : null);
    } else {
      result_intro.data = null;
      this.setState({ openModal: false });
    }

    if (result_popup.data.length > 0) {
      this.popup_p(result_popup.data.length > 0 ? result_popup.data : null);
    } else {
      result_popup.data = null;
      this.setState({ openPopup: false });
    }

    // Check Dimensions windows
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    this.setState({ searchBox: <Searchbox /> });

    let values = {
      Member_ID: localStorage.getItem("Member_ID")
        ? localStorage.getItem("Member_ID")
        : "0",
    };
    let result = await httpClient.post(server.HOME_URL, values);

    //===== call top row
    await this.loadData();

    // //  แยกตามภูมิภาค
    this.setState({ region: result.region });
    // console.log(result.region)
    let cards = await this.renderHitpoint(result.hitpoint);
    this.setState({ hitpointCard: cards });

    // // มุมบ้านใหม่ แนะนำ
    let Newcards = await this.renderNewCard(result.newhome);
    this.setState({ NewCard: Newcards });
    this.setState({
      displaynewhome: result.newhome.length > 0 ? "block" : "none",
    });

    // Load ทรัพย์ GHB
    // let GHBcardss = await this.renderHitpoint(result.ghbdata);
    // // Load ทรัพย์ LED
    // let LEDCards = await this.renderHitpoint(result.leddata);
    // this.setState({ GHBCard: GHBcardss, LEDCard: LEDCards });

    // // Load Blog
    let result2 = await httpClient.get(server.BLOG_URL + "/blog");
    this.setState({
      blogList: await this.renderBloglist(result2.data.slice(1, 4)),
    });
    // Load Blog Recently
    this.setState({
      blogPost: await blog.renderRecently(result2.data.slice(4, 8)),
    });

    //  Check Verrify Member
    let resultVerify = await httpClient.post(
      server.MEMBER_URL + "/verifyalert",
      values
    );
    this.setState({ status: await this.renderTapVerify(resultVerify.data) });

    const script = document.createElement("script");
    script.src = "js/script.js";
    script.async = true;
    document.body.appendChild(script);

    // this.loadblogtab(Top8cards);
    // ---Check Close Browser
    // window.addEventListener("beforeunload", (ev) => {
    //    ev.preventDefault();
    //     return ev.returnValue = 'Are you sure you want to close?';
    // });

    // === Auto Close Popup When other Click Close 30 sec
    let timerId = setTimeout(() => {}, 30000);
    setTimeout(() => {
      this.setState({ openPopup: false });
      clearTimeout(timerId);
    }, 30000);
  }

  loadblogtab = (data) => {
    // console.log(data);
  };
  //=== Check Dimensions
  UNSAFE_componentWillMount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions = () => {
    if (window.innerWidth < 800) {
      this.setState({ itemId: 1 });
    } else {
      this.setState({ itemId: 4 });
    }
  };

  findAmphoe = (item) => {
    if (
      Number.isFinite(item.P_CODE) &&
      item.P_CODE === this.state.provinceSelceted
    ) {
      return true;
    }
  };

  findTumbon = (item) => {
    if (
      Number.isFinite(item.P_CODE) &&
      item.P_CODE == this.state.provinceSelceted &&
      item.A_CODE == this.state.amphoeSelected
    ) {
      return true;
    }
  };

  renderAssetType = async (data) => {
    //  console.log(data)
    try {
      return (
        data != null &&
        (await data.map((item) => (
          <option key={item.ID} value={item.ID}>
            {item.NAME}
          </option>
        )))
      );
    } catch (error) {
      console.log(error);
    }
  };

  renderOptions = async (data) => {
    try {
      return (
        data != null &&
        (await data.map((item) => (
          <option key={item.CODE} value={item.CODE}>
            {item.NAME}
          </option>
        )))
      );
    } catch (error) {
      console.log(error);
    }
  };

  renderNewCard = (data) => {
    try {
      return (
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <OwlCarousel
              autoplay={false}
              className="owl-theme"
              loop={false}
              margin={30}
              nav={false}
              smartSpeed={1000}
              items={this.state.itemId}
            >
              {data != null &&
                data.map((item) => (
                  <div key={item.Asset_ID} className="item">
                    <div className="box">
                      <center>
                        <img
                          lazyload="true"
                          src={item.File_Name}
                          alt={item.Asset_Project + " " + item.Address}
                          style={{ height: 250 }}
                        />
                      </center>
                      <div className="box-content">
                        <ul className="icon">
                          <li>
                            <a
                              href={
                                "/asset?p=" +
                                encrytion(item.datafrom + "/" + item.Asset_ID)
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              <i className="fa fa-search" />
                            </a>
                          </li>
                          <div className="txt">
                            {/* <div> ขนาดที่ดิน {item.rai} ไร่,{item.ngan} งาน ,{item.wa} ตรว.</div> */}
                            <div> พื้นที่ใช้สอย {item.Asset_Usage_Area} </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <div className="box-detail">
                      <div className="blockprice">
                        <h4 className="text-warp">
                          <a
                            href={
                              "/asset?p=" +
                              encrytion(item.datafrom + "/" + item.Asset_ID)
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            {item.Asset_Project}{" "}
                          </a>
                        </h4>
                        <span className="utf-listing-address text-warp">
                          <i className="icon-material-outline-location-on" />{" "}
                          {item.Address}
                        </span>

                        <div>
                          <span className="lbprice" style={{ float: "left" }}>
                            ราคาตั้งขาย
                          </span>
                          {item.Promotion_price > 0 ? (
                            <span
                              className="lbpromo"
                              style={{ float: "right" }}
                            >
                              ราคาพิเศษ
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <br />
                        <div>
                          <span
                            className={
                              item.Promotion_price > 0
                                ? "txtpricelow"
                                : "txtprice"
                            }
                          >
                            <span
                              className={
                                item.Promotion_price > 0 ? "strikethrough" : ""
                              }
                            >
                              {item.Asset_Price > 0
                                ? currencyFormat(item.Asset_Price) + "" + " บาท"
                                : "โปรดติดต่อหน่วยงาน"}
                            </span>
                          </span>
                          <span className="txtpromotion">
                            {item.Promotion_price > 0
                              ? currencyFormat(item.Promotion_price) +
                                "" +
                                " บาท"
                              : ""}
                          </span>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          </div>
        </div>
      );
    } catch (error) {
      // console.log(error);
    }
  };

  renderHitpoint = (data) => {
    try {
      return (
        <div className="row">
          <div className="col-md-12">
            <OwlCarousel
              autoplay={false}
              className="owl-theme"
              loop={true}
              margin={30}
              nav={true}
              smartSpeed={1000}
              items={this.state.itemId}
            >
              {data != null &&
                data.map((item) => (
                  <div key={item.Asset_ID} className="item">
                    <div className="utf-listing-item">
                      <a
                        href={
                          "/asset?p=" +
                          encrytion(item.datafrom + "/" + item.Asset_ID)
                        }
                        className="utf-smt-listing-img-container"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="utf-listing-badges-item">
                          {item.New_Asset == "Y" ? (
                            <span className="featured">โครงการใหม่</span>
                          ) : null}

                          {/* <span className="for-sale"><span className="icon-material-outline-gavel"> </span> ทรัพย์ประมูล</span> */}
                        </div>

                        <div className="utf-listing-img-content-item">
                          {/* src={item.datafrom == 'Asset_Institute_Desc' ? 'images/Logo/LED.jpeg': 'images/Logo/GHB.png'} */}
                          <img
                            className="utf-user-picture"
                            src={
                              item.datafrom == "Asset_Desc"
                                ? "images/Logo/user.png"
                                : item.datafrom == "Asset_Institute_Desc"
                                ? "images/Logo/LED.jpeg"
                                : "images/Logo/GHB.png"
                            }
                            lazyload="true"
                            style={{ width: 60, height: 60, marginTop: 5 }}
                          />

                          {/* <span
                            className="like-icon with-tip"
                            data-tip-content="Bookmark"
                            style={{ left: 20 }}
                          /> */}
                          <span
                            className={
                              item.Bookmark === 1
                                ? "like-icon with-tip liked"
                                : "like-icon with-tip"
                            }
                            data-tip-content="Bookmark"
                            onClick={async (e) => {
                              e.preventDefault();
                              if (localStorage.getItem("Member_ID")) {
                                let liked = "";
                                if (
                                  e.target.className === "like-icon with-tip"
                                ) {
                                  //like
                                  liked = true;
                                } else {
                                  //unlike
                                  liked = false;
                                }

                                let values = {
                                  Member_ID: localStorage.getItem("Member_ID"),
                                  Asset_ID: item.Asset_ID,
                                  Data_From: item.datafrom,
                                  Liked: liked,
                                };
                                let result = await httpClient.post(
                                  server.ASSET_URL + "/bookmarks",
                                  values
                                );
                                if (result.status === "ok") {
                                  if (
                                    e.target.className === "like-icon with-tip"
                                  ) {
                                    e.target.className =
                                      "like-icon with-tip liked";
                                  } else {
                                    e.target.className = "like-icon with-tip";
                                  }
                                } else if (
                                  result.status === "nok" &&
                                  result.message ===
                                    "Bookmarks Field, Admin not approve."
                                ) {
                                  alert(
                                    "กรุณากรอกข้อมูลพื้นฐานให้ครบและรอให้ผู้ดูแลระบบอนุมัติสมาชิก เพื่อใช้ฟังก์ชั่นนี้ \n\n คำอธิบายเกี่ยวกับ  Bookmark \n 1. หากท่านกรอกข้อมูลพื้นฐานไม่ครบ จะทำการ เลือกติดตามได้ 1 โครงการ \n 2. เมื่อท่านกรอกข้อมูลพื้นฐานตามระบบกำหนด จะสามารถเลือกติดตามได้หลายโครงการ"
                                  );
                                  /*
                                  คำอธิบายเกี่ยวกับ  Bookmark 
                                    1. หากท่านกรอกข้อมูลพื้นฐานไม่ครบ จะทำการ เลือกติดตามได้ 1 โครงการ  
                                    2. เมื่อท่านกรอกข้อมูลพื้นฐานตามระบบกำหนด จะสามารถเลือกติดตามได้หลายโครงการ
                                  */
                                } else if (
                                  result.status === "nok" &&
                                  result.message ===
                                    "Bookmarks Field, Please contact admin."
                                ) {
                                  alert(
                                    "สถานะการใช้งานของท่านถูกยกเลิก กรุณาติดต่อผู้ดูและระบบ"
                                  );
                                }
                              } else {
                                alert(
                                  "กรุณาเข้าสู่ระบบเพื่อใช้งานฟังก์ชั่นนี้"
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="utf-listing-carousel-item">
                          <div>
                            <center>
                              <img
                                lazyload="true"
                                src={item.File_Name}
                                alt={item.Asset_Project + " " + item.Address}
                                style={{ height: 250, width: "auto" }}
                              />
                            </center>
                          </div>
                        </div>
                      </a>

                      <div className="utf-listing-content">
                        <div className="utf-listing-title">
                          <h4 className="text-warp">
                            <a
                              href={
                                "/asset?p=" +
                                encrytion(item.datafrom + "/" + item.Asset_ID)
                              }
                            >
                              {item.Asset_Project}
                            </a>
                          </h4>
                          <span className="utf-listing-address text-warp">
                            <i className="icon-material-outline-location-on" />{" "}
                            {item.Address}
                          </span>

                          {/* <span className="txt_refcode">
                          รหัสหน่วยงาน: {item.Ref_code ? item.Ref_code : '-'}
                          
                          </span> */}

                          <span>
                            <div>
                              <div className="row2">
                                <div className="column">
                                  <i
                                    className="fa fa-bed"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={"ห้องนอน"}
                                  />
                                  <span> {item.Number_Bed_Room}</span>
                                </div>
                                <div className="column">
                                  <i
                                    className="fa fa-bath"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={"ห้องน้ำ"}
                                  />
                                  <span> {item.Number_Car_Park}</span>
                                </div>
                                <div className="column">
                                  <i
                                    className="icon-line-awesome-arrows"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={"พท.ใช้สอย"}
                                  />
                                  <span> {item.Asset_Usage_Area} </span>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                        <div className="blockprice">
                          <div>
                            <span className="lbprice" style={{ float: "left" }}>
                              ราคาตั้งขาย
                            </span>
                            {item.Promotion_price > 0 ? (
                              <span
                                className="lbpromo"
                                style={{ float: "right" }}
                              >
                                ราคาพิเศษ
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <br />
                          <div>
                            <span
                              className={
                                item.Promotion_price > 0
                                  ? "txtpricelow"
                                  : "txtprice"
                              }
                            >
                              <span
                                className={
                                  item.Promotion_price > 0
                                    ? "strikethrough"
                                    : ""
                                }
                              >
                                {item.Asset_Price > 0
                                  ? currencyFormat(item.Asset_Price) +
                                    "" +
                                    " บาท"
                                  : "โปรดติดต่อหน่วยงาน"}
                              </span>
                            </span>
                            <span className="txtpromotion">
                              {item.Promotion_price > 0
                                ? currencyFormat(item.Promotion_price) +
                                  "" +
                                  ".-"
                                : ""}
                            </span>
                          </div>

                          <div>
                            <span
                              style={{
                                display: "flex",
                                marginTop: "-25px",
                                marginRight: 10,
                              }}
                            >
                              <i
                                className="fa fa-eye"
                                style={{ marginLeft: "auto" }}
                              >
                                <font
                                  style={{
                                    fontSize: "10pt",
                                    fontFamily: "Kanit-Light",
                                  }}
                                >
                                  {" "}
                                  {Cals.numberWithCommas(
                                    item.All_Hit_Point ? item.All_Hit_Point : 0
                                  )}
                                </font>
                              </i>
                            </span>
                          </div>
                        </div>

                        <div className="utf-listing-user-info">
                          <div className="row">
                            <div className="col-md-12">
                              <i className="icon-line-awesome-user" />{" "}
                              {item.Member_Name}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="utf-listing-content">
                        <div className="utf-listing-title">
                         <h4 className="text-warp">
                            <a
                              href={
                                "/asset?p=" +
                                encrytion(item.datafrom + "/" + item.Asset_ID)
                              }
                            >
                              {item.Asset_Project}
                            </a>
                          </h4>
                          <span className="utf-listing-address text-warp">
                            <i className="icon-material-outline-location-on" />{" "}
                            {item.Address}
                          </span>

                          <div style={{whiteSpace: 'nowrap'}}>             

                      <span style={{display: 'flex', marginTop: '-25px'}}>
                        <i className="fa fa-eye" style={{marginLeft: 'auto'}}>
                          <font style={{fontSize: '10pt', fontFamily: 'Kanit-Light'}}> {Cals.numberWithCommas(item.All_Hit_Point ? item.All_Hit_Point: 0)}</font>
                        </i>
                      </span>
                      </div>




                          
                        </div>

                          <ul className="utf-listing-features">
                          <li>
                            <i
                              className="fa fa-bed"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="ห้องนอน"
                            />{" "}
                            <span>{item.Number_Bed_Room}</span>
                          </li>
                          <li>
                            <i
                              className="fa fa-bath"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="ห้องน้ำ"
                            />{" "}
                            <span>{item.Number_Bath_Room}</span>
                          </li>
                        
                          <li>
                            <i
                              className="icon-line-awesome-arrows"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="พื้นที่ใช้สอย"
                            />
                            <span>{item.Asset_Usage_Area}</span>
                          </li>
                        </ul>  
                        <div className="utf-listing-user-info">
                          <a href="#">
                            <i className="icon-line-awesome-user text-warp" />{" "}
                            {item.Member_Name}
                          </a>{" "}
                          <span>{item.Update_Date}</span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          </div>
        </div>
      );
    } catch (error) {
      console.log(error);
    }
  };

  renderGHB = (data) => {
    try {
      return (
        <div className="row">
          <div className="col-md-12">
            {data != null &&
              data.map((item) => (
                <div key={item.Asset_ID + item.datafrom}>
                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="utf-listing-item">
                      <a
                        href={
                          "/asset?p=" +
                          encrytion(item.datafrom + "/" + item.Asset_ID)
                        }
                        className="utf-smt-listing-img-container"
                      >
                        <div className="utf-listing-badges-item">
                          {item.New_Asset === "Y" ? (
                            <span className="featured">โครงการใหม่</span>
                          ) : null}
                          {item.Action_Type === "4" ? (
                            <span className="for-sale">
                              <span className="icon-material-outline-gavel">
                                {" "}
                              </span>{" "}
                              ประมูล{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="utf-listing-img-content-item">
                          <img
                            className="utf-user-picture"
                            src={
                              item.datafrom == "Asset_Institute_Desc"
                                ? "images/Logo/LED.jpeg"
                                : "images/Logo/GHB.png"
                            }
                            lazyload="true"
                            style={{ width: 60, height: 60, marginTop: 5 }}
                          />
                          <span
                            className={
                              item.Bookmark === 1
                                ? "like-icon with-tip liked"
                                : "like-icon with-tip"
                            }
                            data-tip-content="Bookmark"
                            onClick={async (e) => {
                              e.preventDefault();
                              if (localStorage.getItem("Member_ID")) {
                                let liked = "";
                                if (
                                  e.target.className === "like-icon with-tip"
                                ) {
                                  //like
                                  liked = true;
                                } else {
                                  //unlike
                                  liked = false;
                                }

                                let values = {
                                  Member_ID: localStorage.getItem("Member_ID"),
                                  Asset_ID: item.Asset_ID,
                                  Data_From: item.datafrom,
                                  Liked: liked,
                                };
                                let result = await httpClient.post(
                                  server.ASSET_URL + "/bookmarks",
                                  values
                                );
                                if (result.status === "ok") {
                                  if (
                                    e.target.className === "like-icon with-tip"
                                  ) {
                                    e.target.className =
                                      "like-icon with-tip liked";
                                  } else {
                                    e.target.className = "like-icon with-tip";
                                  }
                                } else if (
                                  result.status === "nok" &&
                                  result.message ===
                                    "Bookmarks Field, Admin not approve."
                                ) {
                                  alert(
                                    "กรุณากรอกข้อมูลพื้นฐานให้ครบและรอให้ผู้ดูแลระบบอนุมัติสมาชิก เพื่อใช้ฟังก์ชั่นนี้ \n\n คำอธิบายเกี่ยวกับ  Bookmark \n 1. หากท่านกรอกข้อมูลพื้นฐานไม่ครบ จะทำการ เลือกติดตามได้ 1 โครงการ \n 2. เมื่อท่านกรอกข้อมูลพื้นฐานตามระบบกำหนด จะสามารถเลือกติดตามได้หลายโครงการ"
                                  );
                                } else if (
                                  result.status === "nok" &&
                                  result.message ===
                                    "Bookmarks Field, Please contact admin."
                                ) {
                                  alert(
                                    "สถานะการใช้งานของท่านถูกยกเลิก กรุณาติดต่อผู้ดูและระบบ"
                                  );
                                }
                              } else {
                                alert(
                                  "กรุณาเข้าสู่ระบบเพื่อใช้งานฟังก์ชั่นนี้"
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="utf-listing-carousel-item">
                          <div>
                            <center>
                              <img
                                lazyload="true"
                                src={item.File_Name}
                                alt={item.Asset_Project + " " + item.Address}
                                style={{ height: 250, width: "auto" }}
                              />
                            </center>
                          </div>
                        </div>
                      </a>
                      <div className="utf-listing-content">
                        <div className="utf-listing-title">
                          <h4 className="text-warp">
                            <a
                              href={
                                "/asset?p=" +
                                encrytion(item.datafrom + "/" + item.Asset_ID)
                              }
                            >
                              {item.Asset_Project}
                            </a>
                          </h4>
                          <span className="utf-listing-address text-warp">
                            <i className="icon-material-outline-location-on" />{" "}
                            {item.Address}
                          </span>

                          {/* <span className="txt_refcode">
                          รหัสหน่วยงาน: {item.Ref_code ? item.Ref_code : '-'}
                          </span> */}

                          <span>
                            <div>
                              <div className="row2">
                                <div className="column">
                                  <i
                                    className="fa fa-bed"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={"ห้องนอน"}
                                  />
                                  <span> {item.Number_Bed_Room}</span>
                                </div>
                                <div className="column">
                                  <i
                                    className="fa fa-bath"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={"ห้องน้ำ"}
                                  />
                                  <span> {item.Number_Car_Park}</span>
                                </div>
                                <div className="column">
                                  <i
                                    className="icon-line-awesome-arrows"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={"พท.ใช้สอย"}
                                  />
                                  <span> {item.Asset_Usage_Area} </span>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                        <div className="blockprice">
                          <div>
                            <span className="lbprice" style={{ float: "left" }}>
                              ราคาตั้งขาย
                            </span>
                            {item.Promotion_price > 0 ? (
                              <span
                                className="lbpromo"
                                style={{ float: "right" }}
                              >
                                ราคาพิเศษ
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <br />
                          <div>
                            <span
                              className={
                                item.Promotion_price > 0
                                  ? "txtpricelow"
                                  : "txtprice"
                              }
                            >
                              <span
                                className={
                                  item.Promotion_price > 0
                                    ? "strikethrough"
                                    : ""
                                }
                              >
                                {item.Asset_Price > 0
                                  ? currencyFormat(item.Asset_Price) + "" + ".-"
                                  : "โปรดติดต่อหน่วยงาน"}
                              </span>
                            </span>
                            <span className="txtpromotion">
                              {item.Promotion_price > 0
                                ? currencyFormat(item.Promotion_price) +
                                  "" +
                                  ".-"
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="utf-listing-user-info">
                          <i className="icon-line-awesome-user text-warp" />{" "}
                          {item.Member_Name}
                          <span>
                            <span
                              style={{
                                display: "flex",
                                marginTop: "-25px",
                                marginRight: 10,
                              }}
                            >
                              <i
                                className="fa fa-eye"
                                style={{ marginLeft: "auto" }}
                              >
                                <font
                                  style={{
                                    fontSize: "10pt",
                                    fontFamily: "Kanit-Light",
                                  }}
                                >
                                  {" "}
                                  {Cals.numberWithCommas(
                                    item.All_Hit_Point ? item.All_Hit_Point : 0
                                  )}
                                </font>
                              </i>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      );
    } catch (error) {
      // console.log(error);
    }
  };

  renderBloglist(data) {
    return data.map((item) => (
      <div className="col-md-4" key={item.id}>
        <div className="blog-post">
          <a
            href={"/blog_detail?p=" + encrytion(item.id)}
            target="_blank"
            rel="noreferrer"
          >
            <img src={item.images_blog} lazyload="true" />
          </a>
          <div
            className="utf-post-content-area"
            style={{ borderBottom: "1px dashed #f2f2f2" }}
          >
            <a
              href={"/blog_detail?p=" + encrytion(item.id)}
              target="_blank"
              rel="noreferrer"
            >
              <h4>
                <Dotdotdot
                  clamp={1}
                  useNativeClamp={false}
                  truncationChar="&raquo;"
                >
                  <div>{item.title_name.trim()}</div>
                </Dotdotdot>
              </h4>
              <div style={{ borderBottom: "1px dashed #f2f2f2" }}></div>

              <div className="word-spacing small">
                <Dotdotdot clamp={3}>
                  <div>{item.subtitle.trim()}</div>
                </Dotdotdot>
              </div>
            </a>
          </div>
          <div className="utf-listing-user-info">
            <i className="icon-line-awesome-user" /> Admin
            <span style={{ fontWeight: 100, margin: 0, fontSize: 13 }}>
              {item.date_blog}
            </span>
          </div>
        </div>
      </div>
    ));
  }

  renderTapVerify(data) {
    // console.log(data)
    let vals = data ? data.Member_Verify : " ";
    if (vals === "N") {
      return (
        // <div style={{backgroundColor: "#645c5c", textAlign: "center", opacity: 0.8}}>
        //   <a href="verify-sendmail" style={{color: "white"}}>
        //     <p style={{color: "white", margin: 0}}>ผู้ใช้งานยังไม่ได้ยืนยันสมาชิกกับทางเว็บไซต์ โปรดยืนยันสมาชิกเพื่อเข้าใช้งาน <u>ที่นี่</u></p>
        //   </a>
        // </div>

        <div
          className="notification warning closeable"
          style={{ padding: 10, marginBottom: 0 }}
        >
          <p>
            <a href="verify-sendmail">
              Warning! ท่านยังไม่ได้ยืนยันตัวตน
              โปรดยืนยันตัวตนเพื่อใช้งานเว็บไซต์ <u>ที่นี่</u>.
            </a>
          </p>
        </div>
      );
    } else {
      return this.setState({ status: null });
    }
  }

  async intro_p(data) {
    if (data) {
      await this.setState({
        file_name: data[0]["file_name"]
          ? imageUrl + "/" + data[0]["file_name"]
          : null,
        bgIntro: data[0]["background_color"],
        start_date: data[0]["start_date"],
        link_url: data[0]["link_url"],
      });

      this.setState({
        intro_class:
          window.innerWidth < 800 ? "intro-content-small" : "intro-content",
      });
    } else {
      this.setState({ openModal: false });
      return null;
    }
  }

  async popup_p(data) {
    if (data) {
      await this.setState({
        popfile_name: data[0]["file_name"]
          ? imageUrl + "/" + data[0]["file_name"]
          : null,
        popbgIntro: data[0]["background_color"],
        popstart_date: data[0]["start_date"],
        poplink_url: data[0]["link_url"],
      });
      // localStorage.setItem("popup_cookie", Date.now());
      // this.setState({ popup_class: window.innerWidth < 800 ? 'popup-content-small' : 'popup-class' })
    } else {
      this.setState({ openPopup: false });
      return null;
    }
  }

  //==== =Load tab
  loadData = async () => {
    try {
      const values = { Member_ID: localStorage.getItem("Member_ID") || "0" };
      const result = await httpClient.post(server.BLOG_TAB_URL, values);

      const Top8cards = this.renderAssetCards(result.top8);
      const RentAsset = this.renderAssetCards(result.rent_asset);

      this.setState({
        Top8Card: Top8cards,
        rent_assetCard: RentAsset,
      });
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  renderAssetCards = (data) => {
    // console.log(data);
    if (!Array.isArray(data)) return null;

    return data.map((item) => (
      <div key={item.Asset_ID + item.DataFrom} className="col-md-2">
        <div className="hover-img">
          <a
            href={`/asset?p=${encrytion(item.datafrom + "/" + item.Asset_ID)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img  src={item.File_Name}  alt={`${item.Asset_Project} ${item.Address}`}   style={{ height: 120, borderRadius: "1rem" }}
              lazyload="true"
            />
            <span
              style={{ display: "flex", marginTop: "-25px", marginRight: 10 }}
            >
              <i
                className="fa fa-eye"
                style={{ marginLeft: "auto", color: "white" }}
              >
                <font style={{ fontSize: "10pt", fontFamily: "Kanit-Light" }}>
                  &nbsp;
                  {Cals.numberWithCommas(
                    item.All_Hit_Point ? item.All_Hit_Point : 0
                  )}
                </font>
              </i>
            </span>
          </a>
          <div className="utf-post-content-area-top">
            <a
              href={`/asset?p=${encrytion(
                item.datafrom + "/" + item.Asset_ID
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="title-hot-asset" style={{ marginTop: "1rem" }}>
                <Dotdotdot
                  clamp={1}
                  useNativeClamp={true}
                  truncationChar="&raquo;"
                >
                  <div>{item.Asset_Project}</div>
                </Dotdotdot>
              </div>
              <div style={{ borderBottom: "1px dashed #f2f2f2" }}></div>
              <div className="word-spacing small">
                <span
                  className="utf-listing-address text-warp"
                  style={{ margin: "0" }}
                >
                  <div className="detail-hot-asset">
                    <div className="ditail-hot-price">
                      เริ่มต้นที่{" "}
                      {item.Asset_Price > 0
                        ? `${currencyFormat(item.Asset_Price)} บาท`
                        : "โปรดติดต่อหน่วยงาน"}
                    </div>
                  </div>
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    ));
  };

  handleTabClick = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const { activeTab, Top8Card, rent_assetCard } = this.state;
    return (
      <div>
        {/* popup Modal */}

        <Modal
          onClose={() => this.setState({ openPopup: false })}
          onOpen={() => this.setState({ openPopup: true })}
          open={this.state.openPopup}
          size="small"
          style={{ backgroundColor: this.state.popbgIntro }}
        >
          <Modal.Content style={{ padding: 50 }}>
            <Modal.Description>
              <div className={"text-center "}>
                {/* <center> */}
                <a
                  href={this.state.poplink_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={this.state.popfile_name} lazyload="true" />
                </a>

                <div className="text-center margin-top-30">
                  <a
                    href={this.state.poplink_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      type="button"
                      className="button-p btn-sm btn-success"
                    >
                      ตอบแบบสอบถาม
                    </button>
                  </a>

                  <button
                    className="button btn-lg utf-search-btn-item"
                    type="button"
                    onClick={() => {
                      this.setState({ openPopup: false });
                      // localStorage.removeItem("mytime");
                    }}
                  >
                    เข้าสู่เว็บไซต์
                  </button>
                </div>
                {/* </center> */}
              </div>
            </Modal.Description>
          </Modal.Content>
        </Modal>

        {/* Modal */}
        <Modal
          onClose={() => this.setState({ openModal: false })}
          onOpen={() => this.setState({ openModal: true })}
          open={this.state.openModal}
          size="fullscreen"
          style={{ backgroundColor: this.state.bgIntro }}
        >
          <Modal.Content style={{ backgroundColor: this.state.bgIntro }}>
            <Modal.Description>
              <div className={"text-center " + this.state.intro_class}>
                <a href={this.state.link_url} target="_blank">
                  <img src={this.state.file_name} lazyload="true" />
                </a>
                <div className="text-center margin-top-30">
                  <button
                    className="button btn-lg utf-search-btn-item"
                    type="button"
                    onClick={() => {
                      this.setState({ openModal: false });
                    }}
                  >
                    เข้าสู่เว็บไซต์
                  </button>
                </div>
              </div>
            </Modal.Description>
          </Modal.Content>
        </Modal>

        {/* แสดง Popupยืนยันตัวตนในระบบ */}
        <div>{this.state.status}</div>

        {/* parallax ในส่วนของ Search */}
        <div
          className="parallax"
          data-background="images/home-parallax-4.jpg"
          lazyload="true"
          data-color="#36383e"
          data-color-opacity="0.01"
          data-img-width={2500}
          data-img-height={1600}
        >
          <div className="utf-parallax-content-area">
            <div className="container">
              <div className="row">
                <div className="col-md-1" />
                <div className="col-md-10">
                  <div className="utf-main-search-container-area">
                    <div className="utf-banner-headline-text-part">
                      <h2>
                        The best place to find <span className="typed-words" />
                      </h2>
                      <span>เว็บไซต์ที่มีบ้านมือสองมากที่สุดในประเทศไทย</span>
                    </div>
                    <div className="margin-top-120" />
                  </div>
                </div>
                <div className="col-md-1" />
              </div>
            </div>
          </div>
        </div>

        {/* search box  */}
        <div className="utf-main-search-container-area inner-map-search-block">
          <div className="container">
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-10">{this.state.searchBox}</div>
              <div className="col-md-1" />
            </div>
          </div>
        </div>

        {/* banner โฆษณาด้านบนสุด  */}
        <section
          className="fullwidth"
          data-background-color="#ffffff"
          style={{ paddingTop: 0, display: "block" }}
        >
          <div className="container">
            {/* <header className="header">My header</header> */}

      <div className="image-row">
      <div className="image-column-30">
        <img src="images/banner/top_left.png" alt="Left Image" loading="lazy" />
      </div>
      <div className="image-column-70">
      <a href="https://www.taladnudbaan.com/login" target="_blank">
        <img src="images/banner/banner_top.jpg" alt="Right Image" loading="lazy"  />
        </a>
      </div>
    </div>
            {/* <aside className="sidebar-h">
              <a href="#"
                target="_blank"   > 
                <img    src="images/banner/top_left.png"   style={{ height: "20" }}  lazyload="true"   />
              </a>
            </aside>
            <article className="content">
              <a href="https://www.taladnudbaan.com/login" target="_blank">
                <img src="images/banner/banner_top.jpg"    lazyload="true"   />
              </a>
            </article> */}


            {/* Full image  */}
            {/* <div>
              <a    href="https://www.taladnudbaan.com/login"   target="_blank"     rel="noreferrer"   >
                <img  src="images/banner/banner_top.jpg"    style={{ height: "50%" }}   lazyload="true"   />
              </a>
            </div> */}
            {/* <footer className="footer">เงื่อนไขเป็นไปตาม</footer> */}
          </div>
        </section>

        {/* รายการทรัพย์ล่าสุด */}
        <section className="fullwidth" data-background-color="#fbfbfb">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="hot-new-title">
                  <div
                    className="utf-section-headline-item centered margin-bottom-30 margin-top-20"
                    style={{ textAlign: "left" }}
                  >
                    <h3 className="headline">
                      รายการทรัพย์ล่าสุด{" "}
                      <small className="utf-slogan-text">
                        รายการทรัพย์ล่าสุด ประจำสัปดาห์
                      </small>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="style-1">
                <ul className="tabs-nav">
                  <li className={activeTab === "tab1" ? "active" : ""}>
                    <a href="#tab1" onClick={() => this.handleTabClick("tab1")}>
                      <i className="sl sl-icon-home"></i>ทรัพย์มาใหม่
                    </a>
                  </li>
                  <li className={activeTab === "tab2" ? "active" : ""}>
                    <a href="#tab2" onClick={() => this.handleTabClick("tab2")}>
                      <i className="sl sl-icon-wallet"></i> ทรัพย์ประเภทเช่า
                    </a>
                  </li>
                </ul>
                <div className="tabs-container">
                  <div
                    className="tab-content"
                    id="tab1"
                    style={{
                      display: activeTab === "tab1" ? "block" : "none",
                    }}
                    key="tab1"
                  >
                    <div className="row">
                      <div className="col-md-12">{Top8Card}</div>
                    </div>
                  </div>
                  <div
                    className="tab-content"
                    id="tab2"
                    style={{
                      display: activeTab === "tab2" ? "block" : "none",
                    }}
                    key="tab2"
                  >
                    <div className="row">
                      <div className="col-md-12">{rent_assetCard}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* มุมบ้านใหม่ */}
        <section
          className="fullwidth"
          style={{ display: this.state.displaynewhome }}
        >
          <div className="container">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="utf-section-headline-item centered margin-bottom-0 margin-top-0">
                <h3 className="headline">มุมบ้านใหม่ แนะนำ </h3>
                <p className="utf-slogan-text">
                  #บ้านเดี่ยว โครงการใหม่, #ทาวน์โฮม โครงการใหม่, #คอนโดมิเนียม
                  โครงการใหม่
                </p>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 col-xs-12">
              {this.state.NewCard}
            </div>

            <div style={{ textAlign: "right" }}>
              {" "}
              <a href="listings?traget=N" target="_blank" className="btn-right">
                ดูข้อมูลทั้งหมด{" "}
                <span className="icon-line-awesome-chevron-circle-right">
                  {" "}
                </span>
              </a>
            </div>
          </div>
        </section>

        {/* Region */}
        <section className="fullwidth" data-background-color="#fbfbfb">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="utf-section-headline-item centered margin-bottom-10 margin-top-20">
                  <h3 className="headline">ค้นหาทรัพย์ตามภูมิภาค</h3>

                  <p className="utf-slogan-text">
                    ค้นหาบ้านมือสองกับย่านที่ชอบ
                    สำรวจที่อยู่อาศัยในทำเลที่ต้องการ เพื่อรับข้อมูลพื้นฐาน
                    ศักยภาพทำเล รีวิว เสมือนไปสำรวจด้วยตัวเอง
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-6">
                <a className="img-box" href="listings?region=P" target="_blank">
                  <img src="images/popular-location-04.jpg" lazyload="true" />
                  <div className="utf-cat-img-box-content visible">
                    <h3 style={{ color: "#fff" }}>กรุงเทพและปริมณฑล</h3>
                    <NumberFormat
                      value={
                        this.state.region.filter((item) => {
                          if (item.Region == "P") {
                            return true;
                          }
                        })[0]._count
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value, props) => (
                        <span {...props}>{value} รายการ</span>
                      )}
                    />
                  </div>
                </a>
              </div>

              <div className="col-md-4 col-sm-6">
                <a className="img-box" href="listings?region=C" target="_blank">
                  <img src="images/popular-location-05.jpg" lazyload="true" />
                  <div className="utf-cat-img-box-content visible">
                    <h3 style={{ color: "#fff" }}>ภาคกลาง</h3>
                    <NumberFormat
                      value={
                        this.state.region.filter((item) => {
                          if (item.Region == "C") {
                            return true;
                          }
                        })[0]._count
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value, props) => (
                        <span {...props}>{value} รายการ</span>
                      )}
                    />
                  </div>
                </a>
              </div>

              <div className="col-md-3 col-sm-6">
                <a className="img-box" href="listings?region=N" target="_blank">
                  <img src="images/popular-location-06.jpg" lazyload="true" />
                  <div className="utf-cat-img-box-content visible">
                    <h3 style={{ color: "#fff" }}>ภาคเหนือ</h3>
                    <NumberFormat
                      value={
                        this.state.region.filter((item) => {
                          if (item.Region == "N") {
                            return true;
                          }
                        })[0]._count
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value, props) => (
                        <span {...props}>{value} รายการ</span>
                      )}
                    />
                  </div>
                </a>
              </div>

              <div className="col-md-4 col-sm-6">
                <a className="img-box" href="listings?region=S" target="_blank">
                  <img src="images/popular-location-01.jpg" lazyload="true" />
                  <div className="utf-cat-img-box-content visible">
                    <h3 style={{ color: "#fff" }}>ภาคใต้</h3>
                    <NumberFormat
                      value={
                        this.state.region.filter((item) => {
                          if (item.Region == "S") {
                            return true;
                          }
                        })[0]._count
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value, props) => (
                        <span {...props}>{value} รายการ</span>
                      )}
                    />
                  </div>
                </a>
              </div>

              <div className="col-md-3 col-sm-6">
                <a className="img-box" href="listings?region=E" target="_blank">
                  <img src="images/popular-location-02.jpg" lazyload="true" />
                  <div className="utf-cat-img-box-content visible">
                    <h3 style={{ color: "#fff" }}>ภาคตะวันออก</h3>
                    <NumberFormat
                      value={
                        this.state.region.filter((item) => {
                          if (item.Region == "E") {
                            return true;
                          }
                        })[0]._count
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value, props) => (
                        <span {...props}>{value} รายการ</span>
                      )}
                    />
                  </div>
                </a>
              </div>
              <div className="col-md-5 col-sm-6">
                <a
                  className="img-box"
                  href="listings?region=NE"
                  target="_blank"
                >
                  <img src="images/popular-location-03.jpg" lazyload="true" />
                  <div className="utf-cat-img-box-content visible">
                    <h3 style={{ color: "#fff" }}>ภาคตะวันออกเฉียงเหนือ</h3>
                    <NumberFormat
                      value={
                        this.state.region.filter((item) => {
                          if (item.Region == "NE") {
                            return true;
                          }
                        })[0]._count
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value, props) => (
                        <span {...props}>{value} รายการ</span>
                      )}
                    />
                  </div>
                </a>
              </div>
            </div>

            <div className="utf-centered-button margin-top-10">
              <a href="all-area" className="button">
                ดูทำเลทั้งหมด
              </a>
            </div>
          </div>
        </section>

        {/* Hitpoint */}

        <section
          className="fullwidth"
          data-background-color="#rgb(255 255 255)"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="utf-section-headline-item centered margin-bottom-10 margin-top-20">
                  {/* <WindowDimensions/>  */}
                  <h3 className="headline">
                    <i className="sl sl-icon-star" /> รายการทรัพย์เด่น{" "}
                  </h3>
                  <p className="utf-slogan-text">
                    รายการทรัพย์ที่มีการดูมากที่สุด
                  </p>
                </div>
              </div>
              <div className="col-md-12">{this.state.hitpointCard}</div>
            </div>
          </div>
        </section>

        {/* ปฏิทินกิจกรรม */}
        <section
          className="fullwidth"
          data-background-color="#ffffff"
          style={{ display: "none" }}
        >
          <div className="container">
            <div className="row">
              {/* <div className="col-md-12"><showcalendar/></div> */}
            </div>
          </div>
        </section>

        {/* แสดงรายการทรัพย์จาก  GHB */}
        <section
          className="fullwidth"
          data-background-color="#fbfbfb"
          style={{ background: "rgb(251, 251, 251)", display: "none" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="utf-section-headline-item centered margin-bottom-10 margin-top-20">
                  {/* <WindowDimensions/>  */}
                  <h3 className="headline">
                    <i className="sl sl-icon-credit-card" /> รายการทรัพย์จาก
                    ธนาคารอาคารสงเคราะห์
                  </h3>
                  <p className="utf-slogan-text">
                    ทรัพย์จากธนาคารอาคารสงเคราะห์ / NPA / บ้านมือสอง
                  </p>
                </div>
              </div>
              <div className="col-md-12">{this.state.GHBCard}</div>
            </div>

            <div className="utf-centered-button margin-top-20 margin-bottom-20">
              <a
                href="listings?datafrom=GHB"
                target="_blank"
                className="button"
              >
                ดูโครงการทั้งหมด
                <span className="icon-feather-arrow-right"> </span>
              </a>
            </div>
          </div>
        </section>

        {/* แสดงรายการทรัพย์จาก กรมบังคับคดี */}
        <section className="fullwidth" style={{ display: "none" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="utf-section-headline-item centered margin-bottom-0 margin-top-20">
                  {/* <WindowDimensions/>  */}
                  <h3 className="headline">
                    {" "}
                    <i className="sl sl-icon-tag" /> รายการทรัพย์จาก
                    กรมบังคับคดี{" "}
                  </h3>
                </div>
              </div>
              <div className="col-md-12">{this.state.LEDCard}</div>

              <div className="utf-centered-button margin-top-10">
                <a
                  href="listings?datafrom=Institute"
                  target="_blank"
                  className="button"
                >
                  ดูโครงการทั้งหมด
                  <span className="icon-feather-arrow-right"> </span>
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* แสดง  Banner ประชาสัมพันธ์ */}
        <section className="fullwidth" data-background-color="#ffffff">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="utf-section-headline-item centered margin-bottom-10 margin-top-0"
                  style={{ textAlign: "left" }}
                >
                  <h3 className="headline">ข่าวประชาสัมพันธ์</h3>
                  <p>ข่าวประชาสัมพันธ์จากเรา</p>
                </div>
              </div>
              <div className="col-md-9 col-sm-12">
                <OwlCarousel
                  autoplay={false}
                  className="owl-theme"
                  loop
                  margin={30}
                  nav={true}
                  smartSpeed={500}
                  items={1}
                  dots={false}
                >
                  <div className="item">
                    <div>
                      <a
                        href="https://www.ghbhomecenter.com/promotion-273"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="utf-user-picture"
                          src="images/banner/banner_1.png"
                          lazyload="true"
                        />
                      </a>
                    </div>
                  </div>
                  {/*   <div className="item">
                    <div>
                      <a href="https://estatecorneragency.com/th/" target="_blank">
                        <img src="images/banner/banner_2.png" lazyload="true" />
                      </a>
                    </div>
                  </div>
                   <div className="item">
                    <div>
                      <a href="https://www.bam.co.th/search?pageNumber=1&pageSize=12&inputText=&assetType=&bedroom=&bathroom=&areaMeter=&province=&district=&firstPriceRange=&secondPriceRange=&groupType=%E0%B8%97%E0%B8%A3%E0%B8%B1%E0%B8%9E%E0%B8%A2%E0%B9%8C%E0%B8%A5%E0%B8%94%E0%B8%96%E0%B8%A5%E0%B9%88%E0%B8%A1%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2&utm_source=website&utm_medium=banner+slide&utm_campaign=BAM+SGS?utm_source=ad%20BAM&utm_medium=Link&utm_campaign=&utm_term=&utm_content=Promote" target="_blank">
                        <img src="images/banner/banner_3.png" lazyload="true" />
                      </a>
                    </div>
                  </div> */}
                </OwlCarousel>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12 ">
                    <div
                      className="utf-listing-item"
                      style={{ margin: "10px 0px 0px 0px", boxShadow: "none" }}
                    >
                      <a
                        href="single-property-page-1.html"
                        className="utf-smt-listing-img-container"
                      ></a>
                      <div className="utf-listing-carousel-item">
                        <a
                          href="single-property-page-1.html"
                          className="utf-smt-listing-img-container"
                        >
                          <div>
                            {/* <img src="images/banner/banner_right1.jpg" /> */}
                            <img src="images/banner/banner_right1.jpg" />
                          </div>
                        </a>
                      </div>
                      <hr />
                      <div
                        className="utf-listing-item"
                        style={{
                          margin: "10px 0px 0px 0px",
                          boxShadow: "none",
                        }}
                      >
                        <a
                          href="single-property-page-1.html"
                          className="utf-smt-listing-img-container"
                        ></a>
                        <div className="utf-listing-carousel-item">
                          <a
                            href="single-property-page-1.html"
                            className="utf-smt-listing-img-container"
                          >
                            <div>
                              <img src="images/banner/banner_right2.jpg" />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* โฆษณาเต็มหน้า */}
        <div className="grid-con" style={{ display: "none" }}>
          <div className="grid-item-1">
            <img src="https://images.unsplash.com/photo-1554995207-c18c203602cb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60" />
          </div>

          <div className="grid-item-2">
            <div className="grid-item-info">
              <div>
                <h3>Hande made</h3>
                <p>
                  จุดเด่นของโครงการ เดอะ คอนเนค บางนา-ศรีวารี 1 <br />*
                  โครงการตั้งอยู่ทำเลดีการเดินทางสะดวก ติดถนนสป.2001
                  ใกล้ทางด่วนพิเศษบูรพาวิถี ใกล้รถไฟฟ้า และท่าอากาศยานสุวรรณภูมิ{" "}
                  <br />* โครงการใกล้แหล่งอำนวยความสะดวก อาทิ คิง พาวเวอร์
                  ศรีวารี เซ็นทรัล วิลเลจ และมหวิทยาลัยหัวเฉียว{" "}
                </p>
                {/* <a href="#" className="black-btn"> View</a>           */}
              </div>
            </div>
            <div>
              <img src="https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8ZnVybml0dXJlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60" />
            </div>
          </div>

          <div className="grid-item-3">
            <div>
              <img src="https://images.unsplash.com/photo-1522708323590-d24dbb6b0267?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHJvb218ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60" />
            </div>
            <div className="grid-item-info">
              <div>
                <h3>Wooosin made</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer{" "}
                </p>
                <a href="#" className="black-btn">
                  {" "}
                  Check Out
                </a>
              </div>
            </div>
          </div>
          <div className="grid-item-4">
            <div>
              <img src="https://images.unsplash.com/photo-1631679706909-1844bbd07221?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGZ1cm5pdHVyZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60" />
            </div>
            <div className="grid-item-info">
              <div>
                <h3>Wooosin made</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
                <a href="#" className="black-btn">
                  {" "}
                  Check Out
                </a>
              </div>
            </div>
          </div>
          <div className="grid-item-5">
            <img src="https://images.unsplash.com/photo-1556228453-efd6c1ff04f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGZ1cm5pdHVyZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60" />
          </div>
        </div>

        {/* Acticle */}
        <section
          className="fullwidth"
          data-background-color="#ffffff"
          style={{ background: "rgb(255, 255, 255)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="utf-section-headline-item centered margin-bottom-10 margin-top-20">
                  <h3 className="headline">
                    <span style={{ background: "none", boxShadow: "none" }}>
                      {" "}
                    </span>{" "}
                    บทความที่น่าสนใจ{" "}
                  </h3>

                  <p className="utf-slogan-text">
                    รวมบทความที่น่าสนใจ รวมทั้งเทคนิคการตกแต่งบ้าน
                    พร้อมคำแนะนำจากผู้เชี่ยวชาญชั้นนำระดับประเทศ
                  </p>
                </div>
                {this.state.blogList}

                <div className="utf-centered-button margin-top-10">
                  <a href="blog" className="button">
                    ดูบทความทั้งหมด
                    <span className="icon-feather-arrow-right"> </span>
                  </a>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 col-xs-12">
                <div className="widget utf-sidebar-widget-item">
                  <div className="utf-boxed-list-headline-item">
                    <h3> บทความล่าสุด</h3>
                  </div>
                  {this.state.blogPost}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner ขนาดใหญ่ในส่วนล่าง */}
        <section
          className="fullwidth"
          data-background-color="#ffffff"
          style={{ background: "rgb(255, 255, 255)", textAlign: "center" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="item">
                  <a
                    href="https://www.ghbhomecenter.com/?utm_source=banner&utm_medium=&utm_campaign=&utm_content="
                    target="_blank"
                  >
                    <img
                      src="images/banner/banner_bottom_home.png"
                      lazyload="true"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Partners */}
        <section
          className="fullwidth margin-bottom-0 padding-top-30 padding-bottom-35"
          data-background-color="linear-gradient(to bottom,rgba(0,0,0,0.03) 0%,rgba(255,255,255,0))"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="utf-section-headline-item centered margin-bottom-30 margin-top-20">
                  <h3 className="headline"> ผู้ให้การสนับสนุนข้อมูล</h3>
                </div>
              </div>

              {/* Carousel */}

              <div className="col-md-12">
                <OwlCarousel
                  className="owl-theme ali"
                  margin={15}
                  nav={true}
                  items={8}
                  justify-content={"center"}
                  // loop
                  // autoplay={true}
                  // smartSpeed={500}
                >
                  {/* <div className="utf-logo-carousel-item dot-navigation"> */}

                  <div className="item">
                    <a
                      href="https://www.mof.go.th/th/home"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="images/banner/mof.jpg"
                        width="151"
                        height="81"
                        lazyload="true"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a
                      href="https://www.ghbank.co.th/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="images/banner/ghb.png"
                        width="151"
                        height="81"
                        lazyload="true"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a
                      href="https://www.reic.or.th/?REICPage=1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="images/banner/reic.png"
                        width="151"
                        height="81"
                        lazyload="true"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a
                      href="https://www.taladnudbaan.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="images/banner/tnb.png"
                        width="100%"
                        height="100%"
                        lazyload="true"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a
                      href="https://www.moj.go.th"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="images/banner/led.jpg"
                        width="151"
                        height="81"
                        lazyload="true"
                      />
                    </a>
                  </div>

                  <div className="item">
                    <a
                      href="http://www.housingbiz.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="images/banner/hba.jpg"
                        width="151"
                        height="81"
                        lazyload="true"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a
                      href="http://thaicondo.or.th/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="images/banner/tca.png"
                        width="151"
                        height="81"
                        lazyload="true"
                      />
                    </a>
                  </div>
                  <div className="item">
                    <a
                      href="http://thairealestate.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="images/banner/tra.jpg"
                        width="151"
                        height="81"
                        lazyload="true"
                      />
                    </a>
                  </div>
                </OwlCarousel>
                {/* </div> */}
              </div>
              {/* Carousel / End */}
            </div>
          </div>
          {/* Logo Carousel / End */}
        </section>

        {/* Email */}
        <section className="utf_subscribe_full">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="utf_subscribe_block clearfix">
                  <div className="col-md-8 col-sm-7">
                    <div className="section-heading">
                      <h2 className="utf_sec_title_item utf_sec_title_item2">
                        สมัครรับจดหมายข่าวจากเรา!
                      </h2>
                      <p className="utf_sec_meta">
                        สมัครเพื่อรับแจ้งอัพเดตโปรโมชั่นและรับสิทธิพิเศษก่อนใคร
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-5">
                    <div className="contact-form-action">
                      <span className="la la-envelope-o" />
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Enter your email"
                        value={this.state.newsletter_email}
                        onChange={(e) => {
                          let check = e.target.value.match(
                            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
                          );
                          this.setState({
                            emailError: check == null,
                            newsletter_email: e.target.value,
                          });
                        }}
                        required
                      />

                      {this.state.emailError &&
                      this.state.newsletter_email != "" ? (
                        <span className="error" style={{ fontSize: "10pt" }}>
                          {" "}
                          รูปแบบอีเมลไม่ถูกต้อง <br />
                        </span>
                      ) : null}

                      <button
                        className="button margin-top-10"
                        style={{
                          backgroundColor: this.state.emailError ? "red" : "",
                        }}
                        onClick={async () => {
                          if (!this.state.emailError) {
                            let result = await httpClient.post(
                              server.NEWSLETTER_URL + "/register",
                              { email: this.state.newsletter_email }
                            );
                            if (result.result == "ok") {
                              alert("Success");
                              this.setState({ newsletter_email: "" });
                            } else if (
                              result.result == "nok" &&
                              result.message == "already email."
                            ) {
                              alert(result.message);
                            }
                          }
                        }}
                      >
                        สมัครรับข่าวสาร
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

// export default Home;
// export default List;
const mapStateToProps = ({ homeReducer }) => ({
  homeReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
