import React, { Component } from 'react';
import UserMenu from './../user-menu/user-menu'

import { httpClient } from "../../utils/HttpClient";
import { server, imageUrl } from "../../constants";
import { ConvuserDate_format } from "./../default-class/default-class";
import "./user-profile.css";



class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  async componentDidMount() {
    document.title = "ข้อมูลส่วนตัว | Clickthaihome"

    let result = await httpClient.post(server.MEMBER_URL + "/getprofile", { id: localStorage.getItem("Member_ID") })
    await this.setState(result.data)
    await this.setState({
      Member_Picture: result.data.Member_Picture ? imageUrl + '/' + result.data.Member_Picture : 'images/agent-02.jpg'
    });

    // console.log(result)
  }




  render() {
    return (<div>

      <div className="parallax titlebar" data-background="images/visual_banner_desktop_2.jpg"
        data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
        <div id="titlebar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>ข้อมูลของฉัน</h2>

                <nav id="breadcrumbs">
                  <ul>
                    <li><a href="home">หน้าแรก</a></li>
                    <li>ข้อมูลของฉัน</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <UserMenu />
          </div>


          <div className="col-md-9">

            <div className="row">
              <div class="col-md-8">
                <div class="utf-section-headline-item centered margin-bottom-0 margin-top-20">
                  <h3 class="headline"> ข้อมูลส่วนตัว</h3><p class="utf-slogan-text">กรุณากรอกข้อมูลทั่วไปให้ครบ</p>           
                </div>
              </div>
              <div className="col-md-4 text-right">
              <a href="profile-edit">
                    <button className="margin-top-30 button"><i className="fa fa-edit" aria-hidden="true" />  แก้ไขข้อมูลส่วนตัว </button>
                  </a>
              </div>
            </div>


            <div className="box-profile">
              <div className="row">
                <div className="col-md-12">
                
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-4">
                          <label>คำนำหน้า <span><font color="red"> * </font></span></label>
                          <input
                            type="text"
                            name="Title_Name"
                            value={this.state.Title_Name}
                            onChange={this.handleUserInput}
                            disabled={true}
                          />

                        </div>
                        <div className="col-md-4">
                          <label>ชื่อ <span><font color="red"> * </font></span></label>
                          <input
                            type="text"
                            name="Member_First_Name"
                            value={this.state.Member_First_Name}
                            onChange={this.handleUserInput}
                            disabled={true}
                          // onChange={(e) =>
                          //   this.setState({ Member_First_Name: e.target.value })
                          // }
                          />

                        </div>
                        <div className="col-md-4">
                          <label>นามสกุล <span><font color="red"> * </font></span></label>
                          <input
                            type="text"
                            name="Member_Second_Name"
                            value={this.state.Member_Second_Name}
                            onChange={this.handleUserInput}
                            disabled={true}
                          // onChange={(e) =>
                          //   this.setState({
                          //     Member_Second_Name: e.target.value,
                          //   })
                          // }
                          />

                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <label>เบอร์โทรศัพท์</label>
                          <input
                            type="text"
                            name="Member_Phone"
                            value={this.state.Member_Phone}
                            onChange={this.handleUserInput}
                            disabled={true}
                          // onChange={(e) =>
                          //   this.setState({ Member_Phone: e.target.value })
                          // }
                          />
                        </div>
                        <div className="col-md-4">
                          <label>เบอร์โทรศัพท์มือถือ</label>
                          <input
                            type="text"
                            name="Member_Mobile"
                            value={this.state.Member_Mobile}
                            disabled={true}
                            // onChange={(e) =>
                            //   this.setState({ Member_Mobile: e.target.value })
                            // }
                            onChange={this.handleUserInput}
                          />
                        </div>
                        <div className="col-md-4">
                          <label>Email</label>
                          <input
                            type="text"
                            value={this.state.Member_Email}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <label>ประเภทบุคคล <span><font color="red"> * </font></span></label>
                          <input
                            type="text"
                            name="Personal_Type_Name"
                            value={this.state.Personal_Type_Name}
                            onChange={this.handleUserInput}
                            disabled={true}
                          />

                        </div>
                        <div className="col-md-4">
                          <label>
                            {this.state.Member_Personal_Type != 0 &&
                              this.state.Member_Personal_Type != 1
                              ? "เลขหนังสือจดทะเบียน"
                              : "เลขบัตรประชาชน"} <span><font color="red"> * </font></span>
                          </label>
                          <input type="text"
                            name="Member_Licensel_ID"
                            value={this.state.Member_Licensel_ID}
                            onChange={this.handleUserInput}
                            disabled={true}
                          //  onChange={e => this.setState({Member_Licensel_ID: e.target.value})}
                          />

                        </div>
                        <div className="col-md-4">
                          <label>วันออกบัตร/เลขที่หนังสือ</label>
                          {/* <input type="date" 
              name="Member_License_StartDate"
              value={this.state.Member_License_StartDate ? this.state.Member_License_StartDate.split('T')[0] : ''} 
              onChange={this.handleUserInput}
             //  onChange={e => this.setState({Member_License_StartDate: e.target.value})}
              /> */}
                          <input type="text"
                            name="Member_License_StartDate"
                            value={this.state.Member_License_StartDate ? ConvuserDate_format(this.state.Member_License_StartDate, 'TH', '/') : "-"}
                            onChange={this.handleUserInput}
                            disabled={true}

                          //  onChange={e => this.setState({Member_Licensel_ID: e.target.value})}
                          />

                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <label>Website</label>
                          <input
                            type="text"
                            name="Member_Web_URL"
                            value={this.state.Member_Web_URL}
                            onChange={this.handleUserInput}
                            disabled={true}
                          />
                        </div>
                        <div className="col-md-4">
                          <label>ความต้องการ <span><font color="red"> * </font></span></label>

                          <input
                            type="text"
                            name="Member_Type_Name"
                            value={this.state.Member_Type_Name}
                            onChange={this.handleUserInput}
                            disabled={true}
                          />
                        </div>
                        <div className="col-md-4">
                          <label>รายได้ต่อเดือน</label>
                          <div className="select-input">
                            <i className="data-unit" />
                            <input
                              type="text"
                              name="Member_Monthly_Income"
                              placeholder="รายได้ต่อเดือน"
                              data-unit="บาท"
                              value={this.state.Member_Monthly_Income}
                              onChange={this.handleUserInput}
                              disabled={true}
                            // onChange={(e) =>
                            //   this.setState({
                            //     Member_Monthly_Income: e.target.value,
                            //   })
                            // }
                            />
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="col-md-3">
                      <div className="utf-edit-profile-photo-area">
                        {" "}
                        <img src={this.state.Member_Picture} />
                        <div className="utf-change-photo-btn-item">

                        </div>
                      </div>


                    </div>
                  
                </div>
              </div>
            </div>


            <div className="row">
              <div class="col-md-12">
                <div class="utf-section-headline-item centered margin-bottom-0 margin-top-20">
                  <h3 class="headline"> ที่อยู่บ้าน/ที่พักอาศัย</h3><p class="utf-slogan-text">กรุณากรอกข้อมูลที่อยู่อาศัย</p>
                </div>
              </div>
            </div>
            <div className="box-profile">
               <div className="row">
                 
               <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-2">
                        <label>เลขที่</label>
                        <div>
                          <input
                            type="text"
                            name="Member_Address_No"
                            value={this.state.Member_Address_No}
                            onChange={this.handleUserInput}
                            disabled={true}
                          // onChange={(e) =>
                          //   this.setState({ Member_Address_No: e.target.value })
                          // }
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <label>หมู่</label>
                        <div>
                          <input
                            type="text"
                            name="Member_Moo"
                            value={this.state.Member_Moo}
                            onChange={this.handleUserInput}
                            disabled={true}
                          // onChange={(e) =>
                          //   this.setState({ Member_Moo: e.target.value })
                          // }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>ถนน</label>
                        <div>
                          <input
                            type="text"
                            name="Member_Road"
                            value={this.state.Member_Road}
                            onChange={this.handleUserInput}
                            disabled={true}
                          // onChange={(e) =>
                          //   this.setState({ Member_Road: e.target.value })
                          // }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <label>ซอย</label>
                        <div>
                          <input
                            type="text"
                            name="Member_Soi"
                            value={this.state.Member_Soi}
                            onChange={this.handleUserInput}
                            disabled={true}
                          // onChange={(e) =>
                          //   this.setState({ Member_Soi: e.target.value })
                          // }
                          />
                        </div>
                      </div>

                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <label>จังหวัด <span><font color="red"> * </font></span></label>

                        <input
                          type="text"
                          name="Province_Name"
                          value={this.state.Province_Name}
                          onChange={this.handleUserInput}
                          disabled={true}
                        // onChange={(e) =>
                        //   this.setState({ Member_Soi: e.target.value })
                        // }
                        />


                      </div>
                      <div className="col-md-4">
                        <label>อำเภอ/เขต <span><font color="red"> * </font></span></label>
                        <input
                          type="text"
                          name="Amphoe_Name"
                          value={this.state.Amphoe_Name}
                          onChange={this.handleUserInput}
                          disabled={true}
                        // onChange={(e) =>
                        //   this.setState({ Member_Soi: e.target.value })
                        // }
                        />

                      </div>
                      <div className="col-md-4">
                        <label>แขวง/ตำบล <span><font color="red"> * </font></span></label>
                        <input
                          type="text"
                          name="Tambon_Name"
                          value={this.state.Tambon_Name}
                          onChange={this.handleUserInput}
                          disabled={true}
                        // onChange={(e) =>
                        //   this.setState({ Member_Soi: e.target.value })
                        // }
                        />
                      </div>

                    </div>
                  </div>

               </div>
            </div>

          <div  style={{ display: 'none' }}> 
            <div className="box-profile">             
              <div className="row">
              <div class="col-md-12">
                <div class="utf-section-headline-item centered margin-bottom-0 margin-top-20">
                  <h3 class="headline"> การแสดงความยินยอม / PDPA</h3><p>&nbsp;</p>
                </div>
              </div>
              <br />
            </div>

             <div className="row"> 
                <div className="col-md-12">
                  <div className="col-md-12">
                    <div className="col-md-3"><label>การแสดงความยินยอม :</label></div>
                    <div className="col-md-9">
                      <div className="checkbox margin-top-0">
                        <input type="checkbox" id="two-step" />
                        <label htmlFor="two-step">
                          <span className="checkbox-icon" /> ต้องการรับข่าวสารออนไลน์ผ่านทางอีเมล์เกี่ยวกับข่าวสารของ Clickthaihome และพันธมิตรทางธุรกิจของเราข้อมูลอัพเดทต่างๆ ข้อเสนอที่น่าสนใจและคุณยินยอมตาม <a herf="#" target="_blank">ข้อกำหนดและนโยบายข้อมูล</a> ของเรา
                                </label>
                      </div>
                    </div>
 
                  </div>
                </div>
                </div>   
              
            </div>
            </div>

            <div className="col-md-12 col-sm-12">
                <center>
                  <a href="profile-edit">
                    <button className="margin-top-30 button"><i className="fa fa-edit" aria-hidden="true" />  แก้ไขข้อมูลส่วนตัว </button>
                  </a>
                </center>

                <br />
              </div>

            </div>

        </div>

      </div>



    </div>);
  }
}

export default UserProfile;


