import { combineReducers } from "redux";
import appReducer from "./app.reducer";
import homeReducer from "./home.reducer";
import searchboxReducer from './searchbox.reducer';


export default combineReducers({
    appReducer,
    homeReducer,
    searchboxReducer
});
