import React from 'react';
import ReactGA from 'react-ga';
export const ConvDate_format = function(pdate, lnq, symbol) {
    let _date = pdate.split(' ')[0]
    let msec = Date.parse(_date.split('/')[1] + '/' + _date.split('/')[0]+ '/' + _date.split('/')[2]);
    let date = new Date(msec);
    let pyears = date.getFullYear();
    let pmonth = String(date.getMonth() + 1).padStart(2, '0');
    let pdays = String(date.getDate()).padStart(2, '0');
    let reqdate = ''
    
    // --- ### Change Years 
    if(lnq == 'TH'){
        if(pyears < 2300){
            reqdate = pyears + 543;
        }else{
            reqdate = pyears;
        }
    }else{
        if(pyears > 2300){
            reqdate = pyears - 543;
        }else{
            reqdate = pyears;
        }
    }

    // --- ### Chang Symbol 
    if(symbol == '/'){
        reqdate = pdays + '/' + pmonth + '/' + reqdate
    }else{
        reqdate = reqdate + '-' + pmonth + '-' + pdays
    }
  
    return reqdate;
  }

export const currencyFormat =  function (num){    
    let pnum  = parseInt(Number(num) ? num : 0)  
    try {
        return  pnum.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') 
    } catch (error) {
        return 0;
    }    
}


export const ConvuserDate_format = function(pdate, lnq, symbol) {
    let _date = pdate.split('T')[0]
    // let msec = Date.parse(_date.split('/')[1] + '/' + _date.split('/')[0]+ '/' + _date.split('/')[2]);
    let date = new Date(_date);
    let pyears = date.getFullYear();
    let pmonth = String(date.getMonth() + 1).padStart(2, '0');
    let pdays = String(date.getDate()).padStart(2, '0');
    let reqdate = ''
    // --- ### Change Years 
    if(lnq == 'TH'){
        if(pyears < 2300){
            reqdate = pyears + 543;
        }else{
            reqdate = pyears;
        }
    }else{
        if(pyears > 2300){
            reqdate = pyears - 543;
        }else{
            reqdate = pyears;
        }
    }

    // --- ### Chang Symbol 
    if(symbol == '/'){
        reqdate = pdays + '/' + pmonth + '/' + reqdate
    }else{
        reqdate = reqdate + '-' + pmonth + '-' + pdays
    }
  
    return reqdate;
  }

  export const useGAEventTracker = (catagory = 'Exvent Catagory') => {
      const trackEvent = ({action = 'action', label= 'label'}) => {
            ReactGA.event(catagory, action, label);
      }

      return trackEvent
  }