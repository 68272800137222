import React, { Component} from "react";
import SearchComponents from './../searchbox/searchbox';

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import './../home/home.css';
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import NumberFormat from "react-number-format";
import Pagination from "react-js-pagination";
import queryString from 'query-string';

import {encrytion} from './../../utils/EnCryption'
import './listings.css'

import * as $ from 'jquery'
import ReactTooltip from "react-tooltip";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import Loader from "react-loader-spinner";
import {currencyFormat } from "./../default-class/default-class";



const initialState = {
  // listsAll: null,
  listsCount: null,
  activePage: 1,
  searchbox: null,
  listingsLists: null,
  // sortBy: 'date',
  breadcrumb: null,
  loading: true,
  noitems: false,
}

class Listings extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.myRef = React.createRef();
  }

  async componentDidMount() {
 
    this.setState({searchbox: <SearchComponents/>})
    let url = window.location.search
    let params = queryString.parse(url);
        params = {...params, ...{Member_ID: localStorage.getItem("Member_ID") ? localStorage.getItem("Member_ID"): '0'}}
    let result = await httpClient.post(server.LISTINGS_URL, params)
 
    this.setState({activePage: params['page'] != '' && Number.isInteger(parseInt(params['page'])) ? parseInt(params['page']) : 1,
                   listsCount: result._length[0]._sum, 
                   sortBy: params['sort'],
                   noitems: result._length[0]._sum == 0 ? true : false
                  });
    let lists = await this.reanderListings(result.assets)
    this.setState({listingsLists: lists, loading: false})
    console.log(localStorage.getItem("selecttype"));
//  console.log(result)
  } 

  async reanderListings(data){
    
     


    return (
      data!= null &&
      data.map(item => (
        <div key={item.asset_ID + item.datafrom} className="col-md-3 col-sm-6 col-xs-12" > 
          <div className="utf-listing-item" > 
            <a href={"/asset?p=" + encrytion(item.datafrom + '/' + item.asset_ID)} className="utf-smt-listing-img-container" style={{height: 250}} target="_blank">
              <div className="utf-listing-badges-item"> <span className="for-rent">{item.action}</span> </div>
              <div className="utf-listing-img-content-item">
            
                <img className="utf-user-picture" 
                src={item.cmp_logo}
                lazyload="true" />
             
                  <span className={item.Bookmark == 1 ? "like-icon with-tip liked": 'like-icon with-tip'} data-tip-content="Bookmark" onClick={async e => {
                      e.preventDefault();
                      if(localStorage.getItem("Member_ID")){
                        let liked = ''
                        if(e.target.className == "like-icon with-tip"){
                          //like
                          liked = true
                        } else {
                          //unlike
                          liked = false
                        }

                        let values = {Member_ID: localStorage.getItem("Member_ID"), 
                          Asset_ID: item.asset_ID,
                          Data_From: item.datafrom,
                          Liked: liked
                        }
                        let result = await httpClient.post(server.ASSET_URL + '/bookmarks', values)
                        if(result.status == 'ok'){
                          if(e.target.className == "like-icon with-tip"){
                            e.target.className = "like-icon with-tip liked"
                          } else {
                            e.target.className = "like-icon with-tip"
                          }
                        } else if(result.status == 'nok' && result.message == 'Bookmarks Field, Admin not approve.'){
                          alert('กรุณากรอกข้อมูลพื้นฐานให้ครบและรอให้ผู้ดูแลระบบอนุมัติสมาชิก เพื่อใช้ฟังก์ชั่นนี้ \n\n คำอธิบายเกี่ยวกับ  Bookmark \n 1. หากท่านกรอกข้อมูลพื้นฐานไม่ครบ จะทำการ เลือกติดตามได้ 1 โครงการ \n 2. เมื่อท่านกรอกข้อมูลพื้นฐานตามระบบกำหนด จะสามารถเลือกติดตามได้หลายโครงการ')
                          /*
                          คำอธิบายเกี่ยวกับ  Bookmark 
                            1. หากท่านกรอกข้อมูลพื้นฐานไม่ครบ จะทำการ เลือกติดตามได้ 1 โครงการ  
                            2. เมื่อท่านกรอกข้อมูลพื้นฐานตามระบบกำหนด จะสามารถเลือกติดตามได้หลายโครงการ
                          */
                        }
                        else if(result.status == 'nok' && result.message == 'Bookmarks Field, Please contact admin.'){
                          alert('สถานะการใช้งานของท่านถูกยกเลิก กรุณาติดต่อผู้ดูและระบบ')
                        }
                      } else{
                        alert('กรุณาเข้าสู่ระบบเพื่อใช้งานฟังก์ชั่นนี้')
                      }
                }}/> 
                {item.youtube_url != ''? <span className="video-button with-tip" data-tip-content="Video" />: null}
              </div>
              <center>
                <img src={item.image_name} alt={item.asset_project + ' ' + item.location + ' ราคา ' + item.asset_Price + ' บาท'} 
                   loading="lazy" style={{height: 250, width: 'auto'}} /> 
              </center>
              </a>
            <div className="utf-listing-content">
              <div className="utf-listing-title">
                  {/* {
                    item.asset_Price > 0 ? 
                    <NumberFormat
                            className="utf-listing-price"
                            value={item.asset_Price}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value, props) => <span {...props}>{value} บาท</span>}
                    />
                  : 
                  <span className="utf-listing-price">โปรดติดต่อหน่วยงาน</span>
                  } */}
                     <h4 className="text-warp">
                            <a href={"/asset?p=" +
                                encrytion(item.datafrom + '/' + item.asset_ID)}>
                              {item.asset_project}
                            </a>
                      </h4>             
                   <span className="utf-listing-address text-warp">
                            <i className="icon-material-outline-location-on" />{" "}
                            {item.location}
                  </span> 
 
              </div>
              <div className="utf-listing-title">
                         <h4 className="text-warp">
                            <a
                              href={
                                "/asset?p=" +
                                encrytion(item.datafrom + "/" + item.Asset_ID)
                              }
                            >
                              {item.Asset_Project}
                            </a>
                          </h4>                         
                          <span className="txt_refcode">
                          รหัสหน่วยงาน: {item.Ref_code ? item.Ref_code : '-'}
                          
                          </span>
                          <span style={{display: 'flex', marginTop: '-25px',marginRight:10}}>
                              <i className="fa fa-eye" style={{marginLeft: 'auto'}}>
                                <font style={{fontSize: '10pt', fontFamily: 'Kanit-Light'}}> 
                               {"  "} {currencyFormat(item.Hit_Point ? item.Hit_Point: 0)}  
                                </font>
                              </i>
                            </span>
                          <span >                            
                            <div>
                                <div className="row2">
                              <div className="column" >
                              <i className="fa fa-bed" data-toggle="tooltip"  data-placement="top"
                              title="ห้องนอน"/> 
                            <span> {item.number_bed_room}</span> 
                                
                              </div>
                              <div className="column">
                              <i className="fa fa-bath" data-toggle="tooltip"  data-placement="top"
                              title="ห้องน้ำ"/> 
                            <span> {item.number_bath_room}</span> 
                               
                              </div>
                              <div className="column" >
                              <i className="icon-line-awesome-arrows" data-toggle="tooltip"  data-placement="top"
                              title="พท.ใช้สอย"/> 
                            <span> { currencyFormat(item.Asset_Usage_Area)} </span>
                              </div>
                            </div>
 
                           </div>
                          </span>
                        </div>    

                        <div className="blockprice">                
                      <div>
                        <span className="lbprice" style={{float: 'left'}}>ราคาตั้งขาย</span> 
                        {(item.Promotion_price > 0 ?  
                          <span className="lbpromo" style={{float: 'right'}}>ราคาพิเศษ</span>  : "")}                         
                      </div>
                      <br/>
                     <div>                                          
                       <span className={item.Promotion_price > 0 ? "txtpricelow": 'txtprice'} >
                         <span className={item.Promotion_price > 0 ? "strikethrough": ''}> 
                         {(item.asset_Price > 0 ?  currencyFormat(item.asset_Price) +""+" บาท" : "โปรดติดต่อหน่วยงาน")} 
                         </span>
                       </span>
                       <span className="txtpromotion">  
                       {(item.Promotion_price > 0 ?  currencyFormat(item.Promotion_price) +""+" บาท" : "")} 
                       </span>
                       </div>
                  </div>  
              <div className="utf-listing-user-info">
                          <a href="#">
                            <i className="icon-line-awesome-user text-warp" />{" "}
                            {item.MemberName}
                          </a> 
                          <span>{item.Update_Date}</span>
              </div>           
            </div>
          </div>
          
        </div>

        

      
      ))

      
    )
  }




  render() {
    return (<div>
        {/* Titlebar */}
        <div className="parallax titlebar" lazyload="true" data-background="images/login.jpg" data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>รายการทรัพย์ทั้งหมด</h2>
                  <nav id="breadcrumbs">
                    <ul>
                      <li><a href="home">หน้าแรก</a></li>
                      <li>รายการทรัพย์</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container"  >
        <div className="row sticky-wrapper" ref={this.myRef}>
      
      {/* <SearchComponents/> */}
      {this.state.searchbox}

      </div>
        </div>

      <div className="container">
        <div className="row sticky-wrapper" >

  
                
          {/* Sorting */}
          <div className ="container"> 
          <div className="row">
            <div className="col-md-12" >
              <div className="widget utf-sidebar-widget-item"> 
                <div className="utf-boxed-list-headline-item">
                  
                  <br />
                  <h3 style={{fontFamily: 'Kanit-Light'}}>จำนวนทรัพย์ที่พบ  <span style={{color: 'red'}}> {this.state.listsCount ? <NumberFormat
                                  className="utf-listing-price"
                                  value={this.state.listsCount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />  : 0}</span> รายการ  
                  </h3>
             

                </div>             
              </div>
            </div>
            {/* <div className="col-md-6" /> */}

            {/* <div className="utf-search-type-block-area text-right margin-top-60" style={{whiteSpace: 'nowrap'}}>
                    <div className="checkbox margin-top-0">
                      <input type="radio" id="t1" name="Approve_Status" value="" defaultChecked onChange={this.handleUserInput}/>
                      <label htmlFor="t1"><span className="checkbox-icon" /> ทั้งหมด</label>
                    </div>
                    <div className="checkbox margin-top-0">
                      <input type="radio" id="t3" name="Approve_Status" value="-3" onChange={this.handleUserInput}/>
                      <label htmlFor="t3"><span className="checkbox-icon" /> มุมบ้านใหม่</label>
                    </div>

            </div> */}
          </div>
          </div>

          {/* <div className="row">
            <div className="col-md-12">
              <span className="pull-left">
                <nav>
                  <ul className="nbreadcrumb">
                    <li>คอนโด</li>
                    <li>กาญจนบุรี</li>
                    <li>ท่ามะกา</li>
                    <li>แสนตอ</li>
                  </ul>
                </nav> 
              </span>
            </div>
          </div> */}
          <div className="utf-sort-box-aera">
            <div className="sort-by">
              <label>เรียงลำดับจาก:</label>
              <div className="sort-by-select">
                {/* className="utf-chosen-select-single-item" */}
                <select data-placeholder="Default Properties"  value={this.state.sortBy} onChange={async (e)=> {
                  var searchParams = new URLSearchParams(window.location.search);
                  searchParams.set("sort", e.target.value);
                  window.location.search = searchParams.toString();
                }}>
                  <option value={'date'}>ใหม่ล่าสุด</option> 
                  <option value={'p_min'}>ราคาต่ำสุด</option>
                  <option value={'p_max'}>ราคาสูงสุด</option>
                </select>
              </div>
            </div>
          </div>

        </div>
      </div>



      {/* Content */}
      <div className="container">
        <div className="row sticky-wrapper">
          <div className="col-md-12" > 
            <div className="row margin-bottom-30 griad-lyout" >
            <div className="no-items">
              <center>
                {/* <Loader
                  type="ThreeDots"
                  color="#e33324"
                  height={100}
                  width={100}
                  visible={this.state.loading}
                /> */}
              </center>

              <div hidden={!this.state.noitems}>
                <center >
                  <i className="fa fa-home fa-5x" aria-hidden="true" style={{color: '#e33324'}}/>
                  <h3 style={{color: '#e33324'}} className="Kanit-light">ไม่พบข้อมูล</h3>
                </center>
              </div>
            </div>
            
              {this.state.listingsLists}
            </div>
            <div className="row">

              {/* <div> */}
              <div className="col-md-12" style={{display: this.state.listsCount < 13 ? 'none' : 'block'}} >
                <nav className="pagination" >
                  <ul>
                    <Pagination
                      // className="backtotop"
                      activePage={this.state.activePage}
                      itemsCountPerPage={12}
                      totalItemsCount={this.state.listsCount ? this.state.listsCount: 0}
                      pageRangeDisplayed={5}
                      // onChange={this.handlePageChange.bind(this)}
                      onChange={pageNumber => {
                        var searchParams = new URLSearchParams(window.location.search);
                        searchParams.set("page", pageNumber);
                        window.location.search = searchParams.toString();
                      }}
                      activeLinkClass="current-page"
                      hideFirstLastPages={true}
                      hideDisabled={true}
                    />
                </ul>
                </nav>  
              </div>

            </div>
          </div>      
        </div>
      </div>

  

      
          {/* Ads */}
          <section className="fullwidth" data-background-color="#ffffff">
            <div className="container">
              <div className="row">

                <div className="col-md-6 col-sm-6" > 

                  <OwlCarousel autoplay={true} className="owl-theme"  loop margin={30} nav={false} lazyLoad={true} smartSpeed={500} items={1} dots={false}>
                    <div className="item">
                        <div ><img className="utf-user-picture" src="images/banner/banner_1.png"  /></div>
                    </div>
                    <div className="item">
                        <div ><img src="images/banner/banner_2.png"  /></div>
                    </div>
                    <div className="item">
                        <div ><img src="images/banner/banner_3.png" /></div>
                    </div>
                   
                  </OwlCarousel>
                </div>
                <div className="col-md-6 col-sm-6">  
                  <div className="row">
                    <div className="col-md-12 col-sm-6 ">		
                    <OwlCarousel autoplay={true} className="owl-theme"  loop margin={30} nav={false} lazyLoad={true} items={1} dots={false}>
                    <div className="item">
                        <div ><img className="utf-user-picture" src="images/banner/banner_3.png" /></div>
                    </div>
                    </OwlCarousel>
                    </div>	
                  </div>
                </div>
              </div></div></section>



    </div>);
  }
}

export default Listings;
