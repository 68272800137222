import React, { Component } from "react";
import './tab-component.css' 
import Tabcontent from './tab-content'; 

class TabComponent extends Component {
 
  render() {
    return (
      <div>     
      <div id="wrapper">
          <div className="container">
        <Tabcontent/>
        </div>
      </div>
      </div>
    );
  }
}

export default TabComponent;
