import React, { Component } from "react";
import UserMenu from "./../user-menu/user-menu";
import { httpClient } from "../../utils/HttpClient";
import { server, imageUrl } from "../../constants";

import province from "./../default_files/province.json";
import amphur from "./../default_files/amphur.json";
import tumbon from "./../default_files/tumbon.json";
import title from "./../default_files/title.json";
import personal_type from "./../default_files/personal_type.json";
import member_type from "./../default_files/member_type.json";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import './user-profile-edit.css'
import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import th from "date-fns/locale/th";

registerLocale("th", th);
const MySwal = withReactContent(Swal)

class UserProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provincesOptions: <option value={""}>จังหวัด</option>,
      // provinceSelceted: params.p,

      amphoesLists: null,
      amphoeOptions: <option value={""}>อำเภอ/เขต</option>,
      // amphoeSelected: params.d,

      tumbonsLists: null,
      tumbonOptions: <option value={""}>ตำบล/แขวง</option>,
      // tumbonSelected: params.sd

      titleOptions: <option value={""}>คำนำหน้า</option>,
      // titleSelected: "",

      personalTypeOptions: <option value={""}>ประเภทบุคคล</option>,

      memberTypeOptions: <option value={""}>ความต้องการ</option>,
      Member_Picture_Name: null,

      Member_Title_Valid: false,
      Member_First_Name_Valid: false,
      Member_Second_Name_Valid: false,
      Member_Personal_Type_Valid: false,
      Member_Licensel_ID_Valid: false,
      Current_Member_Type_Valid: false,
      provinceSelceted_Valid: false,
      amphoeSelected_Valid: false,
      tumbonSelected_Valid: false,
      pdate: new Date(),

      formValid: false,
      formErrors: {Member_Title: '', 
                   Member_First_Name: '', 
                   Member_Second_Name: '', 
                   Member_Personal_Type: '', 
                   Member_Licensel_ID: '', 
                   Current_Member_Type: '', 
                   provinceSelceted: '', 
                   amphoeSelected: '', 
                   tumbonSelected: ''},
    };
  }

  async componentDidMount() {
    document.title = "แก้ไขข้อมูลส่วนตัว | Clickthaihome";

    let result = await httpClient.post(server.MEMBER_URL + "/getprofile", {
      id: localStorage.getItem("Member_ID"),
    });
    await this.setState(result.data);

    let noselect = [{ CODE: "", NAME: "จังหวัด" }];
    let provinceRows = await this.renderOptions([...noselect, ...province]);

    await this.setState({ provincesOptions: provinceRows, 
                          amphoesLists: amphur, 
                          tumbonsLists: tumbon, 
                          provinceSelceted: result.data.Member_Province,
                          amphoeSelected: result.data.Member_Amphoe,
                          tumbonSelected: result.data.Member_Tambon, 
                          Member_Picture:  result.data.Member_Picture ? imageUrl + '/' + result.data.Member_Picture : 'images/agent-02.jpg'
    });

    // titleOptions
    noselect = [{ CODE: "", NAME: "คำนำหน้า" }];
    await this.setState({
      titleOptions: await this.renderOptions([...noselect, ...title]),
    });

    noselect = [{ CODE: "", NAME: "ประเภทบุคคล" }];
    await this.setState({
      personalTypeOptions: await this.renderOptions([
        ...noselect,
        ...personal_type,
      ]),
    });

    noselect = [{ CODE: "", NAME: "ความต้องการ" }];
    await this.setState({
      memberTypeOptions: await this.renderOptions([
        ...noselect,
        ...member_type,
      ]),
    });

    noselect = [{ CODE: "", NAME: "อำเภอ/เขต" }];
    let amphoes = await this.state.amphoesLists.filter(this.findAmphoe);
    this.setState({
      amphoeOptions: await this.renderOptions([...noselect, ...amphoes]),
    });

    noselect = [{ CODE: "", NAME: "ตำบล/แขวง" }];
    let tumbons = await this.state.tumbonsLists.filter(this.findTumbon);
    await this.setState({
      tumbonOptions: await this.renderOptions([...noselect, ...tumbons]),
    });


    //check valid before edit profile  
    this.checkValid()
  }


  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
                  () => { this.ValidateField(name, value) });
  }

  async ValidateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let Member_Title_Valid = this.state.Member_Title_Valid;
    let Member_First_Name_Valid = this.state.Member_First_Name_Valid;
    let Member_Second_Name_Valid = this.state.Member_Second_Name_Valid;
    let Member_Personal_Type_Valid = this.state.Member_Personal_Type_Valid;
    let Member_Licensel_ID_Valid = this.state.Member_Licensel_ID_Valid;
    let Current_Member_Type_Valid = this.state.Current_Member_Type_Valid;
    let provinceSelceted_Valid = this.state.provinceSelceted_Valid;
    let amphoeSelected_Valid = this.state.amphoeSelected_Valid;
    let tumbonSelected_Valid = this.state.tumbonSelected_Valid;

    switch(fieldName) {
      case 'Member_Title': 
        Member_Title_Valid = value != '' && value != 0 ;
        fieldValidationErrors.Member_Title = Member_Title_Valid ? '' : 'โปรดระบุคำนำหน้า';
        break;
      case 'Member_First_Name':
        Member_First_Name_Valid = value.length > 3  ;
        fieldValidationErrors.Member_First_Name = Member_First_Name_Valid ? '' : 'โปรดระบุชื่อ';
        break;
      case 'Member_Second_Name':
        Member_Second_Name_Valid = value.length > 3  ;
        fieldValidationErrors.Member_Second_Name = Member_Second_Name_Valid ? '' : 'โปรดระบุนามสกุล';
        break;
      case 'Member_Personal_Type': 
        Member_Personal_Type_Valid = value != '' && value != 0 ;
        fieldValidationErrors.Member_Personal_Type = Member_Personal_Type_Valid ? '' : 'โปรดระบุประเภทบุคคล';
        break;
      case 'Member_Licensel_ID':
        Member_Licensel_ID_Valid = value.length == 13  ;
        fieldValidationErrors.Member_Licensel_ID = Member_Licensel_ID_Valid ? '' : 'โปรดระบุให้ถูกต้อง';
        break;
      case 'Current_Member_Type': 
        Current_Member_Type_Valid = value != '' && value != 0 ;
        fieldValidationErrors.Current_Member_Type = Current_Member_Type_Valid ? '' : 'โปรดระบุความต้องการ';
        break;
      case 'provinceSelceted': 
        provinceSelceted_Valid = value != '' && value != 0 ;
        fieldValidationErrors.provinceSelceted = provinceSelceted_Valid ? '' : 'โปรดระบุจังหวัด';
        break;
      case 'amphoeSelected': 
        amphoeSelected_Valid = value != '' && value != 0 ;
        fieldValidationErrors.amphoeSelected = amphoeSelected_Valid ? '' : 'โปรดระบุอำเภอ';
        break;
      case 'tumbonSelected': 
        tumbonSelected_Valid = value != '' && value != 0 ;
        fieldValidationErrors.tumbonSelected = tumbonSelected_Valid ? '' : 'โปรดระบุตำบล';
        break;

      default:
        break;
    }

    await this.setState({formErrors: fieldValidationErrors,
                   Member_Title_Valid: Member_Title_Valid,
                   Member_First_Name_Valid: Member_First_Name_Valid,
                   Member_Second_Name_Valid: Member_Second_Name_Valid,
                   Member_Personal_Type_Valid: Member_Personal_Type_Valid,
                   Member_Licensel_ID_Valid: Member_Licensel_ID_Valid,
                   Current_Member_Type_Valid: Current_Member_Type_Valid,
                   provinceSelceted_Valid: provinceSelceted_Valid,
                   amphoeSelected_Valid: amphoeSelected_Valid,
                   tumbonSelected_Valid: tumbonSelected_Valid
                  }, await this.ValidateForm);
  }

  ValidateForm() {
    this.setState({formValid: this.state.Member_Title_Valid  
                              && this.state.Member_First_Name_Valid  
                              && this.state.Member_Second_Name_Valid  
                              && this.state.Member_Personal_Type_Valid
                              && this.state.Member_Licensel_ID_Valid
                              && this.state.Current_Member_Type_Valid
                              && this.state.provinceSelceted_Valid
                              && this.state.amphoeSelected_Valid
                              && this.state.tumbonSelected_Valid
                            });
  }

  
  async checkValid(){
    await this.ValidateField('Member_Title', this.state.Member_Title)
    await this.ValidateField('Member_First_Name', this.state.Member_First_Name ? this.state.Member_First_Name : '')
    await this.ValidateField('Member_Second_Name', this.state.Member_Second_Name ? this.state.Member_Second_Name : '')
    await this.ValidateField('Member_Personal_Type', this.state.Member_Personal_Type)
    await this.ValidateField('Member_Licensel_ID', this.state.Member_Licensel_ID ? this.state.Member_Licensel_ID : '')
    await this.ValidateField('Current_Member_Type', this.state.Current_Member_Type)
    await this.ValidateField('provinceSelceted', this.state.provinceSelceted)
    await this.ValidateField('amphoeSelected', this.state.amphoeSelected)
    await this.ValidateField('tumbonSelected', this.state.tumbonSelected)
  }

  findAmphoe = (item) => {
    if (
      Number.isFinite(item.P_CODE) &&
      item.P_CODE == this.state.provinceSelceted
    ) {
      return true;
    }
  };

  findTumbon = (item) => {
    if (
      Number.isFinite(item.P_CODE) &&
      item.P_CODE == this.state.provinceSelceted &&
      item.A_CODE == this.state.amphoeSelected
    ) {
      return true;
    }
  };

  renderAssetType = async (data) => {
    try {
      return (
        data != null &&
        (await data.map((item) => (
          <option key={item.ID} value={item.ID}>
            {item.NAME}
          </option>
        )))
      );
    } catch (error) {
      // console.log(error);
    }
  };

  renderOptions = async (data) => {
    try {
      return (
        data != null &&
        (await data.map((item) => (
          <option key={item.CODE} value={item.CODE}>
            {item.NAME}
          </option>
        )))
      );
    } catch (error) {
      // console.log(error);
    }
  };


  showPreviewImage = values => {
    if (values.file_obj) {
      return <img src={values.file_obj} style={{ height: 100 }} />;
    }else if (values.image){
      return <img src={`${imageUrl}/images/${values.image}`} style={{ height: 100 }} />
    }
  };



  save = async() => {

    let vals = {
      Member_ID: localStorage.getItem("Member_ID"),
      Member_Title: this.state.Member_Title,
      Member_First_Name: this.state.Member_First_Name,
      Member_Second_Name: this.state.Member_Second_Name,
      Member_Phone: this.state.Member_Phone,
      Member_Mobile: this.state.Member_Mobile,
      Member_Web_URL: this.state.Member_Web_URL,
      Current_Member_Type: this.state.Current_Member_Type,
      Member_Personal_Type: this.state.Member_Personal_Type,
      Member_Licensel_ID: this.state.Member_Licensel_ID,
      Member_License_StartDate: this.state.Member_License_StartDate,
      Member_Monthly_Income: this.state.Member_Monthly_Income,
      Member_Address_No: this.state.Member_Address_No,
      Member_Moo: this.state.Member_Moo,
      Member_Road: this.state.Member_Road,
      Member_Soi: this.state.Member_Soi,
      Member_Tambon: this.state.tumbonSelected,
      Member_Amphoe: this.state.amphoeSelected,
      Member_Province: this.state.provinceSelceted,
      Member_Picture: [ JSON.stringify(this.state.Member_Picture) ], 
      Member_Picture_Name: [ this.state.Member_Picture_Name ],
    }
 
    let result = await httpClient.put(server.MEMBER_URL + '/editprofile', vals)

    if(result.result == 'ok'){
      Swal.fire({
        title: 'สำเร็จ',
        icon: 'success',
        confirmButtonText: 'ตกลง',
      }).then((result) => {
        if (result.isConfirmed) {
          // localStorage.setItem('Member_Picture', [ JSON.stringify(this.state.Member_Picture) ])
          localStorage.setItem('Member_Picture', "images/"+ this.state.Member_Picture_Name)
          this.props.history.push('/profile')
          window.location.reload(false)
        } 
      })
    } else {
      Swal.fire({
        title: 'ผิดพลาด',
        icon: 'error',
        confirmButtonText: 'ปิด',
      })
    }
  }


  render() {
    return (
      <div>
        <div
          className="parallax titlebar"
          data-background="images/visual_banner_desktop_2.jpg"
          data-color="rgba(48, 48, 48, 1)"
          data-color-opacity="0.8"
          data-img-width={800}
          data-img-height={505}
        >
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>ข้อมูลของฉัน</h2>
                  {/* Breadcrumbs */}
                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="home">หน้าแรก</a>
                      </li>
                      <li>แก้ไขข้อมูลส่วนตัว</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <UserMenu />
            </div>



            <div className="col-md-9">
              
              <div className="utf-user-profile-item">
                
               <div className="row">
               <div class="col-md-12">
                    <div class="utf-section-headline-item centered margin-bottom-0 margin-top-20">
                    <h3 class="headline">ข้อมูลของฉัน</h3><p class="utf-slogan-text">กรุณากรอกข้อมูลพื้นฐานตามความเป็นจริง</p>
                     </div>
                  </div>
               </div>
            
                 
                <div className="utf-submit-page-inner-box">
                  
        

                  <div className="content with-padding">

                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-4">
                          <label>คำนำหน้า <span><font color="red"> * </font></span></label>
                          <select
                            name="Member_Title"
                            data-placeholder="Title"
                            value={this.state.Member_Title}
                            onChange={this.handleUserInput}
                            // onChange={(e) => {
                            //   this.setState({ Member_Title: e.target.value });
                            // }}
                          >
                            {this.state.titleOptions}
                          </select>
                          {
                            this.state.formErrors.Member_Title != '' ? <p className="txtValidat"> {this.state.formErrors.Member_Title} </p>  : null
                          }
                        </div>
                        <div className="col-md-4">
                          <label>ชื่อ <span><font color="red"> * </font></span></label>
                          <input
                            type="text"
                            name="Member_First_Name"
                            value={this.state.Member_First_Name}
                            onChange={this.handleUserInput}
                            // onChange={(e) =>
                            //   this.setState({ Member_First_Name: e.target.value })
                            // }
                          />
                          {
                            this.state.formErrors.Member_First_Name != '' ? <p className="txtValidat"> {this.state.formErrors.Member_First_Name} </p>  : null
                          }
                        </div>
                        <div className="col-md-4">
                        <label>นามสกุล <span><font color="red"> * </font></span></label>
                        <input
                          type="text"
                          name="Member_Second_Name"
                          value={this.state.Member_Second_Name}
                          onChange={this.handleUserInput}
                          // onChange={(e) =>
                          //   this.setState({
                          //     Member_Second_Name: e.target.value,
                          //   })
                          // }
                        />
                        {
                            this.state.formErrors.Member_Second_Name != '' ? <p className="txtValidat"> {this.state.formErrors.Member_Second_Name} </p>  : null
                          }
                      </div>
                      </div>
                      
                      <div className="row">
                        <div className="col-md-4">
                          <label>เบอร์โทรศัพท์</label>
                          <input
                            type="text"
                            name="Member_Phone"
                            value={this.state.Member_Phone}
                            onChange={this.handleUserInput}
                            // onChange={(e) =>
                            //   this.setState({ Member_Phone: e.target.value })
                            // }
                          />
                        </div>
                        <div className="col-md-4">
                          <label>เบอร์โทรศัพท์มือถือ</label>
                          <input
                            type="text"
                            name="Member_Mobile"
                            value={this.state.Member_Mobile}
                            // onChange={(e) =>
                            //   this.setState({ Member_Mobile: e.target.value })
                            // }
                            onChange={this.handleUserInput}
                          />
                        </div>
                        <div className="col-md-4">
                          <label>Email (แก้ไขไม่ได้)</label>
                          <input
                            type="text"
                            value={this.state.Member_Email}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <label>ประเภทบุคคล <span><font color="red"> * </font></span></label>
                          <select
                            data-placeholder="Personal Type"
                            name="Member_Personal_Type"
                            value={this.state.Member_Personal_Type}
                            onChange={this.handleUserInput}
                            // onChange={(e) => {
                            //   this.setState({
                            //     Member_Personal_Type: e.target.value,
                            //   });
                            // }}
                          >
                            {this.state.personalTypeOptions}
                          </select>
                          {
                            this.state.formErrors.Member_Personal_Type != '' ? <p className="txtValidat"> {this.state.formErrors.Member_Personal_Type} </p>  : null
                          }
                        </div>
                        <div className="col-md-4">
                          <label>
                            {this.state.Member_Personal_Type != 0 &&
                            this.state.Member_Personal_Type != 1
                              ? "เลขหนังสือจดทะเบียน"
                              : "เลขบัตรประชาชน"} <span><font color="red"> * </font></span>
                          </label>
                          <input type="text" 
                                 name="Member_Licensel_ID"
                                 value={this.state.Member_Licensel_ID} 
                                 onChange={this.handleUserInput}
                                //  onChange={e => this.setState({Member_Licensel_ID: e.target.value})}
                          />
                          {
                            this.state.formErrors.Member_Licensel_ID != '' ? <p className="txtValidat"> {this.state.formErrors.Member_Licensel_ID} </p>  : null
                          }
                        </div>
                        <div className="col-md-4">
                          <label>วันออกบัตร/เลขที่หนังสือ</label>
                          {/* <input type="date" 
                                 name="Member_License_StartDate"
                                 value={this.state.Member_License_StartDate ? this.state.Member_License_StartDate.split('T')[0] : ''} 
                                 onChange={this.handleUserInput}
                                //  onChange={e => this.setState({Member_License_StartDate: e.target.value})}
                                 /> */}
                          <DatePicker 
                            name="Member_License_StartDate"
                            selected={this.state.Member_License_StartDate ? new Date(this.state.Member_License_StartDate) : ""} 
                            placeholder="วันที่สิ้นสุด" 
                            locale="th" 
                            dateFormat="dd-MM-yyyy"
                            onChange={e => {
                              let date = new Date(e);
                              let pyears = date.getFullYear();
                              let pmonth = String(date.getMonth() + 1).padStart(2, '0');
                              let pdays = String(date.getDate()).padStart(2, '0');
                              const values = {target: {name: "Member_License_StartDate", value: pyears +"-" + pmonth + "-" + pdays}}
                              this.handleUserInput(values)
                          }}/>
                        </div>
                      </div> 
                      
                      <div className="row">
                        <div className="col-md-4">
                          <label>Website</label>
                          <input
                            type="text"
                            name="Member_Web_URL"
                            value={this.state.Member_Web_URL}
                            onChange={this.handleUserInput}
                          />
                        </div>
                        <div className="col-md-4">
                          <label>ความต้องการ <span><font color="red"> * </font></span></label>
                          <select
                            data-placeholder="Member Type"
                            name="Current_Member_Type"
                            value={this.state.Current_Member_Type}
                            onChange={this.handleUserInput}
                            // onChange={(e) => {
                            //   this.setState({
                            //     Current_Member_Type: e.target.value,
                            //   });
                            // }}
                          >
                            {this.state.memberTypeOptions}
                          </select>
                          {
                            this.state.formErrors.Current_Member_Type != '' ? <p className="txtValidat"> {this.state.formErrors.Current_Member_Type} </p>  : null
                          }
                        </div>
                        <div className="col-md-4">
                          <label>รายได้ต่อเดือน</label>
                          <div className="select-input">
                            <i className="data-unit" />
                            <input
                              type="number"
                              name="Member_Monthly_Income"
                              placeholder="รายได้ต่อเดือน"
                              data-unit="บาท"
                              style={{ zIndex: '0' }}
                              value={this.state.Member_Monthly_Income}
                              onChange={this.handleUserInput}
                              // onChange={(e) =>
                              //   this.setState({
                              //     Member_Monthly_Income: e.target.value,
                              //   })
                              // }
                            />
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="col-md-3">
                      <div className="utf-edit-profile-photo-area">
                        {" "}
                        <img src={this.state.Member_Picture} />
                        <div className="utf-change-photo-btn-item">
                          <div className="utf-user-photo-upload">
                            {" "}
                            <span>
                              <i className="fa fa-upload" /> Upload Photo
                            </span>
                            <input
                              type="file"
                              accept="image/*"
                              className="upload tooltip top"
                              title="Upload Photo"
                              onChange={async e => {
                                let files = e.target.files;
                                let reader = new FileReader();
                                reader.readAsDataURL(files[0]);

                                reader.onload = (e) => {
                                    this.setState({
                                        Member_Picture: e.target.result,
                                        Member_Picture_Name: files[0].name
                                      })
                                }
                              }}
                            />

                            
                            
                          </div>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                </div>
                <div className="utf-submit-page-inner-box">
           
            
               
                  <div className="content with-padding">
                  <div className="row">
               <div class="col-md-12">
                    <div class="utf-section-headline-item centered margin-bottom-0 margin-top-20">
                    <h3 class="headline"> ที่อยู่บ้าน/ที่พักอาศัย</h3><p class="utf-slogan-text">กรุณากรอกข้อมูลพื้นฐานตามความเป็นจริง</p>
                     </div>
                  </div>
               </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-2">
                          <label>เลขที่</label>
                          <div>
                            <input
                              type="text"
                              name="Member_Address_No"
                              value={this.state.Member_Address_No}
                              onChange={this.handleUserInput}
                              // onChange={(e) =>
                              //   this.setState({ Member_Address_No: e.target.value })
                              // }
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <label>หมู่</label>
                          <div>
                            <input
                              type="text"
                              name="Member_Moo"
                              value={this.state.Member_Moo}
                              onChange={this.handleUserInput}
                              // onChange={(e) =>
                              //   this.setState({ Member_Moo: e.target.value })
                              // }
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label>ถนน</label>
                          <div>
                            <input
                              type="text"
                              name="Member_Road"
                              value={this.state.Member_Road}
                              onChange={this.handleUserInput}
                              // onChange={(e) =>
                              //   this.setState({ Member_Road: e.target.value })
                              // }
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label>ซอย</label>
                          <div>
                            <input
                              type="text"
                              name="Member_Soi"
                              value={this.state.Member_Soi}
                              onChange={this.handleUserInput}
                              // onChange={(e) =>
                              //   this.setState({ Member_Soi: e.target.value })
                              // }
                            />
                          </div>
                        </div>

                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <label>จังหวัด <span><font color="red"> * </font></span></label>
                          <select
                            name="provinceSelceted"
                            data-placeholder="Province"
                            value={this.state.provinceSelceted}
                            onChange={async (e) => {
                              await this.setState({
                                provinceSelceted: e.target.value,
                                amphoeSelected: "",
                                tumbonSelected: "",
                                tumbonOptions: (
                                  <option value={""}>ตำบล/แขวง</option>
                                ),
                              }, async () => { 
                                        await this.ValidateField('provinceSelceted', e.target.value)
                                        await this.ValidateField('amphoeSelected', "")
                                        await this.ValidateField('tumbonSelected', "")
                                      });

                              let amphoes = await this.state.amphoesLists.filter(
                                this.findAmphoe
                              );
                              let noselect = [{ CODE: "", NAME: "อำเภอ/เขต" }];
                              this.setState({
                                amphoeOptions: await this.renderOptions([
                                  ...noselect,
                                  ...amphoes,
                                ]),
                              });
                            }}
                          >
                            {this.state.provincesOptions}
                          </select>
                          {
                            this.state.formErrors.provinceSelceted != '' ? <p className="txtValidat"> {this.state.formErrors.provinceSelceted} </p>  : null
                          }
                        </div>
                        <div className="col-md-4">
                          <label>อำเภอ/เขต <span><font color="red"> * </font></span></label>
                          <select
                            name="amphoeSelected"
                            data-placeholder="District"
                            value={this.state.amphoeSelected}
                            onChange={async (e) => {
                              await this.setState({
                                amphoeSelected: e.target.value
                              }, async () => { 
                                await this.ValidateField('amphoeSelected', e.target.value)
                                await this.ValidateField('tumbonSelected', "")
                              });
                              let tumbons = await this.state.tumbonsLists.filter(
                                this.findTumbon
                              );
                              let noselect = [{ CODE: "", NAME: "ตำบล/แขวง" }];
                              this.setState({
                                tumbonOptions: await this.renderOptions([
                                  ...noselect,
                                  ...tumbons,
                                ]),
                                tumbonSelected: "",
                              });
                            }}
                          >
                            {this.state.amphoeOptions}
                          </select>
                          {
                            this.state.formErrors.amphoeSelected != '' ? <p className="txtValidat"> {this.state.formErrors.amphoeSelected} </p>  : null
                          }
                        </div>
                        <div className="col-md-4">
                          <label>แขวง/ตำบล <span><font color="red"> * </font></span></label>
                          <select
                            name="tumbonSelected"
                            data-placeholder="Sub district"
                            value={this.state.tumbonSelected}
                            // onChange={this.handleUserInput}
                            onChange={async (e) => {
                              this.setState({ tumbonSelected: e.target.value }, 
                                () => { 
                                this.ValidateField('tumbonSelected', e.target.value)
                              });
                            }}
                          >
                            {this.state.tumbonOptions}
                          </select>
                          {
                            this.state.formErrors.tumbonSelected != '' ? <p className="txtValidat"> {this.state.formErrors.tumbonSelected} </p>  : null
                          }
                        </div>
                      
                      </div>
                    </div>

                    
                    
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <center>
                      {" "}
                      <button className="utf-centered-button margin-top-30 button" 
                              // disabled={!this.state.formValid} 
                              onClick={async (e) => {
                                    if(this.state.formValid){
                                      // await this.save()

                                      Swal.fire({
                                        icon: 'warning',
                                        title: 'คุณต้องการแก้ไขข้อมูลส่วนตัวหรือไม่?',
                                        showDenyButton: false,
                                        showCancelButton: true,
                                        confirmButtonText: 'ใช่, ดำเนินการต่อ!',
                                        cancelButtonText: 'ไม่, ยกเลิก!',
                                      }).then(async (result) => {
                                        if (result.isConfirmed) {
                                          await this.save()
                                        } 
                                      })
                                    } else {
                                      await this.checkValid()
                                    }
                                
                                }}>
                        <i className="fa fa-save" aria-hidden="true" /> บันทึก
                      </button>
                    </center>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfileEdit;
