import React, { Component } from "react";
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import {encrytion} from './../../utils/EnCryption';
 
import "./news.css"

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _data: null,
    }
  }
  

  async componentDidMount() {
    let result = await httpClient.get(server.NEWS_URL + "/getNewsCategoty")
    this.setState({_data: await this.renderRows(result)})
    
  }

  async renderRows(result){
    return (
      result.map(item => (


        <div className="col-md-4 col-sm-6 col-xs-6"> 
        <div className="utf-icon-box-item-area"  key={item.id}>
        <a href={"/news_group?g=" + encrytion(item.id)} className="post-img" target="_blank"> 
          <img src={item.pb_img1}   className="blog-img-new" alt={item.pb_title} /> 
          <p>
           <div className="text-warp">{item.pb_title}</div> 
            </p>
          </a>
        </div>
      </div>
      //   <div className="col-md-2" key={item.id}> 
      //   <div className=""> 
      //     <a href={"/news_group?g=" + encrytion(item.id)} className="post-img" target="_blank"> 
      //       <img src={item.pb_img1} 
      //      className="blog-img-new" alt={item.pb_title} /> 
      //     {/* <img src="https://s3-ap-southeast-1.amazonaws.com/o77site/sansiri-condo-hay-huahin-landscape-935x745.jpg"  width="935"  />   */}
      //     </a> 
      //     <div className="utf-post-content-area">
      //       <h3 className="text-warp"><a href={"/news_group?g=" + encrytion(item.id)} target="_blank"> 
      //       <i class="fa fa-newspaper-o" aria-hidden="true"> </i> {item.pb_title}</a></h3>
      //     </div>
      //   </div>
      // </div>
    ))
    )
  }


  render() {
    return (
      <div>   

<section className="fullwidth" data-background-color="#fbfbfb" style={{background: 'rgb(251, 251, 251)'}}> 
  <div className="container">
    <div className="row">
    <div className="col-md-12">	
        <div className="utf-section-headline-item centered margin-bottom-30 margin-top-0">
          <h3 className="headline">  ข่าวอสังหาริมทรัพย์</h3>
          
          <p className="utf-slogan-text">รวมข่าวสารบทความที่น่าสนใจ เกี่ยวกับข้อมูลอสังหาริมทรัพย์</p>
        </div>  
        </div> 
      </div>
    <div className="row">
     
        { this.state._data }

    </div>
  </div>
</section>




{/*   
        <div className="parallax titlebar" data-background="images/Hora/top_banner.png" 
             data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>ข่าวอสังหาริมทรัพย์</h2>
                  <nav id="breadcrumbs">
                    <ul>
                      <li><a href="home">หน้าแรก</a></li>
                      <li>ข่าวอสังหาริมทรัพย์</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        
            <div className="container">  
              <div className="row">
                 
              <div className="utf-section-headline-item centered margin-bottom-30 margin-top-0">
                    <h3 className="headline"><span style={{background: 'none', boxShadow: 'none'}}> &nbsp;</span> ข่าวอสังหาริมทรัพย์</h3>       
                    <p className="utf-slogan-text">รวมข่าวสารบทความที่น่าสนใจ เกี่ยวกับข้อมูลอสังหาริมทรัพย์</p>           
                  </div>    
         
                                  { this.state._data }
               
              </div>
      
           </div>
       */}
      </div>
    );
  }
}

export default News;
