import React, { Component } from 'react';
import UserMenu from './../user-menu/user-menu'
import {encrytion,} from './../../utils/EnCryption';
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import Pagination from "react-js-pagination";
import NumberFormat from "react-number-format";

import province from "./../default_files/province.json";


import './my-properties.css';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)


class MyProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_rows: null,
      back_to_edit:null,
      listsAll: null,
      listsCount: 1,
      activePage: 1,
      provincesOptions: <option value={""}>จังหวัด</option>,
      provinceSelceted: '',
      Asset_Project: '',
      Approve_Status: '',
      // assetLists: null,
    };
  }

  async componentDidMount(){
    document.title = 'ทรัพย์ของฉัน | Clickthaihome.com'

    let noselect = [{ CODE: "", NAME: "จังหวัด" }];
    let provinceRows = await this.renderOptions([...noselect, ...province]);
    await this.setState({provincesOptions: provinceRows})
    this.searchMyAsset()
  }

  async searchMyAsset(){

    let values = {Member_ID: localStorage.getItem('Member_ID'),
                  Asset_Project: this.state.Asset_Project,
                  Approve_Status: this.state.Approve_Status,
                  provinceSelceted: this.state.provinceSelceted
    }
    let result = await httpClient.post(server.ASSET_URL + '/myasset', values)
    await this.setState({listsAll: result.data, listsCount: result.data.length});
    await this.setState({table_rows: await this.renderItems(result.data)})
    await this.setState({ back_to_edit: await this.renderItems(result.back_to_edit) })
 
    this.handlePageChange(1)

    // {console.log(result.data)}
  }

  renderItems = async (data) => {
    return (
      data!= null &&
      data.map(item => (
                  <tr key={item.Asset_ID}>
                    <td className="utf-title-container">
                    <div className="margin-right-30 text-center" style={{maxHeight: 100, height: 100, maxWidth: 100, width: 200}}>
                        <center>
                          <img src={item.image_name} style={{height: 100, width: 'auto'}}/>
                        </center>
                      </div> 
                      <div className="title">
                        <h4><a href={"/asset?p=" + encrytion(item.DataFrom + '/' + item.Asset_ID)}>{item.Asset_Project}</a></h4>
                        <span>{item.location}</span><div className="read-remark-sm"><i className="fa fa-eye" aria-hidden="true" /> ผู้ชม {item.Hit_Point} ครั้ง</div> 
                      </div>
                    </td>
                    <td className="utf-expire-date">{item.Update_Time}</td>
                    <td className="utf-expire-date">{item.Approve_Staus_Name}</td>
                    <td className="action">
                      <a href={"/asset?p=" + encrytion(item.DataFrom + '/' + item.Asset_ID)} className="view tooltip left" title="View"><i className="icon-feather-eye" /></a>
                      <a href={"edit-property?p=" + encrytion(item.Asset_ID)} className="edit tooltip left" title="Edit"><i className="icon-feather-edit" /></a> 
                      <a href="#" className="delete tooltip left" title="Delete" onClick={ async e => {
                        Swal.fire({
                          title: 'ลบข้อมูล?',
                          text: 'ข้อมูลที่ลบแล้วจะไม่สามารถแก้ไขได้',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'ตกลง',
                          cancelButtonText: 'ยกเลิก',
                        }).then(async (result) => {
                          if (result.isConfirmed){
                            let result1 = await httpClient.delete(server.ASSET_URL + '/' + encrytion(item.Asset_ID))
                            if(result1.result == 'ok'){
                              Swal.fire({
                                title: 'สำเร็จ',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'ตกลง',
                              }).then(async (result) => {
                                window.location.reload();
                              })
                            } else{
                              Swal.fire({
                                title: 'ผิดพลาด',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'ตกลง',
                              }).then(async (result) => {
                                // window.location.reload();
                              })
                            }
                          }
                        })
                      }}><i className="icon-feather-trash-2" /></a>
                    </td>
                  </tr>
      ))
    )
  }

  renderOptions = async (data) => {
    try {
      return (
        data != null &&
        (await data.map((item) => (
          <option key={item.CODE} value={item.CODE}>
            {item.NAME}
          </option>
        )))
      );
    } catch (error) {
    }
  };

  async handlePageChange(pageNumber) {
    await this.setState({activePage: pageNumber});
    let lists = null
    lists = await this.renderItems(this.state.listsAll.slice((pageNumber * 5) - 5, pageNumber * 5))
    this.setState({table_rows: lists})
    // this.myRef.current.scrollIntoView({behavior: 'smooth'});
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  render() {
    return (<div>

        <div className="parallax titlebar" data-background="images/visual_banner_desktop_2.jpg" 
             data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>ประกาศของฉัน</h2>
                  <nav id="breadcrumbs">
                    <ul>
                      <li><a href="home">หน้าแรก</a></li>
                      <li>ทรัพย์ของฉัน</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row"> 
            <div className="col-md-3">
              <UserMenu/>
            </div>
          <br />
            <div className="col-md-9">
              <div className="utf-main-search-box-area margin-bottom-10"> 
                <div className="row with-forms"> 
                  <div className="col-md-6 col-sm-12">
                    <input type="text" 
                           name="Asset_Project"
                           className="ico-01" 
                           placeholder="ชื่อ หรือ รหัสประกาศ..." 
                           value={this.state.Asset_Project}
                           onChange={this.handleUserInput}
                           />
                  </div>
                  <div className="col-md-3 col-sm-12">
                      <select
                        name="provinceSelceted"
                        data-placeholder="Province"
                        value={this.state.provinceSelceted}
                        onChange={this.handleUserInput}
                      >
                        {this.state.provincesOptions}
                      </select>         
                  </div>
                  <div className="col-md-3 col-sm-6">				 
                    <button className="button utf-search-btn-item"
                            onClick={() => {
                              this.searchMyAsset()
                            }}>
                      <i className="fa fa-search" /> ค้นหา
                    </button>	
                  </div>  
                </div>    
                <div> 
                    <div className="checkbox margin-top-0">
                      <input type="radio" id="t1" name="Approve_Status" value="" defaultChecked onChange={this.handleUserInput}/>
                      <label htmlFor="t1"><span className="checkbox-icon" /> ทั้งหมด</label>
                    </div>
                    <div className="checkbox margin-top-0">
                      <input type="radio" id="t2" name="Approve_Status" value="1" onChange={this.handleUserInput}/>
                      <label htmlFor="t2"><span className="checkbox-icon" /> ออนไลน์</label>
                    </div>
                    <div className="checkbox margin-top-0">
                      <input type="radio" id="t3" name="Approve_Status" value="-3" onChange={this.handleUserInput}/>
                      <label htmlFor="t3"><span className="checkbox-icon" /> หมดอายุ</label>
                    </div>
                    <div className="checkbox margin-top-0">
                      <input type="radio" id="t4" name="Approve_Status" value="2" onChange={this.handleUserInput}/>
                      <label htmlFor="t4"><span className="checkbox-icon" /> ปิดการขาย</label>
                    </div>
                </div>  
              </div>
              <div className="widget utf-sidebar-widget-item margin-bottom-0 margin-top-20"> 
                <div className="utf-boxed-list-headline-item">
                  <h3 style={{fontFamily: 'Kanit-Light'}}>จำนวนทรัพย์ที่พบ <NumberFormat
                                                                      value={this.state.listsCount}
                                                                      style={{color: 'red'}}
                                                                      displayType={"text"}
                                                                      thousandSeparator={true}
                                                                      renderText={(value, props) => <span {...props}>{value} </span> } 
                                                                    /> รายการ
                  </h3>
                </div>             
              </div>


            <blockquote class="margin-bottom-20">  <i class="icon-feather-edit text-red"></i> มีรายการแก้ไขเพิ่มเติม
              <table className="manage-table responsive-table">
                <tbody>
                  <tr>

                    <th>ประกาศของฉัน</th>
                    <th style={{ width: 300 }}>ลำดับ</th>
                    <th style={{ width: 800 }}>ชื่อทรัพย์</th>
                    <th style={{ width: 1000 }}>ดูรายละเอียด</th>
                  </tr>
                  {this.state.back_to_edit}
                </tbody></table>
            </blockquote>
              <table className="manage-table responsive-table">
                <tbody>
                  <tr>
           
                    <th>ประกาศของฉัน</th>
                    <th style={{width: 300}}>วันที่</th>
                    <th style={{width: 800}}>สถานะ</th>
                    <th style={{width: 1000}}>จัดการ</th>
                  </tr>
                  { this.state.table_rows }
                </tbody></table>



              {/* Pagination */}
              <div className="row">
              <div className="col-md-12" style={{display: this.state.listsCount < 6 ? 'none' : 'block'}} >
                  
                  <div className="utf-pagination-container margin-top-20">
                    <nav className="pagination" >
                      <ul>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={5}
                          totalItemsCount={this.state.listsCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                          activeLinkClass="current-page"
                          hideFirstLastPages={true}
                          hideDisabled={true}
                        />
                    </ul>
                    </nav>           
                  </div>
                  
                </div>
              </div>


              <center> <a href="add-new-property" className="utf-centered-button margin-top-30 button">เพิ่มประกาศใหม่</a> </center>   
            </div>

          </div>
        </div>
    </div>);
  }
}

export default MyProperties;
