import React, { Component } from 'react';
import UserMenu from './../user-menu/user-menu'
import {encrytion} from './../../utils/EnCryption';
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import Pagination from "react-js-pagination";
import NumberFormat from "react-number-format";

import './../my-properties/my-properties.css';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

class UserBookmarks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_rows: null,
      listsAll: null,
      listsCount: 1,
      activePage: 1,
    };
  }

  async componentDidMount() {

    document.title = 'รายการที่ชื่นชอบ | Clickthaihome.com'

    // let values = {Member_ID: localStorage.getItem('Member_ID')}
    let result = await httpClient.get(server.ASSET_URL + '/bookmarks/' + encrytion(localStorage.getItem('Member_ID')))
    await this.setState({listsAll: result.data, listsCount: result.data.length});
    await this.setState({table_rows: await this.renderItems(result.data)})
    this.handlePageChange(1)

  }

  

  renderItems = async (data) => {
    return (
      data!= null &&
      data.map(item => (
        <tr key={item.Asset_ID}>
                    <td className="utf-title-container" >
                      <div className="margin-right-30 text-center" style={{maxHeight: 100, height: 100, maxWidth: 200, width: 200}}>
                        <center>
                          <img src={item.image_name} style={{height: 100, width: 'auto'}}/>
                        </center>
                      </div> 
                      <div className="title">
                        <h4><a href={"/asset?p=" + encrytion(item.Data_From + '/' + item.Asset_ID)}>{item.Asset_Project}</a></h4>
                        <span>{item.location}</span>
                        <div className="read-remark-sm">
                          {
                            item.Asset_Price > 0 ? 
                            <NumberFormat
                                    className="table-property-price"
                                    value={item.Asset_Price}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    renderText={(value, props) => <span {...props}>{value} บาท</span>}
                            />
                          : 
                          <span className="table-property-price">โปรดติดต่อหน่วยงาน</span>
                          }
                        </div> 
                      </div>
                    </td>
                    <td className="action">
                      <a href="#" className="delete tooltip left" title="Delete" onClick={ async e => {
                        let values = {Member_ID: localStorage.getItem("Member_ID"), 
                                      Asset_ID: item.Asset_ID,
                                      Data_From: item.Data_From
                                    }
                        Swal.fire({
                          title: 'ลบข้อมูล?',
                          text: 'ข้อมูลที่ลบแล้วจะไม่สามารถแก้ไขได้',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'ตกลง',
                          cancelButtonText: 'ยกเลิก',
                        }).then(async (result) => {
                          if (result.isConfirmed){
                            let result1 = await httpClient.post(server.ASSET_URL + '/deletebookmarks', values)
                            if(result1.result == 'ok'){
                              Swal.fire({
                                title: 'สำเร็จ',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'ตกลง',
                              }).then(async (result) => {
                                window.location.reload();
                              })
                            } else{
                              Swal.fire({
                                title: 'ผิดพลาด',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'ตกลง',
                              }).then(async (result) => {

                              })
                            }
                          }
                          
                        })
                      }}><i className="icon-feather-trash-2" /></a>
                    </td>
                  </tr>


      ))
    )
  }



  async handlePageChange(pageNumber) {
    await this.setState({activePage: pageNumber});
    let lists = null
    lists = await this.renderItems(this.state.listsAll.slice((pageNumber * 5) - 5, pageNumber * 5))
    this.setState({table_rows: lists})
    // this.myRef.current.scrollIntoView({behavior: 'smooth'});
  }

  render() {
    return <div>
      <div className="parallax titlebar" data-background="images/visual_banner_desktop_2.jpg" 
             data-color="rgba(48, 48, 48, 1)" data-color-opacity="0.8" data-img-width={800} data-img-height={505}>
          <div id="titlebar">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>รายการที่ชื่นชอบ</h2>
                  <nav id="breadcrumbs">
                    <ul>
                      <li><a href="home">หน้าแรก</a></li>
                      <li>รายการที่ชื่นชอบ</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="container">
          <div className="row"> 
            <div className="col-md-3">
              <UserMenu/>
            </div>

            <div className="col-md-9">
              {/* <div className="widget utf-sidebar-widget-item margin-bottom-0 margin-top-20"> 
                <div className="utf-boxed-list-headline-item">
                  <h3 style={{fontFamily: 'Kanit-Light'}}>จำนวนทรัพย์ที่พบ <NumberFormat
                                                                      value={this.state.listsCount}
                                                                      style={{color: 'red'}}
                                                                      displayType={"text"}
                                                                      thousandSeparator={true}
                                                                      renderText={(value, props) => <span {...props}>{value} </span> } 
                                                                    /> รายการ
                  </h3>
                </div>             
              </div> */}



              <table className="manage-table responsive-table">
                <tbody>
                  <tr>
                    <th>รายการทรัพย์ที่ท่านชื่นชอบ</th>
                    <th></th>
                  </tr>
                  { this.state.table_rows }
                </tbody></table>



              {/* Pagination */}
              <div className="row">
              <div className="col-md-12" style={{display: this.state.listsCount < 6 ? 'none' : 'block'}} >
                  
                  <div className="utf-pagination-container margin-top-20">
                    <nav className="pagination" >
                      <ul>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={5}
                          totalItemsCount={this.state.listsCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                          activeLinkClass="current-page"
                          hideFirstLastPages={true}
                          hideDisabled={true}
                        />
                    </ul>
                    </nav>           
                  </div>
                  
                </div>
              </div> 

              <div className="margin-top-30"></div>
            </div>

          </div>
        </div>



    </div>;
  }
}

export default UserBookmarks;
